import { Backdrop, Box, Button, Divider, Fade, Grid, IconButton, List, ListItem, Tooltip, MenuItem, Modal, Paper, Stack, TextField, Typography, ListItemText, ListItemIcon } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchAndFilters from "../../../components/search_filters";
import { ConflictInterestList } from "../../../components/pagination_view";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styled from "@emotion/styled";
import { Article, Delete } from "@mui/icons-material";


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Item = ({ value, data, index, deleteDocument }) => {
    return (
        <ListItem
            key={index}
            sx={{ my: 1 }}
            component={Paper}
            elevation={1}
            secondaryAction={
                <>
                    <IconButton onClick={() => deleteDocument(index)}>
                        <Delete />
                    </IconButton>
                </>
            }
        >
            <ListItemIcon>
                <Article />
            </ListItemIcon>
            <ListItemText primary={value?.name} />
        </ListItem>
    );
};

export const ConflictInterest = () => {
    const [newConflict, setNewConflict] = useState(false);
    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        maxWidth: "calc(70%)",
        minWidth: "calc(60%)",
        boxShadow: 12,
        borderRadius: 1,
        p: 4,
    });
    const width = () => {
        // modal control
        if (window.screen.width < 900) {
            const copy = { ...style };
            copy.maxWidth = "95%";
            setStyle(copy);
        }

        if (window.screen.width > 1600) {
            return window.screen.width / 4
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.4
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const [natureOfInterest, setNatureOfInterest] = useState([
        {
            title: "Nature Of Interest",
            value: "Please Select Of Interest",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Please Select Of Interest",
                    selected: true
                },
                {
                    title: "Related to Client",
                    selected: false
                },
                {
                    title: "Supervisor/ Company Administrator",
                    selected: false
                },
                {
                    title: "Others",
                    selected: false
                },
            ]
        },
    ]);
    const [clientList, setClientList] = useState([
        {
            title: "Clients",
            value: "Please Select Of Client",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Please Select Of Client",
                    selected: true
                },
                {
                    title: "Salman",
                    selected: false
                },
                {
                    title: "Shahid",
                    selected: false
                },
                {
                    title: "Rehan",
                    selected: false
                },
                {
                    title: "Waseem",
                    selected: false
                },
            ]
        },
    ])
    const [detailts, setDetais] = useState([
        {
            title: "First Name",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false
        },
        {
            title: "Last Name",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false
        },
        {
            title: "Email",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false
        },
        {
            title: "Phone",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false
        },
        {
            title: "Role",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false
        }
    ]);

    const [conflictDescription, setConflictDescription] = useState("");
    const [linkToDocument, setLinkToDocument] = useState([]);
    const [comment, setComment] = useState("");

    const deleteDocument = (id) => {
        const copy = [...linkToDocument];
        const array = [];
        for (const key in copy) {
            if (key != id) {
                array.push(copy[key])
            }
        }
        setLinkToDocument(array)
    }

    const interest = (id, first_name, last_name, phone, email, role, related_to, nature_of_interest, conflict_description, date_of_disclosure, actions_taken, approval_status, link_to_document) => {
        return {
            id, first_name, last_name, phone, email, role, related_to, nature_of_interest, conflict_description, date_of_disclosure, actions_taken, approval_status, link_to_document
        };
    }

    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'First Name',
            numeric: false,
            disablePadding: true,
            label: 'First Name',
        },
        {
            id: 'Last Name',
            numeric: false,
            disablePadding: true,
            label: 'Last Name',
        },
        {
            id: 'Phone',
            numeric: false,
            disablePadding: true,
            label: 'Phone',
        },
        {
            id: 'Email',
            numeric: false,
            disablePadding: true,
            label: 'Email',
        },
        {
            id: 'Role',
            numeric: false,
            disablePadding: true,
            label: 'Role',
        },
        {
            id: 'Related To',
            numeric: false,
            disablePadding: true,
            label: 'Related To',
        },
        {
            id: 'Nature Of Interest',
            numeric: false,
            disablePadding: true,
            label: 'Nature Of Interest',
        },
        {
            id: 'Conflict Description',
            numeric: false,
            disablePadding: true,
            label: 'Conflict Description',
        },
        {
            id: 'Date Of Disclosure',
            numeric: false,
            disablePadding: true,
            label: 'Date Of Disclosure',
        },
        {
            id: 'Actions Taken',
            numeric: false,
            disablePadding: true,
            label: 'Actions Taken',
        },
        {
            id: 'Approval Status',
            numeric: false,
            disablePadding: true,
            label: 'Approval Status',
        },
        {
            id: 'Link To Document',
            numeric: false,
            disablePadding: true,
            label: 'Link To Document',
        },
        {
            id: 'Options',
            numeric: false,
            disablePadding: true,
            label: 'Options',
        }
    ];

    const [orgs, setOrgs] = useState([
        interest(1, "Abc Name", "Zxc Name", "0293659870", "admin@cennasupport.com", "Staff", "salman", "abc", "abc", "11-04-2024", "abc", "Pending", "my_uploaded_docs.pdf"),
        interest(2, "Abc Name", "Zxc Name", "0293659870", "admin@cennasupport.com", "Staff", "other", "abc", "abc", "11-04-2024", "abc", "Pending", "my_uploaded_docs.pdf"),
        interest(3, "Abc Name", "Zxc Name", "0293659870", "admin@cennasupport.com", "Staff", "supervisor", "abc", "abc", "11-04-2024", "abc", "Pending", "my_uploaded_docs.pdf"),
        interest(4, "Abc Name", "Zxc Name", "0293659870", "admin@cennasupport.com", "Staff", "supervisor", "abc", "abc", "11-04-2024", "abc", "Pending", "my_uploaded_docs.pdf"),
        interest(5, "Abc Name", "Zxc Name", "0293659870", "admin@cennasupport.com", "Staff", "other", "abc", "abc", "11-04-2024", "abc", "Pending", "my_uploaded_docs.pdf"),
    ]);

    return (
        <Grid container sx={{ display: "flex", justifyContent: "center", my: 2, marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, }}>
            <Grid item={true} xs={11} md={11} lg={11} sx={{ display: "flex", flexDirection: { lg: "row", xs: "column", md: "row", sm: "column" }, }}>
                <Grid item={true} xs={12} sm={12} md={6} lg={6} sx={{ display: "flex", alignItems: "center", flexWrap: 'wrap', justifyContent: { md: "flex-start", lg: "flex-start", xs: "center" } }}>
                    <Button onClick={() => {
                        setNewConflict(true);
                    }} variant="outlined" sx={{ m: 1, fontFamily: "Roboto-Medium", textTransform: "capitalize" }}>
                        Add Conflict Of Interest
                    </Button>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} lg={6} >
                    <SearchAndFilters groupBy={false} favorite={false} inputWidth={8} defaultState={false} />
                </Grid>
            </Grid>
            <Grid item={true} xs={11} sm={12} md={11} lg={11}>
                <ConflictInterestList rows={orgs} headCells={headCells} />
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={newConflict}
                onClose={() => setNewConflict(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={newConflict}>
                    <Box sx={style}>
                        <Box>
                            <Typography variant="h5" component="h2" sx={{ fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                                Create New Conflict
                            </Typography>
                        </Box>
                        <Box sx={{ my: 2 }}>
                            <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap', }}>
                                <List sx={{ m: 1, width: '90%', bgcolor: 'background.paper' }}>
                                    {linkToDocument.map((v, i) => {
                                        return (
                                            <Item key={i} value={v[0]} index={i} deleteDocument={deleteDocument} />
                                        )
                                    })}
                                </List>
                                {natureOfInterest.map((v, i) => {
                                    return (
                                        <div key={v.title}>
                                            <TextField
                                                select={true}
                                                label={v.title}
                                                htmlFor={v.title}
                                                required={v.required}
                                                sx={{ m: 1, width: v.width }}
                                                value={v.value}
                                                onChange={(e) => {
                                                    const copy = [...natureOfInterest];
                                                    if (e.target.value !== "Please Select Of Interest") {
                                                        copy[i].value = e.target.value;
                                                        setNatureOfInterest(copy);
                                                    }
                                                }}
                                            >
                                                {v?.options?.map((nV, nI) => (
                                                    <MenuItem key={nI} value={nV.title} >
                                                        {nV.title}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            {v.value.includes("Client") && clientList.map((vN, iN) => (
                                                <TextField
                                                    key={iN}
                                                    select={true}
                                                    label={vN.title}
                                                    htmlFor={vN.title}
                                                    required={vN.required}
                                                    sx={{ m: 1, width: vN.width }}
                                                    value={vN.value}
                                                    onChange={(e) => {
                                                        const copy = [...clientList];
                                                        if (e.target.value !== "Please Select Of Client") {
                                                            copy[i].value = e.target.value;
                                                            setClientList(copy);
                                                        }
                                                    }}
                                                >
                                                    {vN?.options?.map((nV, nI) => (
                                                        <MenuItem key={nI} value={nV.title} >
                                                            {nV.title}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            ))}
                                        </div>
                                    )
                                })}
                                <Button sx={{ m: 1, width: screenWidth, padding: "15px 16px" }} component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
                                    Link to Document
                                    <VisuallyHiddenInput type="file" onChange={(e) => {
                                        const copy = [...linkToDocument];
                                        copy.push(e.target.files)
                                        setLinkToDocument(copy)
                                    }} />
                                </Button>
                                <TextField
                                    label={"Conflict Description"}
                                    multiline={true}
                                    value={conflictDescription}
                                    onChange={(e) => setConflictDescription(e.target.value)}
                                    sx={{ m: 1, width: screenWidth * 2.04 }}
                                />
                            </Grid>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained" >
                                    Save
                                </Button>
                                <Button variant="contained" onClick={() => setNewConflict(false)}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Grid>
    )
}