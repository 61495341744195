import { Alert, AlertTitle, Backdrop, Box, Button, CircularProgress, Fade, Grid, MenuItem, Modal, Paper, Slide, Snackbar, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchAndFilters from "../../../components/search_filters";
import { AuditListTableView } from "../../../components/pagination_view";
import { CustomModal, EditListItemModal } from "./handle_modal";
import _ from "lodash";
import { AuditCategoryList, AuditDocumentList, AuditDocumentNameList, AuditModuleList, AuditTypeList, CreateNewCategory, CreateNewDocument, CreateNewDocumentName, CreateNewModule, CreateNewType } from "../../../services/admin_service";

const SlideTransition = (props) => {
    return <Slide {...props} direction="left" />;
}

export const AuditSetupList = () => {
    const [screenWidth, setScreenWidth] = useState();
    useEffect(() => {
        const width = () => {
            if (window.screen.width > 1600) {
                return window.screen.width / 4.5
            }
            if (window.screen.width >= 1200 && window.screen.width <= 1600) {
                return window.screen.width / 5
            }
            if (window.screen.width > 600 && window.screen.width < 1600) {
                return window.screen.width / 4
            }
            if (window.screen.width < 600) {
                return window.screen.width
            }
        }
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);
    const [handleOptions, setHandleOptions] = useState("");
    const [categoryInputs, setCategoryInputs] = useState([
        {
            title: "Select Category",
            width: screenWidth,
            value: "Please select category",
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Please select category"
                },
                // {
                //     title: "Company",
                //     description: "Some Company Data"
                // },
                // {
                //     title: "Staff",
                //     description: "Some Staff Data"
                // },
                // {
                //     title: "Client",
                //     description: "Some Client Data"
                // },
                // {
                //     title: "Accommodations",
                //     description: "Some Accommodations Data"
                // },
            ]
        },
    ]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [newModuleModal, setNewModuleModal] = useState(false);
    const handleModal = () => {
        setNewModuleModal(false);
        setSelectedCategory("");
    };
    const [typesInputs, setTypesInputs] = useState([
        {
            title: "Types",
            width: screenWidth,
            value: "Please select type",
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Please select type"
                },
                // {
                //     title: "Policies",
                //     description: "Some Policies Data"
                // },
                // {
                //     title: "Registers",
                //     description: "Some Registers Data"
                // },
                // {
                //     title: "Miscellaneous",
                //     description: "Some Miscellaneous Data"
                // },
                // {
                //     title: "Checks",
                //     description: "Some Checks Data"
                // },
                // {
                //     title: "Trainings and Modules",
                //     description: "Some Trainings and Modules Data"
                // },
                // {
                //     title: "Registers",
                //     description: "Some Registers Data"
                // },
                // {
                //     title: "Records",
                //     description: "Some Records Data"
                // },
                // {
                //     title: "Signed Documents",
                //     description: "Some Signed Documents Data"
                // },
                // {
                //     title: "Other Documents",
                //     description: "Some Other Documents Data"
                // },
                // {
                //     title: "Letter",
                //     description: "Some Letter Data"
                // },
                // {
                //     title: "Certificate",
                //     description: "Some Certificate Data"
                // },
            ]
        },
    ]);
    const [documentNameInputs, setDocumentNameInputs] = useState([
        {
            title: "Document Name",
            width: screenWidth,
            value: "Please select Document Name",
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Please select Document Name"
                },
            ]
        },
    ]);
    const [selectedTypes, setSelectedTypes] = useState("");
    const [documentDescription, setDocumentDescription] = useState("");
    const [editModal, setEditModal] = useState(false);
    const [editItem, setEditItem] = useState(null);
    // /// // // // get dynamic data from server
    const [_auditList, loadingList] = AuditDocumentList();
    const [_auditModuleList, loadingMList] = AuditModuleList();
    const [_auditCategoryList, loadingCList] = AuditCategoryList();
    const [_auditTypeList, loadingTList] = AuditTypeList();
    const [_auditDocumentList, loadingDList] = AuditDocumentNameList();
    // /// /// /// // create new data by query
    const [create_new_document, response_doc, loading_doc, error_doc] = CreateNewDocument();
    const [create_new_module, response_mod, loading_mod, error_mod] = CreateNewModule()
    const [create_new_category, response_cat, loading_cat, error_cat] = CreateNewCategory()
    const [create_new_type, response_type, loading_type, error_type] = CreateNewType()
    const [create_new_document_name, response_tdc_name, loading_dc_name, error_dc_name] = CreateNewDocumentName()

    const [snacks, setSnack] = useState({
        open: false,
        Transition: SlideTransition,
        vertical: 'top',
        horizontal: 'right',
    });

    const [errorSnackMessage, setErrorSnackMessage] = useState({
        title: "",
        message: "",
        severity: "",
        variant: "filled"
    });

    const [coreModuleCategory, setCoreModuleCategory] = useState([
        {
            title: "Core Module Category",
            width: screenWidth,
            value: "Please select option",
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Please select option",
                    description: "",
                    selected: true
                },
                {
                    title: "Policies",
                    titleInput: "Please select file name",
                    description: "",
                    selected: false,
                    value: "",
                    childOption: [
                        { title: "Cultural awareness policy", },
                        { title: "Privacy Policy", },
                        { title: "Confidentiality Policy", },
                        { title: "Legal and Human Rights Policy", },
                        { title: "Advocacy Policy", },
                        { title: "Incidents/ Complaints Policy", },
                        { title: "Prevention of Violence, Abuse, Neglect, Exploitation and Discrimination Policy", },
                        { title: "Conflict of Interest Policy", },
                        { title: "Disaster Planning Policy", },
                        { title: "Infection Control Policy", },
                        { title: "Data Breach Policy", },
                        { title: "Complaints Policy", },
                        { title: "Incident Policy(incl Reportable Incidents)", },
                        { title: "Scheduling Policy", },
                        { title: "Money handling Policy", },
                        { title: "Medication Policy ", },
                        { title: "Mealtime Management Policy", },
                        { title: "Waste Management Policy", },
                    ]
                },
                {
                    title: "Registers",
                    description: "",
                    selected: false,
                    value: "",
                    childOption: [
                        { title: "Conflict of Interest Register" },
                        { title: "Complaints Register" },
                        { title: "Feedback Register" },
                        { title: "Staff induction plan and register" },
                        { title: "Incident Register" },
                    ]
                },
                {
                    title: "Miscellaneous",
                    description: "",
                    selected: false,
                    value: "",
                    childOption: [
                        { title: "Minutes of meetings where complaints are discussed and recorded" },
                        { title: "Organization chart" },
                    ]
                },

            ]
        }
    ]);

    const [coreModule, setCoreModule] = useState([
        {
            title: "Core Module",
            width: screenWidth,
            value: "Please select option",
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Please select option",
                    description: "",
                    selected: true,
                },
                {
                    title: "Organization",
                    description: "",
                    selected: false,
                    childOption: _.cloneDeep(coreModuleCategory)
                },
                {
                    title: "Staff",
                    description: "",
                    selected: false,
                    childOption: _.cloneDeep()
                },
                {
                    title: "Clients",
                    description: "",
                    selected: false
                },
                {
                    title: "Accommodations",
                    description: "",
                    selected: false
                },
            ]
        }
    ]);
    const [moduleInputs, setModuleInputs] = useState([
        {
            title: "Modules",
            width: screenWidth,
            value: "Pleaes select module",
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Pleaes select module",
                },
                // {
                //     title: "Core Module",
                //     description: "Some Core Data",
                //     childOption: _.cloneDeep(coreModule)
                // },
                // {
                //     title: "Module 1",
                //     description: "Some Module Data",
                //     childOption: _.cloneDeep()
                // },
                // {
                //     title: "Module 2",
                //     description: "Some Module Data",
                //     childOption: _.cloneDeep()
                // },
                // {
                //     title: "Module 3",
                //     description: "Some Module Data",
                //     childOption: _.cloneDeep()
                // },
                // {
                //     title: "Module 4",
                //     description: "Some Module Data",
                //     childOption: _.cloneDeep()
                // },
                // {
                //     title: "Module 5",
                //     description: "Some Module Data",
                //     childOption: _.cloneDeep()
                // },
            ]
        }
    ]);
    const [docFormat, setDocFormat] = useState([
        {
            title: "Document Format",
            width: screenWidth,
            value: "Optional",
            type: "drop_down",
            required: true,
            options: [
                { title: ".doc" },
                { title: ".docx" },
                { title: ".xlsx" },
                { title: ".pdf" },
                { title: ".JPEG" },
                { title: ".PNG" },
                { title: "Optional" },
            ]
        },
    ])
    const [createModule, setCreateModule] = useState([
        {
            title: "Create New Module",
            width: screenWidth,
            value: "",
            type: "text",
            required: true,
            multiline: false,
        },
        {
            title: "Description ",
            width: screenWidth,
            value: "",
            type: "text",
            required: false,
            multiline: true,
        },
        {
            title: "Active",
            width: screenWidth,
            value: "True",
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "True",
                },
                {
                    title: "False",
                },
            ]
        }
    ])
    const [createCategory, setCreateCategory] = useState([
        {
            title: "Create New Category",
            width: screenWidth,
            value: "",
            type: "text",
            required: true,
            multiline: false,
        },
        {
            title: "Description",
            width: screenWidth,
            value: "",
            type: "text",
            required: false,
            multiline: true,
        },
        {
            title: "Active",
            width: screenWidth,
            value: "True",
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "True",
                },
                {
                    title: "False",
                },
            ]
        }
    ])
    const [createTypes, setCreateTypes] = useState([
        {
            title: "Create New Type",
            width: screenWidth,
            value: "",
            type: "text",
            required: true,
            multiline: false,
        },
        {
            title: "Description",
            width: screenWidth,
            value: "",
            type: "text",
            required: false,
            multiline: true,
        },
        {
            title: "Active",
            width: screenWidth,
            value: "True",
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "True",
                },
                {
                    title: "False",
                },
            ]
        }
    ])
    const [createDocName, setCreateDocName] = useState([
        {
            title: "Create New Document",
            width: screenWidth,
            value: "",
            type: "text",
            required: true,
            multiline: false,
        },
        {
            title: "Description",
            width: screenWidth,
            value: "",
            type: "text",
            required: false,
            multiline: true,
        },
        {
            title: "File Type",
            width: screenWidth,
            value: "JPG, JPEG, PNG, PDF",
            type: "text",
            required: false,
            multiline: false,
        },
        {
            title: "Active",
            width: screenWidth,
            value: true,
            type: "switch",
            required: false,
            multiline: false,
        },
        {
            title: "Active",
            width: screenWidth,
            value: "True",
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "True",
                },
                {
                    title: "False",
                },
            ]
        }
    ])
    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: false,
            label: 'Id',
        },
        {
            id: 'Module',
            numeric: false,
            disablePadding: false,
            label: 'Module',
        },
        {
            id: 'Category',
            numeric: false,
            disablePadding: false,
            label: 'Category',
        },
        {
            id: 'Types',
            numeric: false,
            disablePadding: false,
            label: 'Types',
        },
        {
            id: 'Document Name',
            numeric: false,
            disablePadding: false,
            label: 'Document Name',
        },
        {
            id: 'Reminder (6 Monthly)',
            numeric: false,
            disablePadding: false,
            label: 'Reminder (6 Monthly)',
        },
        {
            id: 'Options',
            numeric: false,
            disablePadding: false,
            label: 'Options',
        },
    ];

    const Audits = (id, module, category, types, document_name, options) => {
        return {
            id, module, category, types, document_name, options
        };
    };

    const [rows, setRows] = useState([]);

    const createNewDocumentByQuery = async (obj) => {
        try {
            const response = await create_new_document({
                variables: obj
            })
            setSnack({
                ...snacks,
                open: true,
            });
            if (response?.data) {
                // console.log(response?.data?.createAuditDocument?.data?.attributes)
                setErrorSnackMessage({
                    ...errorSnackMessage,
                    title: response?.data?.createAuditDocument?.data?.attributes?.audit_document_name?.data?.attributes?.title,
                    message: "New Audit Document Created.!",
                    severity: "success"
                })
                setNewModuleModal(false);
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error)
            setErrorSnackMessage({
                ...errorSnackMessage,
                title: error?.response?.data?.error?.name,
                message: error?.response?.data?.error?.message,
                severity: "error"
            })
        }
    }

    const createNewDocument = () => {
        const obj = {
            audit_module: _auditModuleList?.find((e) => e.attributes.title === moduleInputs[0].value)?.id,
            audit_category: _auditCategoryList?.find((e) => e.attributes.title === categoryInputs[0].value)?.id,
            audit_type: _auditTypeList?.find((e) => e.attributes.title === typesInputs[0].value)?.id,
            audit_document_name: _auditDocumentList?.find((e) => e.attributes.title === documentNameInputs[0].value)?.id,
            document_description: documentDescription,
            company_id: "1",
            active: true,
            reminder: true,
        }
        console.log(obj)
        createNewDocumentByQuery(obj);
    };

    const createNewModuleQuery = async (obj) => {
        try {
            const response = await create_new_module({
                variables: obj
            })
            setSnack({
                ...snacks,
                open: true,
            });
            if (response?.data) {
                // console.log(response?.data?.createAuditModule?.data?.attributes)
                setErrorSnackMessage({
                    ...errorSnackMessage,
                    title: response?.data?.createAuditModule?.data?.attributes?.title,
                    message: "New Audit Module Created.!",
                    severity: "success"
                })
                setNewModuleModal(false);
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error)
            setErrorSnackMessage({
                ...errorSnackMessage,
                title: error?.response?.data?.error?.name,
                message: error?.response?.data?.error?.message,
                severity: "error"
            })
        }
    }

    const createNewModule = () => {
        const copy = [...createModule];
        const obj = {
            title: createModule[0].value,
            description: createModule[1].value,
            active: createModule[2].value,
            company_id: "1"
        }
        createModule[0].value = "";
        createModule[1].value = "";
        setCreateModule(copy);
        createNewModuleQuery(obj)
    };

    const createNewCategoryQuery = async (obj) => {
        try {
            const response = await create_new_category({
                variables: obj
            })
            setSnack({
                ...snacks,
                open: true,
            });
            if (response?.data) {
                // console.log(response?.data?.createAuditCategory?.data?.attributes)
                setErrorSnackMessage({
                    ...errorSnackMessage,
                    title: response?.data?.createAuditCategory?.data?.attributes?.title,
                    message: "New Audit Category Created.!",
                    severity: "success"
                })
                setNewModuleModal(false);
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error)
            setErrorSnackMessage({
                ...errorSnackMessage,
                title: error?.response?.data?.error?.name,
                message: error?.response?.data?.error?.message,
                severity: "error"
            })
        }
    }

    const createNewCategory = () => {
        const copy = [...createCategory];
        const obj = {
            title: createCategory[0].value,
            description: createCategory[1].value,
            active: createCategory[2].value,
            company_id: "1"
        };
        createCategory[0].value = "";
        createCategory[1].value = "";
        setCreateCategory(copy);
        createNewCategoryQuery(obj)
    };

    const createNewTypeQuery = async (obj) => {
        try {
            const response = await create_new_type({
                variables: obj
            });
            setSnack({
                ...snacks,
                open: true,
            });
            if (response?.data) {
                // console.log(response?.data?.createAuditType?.data?.attributes)
                setErrorSnackMessage({
                    ...errorSnackMessage,
                    title: response?.data?.createAuditType?.data?.attributes?.title,
                    message: "New Audit Type Created.!",
                    severity: "success"
                })
                setNewModuleModal(false);
            } else {
                throw response
            }
        } catch (error) {
            console.log(error)
            setErrorSnackMessage({
                ...errorSnackMessage,
                title: error?.response?.data?.error?.name,
                message: error?.response?.data?.error?.message,
                severity: "error"
            })
        }
    }

    const createNewType = () => {
        const copy = [...createTypes];
        const obj = {
            title: createTypes[0].value,
            description: createTypes[1].value,
            active: createTypes[2].value,
            company_id: "1"
        };
        createTypes[0].value = "";
        createTypes[1].value = "";
        setCreateTypes(copy);
        createNewTypeQuery(obj)
    };

    const createNewDocumentNameQuery = async (obj) => {
        try {
            const response = await create_new_document_name({
                variables: obj
            })
            setSnack({
                ...snacks,
                open: true,
            });
            if (response?.data) {
                // console.log(response?.data?.createAuditDocumentName?.data?.attributes)
                setErrorSnackMessage({
                    ...errorSnackMessage,
                    title: response?.data?.createAuditDocumentName?.data?.attributes?.title,
                    message: "New Audit Type Created.!",
                    severity: "success"
                })
                setNewModuleModal(false);
            } else {
                throw response
            }
        } catch (error) {
            console.log(error)
            setErrorSnackMessage({
                ...errorSnackMessage,
                title: error?.response?.data?.error?.name,
                message: error?.response?.data?.error?.message,
                severity: "error"
            })
        }
    };

    const createNewDocumentName = () => {
        const copy = [...createDocName];
        const obj = {
            title: createDocName[0].value,
            description: createDocName[1].value,
            type: createDocName[2].value,
            active: createDocName[3].value,
            company_id: "1",
        };
        createDocName[0].value = "";
        createDocName[1].value = "";
        setCreateDocName(copy);
        createNewDocumentNameQuery(obj);
    };

    const editListItem = (index) => {
        // setEditItem(index)
        // module[0].value = rows[index].module;
        // category[0].value = rows[index].categoryInputs;
        // types[0].value = rows[index].types;
        // setDocumentName(rows[index].document_name);
        setEditModal(true)
    };

    const saveEditedListItem = (index) => {
        // const copy = [...rows];
        // copy[index].module = module[0].value;
        // copy[index].category = category[0].value;
        // copy[index].types = types[0].value;
        // copy[index].document_name = documentName;
        // setRows(copy)
        setEditModal(false)
    };

    useEffect(() => {
        if (_auditList) {
            const finalizedAarray = [];
            for (const key in _auditList) {
                const title = _auditList[key]?.attributes?.audit_module?.data?.attributes?.title;
                const active = _auditList[key]?.attributes?.audit_module?.data?.attributes?.active;
                const titleCategory = _auditList[key]?.attributes?.audit_category?.data?.attributes?.title;
                const activeCategory = _auditList[key]?.attributes?.audit_category?.data?.attributes?.active;
                const titleType = _auditList[key]?.attributes?.audit_type?.data?.attributes?.title;
                const activeType = _auditList[key]?.attributes?.audit_type?.data?.attributes?.active;
                const docTitle = _auditList[key]?.attributes?.audit_document_name?.data?.attributes?.title
                const docActive = _auditList[key]?.attributes?.audit_document_name?.data?.attributes?.active
                const finalObject = {
                    id: key,
                    module: {
                        title: title,
                        active: active
                    },
                    category: {
                        title: titleCategory,
                        active: activeCategory
                    },
                    types: {
                        title: titleType,
                        active: activeType
                    },
                    document_name: {
                        title: docTitle,
                        active: docActive,
                    },
                    company_name: _auditList[key]?.attributes?.company_id?.data?.attributes?.company_name
                }

                finalizedAarray.push(finalObject)

            };
            setRows(finalizedAarray);
        }
    }, [_auditList]);

    useEffect(() => {
        if (_auditModuleList) {
            const copy = [...moduleInputs];
            for (const key in _auditModuleList) {
                const obj = _auditModuleList[key]?.attributes;
                copy[0].options.push(obj);
            }
            setModuleInputs(copy);
        }
    }, [_auditModuleList])

    useEffect(() => {
        if (_auditCategoryList) {
            const copy = [...categoryInputs];
            for (const key in _auditCategoryList) {
                const obj = _auditCategoryList[key]?.attributes;
                copy[0].options.push(obj);
            }
            setCategoryInputs(copy);
        }
    }, [_auditCategoryList])

    useEffect(() => {
        if (_auditTypeList) {
            const copy = [...typesInputs];
            for (const key in _auditTypeList) {
                const obj = _auditTypeList[key]?.attributes;
                copy[0].options.push(obj);
            }
            setTypesInputs(copy);
        }
    }, [_auditTypeList])

    useEffect(() => {
        if (_auditDocumentList) {
            const copy = [...documentNameInputs];
            for (const key in _auditDocumentList) {
                const obj = _auditDocumentList[key]?.attributes;
                copy[0].options.push(obj);
            }
            setDocumentNameInputs(copy);
        }
    }, [_auditDocumentList])

    const handleCloseSnacks = () => {
        setSnack({
            ...snacks,
            open: false,
        });
    };

    return (
        <Grid container sx={{ display: "flex", justifyContent: "center", my: 1, marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, }}>
            <Grid item={true} xs={11.5} md={12} lg={11} sx={{ display: "flex", flexDirection: { lg: "row", xs: "column", md: "row", sm: "column" }, }}>
                <Grid item={true} xs={12} sm={12} md={6} lg={6} sx={{ display: "flex", alignItems: "center", flexWrap: 'wrap', justifyContent: { md: "flex-start", lg: "flex-start", xs: "center" } }}>
                    <Stack direction={"row"} spacing={2}>
                        <Button onClick={() => {
                            setNewModuleModal(true);
                            setHandleOptions("Document");
                        }}
                            variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Add Document
                        </Button>
                        <Button onClick={() => {
                            setNewModuleModal(true);
                            setHandleOptions("Module");
                        }}
                            variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Add Module
                        </Button>
                        <Button onClick={() => {
                            setNewModuleModal(true);
                            setHandleOptions("Category");
                        }}
                            variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Add Category
                        </Button>
                        <Button onClick={() => {
                            setNewModuleModal(true);
                            setHandleOptions("Types");
                        }}
                            variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Add Types
                        </Button>
                        <Button onClick={() => {
                            setNewModuleModal(true);
                            setHandleOptions("Doc Name");
                        }}
                            variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Add Document Name
                        </Button>
                    </Stack>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} lg={6} >
                    <SearchAndFilters groupBy={false} favorite={false} inputWidth={8} />
                </Grid>
            </Grid>
            <Grid item={true} xs={11.5} md={11.5} lg={11} >
                {loadingList ? (
                    <Box
                        sx={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                        }}
                    >
                        <CircularProgress color="secondary" size={50} />
                    </Box>
                ) : null}
                {rows.length ? <AuditListTableView headCells={headCells} rows={rows} editListItem={editListItem} /> : null}
            </Grid>
            {/* handle modal */}
            <CustomModal
                handleOptions={handleOptions}
                screenWidth={screenWidth}
                newModuleModal={newModuleModal}
                handleModal={handleModal}
                module={_auditModuleList}
                moduleInputs={moduleInputs}
                setModule={setModuleInputs}
                category={_auditCategoryList}
                categoryInputs={categoryInputs}
                setCategory={setCategoryInputs}
                setSelectedCategory={setSelectedCategory}
                types={_auditTypeList}
                typesInputs={typesInputs}
                setTypes={setTypesInputs}
                setSelectedTypes={setSelectedTypes}
                _auditDocumentList={_auditDocumentList}
                documentDescription={documentDescription}
                setDocumentDescription={setDocumentDescription}
                docFormat={docFormat}
                setDocFormat={setDocFormat}
                createModule={createModule}
                setCreateModule={setCreateModule}
                createCategory={createCategory}
                setCreateCategory={setCreateCategory}
                createTypes={createTypes}
                setCreateTypes={setCreateTypes}
                createNewDocument={createNewDocument}
                createNewModule={createNewModule}
                createNewCategory={createNewCategory}
                createNewType={createNewType}
                createDocName={createDocName}
                setCreateDocName={setCreateDocName}
                createNewDocumentName={createNewDocumentName}
                documentNameInputs={documentNameInputs}
                setDocumentNameInputs={setDocumentNameInputs}
                loading_dc_name={loading_dc_name}
                loading_type={loading_type}
                loading_doc={loading_doc}
                loading_mod={loading_mod}
                loading_cat={loading_cat}
            />
            {/* edit list data */}
            <EditListItemModal
                editItem={editItem}
                screenWidth={screenWidth}
                editModal={editModal}
                module={_auditModuleList}
                moduleInputs={moduleInputs}
                setEditModal={setEditModal}
                setModule={setModuleInputs}
                category={_auditCategoryList}
                categoryInputs={categoryInputs}
                setCategory={setCategoryInputs}
                setSelectedCategory={setSelectedCategory}
                types={_auditTypeList}
                typesInputs={typesInputs}
                setTypes={setTypesInputs}
                setSelectedTypes={setSelectedTypes}
                documentDescription={documentDescription}
                setDocumentDescription={setDocumentDescription}
                saveEditedListItem={saveEditedListItem}
            />

            {/* messages */}
            <Snackbar open={snacks.open} autoHideDuration={3000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
                <Alert
                    onClose={handleCloseSnacks}
                    severity={errorSnackMessage.severity}
                    variant={errorSnackMessage.variant}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{errorSnackMessage.title}</AlertTitle>
                    {errorSnackMessage.message}
                </Alert>
            </Snackbar>
        </Grid >
    )
}