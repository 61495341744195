import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import React, { useState } from "react";
import SearchAndFilters from "../../../components/search_filters";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Upload } from '@mui/icons-material';


export const InternalAudit = () => {
    const [auditHistory, setAuditHistory] = useState([
        {
            name: "Dr.Luqman",
            type: "staff",
            status: "active",
            expend: false,
            audits: [
                {
                    module: "core",
                    category: "Compnay",
                    type: "Policies",
                    document: "privacu_policy.doc",
                    validDate: "22-09-2028",
                    validFlag: true,
                    available: true
                },
                {
                    module: "core",
                    category: "Compnay",
                    type: "Policies",
                    document: "privacu_policy.doc",
                    validDate: "22-09-2023",
                    validFlag: false,
                    available: false
                },
                {
                    module: "core",
                    category: "Compnay",
                    type: "Policies",
                    document: "privacu_policy.doc",
                    validDate: "22-09-2028",
                    validFlag: true,
                    available: true
                }
            ]
        },
        {
            name: "Dr.Salman",
            type: "staff",
            status: "active",
            expend: false,
            audits: [
                {
                    module: "core",
                    category: "Compnay",
                    type: "Policies",
                    document: "privacu_policy.doc",
                    validDate: "22-09-2028",
                    validFlag: true,
                    available: true
                },
                {
                    module: "core",
                    category: "Compnay",
                    type: "Policies",
                    document: "privacu_policy.doc",
                    validDate: "22-09-2023",
                    validFlag: false,
                    available: false
                },
                {
                    module: "core",
                    category: "Compnay",
                    type: "Policies",
                    document: "privacu_policy.doc",
                    validDate: "22-09-2028",
                    validFlag: true,
                    available: true
                }
            ]
        },
        {
            name: "Rameez",
            type: "Client",
            status: "active",
            expend: false,
            audits: [
                {
                    module: "core",
                    category: "Compnay",
                    type: "Policies",
                    document: "privacu_policy.doc",
                    validDate: "22-09-2028",
                    validFlag: true,
                    available: true
                },
                {
                    module: "core",
                    category: "Compnay",
                    type: "Policies",
                    document: "privacu_policy.doc",
                    validDate: "22-09-2023",
                    validFlag: false,
                    available: true
                },
                {
                    module: "core",
                    category: "Compnay",
                    type: "Policies",
                    document: "privacu_policy.doc",
                    validDate: "22-09-2028",
                    validFlag: true,
                    available: true
                }
            ]
        },
        {
            name: "Yasir",
            type: "Client",
            status: "active",
            expend: false,
            audits: [
                {
                    module: "core",
                    category: "Compnay",
                    type: "Policies",
                    document: "privacu_policy.doc",
                    validDate: "22-09-2028",
                    validFlag: true,
                    available: true
                },
                {
                    module: "core",
                    category: "Compnay",
                    type: "Policies",
                    document: "privacu_policy.doc",
                    validDate: "22-09-2023",
                    validFlag: false,
                    available: true
                },
                {
                    module: "core",
                    category: "Compnay",
                    type: "Policies",
                    document: "privacu_policy.doc",
                    validDate: "22-09-2028",
                    validFlag: true,
                    available: true
                }
            ]
        }
    ]);

    const handleClick = (index, value) => {
        const copy = [...auditHistory];
        for (let key in copy) {
            copy[key].expend = false;
        }
        copy[index].expend = !value;
        setAuditHistory(copy)
    };
    return (
        <Grid container sx={{ display: "flex", justifyContent: "center", my: 1, marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, }}>
            <Grid item={true} xs={11.5} md={11} lg={11}>
                <SearchAndFilters groupBy={false} favorite={false} />
            </Grid>
            <Grid item={true} xs={11.5} md={11} lg={11} sx={{ px: 1 }}>
                <Grid item={true} xs={12} md={12} lg={12} component={Paper} elevation={1} sx={{ my: 1, }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1em", fontFamily: "Roboto-Bold" }}>Id</TableCell>
                                    <TableCell style={{ fontSize: "1em", fontFamily: "Roboto-Bold" }}>Name</TableCell>
                                    {/* <TableCell style={{ fontSize: "1em", fontFamily: "Roboto-Bold" }}>Status</TableCell> */}
                                    <TableCell style={{ fontSize: "1em", fontFamily: "Roboto-Bold" }}>Module</TableCell>
                                    <TableCell style={{ fontSize: "1em", fontFamily: "Roboto-Bold" }}>Category</TableCell>
                                    <TableCell style={{ fontSize: "1em", fontFamily: "Roboto-Bold" }}>Type</TableCell>
                                    <TableCell style={{ fontSize: "1em", fontFamily: "Roboto-Bold" }}>Document Name</TableCell>
                                    <TableCell style={{ fontSize: "1em", fontFamily: "Roboto-Bold" }}>Available</TableCell>
                                    <TableCell style={{ fontSize: "1em", fontFamily: "Roboto-Bold" }}>Expiry Date</TableCell>
                                    <TableCell style={{ fontSize: "1em", fontFamily: "Roboto-Bold" }}>Up To Date</TableCell>
                                    <TableCell style={{ fontSize: "1em", fontFamily: "Roboto-Bold" }}>Options</TableCell>
                                </TableRow>
                            </TableHead>
                            {auditHistory?.map((v, i) => {
                                return (
                                    <TableBody key={i} >
                                        {v.audits.map((row, i) => (
                                            <TableRow key={i} >
                                                <TableCell>{i + 1}</TableCell>
                                                <TableCell>{v.name}</TableCell>
                                                {/* <TableCell>{v.type}</TableCell> */}
                                                <TableCell>{row.module}</TableCell>
                                                <TableCell>{row.category}</TableCell>
                                                <TableCell>{row.type}</TableCell>
                                                <TableCell>{row.document}</TableCell>
                                                <TableCell>{row.available ? <CheckIcon sx={{ color: "green" }} /> : <CloseIcon sx={{ color: "red" }} />}</TableCell>
                                                <TableCell>{row.validDate}</TableCell>
                                                <TableCell>
                                                    {row.validFlag ? <CheckIcon sx={{ color: "green" }} /> : <CloseIcon sx={{ color: "red" }} />}
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title={"Add Updated File"}>
                                                        <IconButton>
                                                            <Upload />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                )
                            })}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Grid>
    )
}