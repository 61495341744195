import { gql } from "@apollo/client";

export const getUserData = gql`
    query usersPermissionsUser($id: ID){
        usersPermissionsUser(id: $id) {
            data {
                id
                attributes {
                    username
                    email
                    confirmed
                    blocked
                    role {
                        data {
                            attributes {
                                name
                            }
                        }
                    }
                    user_type{
                        data{
                            id
                            attributes{
                                user_type
                                active
                            }
                        }
                    }
                }
            }
        }
    }  
`;

// // // // =======================================Frontend Screen Handales==================================
export const dynamicFrontends = gql`
    query dynamicFrontends{
        dynamicFrontends(pagination: {limit: 1000}, sort: "id", filters:{active: {eq: true}}){
            data{
                id
                attributes{
                    screen_title
                    design_frontend_module_id{
                        data{
                            id
                            attributes{
                                title
                                active
                            }
                        }
                    }
                    dynamic_frontend_sub_categories{
                        data{
                            id
                            attributes{
                                title
                                d_s_c_id {
                                    data{
                                        id
                                        attributes{
                                            title
                                            design_frontend_input_names {
                                                data{
                                                    id
                                                    attributes{
                                                        input_name
                                                        active
                                                        required
                                                        disabled
                                                        design_frontend_input_type{
                                                            data{
                                                                id
                                                                attributes{
                                                                    title
                                                                    active
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    category_input_field_mappings{
                        data{
                            id
                            attributes{
                                title
                                active
                                multiline
                                required
                                disabled
                                design_frontend_category{
                                    data{
                                        id
                                        attributes{
                                            title
                                        }
                                    }
                                }
                            } }
                    }
                    design_frontend_category_id{
                        data{
                            id
                            attributes{
                                title
                                active
                            }
                        }
                    }
                    Input_type
                    design_frontend_input_names(pagination: {limit: 50}){
                        data{
                            id
                            attributes{
                                input_name
                                design_frontend_input_type{
                                    data{
                                        id
                                        attributes{
                                            title
                                        }
                                    }
                                }
                                active
                                multiline
                                required
                                disabled
                                have_child_option
                                drop_down_options(pagination:{limit: 1000}){
                                    data{
                                        id
                                        attributes{
                                            title
                                            active
                                        }
                                    }
                                }
                            }
                        }
                    }
                    question_list_ids(pagination: {limit: 100}, sort: "id"){
                        data{
                            id
                            attributes{
                                question
                                active
                                answer_type{
                                    ... on ComponentAnswersSingleAnswer{
                                        drop_down_option_lovs(pagination: {limit: 100}, sort: "id"){
                                        data{
                                            id
                                            attributes{
                                            active
                                            title
                                            }
                                        }
                                        }
                                        active
                                    }
                                    ... on ComponentAnswersTextAnswer{
                                        details
                                        active
                                        details_value
                                    }
                                    ... on ComponentAnswersHomeRiskInputs{
                                        selected_options{
                                            data{
                                                id
                                                attributes{
                                                    title
                                                    active
                                                }
                                            }
                                        }
                                        details_action
                                        rating
                                        id
                                        details_action_value
                                        rating_value
                                        selected_option_value
                                    }
                                    ... on ComponentAnswersIndividualRiskInputs{
                                        risk_options{
                                            risk_to_individual{
                                                data{
                                                    id
                                                    attributes{
                                                        active
                                                        title
                                                    }
                                                }
                                            }
                                            risk_to_staff{
                                                data{
                                                    id
                                                    attributes{
                                                        active
                                                        title
                                                    }
                                                }
                                            }
                                            risk_to_other{
                                                data{
                                                    id
                                                    attributes{
                                                        active
                                                        title
                                                    }
                                                }
                                            }
                                            risk_to_individual_value
                                            risk_to_staff_value
                                            risk_to_other_value
                                        }
                                        source_of_information
                                        source_of_information_value
                                        management_guides
                                        management_guides_value
                                    }
                                    ... on ComponentAnswersList{
                                        order_unord_json
                                        id
                                    }
                                    ... on ComponentAnswersDateInput{
                                        date
                                        id
                                        date_value
                                    }
                                }
                            }
                        }
                    }
                    can_create_multiple,
                    Documents{
                        audit_documents(pagination: {limit: 100},sort: "id"){
                            data{
                                id
                                attributes{
                                    audit_module{
                                        data{
                                            id
                                            attributes{
                                                title
                                                active
                                            }
                                        }
                                    }
                                    audit_category{
                                        data{
                                            id
                                            attributes{
                                                title
                                                active
                                            }
                                        }
                                    }
                                    audit_type{
                                        data{
                                            id
                                            attributes{
                                                title
                                                active
                                            }
                                        }
                                    }
                                    document_description
                                    active
                                    reminder
                                    company_id{
                                        data{
                                            id
                                            attributes{
                                                company_name
                                                registration_number
                                            }
                                        }
                                    }
                                    audit_document_name{
                                        data{
                                            id
                                            attributes{
                                                title
                                                active
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const designFrontendModules = gql`
    query designFrontendModules{
        designFrontendModules(sort: "id"){
            data{
                id
                attributes{
                    active
                    title
                    category
                }
            }
        }
    }
`;

//  // // // =======================================Client queries.............................................
export const clientDetailList = gql`
    query clientDetails{
        clientDetails(pagination: {limit: 300}, sort: "id", filters:{active: {eq: true},approved_status: {eq: true}}){
            data{
                id
                attributes{
                    first_name
                    last_name
                    date_of_birth
                    age
                    ndis_number
                    primary_diagnosis
                    client_budget
                    rankings
                    medicare_number
                    medicare_expiry_date
                    centrelink_dva_number
                    active
                    approved_status
                    user_type_id
                    profile_picture{
                        data{
                            id
                            attributes{
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const clientDetailsRelationlData = gql`
    query clientAnswerLovsReferences($filterP: PreferenceForStaffWorkerFiltersInput, $filters: ContactDetailFiltersInput, $filter: ClientAnswerLovsReferenceFiltersInput){
        clientAnswerLovsReferences(filters: $filter, pagination: {limit: 1000}){
            data{
                id
                attributes{
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                    module
                    category
                    input_name
                    selected_option_value
                }
            }
        }
        contactDetails(filters: $filters, pagination: {limit: 1000}){
            data{
                id
                attributes{
                    email
                    home_phone
                    mobile_phone
                    address
                    post_code
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                }
            }
        }
        preferenceForStaffWorkers(filters: $filterP){
            data{
                id
                attributes{
                    age
                    language_spoken
                    gender
                    religion
                    client_detail_id{
                        data{
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const clientDetailsWithRelationalData = gql`
    query clientRelationalDataWithId($id:ID,$filterNotes: AdministrativeNoteFiltersInput, $filter: ClientAnswerLovsReferenceFiltersInput){
        clientDetail(id: $id){
            data{
                id
                attributes{
                    first_name
                    last_name
                    date_of_birth
                    age
                    ndis_number
                    primary_diagnosis
                    client_budget
                    rankings
                    medicare_number
                    medicare_expiry_date
                    centrelink_dva_number
                    active
                    approved_status
                    user_type_id
                    profile_picture{
                        data{
                            id
                            attributes{
                                url
                            }
                        }
                    }
                }
            }
        }
        clientAnswerLovsReferences(filters: $filter, pagination: {limit: 100}){
            data{
            id
            attributes{
                client_detail_id{
                data{
                    id
                    attributes{
                    first_name
                    }
                }
                }
                module
                category
                input_name
                selected_option_value
            }
            }
        }
        contactDetail(id: $id){
            data{
            id
            attributes{
                email
                home_phone
                mobile_phone
                address
                post_code
                client_detail_id{
                data{
                    id
                    attributes{
                    first_name
                    }
                }
                }
            }
            }
        }
        emergencyContactDetail(id: $id){
            data{
                id
                attributes{
                    first_name
                    last_name
                    email
                    home_phone
                    mobile_phone
                    address
                    relationship
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                }
            }
        }
        administrativeNotes(filters: $filterNotes){
            data{
            id
            attributes{
                notes
                client_detail_id{
                data{
                    id
                    attributes{
                    first_name
                    }
                }
                }
            }
            }
        }
    }
`;

export const clientDetailById = gql`
    query clientDetail($id: ID){
        clientDetail(id: $id){
            data{
                id
                attributes{
                    first_name
                    last_name
                    date_of_birth
                    age
                    ndis_number
                    primary_diagnosis
                    client_budget
                    rankings
                    medicare_number
                    medicare_expiry_date
                    centrelink_dva_number
                    active
                    approved_status
                    user_type_id
                    profile_picture{
                        data{
                            id
                            attributes{
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const clientAnswerLovsReferences = gql`
    query clientAnswerLovsReferences($filter: ClientAnswerLovsReferenceFiltersInput){
        clientAnswerLovsReferences(filters: $filter, pagination: {limit: 1000}){
            data{
                id
                attributes{
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                    module
                    module_Id
                    category
                    input_name
                    selected_option_value
                }
            }
        }
    }
`;

export const contactInfoById = gql`
    query contactDetails($filter: ContactDetailFiltersInput){
        contactDetails(filters: $filter){
            data{
                id
                attributes{
                    email
                    home_phone
                    mobile_phone
                    address
                    post_code
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                    staff_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const emergencyContactDetailsByFilter = gql`
    query emergencyContactDetails($filter: EmergencyContactDetailFiltersInput){
        emergencyContactDetails(filters: $filter){
            data{
                id
                attributes{
                    relationship
                    email
                    home_phone
                    mobile_phone
                    address
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                    staff_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                    first_name
                    last_name
                }
            }
        }
    }
`;

export const administrativeNotesByFilter = gql`
    query clientNotes($filter: ClientNoteFiltersInput){
        clientNotes(filters: $filter){
            data{
                id
                attributes{
                    general_notes
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const serviceInformationsByFilter = gql`
    query serviceInformations($filter: ServiceInformationFiltersInput){
        serviceInformations(filters: $filter){
            data{
                id
                attributes{
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                    active
                    respite_care
                    community_participation
                    supported_independent_living
                }
            }
        }
    }
`;

export const preferenceForStaffWorkersByFilter = gql`
    query preferenceForStaffWorkers($filter: PreferenceForStaffWorkerFiltersInput){
        preferenceForStaffWorkers(filters: $filter){
            data{
                id
                attributes{
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                    active
                    age
                    gender
                    language_spoken
                    religion
                }
            }
        }
    }
`;

export const clientGoalsByFilter = gql`
    query clientGoals($filter: ClientGoalFiltersInput){
        clientGoals(filters: $filter){
            data{
                id
                attributes{
                    client_detail_id{
                        data{
                            id
                        }
                    }
                    goal
                }
            }
        }
    }
`;

export const checkListAlertsNotificationsByFilter = gql`
    query checkListAlertsNotifications($filter: CheckListAlertsNotificationFiltersInput){
        checkListAlertsNotifications(filters: $filter){
            data{
                id
                attributes{
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                    check_list_title
                    check_list_comments
                    notification_time
                }
            }
        }
    }
`;

export const planReviewByFilter = gql`
    query planReviews($filter: PlanReviewFiltersInput, $filterS: PlanSupportCategoryFiltersInput){
        planReviews(filters: $filter){
            data{
                id
                attributes{
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                    created_by_plan
                    plan_start_date
                    plan_end_date
                }
            }
        }
        planSupportCategories(filters: $filterS, pagination: {limit: 100}){
            data{
                id
                attributes{
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                    budget
                    date
                    dynamic_support_category_id{
                        data{
                            id
                            attributes{
                                title
                                design_frontend_input_names{
                                    data{
                                        id
                                        attributes{
                                            input_name
                                        }
                                    }
                                }
                            }
                        }
                    }
                    plan_review_id{
                        data{
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const medicalInformationById = gql`
    query medicalInformations($filter: MedicalInformationFiltersInput){
        medicalInformations(filters:$filter, sort:"createdAt:DESC"){
            data{
                id
                attributes{
                    medication
                    medication_time
                    comments
                    every_x_days_where_x_is
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const onboardMedicalHistoryDetailById = gql`
     query onboardMedicalHistoryDetails($filter: OnboardMedicalHistoryDetailFiltersInput, $limit: Int = 100) {
        onboardMedicalHistoryDetails(filters: $filter, pagination: { limit: $limit }) {
            data {
                id
                attributes {
                    question_title
                    answer_value
                    client_detail_id {
                        data {
                            id
                            attributes {
                                first_name
                            }
                        }
                    }
            }
        }
    }
}
`;

export const referringDoctorInformationById = gql`
    query referringDoctorInformations($filter: ReferringDoctorInformationFiltersInput){
        referringDoctorInformations(filters:$filter){
            data{
                id
                attributes{
                    referring_doctor
                    referring_doctor_address
                    referring_doctor_phone
                    doctors_email
                    medical_center
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const clientQuestionAnswerRefById = gql`
    query clientQuestionAnswerRefs($filter: ClientQuestionAnswerRefFiltersInput) {
        clientQuestionAnswerRefs(
            filters: $filter
            pagination: { limit: 1000 }
        ) {
            data {
            id
            attributes {
                module
                category
                question_title
                answer_type {
                __typename
                ...on ComponentAnswersIndividualRiskInputs{
                    id
                    source_of_information
                    management_guides
                    risk_options{
                    risk_to_individual{
                        data{
                        id
                        attributes{
                            title
                            active
                        }
                        }
                    }
                    risk_to_staff{
                        data{
                        id
                        attributes{
                            title
                            active
                        }
                        }
                    }
                    risk_to_other{
                        data{
                        id
                        attributes{
                            title
                            active
                        }
                        }
                    }
                    }
                }
                ... on ComponentAnswersHomeRiskInputs {
                    id
                    rating
                    details_action
                    selected_options {
                        data{
                            attributes{
                            title
                            }
                        }
                    }
                    }
                     ... on ComponentAnswersSingleAnswer {
            id
            drop_down_option_lovs{
              data {
                id
                attributes{
                  title
                }
              }
            }
          }
                }
                
                client_detail_id {
                data {
                    id
                    attributes {
                    first_name
                    }
                }
                }
            }
            }
        }
}
`;

export const admissionInformationsById = gql`
    query admissionInformations($filter: AdmissionInformationFiltersInput){
        admissionInformations(filters: $filter){
            data{
                id
                attributes{
                    principal_diagnosis
                    provisional_diagnosis	
                    indentified_major_procedure
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                    client_question_answer_refs{
                        data{
                            id
                            attributes{
                                design_front_module_id{
                                    data{
                                        id
                                        attributes{
                                            title
                                        }
                                    }
                                }
                                design_front_category_id{
                                    data{
                                        id
                                        attributes{
                                            title
                                        }
                                    }
                                }
                                question_list_id{
                                    data{
                                        id
                                        attributes{
                                            question
                                        }
                                    }
                                }
                                answer_type{
                                    ... on ComponentAnswersSingleAnswer{
                                        drop_down_option_lovs(pagination: {limit: 100}, sort: "id"){
                                            data{
                                                id
                                                attributes{
                                                    active
                                                    title
                                                }
                                            }
                                        }
                                        active
                                        
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const referringDoctorInformationsById = gql`
    query referringDoctorInformations($filter: ReferringDoctorInformationFiltersInput){
        referringDoctorInformations(filters:$filter){
            data{
                id
                attributes{
                    referring_doctor
                    medical_record_number
                    referring_doctor_address
                    referring_doctor_phone
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const individualRiskAssessment = gql`
    query individualRiskAssessment($id: ID){
        individualRiskAssessment(id: $id){
            data{
                id
                attributes{
                    company_id{
                        data{
                            id
                            attributes{
                            company_name
                            registration_number
                            }
                        }
                    }
                    client_detail_id{
                        data{
                            id
                            attributes{
                            first_name
                            }
                        }
                    }
                    client_question_answer_refs(pagination: {limit: 100}, sort: "id"){
                        data{
                            id
                            attributes{
                                design_front_category_id{
                                    data{
                                        id
                                        attributes{
                                            title
                                        }
                                    }
                                }
                                question_list_id{
                                    data{
                                        id
                                        attributes{
                                            question
                                        }
                                    }
                                }
                                answer_type{
                                    ... on ComponentAnswersIndividualRiskInputs{
                                        source_of_information
                                        management_guides
                                        risk_options{
                                            risk_to_individual{
                                            data{
                                                id
                                                attributes{
                                                title
                                                }
                                            }
                                            }
                                            risk_to_staff{
                                                data{
                                                    id
                                                    attributes{
                                                    title
                                                    }
                                                }
                                            }
                                            risk_to_other{
                                                data{
                                                    id
                                                    attributes{
                                                    title
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const homeRiskAssessment = gql`
    query homeRiskAssessment($id: ID){
        homeRiskAssessment(id: $id){
            data{
                id
                attributes{
                    company_id{
                        data{
                            id
                            attributes{
                                company_name
                                registration_number
                            }
                        }
                    }
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                    client_answer_lovs_references{
                        data{
                            id
                            attributes{
                                design_front_category_id{
                                    data{
                                    id
                                    attributes{
                                        title
                                    }
                                    }
                                }
                                design_frontend_input_id{
                                    data{
                                    id
                                    attributes{
                                        input_name
                                    }
                                    }
                                }
                                drop_down_option_lov_id{
                                    data{
                                    id
                                    attributes{
                                        title
                                    }
                                    }
                                }
                            }
                        }
                    }
                    client_question_answer_refs(pagination: {limit: 100}, sort: "id"){
                        data{
                            id
                            attributes{
                                design_front_category_id{
                                    data{
                                    id
                                    attributes{
                                        title
                                    }
                                    }
                                }
                                question_list_id{
                                    data{
                                    id
                                    attributes{
                                        question
                                    }
                                    }
                                }
                                answer_type{
                                    ... on ComponentAnswersSingleAnswer{
                                    drop_down_option_lovs{
                                        data{
                                        id
                                        attributes{
                                            title
                                        }
                                        }
                                    }
                                    }
                                    ... on ComponentAnswersHomeRiskInputs{
                                    details_action
                                    selected_options{
                                        data{
                                        id
                                        attributes{
                                            title
                                        }
                                        }
                                    }
                                        rating
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const safetyPlanById = gql`
    query safetyPlan($id: ID){
        safetyPlan(id: $id){
            data{
                id
                attributes{
                    company_id{
                        data{
                            id
                            attributes{
                                company_name
                                registration_number
                            }
                        }
                    }
                    client_detail_id{
                        data{
                            id
                            attributes{
                            first_name
                            }
                        }
                    }
                    client_question_answer_refs{
                        data{
                            id
                            attributes{
                                design_front_category_id{
                                    data{
                                        id
                                        attributes{
                                            title
                                        }
                                    }
                                }
                                question_list_id{
                                    data{
                                        id
                                        attributes{
                                            question
                                        }
                                    }
                                }
                                answer_type{
                                    ... on ComponentAnswersList{
                                    id
                                    order_unord_json
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const clientGoalsAndCareDetailsById = gql`
    query clientGoals($filter: ClientGoalFiltersInput, $filterNote: ClientNoteFiltersInput , $filterWorker: PreferenceForStaffWorkerFiltersInput, $filterService: ServiceInformationFiltersInput, $filterNotifications: CheckListAlertsNotificationFiltersInput, $filterPlans: PlanReviewFiltersInput){
        clientGoals(filters: $filter){
            data{
            id
            attributes{
                client_detail_id{
                data{
                    id
                    attributes{
                    first_name
                    }
                }
                }
                goal
                start_date
                end_date
                client_answer_lovs_ref_id{
                data{
                    id
                    attributes{
                    design_front_category_id{
                        data{
                        id
                        attributes{
                            title
                        }
                        }
                    }
                    design_frontend_input_id{
                        data{
                        id
                        attributes{
                            input_name
                        }
                        }
                    }
                    drop_down_option_lov_id{
                        data{
                        id
                        attributes{
                            title
                        }
                        }
                    }
                    }
                }
                }
            }
            }
        }
        preferenceForStaffWorkers(filters: $filterWorker){
            data{
            id
            attributes{
                client_detail_id{
                data{
                    id
                    attributes{
                    first_name
                    }
                }
                }
                age
                client_answer_lovs_ref{
                data{
                    id
                    attributes{
                    design_front_category_id{
                        data{
                        id
                        attributes{
                            title
                        }
                        }
                    }
                    design_frontend_input_id{
                        data{
                        id
                        attributes{
                            input_name
                        }
                        }
                    }
                    drop_down_option_lov_id{
                        data{
                        id
                        attributes{
                            title
                        }
                        }
                    }
                    }
                }
                }
            }
            }
        }
        serviceInformations(filters: $filterService){
            data{
                id
                attributes{
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                    active
                    respite_care
                    community_participation
                    supported_independent_living
                }
            }
        }
        checkListAlertsNotifications(filters: $filterNotifications){
            data{
            id
            attributes{
                check_list_title
                check_list_comments
                notification_time
                client_answer_lovs_refs{
                data{
                    id
                    attributes{
                    design_front_category_id{
                        data{
                        id
                        attributes{
                            title
                        }
                        }
                    }
                    design_frontend_input_id{
                        data{
                        id
                        attributes{
                            input_name
                        }
                        }
                    }
                    drop_down_option_lov_id{
                        data{
                        id
                        attributes{
                            title
                        }
                        }
                    }
                    }
                }
                }
            }
            }
        }
        planReviews(filters: $filterPlans){
            data{
            id
            attributes{
                client_detail_id{
                data{
                    id
                    attributes{
                    first_name
                    }
                }
                }
                created_by_plan
                plan_created_on
            }
            }
        }
    }
`;


//  // // // // /====================get client all information by id======================================
export const allRelationalClientInformationById = gql`
    query allRelationalClientInformationById($id:ID!, $question: ClientQuestionAnswerRefFiltersInput, $mediHis: OnboardMedicalHistoryDetailFiltersInput, $filterG: ClientGoalFiltersInput, $filterNote: ClientNoteFiltersInput , $filterWorker: PreferenceForStaffWorkerFiltersInput, $filterService: ServiceInformationFiltersInput, $filterNotifications: CheckListAlertsNotificationFiltersInput, $filterPlans: PlanReviewFiltersInput, $referring: ReferringDoctorInformationFiltersInput, $admission: AdmissionInformationFiltersInput, $medico: MedicalInformationFiltersInput, $filterEmer: EmergencyContactDetailFiltersInput,$filterContact: ContactDetailFiltersInput, $filterNotes: AdministrativeNoteFiltersInput, $filter: ClientAnswerLovsReferenceFiltersInput){
        clientDetail(id: $id){
            data{
                id
                attributes{
                    first_name
                    last_name
                    date_of_birth
                    age
                    ndis_number
                    primary_diagnosis
                    client_budget
                    rankings
                    medicare_number
                    medicare_expiry_date
                    centrelink_dva_number
                    active
                    approved_status
                    user_type_id
                    profile_picture{
                        data{
                            id
                            attributes{
                                url
                            }
                        }
                    }
                }
            }
        }
        clientAnswerLovsReferences(filters: $filter, pagination: {limit: 100},sort: "id"){
            data{
            id
            attributes{
                client_detail_id{
                data{
                    id
                    attributes{
                    first_name
                    }
                }
                }
                module
                category
                input_name
                selected_option_value
            }
            }
        }
        contactDetails(filters: $filterContact){
            data{
            id
            attributes{
                email
                home_phone
                mobile_phone
                address
                post_code
                client_detail_id{
                data{
                    id
                    attributes{
                    first_name
                    }
                }
                }
            }
            }
        }
        emergencyContactDetails(filters: $filterEmer){
            data{
                id
                attributes{
                    first_name
                    last_name
                    email
                    home_phone
                    mobile_phone
                    address
                    relationship
                    client_detail_id{
                        data{
                            id
                        }
                    }
                }
            }
        }
        administrativeNotes(filters: $filterNotes){
            data{
            id
            attributes{
                general_notes
                client_detail_id{
                data{
                    id
                    attributes{
                    first_name
                    }
                }
                }
            }
            }
        }
        medicalInformations(filters: $medico){
            data{
            id
            attributes{
                medication_title
                dosage
                expiry_date
                comments
                client_detail_id{
                data{
                    id
                    attributes{
                    first_name
                    }
                }
                }
                notification_time
            }
            }
        }
        admissionInformations(filters: $admission){
            data{
            id
            attributes{
                principal_diagnosis
                principal_diagnosis
                identified_major_procedure
                client_detail_id{
                data{
                    id
                    attributes{
                    first_name
                    }
                }
                }
            }
            }
        }
        referringDoctorInformations(filters: $referring){
            data{
            id
            attributes{
                medical_record_number
                referring_doctor
                referring_doctor_address
                referring_doctor_phone
                client_detail_id{
                data{
                    id
                    attributes{
                    first_name
                    }
                }
                }
            }
            }
        }
        clientGoals(filters: $filterG){
                    data{
                    id
                    attributes{
                        client_detail_id{
                        data{
                            id
                            attributes{
                            first_name
                            }
                        }
                        }
                        goal
                        start_date
                        end_date
                    
                    }
                    }
                }
        preferenceForStaffWorkers(filters: $filterWorker){
                    data{
                    id
                    attributes{
                        client_detail_id{
                        data{
                            id
                            attributes{
                            first_name
                            }
                        }
                        }
                        age
                        
                    }
                    }
                }
        serviceInformations(filters: $filterService){
            data{
                id
                attributes{
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                    active
                    respite_care
                    community_participation
                    supported_independent_living
                }
            }
        }
        checkListAlertsNotifications(filters: $filterNotifications){
            data{
            id
            attributes{
                check_list_title
                check_list_comments
                notification_time

            }
            }
        }
        planReviews(filters: $filterPlans){
            data{
            id
            attributes{
                client_detail_id{
                data{
                    id
                    attributes{
                    first_name
                    }
                }
                }
                created_by_plan
                plan_created_on
            }
            }
        }
        onboardMedicalHistoryDetails(filters: $mediHis,pagination: {limit: 100}, sort: "id"){
            data{
            id
            attributes{
                question_title
                answer_value
                client_detail_id{
                    data{
                        id
                        attributes{
                        first_name
                        }
                    }
                }
            }
            }
        }
        clientQuestionAnswerRefs(filters: $question, pagination: {limit: 200}, sort: "id"){
            data{
            id
            attributes{
                module
                category
                question_title
                client_detail_id{
                data{
                    id
                    attributes{
                    first_name
                    }
                }
                }
                answer_type{
                ... on ComponentAnswersSingleAnswer{
                    drop_down_option_lovs(pagination: {limit: 100}, sort: "id"){
                        data{
                            id
                            attributes{
                            active
                            title
                            }
                        }
                    }
                    active
                }
                ... on ComponentAnswersTextAnswer{
                    details
                    active
                    details_value
                }
                ... on ComponentAnswersHomeRiskInputs{
                    selected_options{
                        data{
                            id
                            attributes{
                                title
                                active
                            }
                        }
                    }
                    details_action
                    rating
                    id
                    details_action_value
                    rating_value
                    selected_option_value
                }
                ... on ComponentAnswersIndividualRiskInputs{
                    risk_options{
                        risk_to_individual{
                            data{
                                id
                                attributes{
                                    active
                                    title
                                }
                            }
                        }
                        risk_to_staff{
                            data{
                                id
                                attributes{
                                    active
                                    title
                                }
                            }
                        }
                        risk_to_other{
                            data{
                                id
                                attributes{
                                    active
                                    title
                                }
                            }
                        }
                        risk_to_individual_value
                        risk_to_staff_value
                        risk_to_other_value
                    }
                    source_of_information
                    source_of_information_value
                    management_guides
                    management_guides_value
                }
                ... on ComponentAnswersList{
                    order_unord_json
                    id
                }
            }
            }
            }
        }
    }
`;

// // // // // ========================================================================================================================
// // // // // ========================================Audit quries====================================================================
export const getDocumentList = gql`
    query{
        auditDocuments(pagination: {limit: 100}, sort: "id"){
            data{
                id
                attributes{
                    active
                    audit_module{
                        data{
                            id
                            attributes{
                                title
                                active
                            }
                        }
                    }
                    audit_category{
                        data{
                            id
                            attributes{
                                title
                                active
                            }
                        }
                    }
                    audit_type{
                        data{
                            id
                            attributes{
                                title
                                active
                            }
                        }
                    }
                    audit_document_name{
                        data{
                            id
                            attributes{
                                title
                                active
                            }
                        }
                    }
                    reminder
                    company_id{
                        data{
                            id
                            attributes{
                                company_name
                                registration_number
                            }
                        }
                    }
                    document_description
                }
            }
        }
    }
`;

export const auditModules = gql`
    query auditModules{
        auditModules(pagination: {limit: 200},sort: "id"){
            data{
                id
                attributes{
                    active
                    title
                    description
                }
            }
        }
    }
`;

export const auditCategories = gql`
    query auditCategories{
        auditCategories(pagination: {limit: 200},sort: "id"){
            data{
                id
                attributes{
                    active
                    title
                    description
                }
            }
        }
    }
`;

export const auditTypes = gql`
    query auditTypes{
        auditTypes(pagination: {limit: 200},sort: "id"){
            data{
                id
                attributes{
                    active
                    title
                    description
                }
            }
        }
    }
`;

export const auditDocumentNames = gql`
    query auditDocumentNames{
        auditDocumentNames(pagination: {limit: 500},sort: "id"){
            data{
                id
                attributes{
                    active
                    title
                    description
                }
            }
        }
    }
`;

// // // // // ==================================== Price List ==================================================
export const priceList = gql`
    query priceLists{
        priceLists(pagination: {limit: 1000},sort: "id"){
            data{
                id
                attributes{
                    code
                    service_name
                    Rate_Type
                    rate
                    afternoon_rate
                    night_rate
                    saturday_rate
                    sunday_rate
                    public_holiday_rate
                }
            }
        }
    }
`;

// // // // // ====================================== organization ===============================================
export const organizationList = gql`
    query orgnizations{
        orgnizations{
            data{
                id
                attributes{
                    registration_number
                    company_name
                    email
                    phone
                    website_url
                    abn
                    description
                    primary_invoice
                    secondary_invoice
                    primary_schedule_email
                    secondary_schedule_email
                    notes
                }
            }
        }
    }
`;

// // // // // ========================================== staff list ============================================
export const staffDetailsList = gql`
    query staffDetails{
        staffDetails(pagination: {limit: 1000}, sort: "id", filters:{ active: {eq: true}, approved_status: {eq: true}}){
            data{
                id
                attributes{
                    first_name
                    last_name
                    date_of_birth
                    ndis_clearance_number
                    registration_expiry_date
                    police_check
                    rankings
                    medicare_number
                    driving_license
                    user_type_id
                    approved_status
                    active
                    do_you_have_driving_license
                    age
                }
            }
        }
    }
`;

export const getStaffDetailsWithId = gql`
    query getStaffDetailsWithId($filters: ContactDetailFiltersInput, $filter: StaffAnswerLovsReferenceFiltersInput){
        staffAnswerLovsReferences(filters: $filter, pagination: {limit: 1000}){
            data{
                id
                attributes{
                    staff_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                    module
                    category
                    input_name
                    selected_option_value
                }
            }
        }
        contactDetails(filters: $filters, pagination: {limit: 1000}){
            data{
                id
                attributes{
                    email
                    home_phone
                    mobile_phone
                    address
                    post_code
                    staff_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const staffDetailsWithRelationalData = gql`
    query staffDetail( $filterDay: WorkingDayFiltersInput,$id:ID, $filter: StaffAnswerLovsReferenceFiltersInput){
        staffDetail(id: $id){
            data{
                id
                attributes{
                    first_name
                    last_name
                    date_of_birth
                    ndis_clearance_number
                    registration_expiry_date
                    religion
                    language_spoken
                    police_check
                    rankings
                    medicare_number
                    driving_license
                    user_type_id
                    approved_status
                    active
                    do_you_have_medical_card
                    do_you_have_driving_license
                }
            }
        }
        staffAnswerLovsReferences(filters: $filter, pagination: {limit: 100}){
            data{
                id
                attributes{
                    staff_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                    module
                    category
                    input_name
                    selected_option_value
                }
            }
        }
        contactDetail(id: $id){
            data{
                id
                attributes{
                    email
                    home_phone
                    mobile_phone
                    address
                    post_code
                    staff_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                }
            }
        }
        emergencyContactDetail(id: $id){
            data{
                id
                attributes{
                    emergency_first_name
                    emergency_last_name
                    email
                    home_phone
                    mobile_phone
                    address
                    relationship
                    staff_detail_id{
                        data{
                            id
                        }
                    }
                }
            }
        }
        staffPaymentDetail(id: $id){
            data{
                id
                attributes{
                    staff_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                    account_name
                    bsb_number
                    account_number
                    fund_name
                    member_number
                    reason_notes
                }
            }
        }
        workingDays(filters: $filterDay){
            data{
                id
                attributes{
                    select_day
                    day_start_time
                    day_end_time
                    whole_day
                    unavailable
                    staff_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const workingDaysByFilters = gql`
    query workingDays($filter: WorkingDayFiltersInput){
        workingDays(filters: $filter){
            data{
                id
                attributes{
                    staff_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                                last_name
                                email_info
                                ndis_clearance_number
                                medicare_number
                            }
                        }
                    }
                    select_day
                    day_start_time
                    day_end_time
                    unavailable
                    whole_day
                }
            }
        }
    }
`;

// /// /// ----=-=--------Admin queries----------------===========================================

export const getAllUserDetails = gql`
    query {
        staffDetails(pagination: { limit: 1000 }, sort: ["createdAt:desc"]) {
            data {
                id
                attributes {
                    first_name
                    last_name
                    email
                    active
                    approved_status
                    createdAt
                    updatedAt
                    user_type_id
                }
            }
        }
        clientDetails(pagination: { limit: 1000 }, sort: ["createdAt:desc"]) {
            data {
                id
                attributes {
                    first_name
                    last_name
                    active
                    approved_status
                    createdAt
                    updatedAt
                    user_type_id
                }
            }
        }
        contactDetails(pagination: { limit: 1000 }, sort: ["createdAt:desc"]) {
            data {
                id
                attributes {
                    email
                    home_phone
                    mobile_phone
                    staff_detail_id {
                    data {
                        id
                        attributes {
                        first_name
                        }
                    }
                    }
                    client_detail_id {
                    data {
                        id
                        attributes {
                        first_name
                        }
                    }
                    }
                }
            }
        }
    }
`;

export const schadAwardsRequest = gql`
    query schadsAwards{
        schadsAwards{
            data{
                id
                attributes{
                    level
                    hourly_rate
                    schads_shift_ids{
                        data{
                            id
                            attributes{
                                title
                                permanent_loading
                            }
                        }
                    }
                }
            }
        }
    }
`;

// // // // service request ========================================================
export const serviceRequests = gql`
    query serviceRequests{
        serviceRequests{
            data{
                id
                attributes{
                    status
                    client_detail_id{
                        data {
                            id
                            attributes {
                                first_name
                                last_name
                            }
                        }
                    }
                    funding_source
                    charge_band_rate_id{
                        data{
                            id
                            attributes{
                                service_title
                            }
                        }
                    }
                    billable
                    pay_travel_km
                    company_id{
                        data{
                            id
                            attributes{
                                company_name
                                registration_number
                            }
                        }
                    }
                    remove_plan_managers
                }
            }
        }
    }
`;

export const filterVisitDayAndCheckList = gql`
    query filterVisitDayAndCheckList($filterVisit: VisitDayFiltersInput, $filterCheck: VisitShiftCheckListFiltersInput){
        visitDays(filters: $filterVisit){
            data{
                id
                attributes{
                    recurring_type
                    start_time
                    end_time
                    start_date
                    end_date
                    week_days
                    selected_days
                    x_days
                    service_request_id{
                        data{
                            id
                        }
                    }
                    staff_id{
                        data{
                            id
                        }
                    }
                    previous_staff_id{
                        data{
                            id
                        }
                    }
                }
            }
        }
        visitShiftCheckLists(filters: $filterCheck){
            data{
                id
                attributes{
                    shift_title
                    shift_comments
                    client_detail_id{
                        data{
                            id
                        }
                    }
                    service_request_id{
                        data{
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const workingDays = gql`
    query workingDays{
        workingDays{
            data{
                id
                attributes{
                    staff_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                                last_name
                            }
                        }
                    }
                    select_day
                    day_start_time
                    day_end_time
                    unavailable
                    whole_day
                }
            }
        }
    }
`;

export const servicePlans = gql`
    query servicePlans{
        servicePlans{
            data{
            id
            attributes{
                description
                service_request_status
                staff_id{
                data{
                    id
                    attributes{
                    first_name
                    last_name
                    email_info
                    }
                }
                }
                service_request_id{
                    data{
                        id
                        attributes{
                            client_detail_id{
                                data{
                                    id
                                    attributes{
                                        first_name
                                        last_name
                                    }
                                }
                            }
                            charge_band_rate_id{
                                data{
                                    id
                                }
                            }
                            status
                        }
                    }
                }
            }
            }
        }
    }
`;

// // // // // user management with dashboard ===========================================================
export const getStaffDataWithId = gql`
    query getStaffDataWithId($filters: StaffDetailFiltersInput){
        staffDetails(filters: $filters){
            data{
                id
                attributes{
                    first_name
                    last_name
                    email
                    date_of_birth
                    ndis_clearance_number
                    do_you_have_medical_card
                    police_check
                    rankings
                    registration_expiry_date
                    active
                    do_you_have_driving_license
                    user_type_id
                    approved_status
                    language_spoken
                    religion
                    medicare_number
                    driving_license
                }
            }
        }
    }
`;

// // / / // / / ///  =============invoicing==============================================================
export const reimbursementsByServicePlan = gql`
    query reimbursements($filters: ReimbursementFiltersInput){
        reimbursements(filters: $filters, pagination: { limit: 1000 }){
            data{
            id
            attributes{
                amount
                purpose_note
                address_of_payment
                created_date
                updated_date
                approved_status
                rejected_status
                document_link{
                    data{
                        id
                        attributes{
                            file_url
                        }
                    }
                }
                service_shift_id{
                data{
                    id
                    attributes{
                    shift_date
                    shift_start
                    shift_end
                    shift_status
                    staff_detail_id{
                        data{
                        id
                        attributes{
                            first_name
                            last_name
                        }
                        }
                    }
                    visit_day_id{
                        data{
                        id
                        attributes{
                            service_request_id{
                            data{
                                id
                                attributes{
                                    charge_band_rate_id{
                                        data{
                                        id
                                        attributes{
                                            service_title
                                            service_code{
                                                data{
                                                    id
                                                    attributes{
                                                        support_item_number
                                                    }
                                                }
                                            }
                                        }
                                        }
                                    }
                                    client_detail_id{
                                        data{
                                        id
                                        attributes{
                                            first_name
                                            last_name
                                        }
                                        }
                                    }
                                    funding_source
                                }
                            }
                            }
                        }
                        }
                    }
                    }
                }
                }
            }
            }
        }
    }
`;

export const kilometersClaimsByServicePlan = gql`
    query kilometersClaims($filters: KilometersClaimFiltersInput){
        kilometersClaims(filters: $filters, pagination: { limit: 1000 }){
            data{
                id
                attributes{
                    date_of_travel
                    start_time
                    end_time
                    start_location
                    end_location
                    total_kilometers
                    purpose_of_travel
                    vehicle_used
                    additional_notes
                    approved_status
                    rejected_status
                    service_shift_id{
                      data{
                        id
                        attributes{
                          shift_date
                          shift_start
                          shift_end
                          shift_status
                          staff_detail_id{
                                data{
                                id
                                attributes{
                                    first_name
                                    last_name
                                }
                                }
                            }
                          visit_day_id{
                            data{
                              id
                              attributes{
                                service_request_id{
                                  data{
                                    id
                                    attributes{
                                        charge_band_rate_id{
                                            data{
                                                id
                                                attributes{
                                                    service_title
                                                    service_code{
                                                        data{
                                                            id
                                                            attributes{
                                                                support_item_number
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        client_detail_id{
                                            data{
                                            id
                                            attributes{
                                                first_name
                                                last_name
                                            }
                                            }
                                        }
                                        funding_source
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    document_link{
                        data{
                            id
                            attributes{
                                file_url
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const servicePlansFilter = gql`
    query servicePlans($filter: ServicePlanFiltersInput){
        servicePlans(filters: $filter, pagination: { limit: 1000 }){
            data{
                id
                attributes{
                    created_date
                    staff_id{
                        data{
                            id
                            attributes{
                                first_name
                                last_name
                                ndis_clearance_number
                                medicare_number
                                email_info
                            }
                        }
                    }
                    service_request_id {
                        data{
                            id
                            attributes{
                                charge_band_rate_id{
                                    data{
                                        id
                                        attributes{
                                            service_title
                                            service_code{
                                                data{
                                                    id
                                                    attributes{
                                                        support_item_number
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                pay_travel_km
                                funding_source
                                billable
                                funding_source
                                company_id{
                                    data{
                                        id
                                        attributes{
                                            company_name
                                            registration_number
                                        }
                                    }
                                }
                                client_detail_id{
                                    data{
                                        id
                                        attributes{
                                            first_name
                                            last_name
                                            primary_diagnosis
                                            ndis_number
                                        }
                                    }
                                }
                            }
                        }
                    }
                    service_request_status
                }
            }
        }
    }
`;

export const visitDaysByServiceId = gql`
    query visitDays($filterVisit: VisitDayFiltersInput, $filterCheck: VisitShiftCheckListFiltersInput){
        visitDays(filters: $filterVisit){
            data{
                id
                attributes{
                    recurring_type
                    start_time
                    end_time
                    start_date
                    staff_id{
                        data{
                            id
                        }
                    }
                    previous_staff_id{
                        data{
                            id
                        }
                    }
                    end_date
                    week_days
                    selected_days
                    x_days
                    service_request_id{
                        data{
                            id
                        }
                    }
                }
            }
        }
        visitShiftCheckLists(filters: $filterCheck){
            data{
                id
                attributes{
                    shift_title
                    shift_comments
                    client_detail_id{
                        data{
                            id
                        }
                    }
                    service_request_id{
                        data{
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const serviceShiftFilters = gql`
    query serviceShifts($filter: ServiceShiftFiltersInput){
        serviceShifts(filters: $filter, pagination:{ limit: 1000}){
            data{
                id
                attributes{
                    shift_date
                    shift_start
                    shift_end
                    visit_day_id{
                        data{
                          id
                          attributes{
                            start_date
                            end_date
                            recurring_type
                            start_time
                            end_time
                            service_request_id{
                              data{
                                id
                                attributes{
                                    funding_source
                                    billable
                                    pay_travel_km
                                    client_detail_id{
                                        data{
                                            id
                                            attributes{
                                                first_name
                                                last_name
                                                ndis_number
                                            }
                                        }
                                    }
                                    status
                                    charge_band_rate_id{
                                        data{
                                            id
                                            attributes{
                                                service_code{
                                                data{
                                                    id
                                                    attributes{
                                                    support_item_number
                                                    }
                                                }
                                                }
                                                service_title
                                                service_rate
                                            }
                                        }
                                    }
                                    company_id{
                                        data{
                                        id
                                        attributes{
                                            company_name
                                            registration_number
                                        }
                                        }
                                    }
                                }
                              }
                            }
                          }
                        }
                    }
                    total_shift_hours
                    shift_start_flag
                    shift_end_flag
                    shift_completed
                    shift_comments
                    day_time_hours
                    evening_hours
                    night_hours
                    sleepover_hours
                    saturday_hours
                    sunday_hours
                    holiday_hours
                    shift_status
                    staff_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                                last_name
                                email_info
                                ndis_clearance_number
                                weekday_day_rate
                                weekday_evening_rate
                                weekday_night_rate
                                saturday_rate
                                sunday_rate
                                public_holiday_rate
                                night_sleepover_rate
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const preferenceForStaffWorkerById = gql`
    query preferenceForStaffWorkers($filter: PreferenceForStaffWorkerFiltersInput){
        preferenceForStaffWorkers(filters: $filter){
            data{
            id
            attributes{
                age
                language_spoken
                gender
                religion
                client_detail_id{
                data{
                    id
                }
                }
            }
            }
        }
    }
`;

export const ndisPriceListCategory = gql`
query ndisPriceListCategory($filter: NdisPriceListCategoryFiltersInput) {
	ndisPriceListCategories(
		filters: $filter,
		pagination: { limit: 1000 },
		sort:"createdAt:DESC",
	) {
		data {
			id
			attributes{
				Ndis_price_list_category
			}
		}
	}
}
`;

export const ndisPriceList = gql`
    query ndisPriceLists($filter: NdisPriceListFiltersInput){
        ndisPriceLists(filters: $filter, pagination: { limit: 1000}){
            data{
                id
                attributes{
                    support_item_number
                    support_item_name
                    registration_group_number
                    registration_group_name
                    support_category_number
                    support_category_number_pace
                    support_category_name
                    support_category_name_pace
                    unit
                    quote
                    start_date
                    end_date
                    WA
                    non_face_to_face_support_provision
                    provider_travel
                    short_notice_cancellations
                    ndia_requested_reports
                    irregular_sil_supports
                    type
                }
            }
        }
    }
`;

export const chargeBandDetails = gql`
    query chargeBandDetails{
        chargeBandDetails(pagination: {limit: 1000}, sort: "id"){
            data{
                id
                attributes{
                    charge_band_name
                    filter_key_brokerage_private_ndis_aged_care_pakage
                    status
                }
            }
        }
    }
`;

export const chargeBandRates = gql`
    query chargeBandRates($filter: ChargeBandRateFiltersInput){
        chargeBandRates(filters: $filter){
            data{
                id
                attributes{
                    service_title
                    service_code{
                        data{
                            id
                            attributes{
                                support_item_number
                            }
                        }
                    }
                    service_rate
                    service_actual_rate
                    charge_band_detail{
                        data{
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const historyOfClientService = gql`
    query serviceRequests($Rfilter: ServiceRequestFiltersInput){
        serviceRequests(filters: $Rfilter, pagination: { limit: 1000 }){
            data{
                id
                attributes{
                    funding_source
                    billable
                    pay_travel_km
                    client_detail_id{
                        data{
                            id
                        }
                    }
                    company_id{
                        data{
                            id
                        }
                    }
                    remove_plan_managers
                    status
                    charge_band_rate_id{
                        data{
                            id
                            attributes{
                                service_title
                                service_code{
                                    data{
                                        id
                                        attributes{
                                            support_category_number
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;