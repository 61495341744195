import * as React from 'react';
import {
    Divider,
    StepContent,
    StepLabel,
    Box,
    Stepper,
    Step,
    StepButton,
    Button,
    Typography,
    Grid
} from '@mui/material';
import { setCategoryStep } from '../../middleware/reducers/category_step_reducer';
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingState } from '../../middleware/reducers/loading_state_reducer';

export const HorizontalNonLinearStepper = ({ steps }) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((v, index) => (
                    <Step key={v.label} completed={completed[index]}>
                        <StepButton color="inherit" onClick={handleStep(index)}>
                            {v.label}
                        </StepButton>
                        {/* <Typography>
                            {v.description}
                        </Typography> */}
                    </Step>
                ))}
            </Stepper>
            <div>
                {allStepsCompleted() ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <br />
                        <Divider />
                        <Box sx={{ mt: 1, mb: 1, py: 1, display: 'flex', flexWrap: 'wrap', overflowY: 'auto', height: "calc(60vh)" }}>
                            {steps[activeStep].screen}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleNext} sx={{ mr: 1 }}>
                                Next
                            </Button>
                            {activeStep !== steps.length &&
                                (completed[activeStep] ? (
                                    <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                        Step {activeStep + 1} already completed
                                    </Typography>
                                ) : (
                                    <Button onClick={handleComplete}>
                                        {completedSteps() === totalSteps() - 1
                                            ? 'Finish'
                                            : 'Complete Step'}
                                    </Button>
                                ))}
                        </Box>
                    </React.Fragment>
                )}
            </div>
        </Box>
    );
};


export const VerticalLinearStepper = ({ steps, error, horizontalNextStepIndex }) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [stepError, setStepError] = React.useState(false);
    const [stepIndex, setStepIndex] = React.useState(null);

    const loadingState = useSelector((state) => state._loading_state.loading_state);
    const _saving_list = useSelector((state) => state._client_onboarding_module.client_onboard_data_list);
    const _category_step = useSelector((state) => state._category_step_state.category_step);

    const keysToCheck = [
        'client_information',
        'medical_information',
        'individual_risk_assessment',
        'home_risk_assessment',
        'safety_plans'
    ];

    const labelKey = _category_step?.toLowerCase().split('/ ').join('')?.split(' ').join('_');

    const isStepInSavingList = keysToCheck.some(key => {
        return Object.keys(_saving_list?.[key] || {}).includes(labelKey)
    });

    const stepErrorRef = React.useRef(stepError);

    const dispatch = useDispatch();

    function debounce(func, timeout = 1000) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    const handleReset = () => {
        setActiveStep(0);
        setStepIndex(0)
    };

    const setActiveStepFunc = debounce((index) => {
        if (stepErrorRef.current === false) {
            setActiveStep(index);
            dispatch(setLoadingState('stale'))
        }
    });

    const handleClickStep = (index) => {
        setStepIndex(index)
        const prevStep = steps[index - 1];

        if (!isStepInSavingList && index > (activeStep + 1)) {
            dispatch(setCategoryStep(null))
        }

        if (index === (activeStep + 1)) {
            dispatch(setCategoryStep(prevStep?.label ?? 'basic_information'))
        }

    };

    React.useEffect(() => {
        if (stepIndex && loadingState === 'completed') {
            setActiveStepFunc(stepIndex)
        }
        else if (stepIndex && loadingState === 'error') {
            dispatch(setCategoryStep(null))
            dispatch(setLoadingState('stale'))
        }
    }, [loadingState])

    React.useEffect(() => {

        const isPrevStepInSavingList = keysToCheck.some(key => {
            return Object.keys(_saving_list?.[key] || {}).includes(steps[stepIndex - 1]?.label?.toLowerCase().split('/ ').join('')?.split(' ').join('_'))
        });

        if (isStepInSavingList && (stepIndex === (activeStep + 1) || stepIndex < (activeStep + 1))) {
            setActiveStepFunc(stepIndex);
        }
        if (isPrevStepInSavingList) {
            setActiveStepFunc(stepIndex);
        }

    }, [stepIndex])

    React.useEffect(() => { stepErrorRef.current = stepError; }, [stepError]);

    React.useEffect(() => { setStepError(error) }, [error])

    React.useEffect(() => { handleReset() }, [steps, horizontalNextStepIndex])

    return (
        <Grid item={true} xs={12} sm={12} md={12} lg={12} >
            <Stepper activeStep={activeStep} orientation="vertical" >
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel
                            sx={{
                                cursor: "pointer",
                                '& .MuiStepIcon-text': {
                                    fill: '#ffffff', // Default text color inside the circle
                                    '&.Mui-active': {
                                        fill: '#ffffff', // Active step text color inside the circle
                                    },
                                    '&.Mui-completed': {
                                        fill: '#ffffff', // Completed step text color inside the circle
                                    },
                                },
                                '& .MuiStepIcon-root': {
                                    color: '#cccccc', // Active step color
                                    '&.Mui-active': {
                                        color: '#6ECA35', // Color for active step
                                    },
                                    '&.Mui-completed': {
                                        color: '#6ECA35', // Color for completed step
                                    },
                                },
                            }}
                            // disabled={(activeStep + 1) === index ? false : true}
                            onClick={() => handleClickStep(index)}
                        >
                            <Typography>{step.label}</Typography>
                        </StepLabel>
                        <StepContent>
                            <Box sx={{ mt: 1, mb: 1, py: 1, display: 'flex', flexWrap: 'wrap', }}>
                                {steps[activeStep]?.screen}
                            </Box>

                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {/* {activeStep === steps.length && (
                    <Paper square elevation={0}>
                        <Typography>Information has been submit..</Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                            Reset
                        </Button>
                    </Paper>
            )} */}
        </Grid >
    );
}