export default {
  // tasks: {
  //   "task-1": { id: "task-1", content: "I am task 1" },
  //   "task-2": { id: "task-2", content: "I am task 2" },
  //   "task-3": { id: "task-3", content: "I am task 3" },
  //   "task-4": { id: "task-4", content: "I am task 4" }
  // },
  participants: [
    {
      name: "Farha",
      serviceRequired: [
        {
          dateFrom: "2024-01-30",
          dateTo: "2024-02-30",
          startTime: "08:00",
          endTime: "17:00",
          status: "Pending",
        },
      ],
      id: 1,
      serviceType: "Core: Assistance with self care",
      serviceProvider: null
    },
    {
      name: "Zara",
      serviceRequired: [
        {
          dateFrom: "2024-02-17",
          dateTo: "2024-03-17",
          startTime: "08:00",
          endTime: "17:00",
          status: "Pending",
        },
      ],
      id: 2,
      serviceType: "Core: Assistance with self care",
      serviceProvider: null
    },
    {
      name: "Veena",
      serviceRequired: [
        {
          dateFrom: "2024-03-17",
          dateTo: "2024-05-17",
          startTime: "08:00",
          endTime: "17:00",
          status: "Pending",
        },
      ],
      id: 3,
      serviceType: "Core: Assistance with self care",
      serviceProvider: null
    },
    {
      name: "Tahira",
      serviceRequired: [
        {
          dateFrom: "2024-05-17",
          dateTo: "2024-08-17",
          startTime: "08:00",
          endTime: "17:00",
          status: "Pending",
        },
      ],
      id: 4,
      serviceType: "Core: Assistance with self care",
      serviceProvider: null
    },
    {
      name: "Elma",
      serviceRequired: [
        {
          dateFrom: "2024-03-15",
          dateTo: "2024-03-30",
          startTime: "08:00",
          endTime: "17:00",
          status: "Pending",
        },
      ],
      id: 5,
      serviceType: "Core: Assistance with self care",
      serviceProvider: null
    },
  ],
  staff: {
    "column-1": {
      id: "column-1",
      title: "Clients",
      taskIds: [1, 2, 3, 4, 5]
    },
    "column-2": {
      id: "column-2",
      title: "Staff",
      taskIds: []
    },
    "column-3": {
      id: "column-3",
      title: "Staff",
      taskIds: []
    },
    "column-4": {
      id: "column-4",
      title: "Staff",
      taskIds: []
    },
    "column-5": {
      id: "column-5",
      title: "Staff",
      taskIds: []
    },
  },
  employees: [
    {
      name: "Saba",
      serviceTime: [
        {
          dateFrom: "2024-01-30",
          dateTo: "2024-02-30",
          startTime: "08:00",
          endTime: "17:00",
          jobStatus: "Pending",
        },
      ],
      id: 1,
      serviceType: "Core: Assistance with self care",
      serviceTo: []
    },
    {
      name: "salman",
      serviceTime: [
        {
          dateFrom: "2024-01-30",
          dateTo: "2024-02-30",
          startTime: "08:00",
          endTime: "17:00",
          jobStatus: "Pending",
        },
      ],
      id: 2,
      serviceType: "Core: Assistance with self care",
      serviceTo: []
    },
    {
      name: "Rameez",
      serviceTime: [
        {
          dateFrom: "2024-01-30",
          dateTo: "2024-02-30",
          startTime: "08:00",
          endTime: "17:00",
          jobStatus: "Pending",
        },
      ],
      id: 3,
      serviceType: "Core: Assistance with self care",
      serviceTo: []
    },
    {
      name: "Roshan",
      serviceTime: [
        {
          dateFrom: "2024-01-30",
          dateTo: "2024-02-30",
          startTime: "08:00",
          endTime: "17:00",
          jobStatus: "Pending",
        },
      ],
      id: 4,
      serviceType: "Core: Assistance with self care",
      serviceTo: []
    },
    {
      name: "Uzma",
      serviceTime: [
        {
          dateFrom: "2024-01-30",
          dateTo: "2024-02-30",
          startTime: "08:00",
          endTime: "17:00",
          jobStatus: "Pending",
        },
      ],
      id: 5,
      serviceType: "Core: Assistance with self care",
      serviceTo: []
    },
    {
      name: "Farzana",
      serviceTime: [
        {
          dateFrom: "2024-01-30",
          dateTo: "2024-02-30",
          startTime: "08:00",
          endTime: "17:00",
          jobStatus: "Pending",
        },
      ],
      id: 1,
      serviceType: "Core: Assistance with self care",
      serviceTo: []
    },

  ],
  columnOrder: ["column-1", "column-2", "column-3", "column-4", "column-5"]
};
