import { Backdrop, Box, Button, Divider, Fade, Grid, IconButton, List, ListItem, Tooltip, MenuItem, Modal, Paper, Stack, TextField, Typography, ListItemIcon, ListItemText } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FeedbackRegister } from "../../form_screens/register_form";
import SearchAndFilters from "../../../components/search_filters";
import { TrainingList } from "../../../components/pagination_view";
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePickerUI } from "../../../components/Inputs";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styled from "@emotion/styled";


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const MyTrainings = () => {
    const [newFeedback, setNewFeedback] = useState(false);

    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        maxWidth: "calc(70%)",
        minWidth: "calc(60%)",
        boxShadow: 12,
        borderRadius: 1,
        p: 4,
    });
    
    const width = () => {
        // modal control
        if (window.screen.width < 900) {
            const copy = { ...style };
            copy.maxWidth = "95%";
            setStyle(copy);
        }

        if (window.screen.width > 1600) {
            return window.screen.width / 4
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.4
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const trainings = (id, description, category, documentName, expiryDate) => {
        return {
            id,
            description,
            category,
            documentName,
            expiryDate
        };
    };

    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'Description',
            numeric: false,
            disablePadding: true,
            label: 'Description',
        },
        {
            id: 'Category',
            numeric: false,
            disablePadding: true,
            label: 'Category',
        },
        {
            id: 'Document Name',
            numeric: false,
            disablePadding: true,
            label: 'Document Name',
        },
        {
            id: 'Expiry Date',
            numeric: false,
            disablePadding: true,
            label: 'Expiry Date',
        },
        {
            id: 'Options',
            numeric: false,
            disablePadding: true,
            label: 'Options',
        },
    ];

    const [orgs, setOrgs] = useState([
        trainings(1, "Description", "Training", "NDIS Communication Module", "02-05-2024"),
        trainings(2, "Description", "Training", "NDIS Communication Module", "02-05-2024"),
        trainings(2, "Description", "Certifications", "Infection Control Certification", "02-05-2024"),
    ]);

    const [documentName, setDocumentName] = useState("");

    const [miscellaneous, setMiscellaneous] = useState([
        {
            title: "Select Option to Upload Documents",
            value: "Select option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Select option",
                    selected: true
                },
                {
                    title: "Minutes of meetings where complaints are discussed and recorded",
                    selected: false
                },
                {
                    title: "Organization chart",
                    selected: false
                },
            ]
        }
    ]);

    const [jobProfileDetails, setJobProfileDetails] = useState([
        {
            title: "Description",
            value: "acf",
            width: screenWidth,
            type: "text",
            required: true,
        },
        {
            title: "Expiry Date",
            value: "20-2-2028",
            width: screenWidth,
            type: "date",
            required: true,
        },
    ]);

    const [training, setTraining] = useState([
        {
            title: "Select Option to Upload Documents",
            value: "Select option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Select option",
                    selected: true
                },
                {
                    title: "NDIS New Worker NDIS Induction Module",
                    selected: false
                },
                {
                    title: "NDIS Communication Module",
                    selected: false
                },
                {
                    title: "NDIS Mealtime module",
                    selected: false
                },
                {
                    title: "Manual Handling Training",
                    selected: false
                },
                {
                    title: "Medication management training as appropriate",
                    selected: false
                },
                {
                    title: "PPE Training",
                    selected: false
                },
            ]
        }
    ]);

    const [certificates, setCertificates] = useState([
        {
            title: "Select Option to Upload Documents",
            value: "Select option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Select option",
                    selected: true
                },
                {
                    title: "NDIS Worker Orientation Module Certificate",
                    selected: false
                },
                {
                    title: "NDIS Supporting Effective Communication Certificate",
                    selected: false
                },
                {
                    title: "Infection Control Certificate",
                    selected: false
                },
                {
                    title: "PPE Training Certificate",
                    selected: false
                },
                {
                    title: "CPR Training Certificate",
                    selected: false
                },
            ]
        }
    ]);

    const [iDPermit, setiDPermit] = useState([
        {
            title: "Select Option to Upload Documents",
            value: "Select option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                { title: "Select option" },
                { title: 'Profile Picture' },
                { title: '100 points of ID' },
                { title: 'Letter of Engagement' },
                { title: 'NDIS Mandatory Workers Orientation Certificate' },
                { title: 'Ahpra registration(if applicable)' },
                { title: 'Resume' },
                { title: 'Driving License' },
                { title: 'Qualification certificates' },
                { title: 'Vaccination Records' },
                { title: 'Next of Kin contact' },
                { title: 'Induction records' },
                { title: 'Right to work in Australia visa check via DFAT(if applicable)' },
                { title: 'Police Check' },
                { title: "Working with Children's Check" },
                { title: 'NDIS Screening Check(when available)' },
            ]
        }
    ]);

    const [uploadDocs, setUploadDocs] = useState([
        {
            title: "Select Category",
            value: "Select option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Select option",
                    selected: true
                },
                {
                    title: "Training",
                    selected: false,
                },
                {
                    title: "Certificates",
                    selected: false,
                },
                {
                    title: "ID / Permit",
                    selected: false,
                },
                {
                    title: "Miscellaneous",
                    selected: false,
                }
            ],
        }
    ]);

    const uploadDocsInArray = (event, index) => {
        const copy = [...uploadDocs];
        const { optionkey, parentkey } = index.props;
        const value = event.target.value;
        copy[parentkey].value = value;
        for (const keyLop in copy[parentkey].options) {
            if (copy[parentkey].options[keyLop].title === value) {
                copy[parentkey].options[keyLop].selected = true;
            } else {
                copy[parentkey].options[keyLop].selected = false;
            }
        }
        setUploadDocs(copy)
    };

    const handleTrainingInputs = (index, event) => {
        const copy = [...training];
        copy[index].value = event.target.value;
        setTraining(copy);
        setDocumentName(event.target.value)
    };

    const handlecertificatesInputs = (index, event) => {
        const copy = [...certificates];
        copy[index].value = event.target.value;
        setCertificates(copy);
        setDocumentName(event.target.value)
    };

    const handleiDPermitInputs = (index, event) => {
        const copy = [...iDPermit];
        copy[index].value = event.target.value;
        setiDPermit(copy);
        setDocumentName(event.target.value)
    };

    const handleMiscellaneousInputs = (index, event) => {
        const copy = [...miscellaneous];
        copy[index].value = event.target.value;
        setMiscellaneous(copy);
        setDocumentName(event.target.value)
    };

    return (
        <Grid container sx={{ display: "flex", justifyContent: "center", my: 2, marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, }}>
            <Grid item={true} xs={11} md={11} lg={11} sx={{ display: "flex", flexDirection: { lg: "row", xs: "column", md: "row", sm: "column" }, }}>
                <Grid item={true} xs={12} sm={12} md={6} lg={6} sx={{ display: "flex", alignItems: "center", flexWrap: 'wrap', justifyContent: { md: "flex-start", lg: "flex-start", xs: "center" } }}>
                    <Button onClick={() => {
                        setNewFeedback(true);
                    }} variant="outlined" sx={{ m: 1, fontFamily: "Roboto-Medium", textTransform: "capitalize" }}>
                        New Document
                    </Button>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} lg={6} >
                    <SearchAndFilters groupBy={false} favorite={false} inputWidth={8} defaultState={false} />
                </Grid>
            </Grid>
            <Grid item={true} xs={11} sm={12} md={11} lg={11}>
                <TrainingList rows={orgs} headCells={headCells} />
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={newFeedback}
                onClose={() => setNewFeedback(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={newFeedback}>
                    <Box sx={style}>
                        <Box>
                            <Typography variant="h5" component="h2" sx={{ fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                                Create new Training And Certifications
                            </Typography>
                        </Box>
                        <Box sx={{ my: 2 }}>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                {uploadDocs.map((v, i) => {
                                    return (
                                        <div key={i}>
                                            <TextField
                                                select={true}
                                                label={v.title}
                                                key={i}
                                                required={v.required}
                                                sx={{ m: 1, width: v.width }}
                                                value={v.value}
                                                onChange={uploadDocsInArray}
                                            >
                                                {v?.options?.map((option, nI) => (
                                                    <MenuItem key={nI} optionkey={nI} parentkey={i} value={option.title}>
                                                        {option.title}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            {v.value.includes("Training") && training.map((vN, iN) => (
                                                <TextField
                                                    select={true}
                                                    label={vN.title}
                                                    key={iN}
                                                    required={vN.required}
                                                    sx={{ m: 1, width: v.width }}
                                                    value={vN.value}
                                                    onChange={(e) => handleTrainingInputs(iN, e)}
                                                >
                                                    {vN?.options?.map((option, nI) => (
                                                        <MenuItem key={nI} optionkey={nI} parentkey={i} value={option.title}>
                                                            {option.title}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            ))}
                                            {v.value.includes("Certificates") && certificates.map((vN, iN) => (
                                                <TextField
                                                    select={true}
                                                    label={vN.title}
                                                    key={iN}
                                                    required={vN.required}
                                                    sx={{ m: 1, width: v.width }}
                                                    value={vN.value}
                                                    onChange={(e) => handlecertificatesInputs(iN, e)}
                                                >
                                                    {vN?.options?.map((option, nI) => (
                                                        <MenuItem key={nI} optionkey={nI} parentkey={i} value={option.title}>
                                                            {option.title}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            ))}
                                            {v.value.includes("Permit") && iDPermit.map((vN, iN) => (
                                                <TextField
                                                    select={true}
                                                    label={vN.title}
                                                    key={iN}
                                                    required={vN.required}
                                                    sx={{ m: 1, width: v.width }}
                                                    value={vN.value}
                                                    onChange={(e) => handleiDPermitInputs(iN, e)}
                                                >
                                                    {vN?.options?.map((option, nI) => (
                                                        <MenuItem key={nI} optionkey={nI} parentkey={i} value={option.title}>
                                                            {option.title}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            ))}
                                            {v.value.includes("Miscellaneous") && miscellaneous.map((vN, iN) => (
                                                <TextField
                                                    select={true}
                                                    label={vN.title}
                                                    key={iN}
                                                    required={vN.required}
                                                    sx={{ m: 1, width: v.width }}
                                                    value={vN.value}
                                                    onChange={(e) => handleMiscellaneousInputs(iN, e)}
                                                >
                                                    {vN?.options?.map((option, nI) => (
                                                        <MenuItem key={nI} optionkey={nI} parentkey={i} value={option.title}>
                                                            {option.title}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            ))}
                                        </div>
                                    )
                                })}
                            </Box>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                {documentName && documentName.toLocaleLowerCase() !== "select option" && (
                                    <Button sx={{ m: 1, width: screenWidth, padding: "15px 16px" }} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                                        {documentName.length > 42 ? documentName.slice(0, 41) + "..." : documentName}
                                        <VisuallyHiddenInput type="file" onChange={(e) => console.log(e.target.files)} />
                                    </Button>
                                )}
                                {jobProfileDetails.map((v, i) => {
                                    return (
                                        <div key={i}>
                                            {v.type === "date" ? (
                                                <DatePickerUI label={v.title} defaultValue={v.value} width={screenWidth} />
                                            ) : null}
                                            {v.type === "text" ? (
                                                <TextField
                                                    type={v.type}
                                                    required={v.required}
                                                    label={v.title}
                                                    defaultValue={v.value}
                                                    sx={{ m: 1, width: v.width }}
                                                />
                                            ) : null}
                                        </div>
                                    )
                                })}
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained" >
                                    Save
                                </Button>
                                <Button variant="contained" onClick={() => setNewFeedback(false)}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Grid>
    )
}