import styled from "@emotion/styled";
import { Button, Divider, Grid, IconButton, Paper, InputBase, Menu, alpha, Stack, Box, Typography, List, ListItemButton, ListItemIcon, ListItemText, ListItem, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import { Cancel, Tag } from "@mui/icons-material";
import StarIcon from '@mui/icons-material/Star';
import { useDispatch } from "react-redux";
import { filterReducer } from "../../middleware/reducers/search";
import { useSelector } from "react-redux";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

const StyledMenu = styled((props) => (
    <Menu
        elevation={8}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        width: window.screen.width > 1400 ? window.screen.width / 3 : window.screen.width > 900 && window.screen.width < 1400 ? window.screen.width / 2.6 : "inherit",
        marginTop: theme.spacing(1),
        backgroundColor: "#F5F7F6",
        border: "1px solid #6ECA35",
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const SearchAndFilters = ({ defaultState, userType = "", placeholder = "Search", groupBy = true, favorite = true, topMargin = 1, centerFlag = "flex-end", inputWidth = 4 }) => {
    const _filters = useSelector((state) => state._search.filters);
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [tags, SetTags] = useState([]);
    const tagRef = useRef();
    const [enableEnter, setEnableEnter] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (_filters.length && defaultState) {
            SetTags([...tags, _filters]);
        }
    }, [])

    const handleDelete = (value) => {
        const newtags = tags.filter((val) => val !== value);
        SetTags(newtags);
        const copy = [..._filters];
        const newcopy = copy.filter((val) => val !== value);
        dispatch(filterReducer(newcopy));
    };

    const handleOnSubmit = () => {
        if (tagRef.current.value !== "" && tagRef.current.value !== " ") {
            SetTags([...tags, tagRef.current.value]);
            const copy = [..._filters];
            copy.push(tagRef.current.value);
            dispatch(filterReducer(copy));
            tagRef.current.value = "";
        }
    };

    const addDefineTags = (value) => {
        if (!tags.includes(value)) {
            if (value === "Inactive") {
                handleDelete("Active")
            }
            if (value === "Active") {
                handleDelete("Inactive")
            }
            SetTags([...tags, value]);
            const copy = [..._filters];
            copy.push(value);
            dispatch(filterReducer(copy));
        }
    }

    const Tags = ({ data, handleDelete }) => {
        return (
            <Box
                sx={{
                    background: "#283240",
                    height: "100%",
                    display: "flex",
                    padding: "0.4rem",
                    margin: "0 0.5rem 0 0",
                    justifyContent: "center",
                    alignContent: "center",
                    color: "#ffffff",
                }}
            >
                <Stack direction='row' gap={1}>
                    <Typography>{data}</Typography>
                    <Cancel
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                            handleDelete(data);
                        }}
                    />
                </Stack>
            </Box>
        );
    };

    const handleKeyDown = (event) => {
        if (event?.key === 'Enter' && enableEnter) {
            event.preventDefault();
            handleOnSubmit();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleInputFocus = () => {
        setEnableEnter(true);
    };

    const handleInputBlur = () => {
        setEnableEnter(false);
    };

    return (
        <Grid container sx={{ px: 1, py: topMargin, display: "flex", justifyContent: { xs: "center", md: centerFlag, lg: centerFlag }, }}>
            <Grid item xs={11} sm={5} md={inputWidth} lg={inputWidth}>
                <Paper
                    elevation={0}
                    component="form"
                    sx={{ p: '0px 3px', display: 'flex', alignItems: 'center', borderRadius: 2, border: "1px solid #6ECA35" }}
                >
                    <TextField
                        inputRef={tagRef}
                        sx={{ flex: 1, }}
                        placeholder={placeholder}
                        onFocus={() => handleInputFocus()}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onBlur={() => handleInputBlur()}
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <Box sx={{ margin: "0 0.2rem 0 0", display: "flex" }}>
                                    {tags.map((data, index) => {
                                        return (
                                            <Tags data={data} handleDelete={handleDelete} key={index} />
                                        );
                                    })}
                                </Box>
                            ),
                        }}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon color="secondary" />
                    </IconButton>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton
                        onClick={handleClick}
                        color="primary"
                        sx={{ p: '10px' }}
                        aria-label="directions"
                    >
                        <FilterAltOutlinedIcon color="primary" />
                    </IconButton>
                </Paper>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <Grid container sx={{ display: "flex", flexDirection: "row", }}>
                        <Grid item xs={5.5} md={groupBy || favorite ? 5.5 : 12} lg={groupBy || favorite ? 4 : 12} sx={{ pl: 2, pb: 2 }}>
                            <List
                                sx={{ width: '100%', p: 0, }}
                                aria-label="Filters"
                            >
                                <ListItem disablePadding sx={{ bgcolor: "#F5F7F6" }}>
                                    <ListItemButton disableTouchRipple >
                                        <ListItemText primary={<span style={{ fontFamily: "Roboto-Medium", color: "#6ECA35", fontSize: 18 }}>Filters</span>} />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider />
                            <List
                                sx={{ width: '100%', borderRadius: 2, p: 0, }}
                                aria-label="Filters"
                            >
                                {/* <ListItem disablePadding sx={{ bgcolor: "#eaeaea" }}>
                                    <ListItemButton disableTouchRipple >
                                         <ListItemIcon>
                                            <FilterAltIcon color="primary" />
                                        </ListItemIcon> 
                                        <ListItemText primary={<span style={{ fontFamily: "Roboto-Medium", color: "#6ECA35", fontSize: 18 }}>Filters</span>} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider /> */}
                                <ListItem disablePadding sx={{ borderRadius: 2, overflow: "hidden" }}>
                                    <ListItemButton sx={{ ":hover": { bgcolor: "#ffffff" } }}>
                                        <ListItemText primary="First Name" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding sx={{ borderRadius: 2, overflow: "hidden" }}>
                                    <ListItemButton sx={{ ":hover": { bgcolor: "#ffffff" } }}>
                                        <ListItemText primary="Last Name" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding sx={{ borderRadius: 2, overflow: "hidden" }}>
                                    <ListItemButton sx={{ ":hover": { bgcolor: "#ffffff" } }}>
                                        <ListItemText primary="Age" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding sx={{ borderRadius: 2, overflow: "hidden" }}>
                                    <ListItemButton sx={{ ":hover": { bgcolor: "#ffffff" } }}>
                                        <ListItemText primary="Add Custom Filter" />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Grid>
                        {/* {groupBy && (<Divider sx={{ height: "auto", m: 0.5 }} orientation="vertical" />)} */}
                        {groupBy && (
                            <Grid item xs={6.2} md={6.2} lg={4} sx={{ pl: 2, pb: 2 }}>
                                <List
                                    sx={{ width: '100%', bgcolor: '#F5F7F6', p: 0 }}
                                    aria-label="GroupBy"
                                >
                                    <ListItem disablePadding>
                                        <ListItemButton disableTouchRipple >
                                            <ListItemText primary={<span style={{ fontFamily: "Roboto-Medium", color: "#6ECA35", fontSize: 18 }}>Group By</span>} />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                                <Divider />
                                <List
                                    sx={{ width: '100%', borderRadius: 2, p: 0, }}
                                    aria-label="GroupBy"
                                >
                                    <ListItem disablePadding sx={{ borderRadius: 2, overflow: "hidden" }}>
                                        <ListItemButton sx={{ ":hover": { bgcolor: "#ffffff" } }}>
                                            <ListItemText primary="Servies" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding sx={{ borderRadius: 2, overflow: "hidden" }}>
                                        <ListItemButton sx={{ ":hover": { bgcolor: "#ffffff" } }}>
                                            <ListItemText primary="Tags" />
                                        </ListItemButton>
                                    </ListItem>
                                    {userType === "client" && (
                                        <ListItem disablePadding sx={{ borderRadius: 2, overflow: "hidden" }}>
                                            <ListItemButton sx={{ ":hover": { bgcolor: "#ffffff" } }}>
                                                <ListItemText primary="Medication" />
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                </List>
                            </Grid>
                        )}
                        {/* {favorite && (<Divider sx={{ height: "auto", m: 0.5, display: { lg: "block", xs: "none", md: "none" } }} orientation="vertical" />)} */}
                        {favorite && (
                            <Grid item xs={12} md={12} lg={3.5} sx={{ pl: 2, pb: 2 }}>
                                <List
                                    sx={{ width: '100%', bgcolor: '#F5F7F6', p: 0, }}
                                    aria-label="GroupBy"
                                >
                                    <ListItem disablePadding>
                                        <ListItemButton disableTouchRipple >
                                            <ListItemText primary={<span style={{ fontFamily: "Roboto-Medium", color: "#6ECA35", fontSize: 18 }}>Favorite</span>} />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                                <Divider />
                                <List
                                    sx={{ width: '100%', borderRadius: 2, p: 0, }}
                                    aria-label="GroupBy"
                                >
                                    <ListItem disablePadding sx={{ borderRadius: 2, overflow: "hidden" }}>
                                        <ListItemButton sx={{ ":hover": { bgcolor: "#ffffff" } }} selected={tags.includes("Active") ? true : false} onClick={() => addDefineTags("Active")}>
                                            <ListItemText primary="Active" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding sx={{ borderRadius: 2, overflow: "hidden" }}>
                                        <ListItemButton sx={{ ":hover": { bgcolor: "#ffffff" } }} selected={tags.includes("Inactive") ? true : false} onClick={() => addDefineTags("Inactive")}>
                                            <ListItemText primary="Inactive" />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </Grid>
                        )}
                    </Grid>
                </StyledMenu>
            </Grid>
        </Grid>
    )
}

export default SearchAndFilters;