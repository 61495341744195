import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    reimbursement_list: null,
    kilometers_claim_list: null,
    completed_service_plan: null,
    completed_shifts: null,
    process_state: {
        start_date: "",
        end_date: ""
    }
};

export const PaymentProcessingReducer = createSlice({
    name: 'payment_processing',
    initialState,
    reducers: {
        reimbursementListState: (state, action) => {
            state.reimbursement_list = action.payload;
        },
        kilometersClaimState: (state, action) => {
            state.kilometers_claim_list = action.payload;
        },
        completedServicePlan: (state, action) => {
            state.completed_service_plan = action.payload;
        },
        processStates: (state, action) => {
            state.process_state = action.payload;
        },
        completedShifts: (state, action) => {
            state.completed_shifts = action.payload;
        },
    },
});

export const {
    reimbursementListState,
    kilometersClaimState,
    completedServicePlan,
    processStates,
    completedShifts,
} = PaymentProcessingReducer.actions;

export default PaymentProcessingReducer.reducer;