import { Grid, Typography } from "@mui/material";
import React from "react";
import CopyrightIcon from '@mui/icons-material/Copyright';
export const Footer = () => {

    return (
        <Grid className="footer" sx={{ bgcolor: "#013447" }}>
            < div className="footer-icon" >
                <span> All Rights Reserved. <a href="#">Privacy Policy</a> <a href="#">Terms of Use</a> </span>
            </div >
            <div className="footer-text">
                <span>
                    Email: <a href="#">hello@cennasupports.com</a>
                </span>
            </div>
            <div className="footer-text">
                <span>
                    Copyright &#169; 2023 CennaSupports.
                </span>
            </div>
        </Grid >
    )
}