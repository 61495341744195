import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dynamic_staff_onboard: null,
    created_id: null,
    general_information: null,
    contact_information: null,
    emergency_contact_information: null,
    working_days: null,
    professional_details: null,
    others_information: null,
    payment_details: null,
    draftList: [
        {
            general_information: {
                first_name: "Salman",
                last_name: "Zafar",
                gender: "Male",
                date_of_birth: "1991-08-22",
                ndis_registration_number: "2569878",
                registration_expiry_date: "2025-05-25",
                visa_status: "Temporary",
                designation: "Support Worker",
                have_medical_card: "Yes",
                medicare_card: "123987-1",
                police_check: "Verified",
                driving_license: "3265987",
            },
            contact_information: {
                email: "admin@belacorp.com.au",
                home_phone: "0236598778",
                mobile_phone: "0236598778",
                address_1: "its my address 1",
                address_2: "its my address 2",
                town_suburb: "Blakeview",
                postal_code: "BV1235",
            },
            emergency_contact_information: {
                first_name: "Salman",
                last_name: "Zafar",
                relationship: "Own",
                email: "admin@belacorp.com.au",
                home_phone: "0236598778",
                mobile_phone: "0236598778",
                address_1: "its my address 1",
                address_2: "its my address 2",
            },
            working_days: [
                {
                    day: "Monday",
                    slots: [
                        {
                            start_time: { H: "08", M: "00" },
                            end_time: { H: "17", M: "00" },
                        }
                    ],
                    availability: {
                        unavailable: false,
                        whole_day: false
                    }
                },
                {
                    day: "Tuesday",
                    slots: [
                        {
                            start_time: { H: "08", M: "00" },
                            end_time: { H: "17", M: "00" },
                        }
                    ],
                    availability: {
                        unavailable: false,
                        whole_day: true
                    }
                }
            ],
            others_information: {
                are_you_a_smoker_drinker: "No",
                do_you_have_any_allergies: "No",
                do_you_have_any_type_of_conveyance_to_commute: "No",
                do_you_have_a_driving_license: "No",
            },
            payment_details: {
                account_name: "",
                bsb_number: "",
                account_number: "",
                fund_name: "",
                member_name: "",
                tfn_number: "",
                have_tax_free_threshold: "",
                reason_notes: "",
            }
        }
    ],
    selectedDraft: null
};

export const StaffOnboardReducer = createSlice({
    name: 'staff_onboarding',
    initialState,
    reducers: {
        GeneralInformation: (state, action) => {
            state.general_information = action.payload;
        },
        createdId: (state, action) => {
            state.created_id = action.payload;
        },
        ContactInformation: (state, action) => {
            state.contact_information = action.payload;
        },
        EmergencyContactInformation: (state, action) => {
            state.emergency_contact_information = action.payload;
        },
        WorkingDays: (state, action) => {
            state.working_days = action.payload;
        },
        ProfessionalDetails: (state, action) => {
            state.professional_details = action.payload;
        },
        OthersInformation: (state, action) => {
            state.others_information = action.payload;
        },
        PaymentDetailsM: (state, action) => {
            state.payment_details = action.payload;
        },
        DraftList: (state, action) => {
            state.draftList = action.payload;
        },
        SelectedDraft: (state, action) => {
            state.selectedDraft = action.payload;
        },
        DynamicStaffOnboard: (state, action) => {
            state.dynamic_staff_onboard = action.payload;
        },
    },
});

export const {
    GeneralInformation,
    ContactInformation,
    EmergencyContactInformation,
    WorkingDays,
    ProfessionalDetails,
    OthersInformation,
    PaymentDetailsM,
    DraftList,
    SelectedDraft,
    DynamicStaffOnboard,
    createdId,
} = StaffOnboardReducer.actions;

export default StaffOnboardReducer.reducer;