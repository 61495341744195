import React, { useState } from "react";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import './style.css'
import { DateTimePicker, DesktopTimePicker } from "@mui/x-date-pickers";

export const CsutomInput = ({ type, placeholder, label, defaultValue }) => {
    const [value, setValue] = useState(defaultValue)
    const input = (
        <div className="textOnInput">
            <label htmlFor={label}>{label}</label>
            <input
                value={value}
                type={type}
                placeholder={placeholder}
                onChange={(e) => setValue(e.target.value)}
                className="form-control"
            />
        </div>
    );

    return [value, input];
};

export const CheckBox = () => {
    const [check, setCheck] = useState(false);

    const clickCheckMark = () => {
        setCheck(!check)
    }
    const checkBox = (
        <div className="custom-checkbox">
            <div className="left-box">
                {check ? (
                    <div onClick={() => clickCheckMark()} className="checkbox-true">
                        <i className="fas fa-check"></i>
                    </div>
                ) : (
                    <div onClick={() => clickCheckMark()} className="checkbox-false"></div>
                )}
            </div>
            <div className="right-box">
                <span>Keep me signed in.</span>
            </div>
        </div>
    );

    return [check, checkBox];
}

export const DatePickerUI = ({ label, defaultValue, width }) => {
    const [value, setValue] = React.useState(null);
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                htmlFor={label}
                label={label}
                value={value}
                sx={{ m: 1, width: width }}
                defaultValue={dayjs(defaultValue)}
                views={['month', 'day', 'year']}
                onChange={(e) => setValue(e)}
            />
        </LocalizationProvider >
    );
}

export const TimePickerUI = ({ label, defaultValue, width, disabled = false }) => {
    const [value, setValue] = React.useState(defaultValue);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopTimePicker
                disabled={disabled}
                htmlFor={label}
                label={label}
                value={value}
                sx={{ m: 1, width: width }}
                onChange={(e) => setValue(e)}
            />
        </LocalizationProvider >
    );
}

export const DateAndTimePicker = ({ label, defaultValue, width }) => {
    const [value, setValue] = React.useState(null);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                htmlFor={label}
                label={label}
                value={value}
                sx={{ m: 1, width: width }}
                onChange={(e) => setValue(e)}
            />
        </LocalizationProvider >
    );
}