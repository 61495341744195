import * as React from 'react';
import {
    StepContent,
    StepLabel,
    Box,
    Stepper,
    Step,
    Typography,
    Grid
} from '@mui/material';
import { setCategoryStep } from '../../middleware/reducers/category_step_reducer';
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingState } from '../../middleware/reducers/loading_state_reducer';

export const VerticalLinearStepper = ({ steps, error }) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [stepError, setStepError] = React.useState(false);
    const [stepIndex, setStepIndex] = React.useState(null);

    const loadingState = useSelector((state) => state._loading_state.loading_state);
    const _saving_staff_list = useSelector((state) => state._staff_onboarding_module.dynamic_staff_onboard);
    const _category_step = useSelector((state) => state._category_step_state.category_step);

    const labelKey = _category_step?.toLowerCase().split('/ ').join('')?.split(' ').join('_')

    const isStepInSavingList = Object.keys(_saving_staff_list || '').includes(labelKey);

    const stepErrorRef = React.useRef(stepError);

    const dispatch = useDispatch();

    function debounce(func, timeout = 1000) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    const handleReset = () => {
        setActiveStep(0);
    };

    const setActiveStepFunc = debounce((index) => {
        if (stepErrorRef.current === false) {
            setActiveStep(index);
            dispatch(setLoadingState('stale'))
        }
    });

    const handleClickStep = (index) => {
        setStepIndex(index)
        const prevStep = steps[index - 1];

        if (!isStepInSavingList && index > (activeStep + 1)) {
            dispatch(setCategoryStep(null))
        }

        if (index === (activeStep + 1)) {
            dispatch(setCategoryStep(prevStep?.label ?? 'general_information_(staff)'))
        }

    };

    React.useEffect(() => {
        if (stepIndex && loadingState === 'completed') {
            setActiveStepFunc(stepIndex)
        }
        else if (stepIndex && loadingState === 'error') {
            dispatch(setCategoryStep(null))
            dispatch(setLoadingState('stale'))
        }
    }, [loadingState])

    React.useEffect(() => {
        const isPrevStepInSavingList = Object.keys(_saving_staff_list || {}).includes(steps[stepIndex - 1]?.label?.toLowerCase().split('/ ').join('')?.split(' ').join('_'));

        if (isStepInSavingList && (stepIndex === (activeStep + 1) || stepIndex < (activeStep + 1))) {
            setActiveStepFunc(stepIndex);
        }
        if (isPrevStepInSavingList) {
            setActiveStepFunc(stepIndex);
        }

    }, [stepIndex])

    React.useEffect(() => { stepErrorRef.current = stepError; }, [stepError]);

    React.useEffect(() => { setStepError(error) }, [error])

    React.useEffect(() => { handleReset() }, [steps])

    return (
        <Grid item={true} xs={12} sm={12} md={12} lg={12} >
            <Stepper activeStep={activeStep} orientation="vertical" >
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel
                            sx={{
                                cursor: "pointer",
                                '& .MuiStepIcon-text': {
                                    fill: '#ffffff', // Default text color inside the circle
                                    '&.Mui-active': {
                                        fill: '#ffffff', // Active step text color inside the circle
                                    },
                                    '&.Mui-completed': {
                                        fill: '#ffffff', // Completed step text color inside the circle
                                    },
                                },
                                '& .MuiStepIcon-root': {
                                    color: '#cccccc', // Active step color
                                    '&.Mui-active': {
                                        color: '#6ECA35', // Color for active step
                                    },
                                    '&.Mui-completed': {
                                        color: '#6ECA35', // Color for completed step
                                    },
                                },
                            }}
                            // disabled={(activeStep + 1) === index ? false : true}
                            onClick={() => handleClickStep(index)}
                        >
                            <Typography>{step.label}</Typography>
                        </StepLabel>
                        <StepContent>
                            <Box sx={{ mt: 1, mb: 1, py: 1, display: 'flex', flexWrap: 'wrap', }}>
                                {steps[activeStep]?.screen}
                            </Box>

                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </Grid >
    );
}
