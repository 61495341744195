import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import APIGQL from "./graphql";


export const GetAllRelationalClientInformationById = () => {
    const [getById, { loading, error }] = useLazyQuery(APIGQL.ApiGetAllRelationalClientInformationById, {
        fetchPolicy: "network-only"
    })
    return [getById, loading, error];
}


export const AuditDocumentList = () => {
    const { data, loading } = useQuery(APIGQL.ApiGetDocumentList, {
        fetchPolicy: "network-only"
    });
    const auditList = data?.auditDocuments?.data;

    return [auditList, loading]
}

export const AuditModuleList = () => {
    const { data, loading } = useQuery(APIGQL.ApiGetAuditModules, {
        fetchPolicy: "network-only"
    });
    const auditModules = data?.auditModules?.data;
    return [auditModules, loading]
}

export const AuditCategoryList = () => {
    const { data, loading } = useQuery(APIGQL.ApiGetAuditCategories, {
        fetchPolicy: "network-only"
    });
    const auditCategories = data?.auditCategories?.data;
    return [auditCategories, loading]
}

export const AuditTypeList = () => {
    const { data, loading } = useQuery(APIGQL.ApiGetAuditTypes, {
        fetchPolicy: "network-only"
    });
    const auditTypes = data?.auditTypes?.data;
    return [auditTypes, loading]
}

export const AuditDocumentNameList = () => {
    const { data, loading } = useQuery(APIGQL.ApiGetAuditDocumentNames, {
        fetchPolicy: "network-only",
    });
    const auditDocumentNames = data?.auditDocumentNames?.data;
    return [auditDocumentNames, loading]
}

export const PriceLists = () => {
    const { data, loading } = useQuery(APIGQL.ApiGetPriceList, {
        fetchPolicy: "network-only",
    });
    const priceLists = data?.priceLists?.data;
    return [priceLists, loading]
}

export const OrganizationList = () => {
    const { data, loading } = useQuery(APIGQL.ApiGetOrganizationList, {
        fetchPolicy: "network-only",
    });
    const orgnizations = data?.orgnizations?.data;
    return [orgnizations, loading]
}

export const GetAllUserDetails = () => {
    const { data, loading } = useQuery(APIGQL.ApiGetAllUserDetails, {
        fetchPolicy: "network-only",
    });
    return [data, loading]
}

// // // // ================mutation==================================================================
export const CreateNewDocument = () => {
    const [createNewDocument, { loading, error }] = useMutation(APIGQL.ApiCreateNewDocument);
    return [createNewDocument, loading, error]
}

export const CreateNewModule = () => {
    const [createModule, { loading, error }] = useMutation(APIGQL.ApiCreateNewModule);
    return [createModule, loading, error]
}

export const CreateNewCategory = () => {
    const [createCategory, { loading, error }] = useMutation(APIGQL.ApiCreateAuditCategory);
    return [createCategory, loading, error]
}

export const CreateNewType = () => {
    const [createType, { loading, error }] = useMutation(APIGQL.ApiCreateAuditType);
    return [createType, loading, error]
}

export const CreateNewDocumentName = () => {
    const [createDocName, { loading, error }] = useMutation(APIGQL.ApiCreateAuditDocumentName);
    return [createDocName, loading, error]
}

export const CreateNewPriceList = () => {
    const [priceList, { loading, error }] = useMutation(APIGQL.ApiCreatePriceList);
    return [priceList, loading, error];
}

export const CreateNewOrganization = () => {
    const [organizationList, { loading, error }] = useMutation(APIGQL.ApiCreateOrganization);
    return [organizationList, loading, error];
}

export const UpdateStaffDetail = () => {
    const [updateStaff, { loading, error }] = useMutation(APIGQL.ApiUpdateStaffDetail);
    return [updateStaff, loading, error];
}

export const UpdateClientDetail = () => {
    const [updateClient, { loading, error }] = useMutation(APIGQL.ApiUpdateClientDetail);
    return [updateClient, loading, error];
}

export const UpdateClientAnswerLovsReferenceById = () => {
    const [updateClient, { loading, error }] = useMutation(APIGQL.ApiUpdateClientAnswerLovsReference);
    return [updateClient, loading, error];
}

export const UpdateContactDetail = () => {
    const [updateClient, { loading, error }] = useMutation(APIGQL.ApiUpdateContactDetail);
    return [updateClient, loading, error];
}

export const UpdatReferringDoctorInformation = () => {
    const [updateClient, { loading, error }] = useMutation(APIGQL.ApiUpdateReferringDoctorInformation);
    return [updateClient, loading, error];
}


export const UpdateOnboardMedicalHistoryDetail = () => {
    const [updateClient, { loading, error }] = useMutation(APIGQL.ApiUpdateOnboardMedicalHistoryDetail);
    return [updateClient, loading, error];
}

export const UpdateUpdateClientQuestionAnswerRef = () => {
    const [updateClient, { loading, error }] = useMutation(APIGQL.ApiUpdateClientQuestionAnswerRef);
    return [updateClient, loading, error];
}

export const UpdateMedicalInformation = () => {
    const [updateClient, { loading, error }] = useMutation(APIGQL.ApiUpdateMedicalInformation);
    return [updateClient, loading, error];
}

export const UpdateEmergencyContactDetail = () => {
    const [updateClient, { loading, error }] = useMutation(APIGQL.ApiUpdateEmergencyContactDetail);
    return [updateClient, loading, error];
}

export const UpdateAdministrativeNote = () => {
    const [updateClient, { loading, error }] = useMutation(APIGQL.ApiUpdateAdministrativeNote);
    return [updateClient, loading, error];
}

export const UpdateServiceInformation = () => {
    const [updateClient, { loading, error }] = useMutation(APIGQL.ApiUpdateServiceInformation);
    return [updateClient, loading, error];
}

export const UpdatePreferenceForStaffWorker = () => {
    const [updateClient, { loading, error }] = useMutation(APIGQL.ApiUpdatePreferenceForStaffWorker);
    return [updateClient, loading, error];
}

export const UpdateClientGoal = () => {
    const [updateClient, { loading, error }] = useMutation(APIGQL.ApiUpdateClientGoal);
    return [updateClient, loading, error];
}

export const UpdateCheckListAlertsNotification = () => {
    const [updateClient, { loading, error }] = useMutation(APIGQL.ApiUpdateCheckListAlertsNotification);
    return [updateClient, loading, error];
}

export const UpdatePlanReview = () => {
    const [updateClient, { loading, error }] = useMutation(APIGQL.ApiUpdatePlanReview);
    return [updateClient, loading, error];
}

export const UpdatePlanSupportCategory = () => {
    const [updateClient, { loading, error }] = useMutation(APIGQL.ApiUpdatePlanSupportCategory);
    return [updateClient, loading, error];
}