import { Alert, AlertTitle, Backdrop, Box, Button, CircularProgress, Fade, Grid, MenuItem, Modal, Slide, Snackbar, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CreateNewOrganization, OrganizationList } from "../../../services/admin_service";
import { OrganizationGrid } from "../../../components/pagination_view";

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.screen.width / 1.5,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    p: 4,
};

const SlideTransition = (props) => {
    return <Slide {...props} direction="left" />;
}

const headCells = [
    {
        id: 'Id',
        numeric: false,
        disablePadding: true,
        label: 'Id',
    },
    {
        id: 'Registration#',
        numeric: false,
        disablePadding: true,
        label: 'Registration#',
    },
    {
        id: 'ABN',
        numeric: false,
        disablePadding: true,
        label: 'ABN',
    },
    {
        id: 'Company Name',
        numeric: false,
        disablePadding: true,
        label: 'Company Name',
    },
    {
        id: 'Email',
        numeric: true,
        disablePadding: false,
        label: 'Email',
    },
    {
        id: 'Phone',
        numeric: true,
        disablePadding: false,
        label: 'Phone',
    },
    {
        id: 'Primary Invoice',
        numeric: true,
        disablePadding: false,
        label: 'Primary Invoice',
    },
    {
        id: 'Primary Schedule Email',
        numeric: true,
        disablePadding: false,
        label: 'Primary Schedule Email',
    },
    {
        id: 'Secondary Invoice',
        numeric: true,
        disablePadding: false,
        label: 'Secondary Invoice',
    },
    {
        id: 'Seconday Schedule Email',
        numeric: true,
        disablePadding: false,
        label: 'Seconday Schedule Email',
    },
    {
        id: 'Website Url',
        numeric: true,
        disablePadding: false,
        label: 'Website Url',
    },
    {
        id: 'Notes',
        numeric: true,
        disablePadding: false,
        label: 'Notes',
    },
    {
        id: 'Description',
        numeric: true,
        disablePadding: false,
        label: 'Description',
    },
    {
        id: 'Options',
        numeric: true,
        disablePadding: false,
        label: 'Options',
    },
];

const ManageOrganization = () => {
    // const navigate = useNavigate();
    const height = window.screen.height;
    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 5
        }
        if (window.screen.width > 600 && window.screen.width < 1600) {
            return window.screen.width / 4
        } else {
            return window.screen.width / 1.3
        }
    }
    const [screenWidth, setScreenWidth] = useState(width);
    const [open, setOpen] = React.useState(false);
    const [rows, setRows] = useState([]);

    const [orgnizations, loading_list] = OrganizationList()
    const [create_organization, loading, error] = CreateNewOrganization();

    const handleOpen = () => setOpen(true);

    const [snacks, setSnack] = useState({
        open: false,
        Transition: SlideTransition,
        vertical: 'top',
        horizontal: 'right',
    });

    const [errorSnackMessage, setErrorSnackMessage] = useState({
        title: "",
        message: "",
        severity: "",
        variant: "filled"
    });

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen])

    const [stateOrganiz, setStateOrganiz] = useState([
        {
            title: "Registration Number",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false,
            validation_query: /^\d+$/,
            validate: true,
        },
        {
            title: "Company Name",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false,
            validation_query: /^[a-zA-Z ]*$/,
            validate: true,
        },
        {
            title: "Email",
            value: "",
            width: screenWidth,
            type: "email",
            required: true,
            multiline: false,
            validation_query: /^\S+@\S+\.\S+$/,
            validate: true,
        },
        {
            title: "Phone",
            value: "",
            width: screenWidth,
            type: "number",
            required: true,
            multiline: false,
            validation_query: /^\+?[0-9][0-9]{9,10}$/,
            validate: true,
        },
        {
            title: "Website URL",
            value: "",
            width: screenWidth,
            type: "url",
            required: true,
            multiline: false,
            validation_query: /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
            validate: true,
        },
        {
            title: "ABN",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false,
            validation_query: /^\d+$/,
            validate: true,
        },
        {
            title: "Description",
            value: "",
            width: screenWidth,
            type: "text",
            required: false,
            multiline: true,
            validation_query: /^[a-zA-Z ]*$/,
            validate: true,
        },
        // {
        //     title: "Invoice To",
        //     value: "",
        //     width: screenWidth,
        //     type: "email",
        //     required: true,
        //     multiline: false,
        // },
        {
            title: "Primary Invoice",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false,
            validation_query: /^[a-zA-Z ]*$/,
            validate: true,
        },
        {
            title: "Secondary Invoice",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false,
            validation_query: /^[a-zA-Z ]*$/,
            validate: true,
        },
        {
            title: "Primary Schedule Email",
            value: "",
            width: screenWidth,
            type: "email",
            required: true,
            multiline: false,
            validation_query: /^\S+@\S+\.\S+$/,
            validate: true,
        },
        {
            title: "Secondary Schedule Email",
            value: "",
            width: screenWidth,
            type: "email",
            required: true,
            multiline: false,
            validation_query: /^\S+@\S+\.\S+$/,
            validate: true,
        },
        {
            title: "Notes",
            value: "",
            width: screenWidth,
            type: "text",
            required: false,
            multiline: true,
            validation_query: /^[a-zA-Z ]*$/,
            validate: true,
        },
    ])

    const editItemEmpty = () => {
        const copy = [...stateOrganiz];
        for (const key in copy) {
            copy[key].value = "";
        }
        setOpen(false);
        setStateOrganiz(copy);
    }

    const createNewOrganizationQuery = async (obj) => {
        try {
            const response = await create_organization({
                variables: obj
            });
            setSnack({
                ...snacks,
                open: true,
            });
            if (response?.data) {
                setErrorSnackMessage({
                    ...errorSnackMessage,
                    title: response?.data?.createOrgnization?.data?.attributes?.company_name,
                    message: "New Organization Created.!",
                    severity: "success"
                })
                editItemEmpty()
            } else {
                throw response
            }
        } catch (error) {
            setErrorSnackMessage({
                ...errorSnackMessage,
                title: error?.response?.data?.error?.name,
                message: error?.response?.data?.error?.message,
                severity: "error"
            })
        }
    }
    const convertDataIntoSimplifyObjects = (data) => {
        let inputs = {};
        for (const key in data) {
            inputs[data[key].title.toLowerCase().split('/ ').join('')?.split(' ').join('_')] = data[key].value;
        }
        return inputs
    }
    const saveItem = () => {
        const copy = [...stateOrganiz];
        const object = convertDataIntoSimplifyObjects(copy)
        // console.log(object)
        createNewOrganizationQuery(object);
    }

    const handleCloseSnacks = () => {
        setSnack({
            ...snacks,
            open: false,
        });
    };

    useEffect(() => {
        if (orgnizations) {
            const list = [];
            for (const key in orgnizations) {
                const data = orgnizations[key]?.attributes;
                list.push({
                    id: Number(key) + 1,
                    ABN: data?.abn,
                    registration_number: data?.registration_number,
                    company_name: data?.company_name,
                    description: data?.description,
                    email: data?.email,
                    notes: data?.notes,
                    phone: data?.phone,
                    primary_invoice: data?.primary_invoice,
                    primary_schedule_email: data?.primary_schedule_email,
                    secondary_invoice: data?.secondary_invoice,
                    secondary_schedule_email: data?.secondary_schedule_email,
                    website_url: data?.website_url,
                })
            }
            setRows(list);
        }
    }, [orgnizations])

    const editOrgnizItem = (id) => {

    }

    return (
        <Grid container sx={{ py: 2, display: "flex", justifyContent: "center", }}>
            <Grid item={true} xs={11.5} sm={12} md={11} lg={11} sx={{ display: "flex", justifyContent: "flex-end", }}>
                <Stack direction="row" spacing={2}>
                    <Button onClick={handleOpen} variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                        Create Organization
                    </Button>
                </Stack>
            </Grid>
            <Grid item={true} xs={11.5} sm={12} md={11} lg={11} sx={{ my: 1, }}>
                {rows.length ? <OrganizationGrid headCells={headCells} rows={rows} editOrgnizItem={editOrgnizItem} /> : <>
                    {loading_list ? (
                        <Box
                            sx={{
                                position: "fixed",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        >
                            <CircularProgress color="secondary" size={50} />
                        </Box>
                    ) : null}
                </>}
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box>
                            <Typography color={"primary"} sx={{ fontFamily: "Roboto-Bold", fontSize: "1.5em" }}>
                                Create Organization
                            </Typography>
                        </Box>
                        <Box sx={{ py: 2, }}>
                            {stateOrganiz.map((v, i) => {
                                return (
                                    <TextField
                                        multiline={v.multiline}
                                        key={i}
                                        required={v.required}
                                        label={v.title}
                                        value={v.value}
                                        sx={{ m: 1, my: 1, width: screenWidth }}
                                        // helperText={value.validate ? value.error : ""}
                                        error={v.validate ? false : true}
                                        onChange={(e) => {
                                            const copy = [...stateOrganiz];
                                            const valueTobe = e.target.value;
                                            const validateFlag = copy[i].validation_query.test(valueTobe);
                                            copy[i].validate = validateFlag;
                                            copy[i].value = valueTobe;
                                            setStateOrganiz(copy);
                                        }}
                                    />
                                )
                            })}
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
                            <Stack direction="row" spacing={2}>
                                <Button onClick={() => saveItem()} variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Save {loading ? <CircularProgress color="secondary" size={26} /> : null}
                                </Button>
                                <Button onClick={() => editItemEmpty()} variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
            {/* messages */}
            <Snackbar open={snacks.open} autoHideDuration={3000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
                <Alert
                    onClose={handleCloseSnacks}
                    severity={errorSnackMessage.severity}
                    variant={errorSnackMessage.variant}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{errorSnackMessage.title}</AlertTitle>
                    {errorSnackMessage.message}
                </Alert>
            </Snackbar>
        </Grid>
    )
}

export default ManageOrganization;