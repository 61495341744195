import styled from "@emotion/styled";
import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { VerticalLinearStepper } from "../../../components/form_steps";
import { AdminReimbursementList } from "../sub-screen/admin-reimbursment";
import { AdminKilometerClaims } from "../sub-screen/admin-kilometers-claim";
import { PayrollProcessList } from "../sub-screen/payroll_process";
import { ClaimProcess } from "../sub-screen/claim";

const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
}));

const PaymentProcessingSteps = ({ screenWidth }) => {
    const [steps, setSteps] = useState([
        {
            label: 'Reimbursement',
            // description: '',
            tabActive: false,
            screen: <AdminReimbursementList />
        },
        {
            label: 'Kilometers Claims',
            // description: '',
            tabActive: false,
            screen: <AdminKilometerClaims />
        },
        {
            label: "Invoicing (Organization, Self, NDIS)",
            // description: '',
            tabActive: false,
            screen: <ClaimProcess screenWidth={screenWidth} />
        },
        {
            label: "Pays (Accept/Reject)",
            // description: '',
            tabActive: false,
            screen: <PayrollProcessList />
        },
        // {
        //     label: "Reports (downloadable PDF/CSV)",
        //     // description: '',
        //     tabActive: false,
        //     screen: "Opps"
        // }
    ]);

    return (
        <Grid container sx={{ justifyContent: "center", marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, overflowY: "hidden" }} >
            <Grid item={true} xs={12} sm={12} md={12} lg={12} >
                <StyledPaper
                    sx={{
                        my: 1,
                        mx: 'auto',
                    }}
                    elevation={0}
                >
                    <VerticalLinearStepper steps={steps} />
                </StyledPaper>
            </Grid >
        </Grid>
    )
}

export default PaymentProcessingSteps;