import { Backdrop, Box, Button, Fade, Grid, MenuItem, Modal, Stack, TextField, Typography, styled, List, ListItemText, ListItem, IconButton, } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchAndFilters from "../../../components/search_filters";
import { MeetingList } from "../../../components/pagination_view";
import { DatePickerUI, TimePickerUI } from "../../../components/Inputs";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


export const MyMeetings = () => {

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
    const year = currentDate.getFullYear();
    const monthValue = month < 10 ? "0" + month : month;

    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        maxWidth: "calc(70%)",
        minWidth: "calc(60%)",
        boxShadow: 12,
        borderRadius: 1,
        p: 4,
        maxHeight: "calc(90%)",
        overflowY: "auto"
    });

    const [newFeedback, setNewFeedback] = useState(false);

    // const height = window.screen.height;

    const width = () => {
        // modal control
        if (window.screen.width < 900) {
            const copy = { ...style };
            copy.maxWidth = "95%";
            setStyle(copy);
        }

        if (window.screen.width > 1600) {
            return window.screen.width / 4
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.4
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const meetings = (id, meetingTitle, meetingCategory, meetingDate, meetingTime, attendeesName, agendaItems, actionItems, notes, follow_up_actions, NextMeetingDate) => {
        return {
            id, meetingTitle, meetingCategory, meetingDate, meetingTime, attendeesName, agendaItems, actionItems,
            notes,
            follow_up_actions,
            NextMeetingDate,
        };
    }

    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'Meeting Title',
            numeric: false,
            disablePadding: true,
            label: 'Meeting Title',
        },
        {
            id: 'Meeting Category',
            numeric: false,
            disablePadding: true,
            label: 'Meeting Category',
        },
        {
            id: 'Meeting Date',
            numeric: false,
            disablePadding: true,
            label: 'Meeting Date',
        },
        {
            id: 'Meeting Time',
            numeric: false,
            disablePadding: true,
            label: 'Meeting Time',
        },
        {
            id: 'Attendees Name',
            numeric: false,
            disablePadding: true,
            label: 'Attendees Name',
        },
        {
            id: 'Agenda Items',
            numeric: false,
            disablePadding: true,
            label: 'Agenda Items',
        },
        {
            id: 'Action Items',
            numeric: false,
            disablePadding: true,
            label: 'Action Items',
        },
        {
            id: 'Notes',
            numeric: false,
            disablePadding: true,
            label: 'Notes',
        },
        {
            id: 'Follow Up Actions',
            numeric: false,
            disablePadding: true,
            label: 'Follow Up Actions',
        },
        {
            id: 'Next Meeting Date',
            numeric: false,
            disablePadding: true,
            label: 'Next Meeting Date',
        },
        {
            id: 'Options',
            numeric: false,
            disablePadding: true,
            label: 'Options',
        },
    ];

    const [orgs, setOrgs] = useState([
        meetings(1, "Cenna Support QA", "Daily", "21-03-2024", "12:00 PM", "Salman", "Abc Items", "Actions Items", "Abc Notes", "abc follow action", "22-03-2024"),
        meetings(2, "Cenna Support QA", "Daily", "21-03-2024", "12:00 PM", "Salman", "Abc Items", "Actions Items", "Abc Notes", "abc follow action", "22-03-2024"),
    ]);

    const [clientList, setClientList] = useState([
        {
            title: "Client List",
            value: "Please Select Client",
            width: screenWidth,
            type: "drop_down",
            required: true,
            multiline: false,
            options: [
                {
                    title: "Please Select Client",
                    selected: true
                },
                {
                    title: "Rehan",
                    selected: false
                },
                {
                    title: "Sohail",
                    selected: false
                },
                {
                    title: "Fazal",
                    selected: false
                },
                {
                    title: "Adnan",
                    selected: false
                },
                {
                    title: "Junaid",
                    selected: false
                },
            ]
        },
    ])

    const [staffList, setStaffList] = useState([
        {
            title: "Staff List",
            value: "Please Select Staff",
            width: screenWidth,
            type: "drop_down",
            required: true,
            multiline: false,
            options: [
                {
                    title: "Please Select Staff",
                    selected: true
                },
                {
                    title: "Faizan",
                    selected: false
                },
                {
                    title: "Gul Khan",
                    selected: false
                },
                {
                    title: "Imran",
                    selected: false
                },
                {
                    title: "Pervaiz",
                    selected: false
                },
                {
                    title: "Waheed",
                    selected: false
                },
            ]
        },
    ])

    const [notesText, setNotesText] = useState("");

    const [meetingsInput, setMeetingsInput] = useState([
        {
            title: "Meeting Related To",
            value: "Please Select Option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            multiline: false,
            options: [
                {
                    title: "Please Select Option",
                    selected: true
                },
                {
                    title: "Client",
                    selected: false
                },
                {
                    title: "Staff",
                    selected: false
                },
                {
                    title: "Other",
                    selected: false
                },
            ],

        },
        {
            title: "Meeting Category",
            value: "Please Select Option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            multiline: false,
            options: [
                {
                    title: "Please Select Option",
                    selected: true
                },
                {
                    title: "Daily",
                    selected: false
                },
                {
                    title: "Weekly",
                    selected: false
                },
                {
                    title: "Monthly",
                    selected: false
                },
            ]
        },
        {
            title: "Meeting Date",
            value: year + "-" + monthValue + "-" + day,
            width: screenWidth,
            type: "date",
            required: true,
            multiline: false,
        },
        {
            title: "Meeting Time",
            value: "12:00",
            width: screenWidth,
            type: "time",
            required: true,
            multiline: false,
        },
        {
            title: "Meeting Title",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
        },
        {
            title: "Attendees Name",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false,
        },
        {
            title: "Agenda Items",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: true,
        },
        {
            title: "Action Items",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: true,
        },
        {
            title: "Notes",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: true,
        },
        {
            title: "Follow-Up Actions",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: true,
        },
        {
            title: "Next Meeting Date",
            value: year + "-" + monthValue + "-" + day,
            width: screenWidth,
            type: "date",
            required: true,
            multiline: false,
        },
        {
            title: "Meetings Notes (PDF)",
            value: [],
            width: screenWidth,
            type: "file",
            required: false,
            multiline: false,
        }
    ]);

    return (
        <Grid container sx={{ display: "flex", justifyContent: "center", my: 2, marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, marginTop: { xs: "15%", sm: "2%", lg: "2%" }, }}>
            <Grid item={true} xs={11} md={11} lg={11} sx={{ display: "flex", flexDirection: { lg: "row", xs: "column", md: "row", sm: "column" }, }}>
                <Grid item={true} xs={12} sm={12} md={6} lg={6} sx={{ display: "flex", alignItems: "center", flexWrap: 'wrap', justifyContent: { md: "flex-start", lg: "flex-start", xs: "center" } }}>
                    <Button onClick={() => {
                        setNewFeedback(true);
                    }} variant="outlined" sx={{ m: 1, fontFamily: "Roboto-Medium", textTransform: "capitalize" }}>
                        Create Meeting
                    </Button>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} lg={6} >
                    <SearchAndFilters groupBy={false} favorite={false} inputWidth={8} />
                </Grid>
            </Grid>
            <Grid item={true} xs={11} sm={12} md={11} lg={11}>
                <MeetingList rows={orgs} headCells={headCells} />
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={newFeedback}
                onClose={() => setNewFeedback(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={newFeedback}>
                    <Box sx={style}>
                        <Box>
                            <Typography variant="h5" component="h2" sx={{ fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                                Create New Meeting
                            </Typography>
                        </Box>
                        <Box sx={{ my: 2 }}>
                            <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap', }}>
                                {meetingsInput.map((v, i) => {
                                    return (
                                        <div key={i}>
                                            {v.type === "drop_down" &&
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <TextField
                                                        select={true}
                                                        label={v.title}
                                                        htmlFor={v.title}
                                                        required={v.required}
                                                        sx={{ m: 1, width: v.width }}
                                                        value={v.value}
                                                        onChange={(e) => {
                                                            const copy = [...meetingsInput]
                                                            copy[i].value = e.target.value;
                                                            setMeetingsInput(copy);
                                                        }}
                                                    >
                                                        {v?.options?.map((option) => (
                                                            <MenuItem key={option.title} value={option.title}>
                                                                {option.title}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                    {v.value === "Client" && clientList.map((vN, iN) => (
                                                        <TextField
                                                            key={iN}
                                                            select={true}
                                                            label={vN.title}
                                                            htmlFor={vN.title}
                                                            required={vN.required}
                                                            sx={{ m: 1, width: vN.width }}
                                                            value={vN.value}
                                                            onChange={(e) => {
                                                                const copy = [...clientList]
                                                                copy[iN].value = e.target.value;
                                                                setClientList(copy);
                                                            }}
                                                        >
                                                            {vN?.options?.map((option) => (
                                                                <MenuItem key={option.title} value={option.title}>
                                                                    {option.title}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    ))}
                                                    {v.value === "Staff" && staffList.map((vN, iN) => (
                                                        <TextField
                                                            key={iN}
                                                            select={true}
                                                            label={vN.title}
                                                            htmlFor={vN.title}
                                                            required={vN.required}
                                                            sx={{ m: 1, width: vN.width }}
                                                            value={vN.value}
                                                            onChange={(e) => {
                                                                const copy = [...staffList]
                                                                copy[iN].value = e.target.value;
                                                                setStaffList(copy);
                                                            }}
                                                        >
                                                            {vN?.options?.map((option) => (
                                                                <MenuItem key={option.title} value={option.title}>
                                                                    {option.title}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    ))}
                                                    {v.value === "Other" && (
                                                        <TextField
                                                            multiline={true}
                                                            required={true}
                                                            label={"Description"}
                                                            sx={{ m: 1, width: v.width }}
                                                            value={notesText}
                                                            onChange={(e) => {
                                                                setNotesText(e.target.value);
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                            }
                                            {(v.type === "text" || v.type === "date" || v.type === "time") &&
                                                <TextField
                                                    type={v.type}
                                                    multiline={v.multiline}
                                                    required={v.required}
                                                    label={v.title}
                                                    htmlFor={v.title}
                                                    sx={{ m: 1, width: v.width }}
                                                    value={v.value}
                                                    onChange={(e) => {
                                                        const copy = [...meetingsInput]
                                                        copy[i].value = e.target.value;
                                                        setMeetingsInput(copy);
                                                    }}
                                                />
                                            }
                                            {v.type === "file" &&
                                                <Box sx={{ m: 1, display: 'flex', flexWrap: 'wrap' }}>
                                                    <List
                                                        sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}
                                                        component="nav"
                                                        aria-labelledby="nested-list-subheader"
                                                    >
                                                        {v.value.map((vN, iN) => {
                                                            return (
                                                                <ListItem
                                                                    key={iN}
                                                                    secondaryAction={
                                                                        <IconButton onClick={() => {
                                                                            const copy = [...meetingsInput];
                                                                            const copyList = copy[i].value;
                                                                            delete copyList[iN]
                                                                            setMeetingsInput(copy);
                                                                        }} edge="end" aria-label="delete">
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    }
                                                                >
                                                                    <ListItemText
                                                                        primary={vN.name}
                                                                    // secondary={secondary ? 'Secondary text' : null}
                                                                    />
                                                                </ListItem>
                                                            )
                                                        })}
                                                    </List>
                                                    <Button sx={{ width: v.width }} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                                                        {v.title}
                                                        <VisuallyHiddenInput type="file" onChange={(e) => {
                                                            const copy = [...meetingsInput];
                                                            const copyList = copy[i].value;
                                                            copyList.push(e.target.files[0]);
                                                            setMeetingsInput(copy);
                                                        }} />
                                                    </Button>
                                                </Box>
                                            }
                                        </div>
                                    )
                                })}
                            </Grid>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained" >
                                    Save
                                </Button>
                                <Button variant="contained" onClick={() => setNewFeedback(false)}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Grid>
    )
}