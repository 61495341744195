import * as React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, Toolbar, Tooltip, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { ClientRegister, ComplaintRegister, ConflictOfInterestRegister, ContinousRegister, FeedbackRegister, IncedentRegister, KilometerRegister, MedicationRegister, OrgnizationRegister, ReimbursementsRegister, StaffMeetingRegister, StaffRegister } from '../form_screens/register_form';
import { HeaderToolbar } from '../../components/header/header_toolbar';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import ModeOfTravelOutlinedIcon from '@mui/icons-material/ModeOfTravelOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import TryOutlinedIcon from '@mui/icons-material/TryOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import MedicationOutlinedIcon from '@mui/icons-material/MedicationOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';


const drawerWidth = 70;

const RegistrationDashboard = (props) => {
    const navigate = useNavigate()
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const [options, setOptions] = React.useState([
        {
            option: "Client Register",
            icon: <AssignmentIndOutlinedIcon fontSize={"large"} />,
            active: true,
            screen: <ClientRegister />
        },
        {
            option: "Staff Register",
            icon: <PeopleAltOutlinedIcon fontSize={"large"} />,
            active: false,
            screen: <StaffRegister />
        },
        {
            option: "Medication Register",
            icon: <MedicationOutlinedIcon fontSize={"large"} />,
            active: false,
            screen: <MedicationRegister />
        },
        {
            option: "Continous Improvements Register",
            icon: <InterestsOutlinedIcon fontSize={"large"} />,
            active: false,
            screen: <ContinousRegister />
        },
        {
            option: "Incident Register",
            icon: <GppMaybeOutlinedIcon fontSize={"large"} />,
            active: false,
            screen: <IncedentRegister />
        },
        {
            option: "Complaint Register",
            icon: <ReportGmailerrorredOutlinedIcon fontSize={"large"} />,
            active: false,
            screen: <ComplaintRegister />
        },
        {
            option: "Orgnizations Register",
            icon: <BusinessOutlinedIcon fontSize={"large"} />,
            active: false,
            screen: <OrgnizationRegister />
        },
        {
            option: "Feedback Register",
            icon: <TryOutlinedIcon fontSize={"large"} />,
            active: false,
            screen: <FeedbackRegister />
        },
        {
            option: "Conflict Of Interest Register",
            icon: <WarningAmberOutlinedIcon fontSize={"large"} />,
            active: false,
            screen: <ConflictOfInterestRegister />
        },
        {
            option: "Staff Meeting Register",
            icon: <GroupsOutlinedIcon fontSize={"large"} />,
            active: false,
            screen: <StaffMeetingRegister />
        },
        {
            option: "Reimbursements Register",
            icon: <CurrencyExchangeOutlinedIcon fontSize={"large"} />,
            active: false,
            screen: <ReimbursementsRegister />
        },
        {
            option: "Kilometer Register",
            icon: <ModeOfTravelOutlinedIcon fontSize={"large"} />,
            active: false,
            screen: <KilometerRegister />
        },
    ]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleMenuItem = (option, i) => {
        const copy = [...options];
        for (const key in copy) {
            if (key === String(i)) {
                copy[key].active = true
            } else {
                copy[key].active = false
            }
        }
        setOptions(copy);
    };

    const drawer = (
        <div style={{ overflow: 'hidden' }}>
            <Toolbar onClick={() => navigate(-1)} sx={{ justifyContent: 'center' }}>
                <img alt="Logo" style={{ width: 40, objectFit: 'fitt' }} src="/assets/icons/logo-without-text.png" />
            </Toolbar>
            <Divider />
            <List sx={{ pt: 0 }}>
                {options.map((v, i) => (
                    <ListItem key={i} disablePadding sx={{ backgroundColor: v.active ? "#013447" : "#ffffff", }}>
                        <ListItemButton onClick={() => handleMenuItem(v.option, i)}>
                            <Tooltip title={v.option} placement="right">
                                <ListItemIcon style={{ color: v.active ? "#ffffff" : "#013447", }}>
                                    {v.icon}
                                </ListItemIcon>
                            </Tooltip>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    // Remove this const when copying and pasting into your project.
    const container = window !== undefined ? () => window().document.body : undefined;
    const activeTab = options.find((e) => e.active)
    return (
        <Box sx={{ display: 'flex', overflow: "hidden", bgcolor: "#F5F7F6" }}>
            <CssBaseline />
            <AppBar
                color="primaryHeader"
                position="fixed"
                elevation={0}
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography color={"secondary"} style={{ fontFamily: "Roboto-Bold", fontSize: 24 }}>
                        {activeTab?.option}
                    </Typography>
                    <Box sx={{ flexGrow: 1, justifyContent: "flex-end", display: { xs: 'none', md: 'flex', lg: 'flex' }, }} >
                        <HeaderToolbar />
                    </Box>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, overflowY: "scroll", height: "calc(97vh)",
                }}
            >
                <Toolbar />
                {activeTab?.screen}
            </Box>
        </Box>
    );
};

RegistrationDashboard.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * Remove this when copying and pasting into your project.
     */
    window: PropTypes.func,
};

export default RegistrationDashboard;