import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    design_front_data: null,
};

export const DesignReducer = createSlice({
    name: 'design_module',
    initialState,
    reducers: {
        designFrontData: (state, action) => {
            state.design_front_data = action.payload;
        },
    },
});

export const {
    designFrontData,
} = DesignReducer.actions;

export default DesignReducer.reducer;