import { Alert, AlertTitle, Backdrop, Box, Button, CircularProgress, Fade, Grid, InputAdornment, MenuItem, Modal, Slide, Snackbar, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { API, AUTH_TOKEN } from "../../../config/constant";


import { ChargeBandListGrid, NDISPriceListGrid, PriceListGrid } from "../../../components/pagination_view";
import BrowserUpdatedOutlinedIcon from '@mui/icons-material/BrowserUpdatedOutlined';

import { CreateNewPriceList, PriceLists } from "../../../services/admin_service";
import { ChargeBandRates, CreateChargeBandDetail, CreateChargeBandRate, GetChargeBandDetails, NdisPriceListCategory, NdisPriceList, UpdateChargeBandRate } from "../../../services/finance_service";
import PropTypes from 'prop-types';

import { UploadFileIcon, PriceChangeIcon } from "../../../components/common/Icons";

import NotificationModal from '../../../components/common/NotificationModal';
import AutoCompleteComp from '../../../components/common/AutoCompleteComp';
import SelectComp from '../../../components/common/SelectComp';
import DragAndDrop from '../../../components/common/DragAndDrop';
import DataGridComp from '../../../components/common/DataGridComp';
import _ from "lodash";

const SlideTransition = (props) => {
  return <Slide {...props} direction="left" />;
}

const headCells = [  
  {
    field: 'support_item_number',
    numeric: false,
    disablePadding: true,
    headerName: 'Support Item Number',
    width: 200,
  },
  {
    field: 'support_item_name',
    numeric: false,
    disablePadding: true,
    headerName: 'Support Item Name',
    width: 500,
  },
  {
    field: 'registration_group_number',
    numeric: true,
    disablePadding: false,
    headerName: 'Registration Group Number',
    width: 200,
  },
  {
    field: 'registration_group_name',
    numeric: true,
    disablePadding: false,
    headerName: 'Registration Group Name',
    width: 300,
  },
  {
    field: 'support_category_number',
    numeric: true,
    disablePadding: false,
    headerName: 'Support Category Number',
    width: 200,
  },
  {
    field: 'support_category_number_pace',
    numeric: true,
    disablePadding: false,
    headerName: 'Support Category Number (Pace)',
    width: 200,
  },
  {
    field: 'support_category_name',
    numeric: true,
    disablePadding: false,
    headerName: 'Support Category Name',
    width: 300,
  },
  {
    field: 'support_category_name_pace',
    numeric: true,
    disablePadding: false,
    headerName: 'Support Category Name (Pace)',
    width: 300,
  },
  {
    field: 'unit',
    numeric: true,
    disablePadding: false,
    headerName: 'Unit',
    width: 100,
  },
  {
    field: 'quote',
    numeric: true,
    disablePadding: false,
    headerName: 'Quote',
    width: 100,
  },
  {
    field: 'start_date',
    numeric: true,
    disablePadding: false,
    headerName: 'Start Date',
    width: 100,
  },
  {
    field: 'end_date',
    numeric: true,
    disablePadding: false,
    headerName: 'End Date',
    width: 100,
  },
  {
    field: 'wa',
    numeric: true,
    disablePadding: false,
    headerName: 'WA',
    renderCell: (data) => `$${data.row.wa}`,
    width: 100,
  },
  {
    field: 'non_face_to_face_support_provision',
    numeric: true,
    disablePadding: false,
    headerName: 'Non Face to Face Support Provision',
    width: 200,
  },
  {
    field: 'provider_travel',
    numeric: true,
    disablePadding: false,
    headerName: 'Provider Travel',
    width: 200,
  },
  {
    field: 'short_notice_cancellations',
    numeric: true,
    disablePadding: false,
    headerName: 'Short Notice Cancellations',
    width: 200,
  },
  {
    field: 'ndia_requested_reports',
    numeric: true,
    disablePadding: false,
    headerName: 'Ndia Requested Reports',
    width: 200,
  },
  {
    field: 'irregular_sil_supports',
    numeric: true,
    disablePadding: false,
    headerName: 'Irregular Sil Supports',
    width: 200,
  },
  {
    field: 'type',
    numeric: true,
    disablePadding: false,
    headerName: 'Type',
    width: 200,
  },
  // {
  //   field: 'Options',
  //   numeric: true,
  //   disablePadding: false,
  //   headerName: 'Options',
  //   width: 200,
  // },
];

const headCellsBand = [
  {
    id: 'Id',
    numeric: false,
    disablePadding: true,
    label: 'Id',
  },
  {
    id: 'charge_band_name',
    numeric: false,
    disablePadding: true,
    label: 'charge_band_name',
  },
  {
    id: 'filter_key_brokerage_private_ndis_aged_care_pakage',
    numeric: false,
    disablePadding: true,
    label: 'filter_key_brokerage_private_ndis_aged_care_pakage',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'status',
  },
  {
    id: 'options',
    numeric: false,
    disablePadding: true,
    label: 'options',
  },
]

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const NDISPriceList = () => {
  const [fileData, setFileData] = useState([]);
  const [fileError, setFileError] = useState(false);

  const [style, setStyle] = useState({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#F5F7F6',
    maxWidth: "calc(70%)",
    minWidth: "calc(60%)",
    boxShadow: 12,
    borderRadius: 3,
    p: 4,
  });
  // const navigate = useNavigate();
  const height = window.screen.height;
  const width = () => {
    if (window.screen.width > 1600) {
      return window.screen.width / 5
    }
    if (window.screen.width > 600 && window.screen.width < 1600) {
      return window.screen.width / 4
    } else {
      return window.screen.width / 1.3
    }
  }
  const [screenWidth, setScreenWidth] = useState(width);
  const [open, setOpen] = React.useState(false);
  const [openDragAndDropModal, setOpenDragAndDropModal] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleOpen = () => setOpen(true);

  const [changeBand, setChargeBand] = useState([
    {
      title: "Charge Band Name",
      value: "",
      placeholder: "Enter the charge band name",
      width: screenWidth,
      type: "text",
      required: true,
      multiline: false,
      error: null
    },
    {
      title: "Filter key 'B'rokerage, 'P'rivate, 'N'DIS, 'A'ged care pakage",
      value: "",
      placeholder: "Enter the filter key string",
      width: screenWidth,
      type: "text",
      required: true,
      multiline: false,
      error: null
    },
    // {
    //     title: "Boost",
    //     value: "100",
    //     placeholder: "",
    //     width: screenWidth,
    //     type: "number",
    //     required: true,
    //     multiline: false,
    // },
  ])

  const [ndis_price_list, ndis_loading_list] = NdisPriceList();
  const [ndis_price_list_category, ndis_category_loading_list] = NdisPriceListCategory();
  const [ndisPricceListCategories, setNdisPriceListCategories] = useState(null);
  const [selectedNdisPriceListCategory, setSelectedNdisPriceListCategory] = useState(null);
  const [ndisPricceList, setNdisPriceList] = useState(null);
  const [chargeBandDetails, setChargeBandDetails] = useState(null);
  const [ceateNewChargeBand, loading_res, error_res] = CreateChargeBandDetail();
  const [ceateChargeBandRate, loading_rate, error_rate] = CreateChargeBandRate();
  const [getChargeBandList, loading_band, error_band] = GetChargeBandDetails();
  const [getChargeBandRates, loading_rates, error_rates] = ChargeBandRates()
  const [updateChargeBandsData, loading_ch, error_ch] = UpdateChargeBandRate()
  const [editChargeBand, setEditChargeBand] = useState(null);

  const [chargeBandInstant, setchargeBandInstant] = useState({
    title: "Select Service Category",
    value: "Please select option",
    width: screenWidth,
    type: "drop_down",
    required: true,
    multiline: false,
    disabled: false,
    options: [
      {
        title: "Please select option",
      },
      {
        title: "Standard",
      },
      {
        title: "High Intensity",
      },
      {
        title: "STA",
      },
      {
        title: "Others",
      },
    ]
  })

  const getNdisPriceListCategory = async () => {
    try {
      const response = await ndis_price_list_category();
      if (response?.data) {
        const data = response?.data?.ndisPriceListCategories?.data;
        if (data?.length > 0) {
          const simplify = data.map(item => ({
            id: item.id,
            __typename: item.attributes.__typename,
            value: item.attributes.Ndis_price_list_category,
            label: item.attributes.Ndis_price_list_category
          }));
          setSelectedNdisPriceListCategory(simplify[0])
          setNdisPriceListCategories(simplify)
        }
      }
    } catch (error) {
      console.error("ndis_price_list_category", error)
    }
  }

  const getNdisPriceList = async (selectedNdisPriceListCategory) => {
    let filterObj = {
      variables: {
        "filter": {
          "ndis_price_list_category_id": {
            "id": {
              "eq": selectedNdisPriceListCategory.id
            }
          }
        },
      }
    }

    try {
      const response = await ndis_price_list(filterObj);
      if (response?.data) {
        const data = response?.data?.ndisPriceLists?.data;
        const simplify = convertDataIntoSimplifyObjects(data)
        setNdisPriceList(simplify)
      }
    } catch (error) {
      console.error("ndis_price_list", error)
    }
  }

  const getChargeBandsData = async () => {
    try {
      const response = await getChargeBandList();
      if (response?.data) {
        const data = response?.data?.chargeBandDetails?.data;
        const simplify = convertDataIntoSimplifyObjects(data)
        setChargeBandDetails(simplify);
      }
    } catch (error) {
      console.error("error", error_band, error)
    }
  }

  useEffect(() => {
    getNdisPriceListCategory()
    getChargeBandsData()
  }, [])

  useEffect(() => {
    if (selectedNdisPriceListCategory !== null) {
      getNdisPriceList(selectedNdisPriceListCategory)
    }
  }, [selectedNdisPriceListCategory])

  const [snacks, setSnack] = useState({
    open: false,
    Transition: SlideTransition,
    vertical: 'top',
    horizontal: 'right',
  });

  const [errorSnackMessage, setErrorSnackMessage] = useState({
    title: "",
    message: "",
    severity: "",
    variant: "filled"
  });

  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (window.screen) {
      setScreenWidth(width);
    }
  }, [window.screen])

  useEffect(() => {
    if (!openDragAndDropModal) {
      setFileData([]);
      setFileError(false); //
    }
  }, [openDragAndDropModal])

  const editPriceItemEmpty = () => {
    const copy = [...changeBand];
    for (const key in copy) {
      copy[key].value = "";
    }
    setEditChargeBand(null)
    setOpen(false);
    setChargeBand(copy);
  }

  const convertDataIntoSimplifyObjects = (data) => {
    const list = [];
    for (const key of data) {
      const inputs = {};
      const id = key.id;
      const property = key.attributes
      for (const prop in property) {
        if (prop !== "__typename") {
          inputs[prop.toLowerCase().split('/ ').join('')?.split(' ').join('_')] = property[prop]
        }
      }
      inputs.db_id = id;
      inputs.id = list.length + 1;
      list.push(inputs)
    }
    return list
  }

  const handleCloseSnacks = () => {
    setSnack({
      ...snacks,
      open: false,
    });
  };

  const simplifyServiceRates = (changeBand_id) => {
    const list_array = []
    const list = ndisPricceList.filter((e) => e.support_item_name.includes("Self-Care") && e.support_item_name.includes("Standard"));

    for (const key in list) {
      if (list[key].support_item_name.includes("Weekday Daytime")) {
        list_array.push({
          service_title: list[key].support_item_name,
          service_code: list[key].db_id,
          service_rate: list[key].wa.slice(1),
          service_actual_rate: list[key].wa,
          charge_band_detail: changeBand_id
        })
      }
      if (list[key].support_item_name.includes("Saturday")) {
        list_array.push({
          service_title: list[key].support_item_name,
          service_code: list[key].db_id,
          service_rate: list[key].wa.slice(1),
          service_actual_rate: list[key].wa,
          charge_band_detail: changeBand_id
        })
      }
      if (list[key].support_item_name.includes("Sunday")) {
        list_array.push({
          service_title: list[key].support_item_name,
          service_code: list[key].db_id,
          service_rate: list[key].wa.slice(1),
          service_actual_rate: list[key].wa,
          charge_band_detail: changeBand_id
        })
      }
      if (list[key].support_item_name.includes("Public Holiday")) {
        list_array.push({
          service_title: list[key].support_item_name,
          service_code: list[key].db_id,
          service_rate: list[key].wa.slice(1),
          service_actual_rate: list[key].wa,
          charge_band_detail: changeBand_id
        })
      }
      if (list[key].support_item_name.includes("Evening")) {
        list_array.push({
          service_title: list[key].support_item_name,
          service_code: list[key].db_id,
          service_rate: list[key].wa.slice(1),
          service_actual_rate: list[key].wa,
          charge_band_detail: changeBand_id
        })
      }
      if (list[key].support_item_name.includes("Night")) {
        list_array.push({
          service_title: list[key].support_item_name,
          service_code: list[key].db_id,
          service_rate: list[key].wa.slice(1),
          service_actual_rate: list[key].wa,
          charge_band_detail: changeBand_id
        })
      }
    }
    return list_array
  }

  const createNewBand = async () => {
    try {
      const copy = [...changeBand]
      let foundError = false
      for (const key in copy) {
        if (copy[key].value === "" || copy[key].value === " " || /^\d+$/.test(copy[key].value)) {
          copy[key].error = "Empty / Irrelevant data is not allowed";
          foundError = true;
        } else {
          copy[key].error = "";
        }
      }
      setChargeBand(copy)
      if (foundError) {
        return true
      }
      const response = await ceateNewChargeBand({
        variables: {
          "charge_band_name": changeBand[0].value,
          "filter_key": changeBand[1].value
        }
      });
      if (response?.data) {
        const changeBand_id = response?.data?.createChargeBandDetail?.data?.id
        const array_of_rates = simplifyServiceRates(changeBand_id)
        let count = 0;
        if (array_of_rates) {
          for (const key in array_of_rates) {
            const response_rate = await ceateChargeBandRate({
              variables: array_of_rates[key]
            })
            if (response_rate?.data) {
              count++
            }
          }
          if (count === array_of_rates.length) {
            setSnack({
              ...snacks,
              open: true,
            });
            getChargeBandsData();
            setErrorSnackMessage({
              ...errorSnackMessage,
              title: "New Charge Band",
              message: "New Charge Band Created : " + response?.data?.createChargeBandDetail?.data?.attributes?.charge_band_name,
              severity: "success"
            })
            editPriceItemEmpty()
          }
        }
      }
    } catch (error) {
      let message = "";
      if (error?.message || error_res) {
        const errors = error_res;
        if (errors?.networkError) {
          message = "Network error occurred. Please try again later"
        }
        if (errors?.graphQLErrors) {
          message = errors.graphQLErrors[0]?.message
        }
        if (error?.message) {
          message = error?.message;
        }
        setErrorSnackMessage({
          ...errorSnackMessage,
          title: "Create New Charge Band",
          message: message,
          severity: "error"
        })
      }
    }
  }

  const editChargeBandDetails = async (index, dbIndex) => {
    const rowList = [...chargeBandDetails];
    const ListItem = rowList[index];
    const copy = { ...ListItem }
    const response = await getChargeBandRates({
      variables: {
        "filter": {
          "charge_band_detail": {
            "id": {
              "eq": copy.db_id
            }
          }
        }
      }
    })
    if (response?.data) {
      const data = response?.data?.chargeBandRates?.data;
      copy.rates = data;
    }
    setEditChargeBand(copy);
    setOpen(true);
  }

  const saveChargeBandRates = async () => {
    try {
      const list = editChargeBand.rates;
      let count = 0;
      for (const key in list) {
        const response = await updateChargeBandsData({
          variables: {
            "id": list[key].id,
            "data": {
              service_rate: list[key].attributes.service_rate
            }
          }
        })
        if (response?.data) {
          count++;
        }
      }
      if (count === list.length) {
        setSnack({
          ...snacks,
          open: true,
        });
        setErrorSnackMessage({
          ...errorSnackMessage,
          title: editChargeBand.charge_band_name,
          message: "Charge Band Rated Updated.!",
          severity: "success"
        })
        setOpen(false);
        setEditChargeBand(null)
      }
    } catch (error) {
      let message = "";
      if (error?.message || error_ch) {
        const errors = error_ch;
        if (errors?.networkError) {
          message = "Network error occurred. Please try again later"
        }
        if (errors?.graphQLErrors) {
          message = errors.graphQLErrors[0]?.message
        }
        if (error?.message) {
          message = error?.message;
        }
        setErrorSnackMessage({
          ...errorSnackMessage,
          title: editChargeBand.charge_band_name,
          message: message,
          severity: "error"
        })
      }
    }
  }

  const uploadNdisPriceList = async () => {    

    const formData = new FormData();
    formData.append('data', JSON.stringify({
      userID: JSON.parse(localStorage.getItem('user')).id
    })); 
    formData.append('files.uploadedFile', fileData);    

    try {
      setShowSpinner(true);
      const response = await axios.post(`${API}/import-ndis-price-list`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${AUTH_TOKEN}`,
        },        
      });

      if (response.status === 200) {
        setShowSpinner(false);
        setOpenDragAndDropModal(false);
        setSnack({
          ...snacks,
          open: true,
        });
        getChargeBandsData();
        setErrorSnackMessage({
          ...errorSnackMessage,
          title: "NDIS Import List",
          message: `${response.data.message}`,
          severity: "success"
        })
      }
    } catch (error) {
      setShowSpinner(false);
      setOpenDragAndDropModal(false);
      setSnack({
        ...snacks,
        open: true,
      });
      getChargeBandsData();
      setErrorSnackMessage({
        ...errorSnackMessage,
        title: "NDIS Import List",
        message: `${error.response.data.message}`,
        severity: "error"
      })
    }
  }
  const dragAndDropForm = <>
    <Box>
      <Typography color={"primary"} sx={{ fontFamily: "Roboto-Bold", fontSize: "1.5em" }}>
        Upload CSV/Excel File of Price List
      </Typography>
    </Box>
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", py: 2 }}>
      <DragAndDrop
        setFileData={setFileData}
        setFileError={setFileError}
        showSpinner={showSpinner}
      />
    </Box>
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        onClick={uploadNdisPriceList}
        startIcon={
          <UploadFileIcon
            color="primary"
          />
        }
        variant="outlined"
        disabled={fileError || fileData.length === 0}
        sx={{
          bgcolor: "#ffffff",
          fontFamily: "Roboto-Medium",
          textTransform: "capitalize",
          color: "#000000",
          border: "2px solid #6ECA35",
          ":hover": {
            border: "2px solid #6ECA35",
          }
        }}>
        Upload List
      </Button>
    </Box>
  </>

  // Handle change
  const handleSelectValueChange = (event) => {
    const selectedId = event.target.value;
    const selected = ndisPricceListCategories.find((option) => option.id === selectedId);
    setSelectedNdisPriceListCategory(selected);
  };

  return (
    <Grid container sx={{ py: 2, display: "flex", justifyContent: "center", }}>
      <NotificationModal
        content={dragAndDropForm}
        openModalVariable={openDragAndDropModal}
        setOpenModalVariable={setOpenDragAndDropModal}
      />
      <Grid item={true} xs={11.5} sm={11.5} md={11} lg={11} sx={{ p: 1, }}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab color="secondary.text3" sx={{ fontFamily: "Roboto-Medium" }} label="NDIS Price List" {...a11yProps(0)} />
              <Tab color="secondary.text3" sx={{ fontFamily: "Roboto-Medium" }} label="Charge Band" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Box sx={{ margin: '10px 0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
              <Button
                onClick={() => {
                  setOpenDragAndDropModal(true);
                }}
                startIcon={
                  <PriceChangeIcon
                    color="primary"
                  />
                }
                variant="outlined"
                sx={{
                  bgcolor: "#ffffff",
                  fontFamily: "Roboto-Medium",
                  textTransform: "capitalize",
                  color: "#000000",
                  border: "2px solid #6ECA35",
                  ":hover": {
                    border: "2px solid #6ECA35",
                  }
                }}>
                Import NDIS Price List
              </Button>
              {(!ndis_category_loading_list && selectedNdisPriceListCategory !== null) &&
                <SelectComp
                  options={ndisPricceListCategories}
                  label={"Ndis Price List Category"}
                  initialValue={selectedNdisPriceListCategory}
                  handleChange={handleSelectValueChange}
                />
              }
            </Box>
            {ndisPricceList?.length              
              ? <DataGridComp rows={ndisPricceList} columns={headCells} loading={ndis_loading_list} />
              : <>
                {ndis_loading_list ? (
                  <Box
                    sx={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <CircularProgress color="secondary" size={50} />
                  </Box>
                ) : null}
              </>}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Box sx={{ width: "100%" }}>
              {/* button box */}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Stack direction="row" spacing={2}>
                  <Button onClick={handleOpen} startIcon={<PriceChangeIcon color="primary" />} variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                    New Charge Band
                  </Button>
                </Stack>
              </Box>
              <Box sx={{ my: 1, }}>
                {chargeBandDetails?.length ? <ChargeBandListGrid loading_rates={loading_rates} headCells={headCellsBand} rows={chargeBandDetails} editPriceItem={editChargeBandDetails} /> : <>
                  {loading_band ? (
                    <Box
                      sx={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <CircularProgress color="secondary" size={50} />
                    </Box>
                  ) : null}
                </>}
              </Box>
            </Box>
          </CustomTabPanel>
        </Box>
      </Grid>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box>
              <Typography color={"primary"} sx={{ fontFamily: "Roboto-Bold", fontSize: "1.5em" }}>
                {editChargeBand ? "Modify Charge Band Rates" : "Create New Charge Band"}
              </Typography>
            </Box>
            <Box sx={{ py: 2, }}>
              {/* {!editChargeBand ? (
                                <TextField
                                    select={true}
                                    multiline={chargeBandInstant.multiline}
                                    key={chargeBandInstant.title}
                                    label={chargeBandInstant.title}
                                    htmlFor={chargeBandInstant.title}
                                    value={chargeBandInstant.value}
                                    sx={{ m: 1, width: chargeBandInstant.width }}
                                    onChange={(e) => {
                                        const copy = { ...chargeBandInstant };
                                        if (e.target.value !== "Please select option") {
                                            copy.value = e.target.value;
                                        }
                                        setchargeBandInstant(copy);
                                    }}
                                >
                                    {chargeBandInstant.options?.map((option) => (
                                        <MenuItem key={option.title} value={option.title}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            ) : null} */}
              {!editChargeBand && changeBand.map((value, index) => (
                <TextField
                  type={value.type}
                  multiline={value.multiline}
                  key={index + value.title}
                  required={value.required}
                  label={value.title}
                  placeholder={value.placeholder}
                  htmlFor={value.title}
                  value={value.value}
                  sx={{ m: 1, width: value.width }}
                  helperText={value.error ? value.error : ""}
                  error={value.error ? true : false}
                  onChange={(e) => {
                    const copy = [...changeBand];
                    copy[index].value = e.target.value;
                    setChargeBand(copy);
                  }}
                />
              ))}
              {editChargeBand ? (
                <Box sx={{ width: "100%", }}>
                  <Box sx={{ display: "flex", width: "100%", }}>
                    <Box sx={{ flex: 1, mx: 1 }}>
                      <Typography color={"secondary"} sx={{ textTransform: "capitalize", fontFamily: "Roboto-Medium", fontSize: "1.1em" }}>
                        Charge Band Name
                      </Typography>
                      <Typography color={"secondary"} sx={{ px: 1, textTransform: "capitalize", fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                        {editChargeBand?.charge_band_name}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1, mx: 1 }}>
                      <Typography color={"secondary"} sx={{ textTransform: "capitalize", fontFamily: "Roboto-Medium", fontSize: "1.1em" }}>
                        Filter key brokerage private ndis aged care pakage
                      </Typography>
                      <Typography color={"secondary"} sx={{ px: 1, textTransform: "capitalize", fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                        {editChargeBand?.filter_key_brokerage_private_ndis_aged_care_pakage}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ width: "100%", my: 2, }}>
                    {editChargeBand?.rates.map((value, index) => (
                      <Box key={index + value.attributes.service_title} sx={{ display: "flex", }}>
                        <Box sx={{ flex: 1.5, }}>
                          <TextField
                            type={"text"}
                            multiline={false}
                            label={"Service Title"}
                            htmlFor={value.attributes.service_title.slice(39)}
                            value={value.attributes.service_title.slice(39)}
                            sx={{ m: 1, width: "95%", }}
                            disabled={true}
                          />
                        </Box>
                        <Box sx={{ flex: 1, }}>
                          <TextField
                            type={"text"}
                            multiline={false}
                            label={"Actual Rate"}
                            htmlFor={value.attributes.service_actual_rate}
                            value={value.attributes.service_actual_rate}
                            sx={{ m: 1, width: "95%" }}
                            disabled={true}
                          />
                        </Box>
                        <Box sx={{ flex: 1, }}>
                          <TextField
                            type={"number"}
                            multiline={false}
                            label={"Service Rate"}
                            htmlFor={value.attributes.service_rate}
                            value={value.attributes.service_rate}
                            sx={{ m: 1, width: "95%" }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            onChange={(e) => {
                              const copy = { ...editChargeBand };
                              const rates = _.cloneDeep(copy.rates);
                              rates[index].attributes.service_rate = e.target.value;
                              copy.rates = rates;
                              setEditChargeBand(copy);
                            }}
                          />
                        </Box>
                        <Box sx={{ flex: 1, }}>
                          <TextField
                            type={"text"}
                            multiline={false}
                            label={"Service Code"}
                            htmlFor={value.attributes.service_code.data.attributes.support_item_number}
                            value={value.attributes.service_code.data.attributes.support_item_number}
                            sx={{ m: 1, width: "95%" }}
                            disabled={true}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : null}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
              <Stack direction="row" spacing={2}>
                {!editChargeBand ? (
                  <Button
                    onClick={() => createNewBand()}
                    variant="outlined"
                    sx={{
                      bgcolor: "#ffffff",
                      fontFamily: "Roboto-Medium",
                      textTransform: "capitalize",
                      color: "#000000",
                      border: "2px solid #6ECA35",
                      ":hover": {
                        border: "2px solid #6ECA35",
                      }
                    }}>
                    Create {loading_res || loading_rate ? <CircularProgress color="secondary" size={26} /> : null}
                  </Button>
                ) : (
                  <Button onClick={() => saveChargeBandRates()} variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                    Save {loading_ch ? <CircularProgress color="secondary" size={26} /> : null}
                  </Button>
                )}
                <Button onClick={() => editPriceItemEmpty()} variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                  Cancel
                </Button>
              </Stack>
            </Box>
          </Box>
        </Fade>
      </Modal>
      {/* messages */}
      <Snackbar open={snacks.open} autoHideDuration={3000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
        <Alert
          onClose={handleCloseSnacks}
          severity={errorSnackMessage.severity}
          variant={errorSnackMessage.variant}
          sx={{ width: '100%' }}
        >
          <AlertTitle>{errorSnackMessage.title}</AlertTitle>
          {errorSnackMessage.message}
        </Alert>
      </Snackbar>
    </Grid>
  )
}