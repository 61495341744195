import { useLazyQuery, useMutation } from "@apollo/client";
import APIGQL from "./graphql";

// // // //=============================query=========================
export const GetReimbursementsByServicePlan = () => {
    const [getByFilter, { loading, error }] = useLazyQuery(APIGQL.ApiGetReimbursementsByServicePlan, {
        fetchPolicy: "cache-and-network"
    })
    return [getByFilter, loading, error];
}

export const GetKilometersClaimsByServicePlan = () => {
    const [getByFilter, { loading, error }] = useLazyQuery(APIGQL.ApiGetKilometersClaimsByServicePlan, {
        fetchPolicy: "cache-and-network"
    })
    return [getByFilter, loading, error];
}

export const ServicePlansFilter = () => {
    const [getByFilter, { loading, error }] = useLazyQuery(APIGQL.ApiServicePlansFilter, {
        fetchPolicy: "cache-and-network"
    })
    return [getByFilter, loading, error];
}

export const NdisPriceListCategory = () => {
    const [getNdisPriceListCategory, { loading, error }] = useLazyQuery(APIGQL.ApiNdisPriceListCategory, {
        fetchPolicy: "cache-and-network"
    })
    return [getNdisPriceListCategory, loading, error];
}

export const NdisPriceList = () => {
    const [getByFilter, { loading, error }] = useLazyQuery(APIGQL.ApiNdisPriceList, {
        fetchPolicy: "cache-and-network"
    })
    return [getByFilter, loading, error];
}

export const GetChargeBandDetails = () => {
    const [chargeBands, { loading, error }] = useLazyQuery(APIGQL.ApiGetChargeBandDetails, {
        fetchPolicy: "network-only"
    })
    return [chargeBands, loading, error];
}

export const ChargeBandRates = () => {
    const [bandsRate, { loading, error }] = useLazyQuery(APIGQL.ApiChargeBandRates, {
        fetchPolicy: "cache-and-network"
    })
    return [bandsRate, loading, error];
}

// // // //=============================mutation-=========================
export const UpdateReimbursement = () => {
    const [updateClaims, { loading, error }] = useMutation(APIGQL.ApiUpdateReimbursement);
    return [updateClaims, loading, error]
}

export const UpdateKilometersClaim = () => {
    const [updateClaims, { loading, error }] = useMutation(APIGQL.ApiUpdateKilometersClaim);
    return [updateClaims, loading, error]
}

export const CreateChargeBandDetail = () => {
    const [createBand, { loading, error }] = useMutation(APIGQL.ApiCreateChargeBandDetail);
    return [createBand, loading, error]
}

export const CreateChargeBandRate = () => {
    const [createBand, { loading, error }] = useMutation(APIGQL.ApiCreateChargeBandRate);
    return [createBand, loading, error]
}

export const UpdateChargeBandRate = () => {
    const [updateBand, { loading, error }] = useMutation(APIGQL.ApiUpdateChargeBandRate);
    return [updateBand, loading, error]
}

export const CreateInvoicing = () => {
    const [createInvoice, { loading, errors }] = useMutation(APIGQL.ApiCreateInvoicing);
    return [createInvoice, loading, errors]
}

export const CreateNewStaffPay = () => {
    const [createStaffPay, { loading, errors }] = useMutation(APIGQL.ApiCreateNewStaffPay);
    return [createStaffPay, loading, errors]
}
