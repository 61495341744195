import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    visitDaysList: [],
    information: null,
    editInformation: null,
    shiftCheckList: null,
    draft: [
        {
            information: {
                first_name: "Rehan",
                last_name: "Khan",
                date_of_birth: "2000-08-19",
                gender: "Male",
                funding_source: "NDIS Funded",
                service_type: "Assistance from live-in carer",
                invoice_to: "NDIS",
                billable: "yes",
                status: "ongoing"
            },
            visit_days: [
                {
                    recurring_type: "X-Days",
                    x_days: "3",
                    start_date: "2024-04-26",
                    end_date: "2024-07-26",
                    start_time: "8:00",
                    end_time: "13:00",
                    check_list: [
                        {
                            shift_title: "Shopping",
                            shift_comments: "Purchase some groceries"
                        },
                        {
                            shift_title: "Gardening",
                            shift_comments: "Create new area and purchase new one"
                        },
                    ]
                }
            ],
            pay_travel_mileage: "yes"
        },
        {
            information: {
                first_name: "Rehan",
                last_name: "Khan",
                date_of_birth: "2000-08-19",
                gender: "Male",
                funding_source: "NDIS Funded",
                service_type: "Assistance from live-in carer",
                invoice_to: "NDIS",
                billable: "yes",
                status: "complete"
            },
            visit_days: [
                {
                    recurring_type: "X-Days",
                    x_days: "2",
                    start_date: "2024-04-15",
                    end_date: "2024-07-24",
                    start_time: "8:00",
                    end_time: "13:00",
                    check_list: [
                        {
                            shift_title: "Shopping",
                            shift_comments: "Purchase some groceries"
                        },
                        {
                            shift_title: "Gardening",
                            shift_comments: "Create new area and purchase new one"
                        },
                    ]
                }
            ],
            pay_travel_mileage: "yes"
        },
        {
            information: {
                first_name: "Rehan",
                last_name: "Khan",
                date_of_birth: "2000-08-19",
                gender: "Male",
                funding_source: "NDIS Funded",
                service_type: "Assistance from live-in carer",
                invoice_to: "NDIS",
                billable: "yes",
                status: "complete"
            },
            visit_days: [
                {
                    recurring_type: "X-Days",
                    x_days: "2",
                    start_date: "2024-04-15",
                    end_date: "2024-07-24",
                    start_time: "8:00",
                    end_time: "13:00",
                    check_list: [
                        {
                            shift_title: "Shopping",
                            shift_comments: "Purchase some groceries"
                        },
                        {
                            shift_title: "Gardening",
                            shift_comments: "Create new area and purchase new one"
                        },
                    ]
                }
            ],
            pay_travel_mileage: "yes"
        }
    ],
    assignStaffWorker: null
};

export const ServiceRequestd = createSlice({
    name: 'visit_days_list',
    initialState,
    reducers: {
        visit_days: (state, action) => {
            state.visitDaysList = action.payload;
        },
        clinet_information: (state, action) => {
            state.information = action.payload;
        },
        edit_information: (state, action) => {
            state.editInformation = action.payload;
        },
        shift_check_list: (state, action) => {
            state.shiftCheckList = action.payload;
        },
        assign_staff_worker: (state, action) => {
            state.assignStaffWorker = action.payload;
        },

    },
});

export const {
    visit_days,
    clinet_information,
    edit_information,
    shift_check_list,
    assign_staff_worker,
} = ServiceRequestd.actions;

export default ServiceRequestd.reducer;