import styled from "@emotion/styled";
import { Alert, AlertTitle, Box, Button, CircularProgress, Grid, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Slide, Snackbar, Stack, TextField, TextareaAutosize, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
// import CircleIcon from '@mui/icons-material/Circle';
import { VerticalLinearStepper } from "../../../components/form_steps";
// import SearchAndFilters from "../../../components/search_filters";
import { Information, ShiftsAndComments, StaffPreference, TravelInfo, VisitDaysModal } from "./service_forms";
import { ClientDetailList, ClientDetailsRelationlData } from "../../../services/client_service";
import { useDispatch } from "react-redux";
import { assign_staff_worker, clinet_information, edit_information, shift_check_list, visit_days } from "../../../middleware/reducers/service_request";
import { CreateServicePlan, CreateServiceRequest, CreateServiceShift, CreateVisitDay, CreateVisitShiftCheckList } from "../../../services/service_request";
import { useSelector } from "react-redux";
import moment from "moment";
import { calculateHours } from "../../../components/functions";

const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
}));

const SlideTransition = (props) => {
    return <Slide {...props} direction="left" />;
}

const ServiceForms = () => {
    // const height = window.screen.height;
    const dispatch = useDispatch()
    const [clientList, loading] = ClientDetailList();
    const [getDetailsById, loading_details] = ClientDetailsRelationlData();

    const [apiCreateServiceRequest, loading_service, error_service] = CreateServiceRequest();
    const [apiCreateVisitDay, loading_day, error_day] = CreateVisitDay();
    const [apiCreateVisitShift, loading_shift, error_shift] = CreateVisitShiftCheckList();
    const [apiCreateServiceShift, loading_serv, error_erv] = CreateServiceShift()
    const [apiServicePlan, loading_plan, error_plan] = CreateServicePlan()

    const _visit_req = useSelector((state) => state._service_request.editInformation);
    const _visit_days = useSelector((state) => state._service_request.visitDaysList);
    const _visit_check_list = useSelector((state) => state._service_request.shiftCheckList);
    const _assign_staff_worker = useSelector((state) => state._service_request.assignStaffWorker);

    const [selectClient, setSelectClient] = useState({
        title: "Client List",
        value: {
            title: "Please Select Client",
            id: "D1"
        },
        options: [
            {
                title: "Please Select Client",
                id: "D1"
            }
        ]
    })

    useEffect(() => {
        if (clientList?.length) {
            const copy = { ...selectClient }
            // console.log("clientList", clientList);
            for (const key in clientList) {
                const data = clientList[key]?.attributes
                const find = copy.options.find((e) => e?.id === clientList[key]?.id)
                if (!find) {
                    const obj = {
                        title: data?.first_name + " " + data?.last_name,
                        id: clientList[key]?.id
                    }
                    copy.options.push(obj)
                }
            }
            setSelectClient(copy)
        }


    }, [clientList])

    const getDetailsFromDB = async (data) => {
        try {
            const response = await getDetailsById({
                variables: {
                    "filter": {
                        "client_detail_id": {
                            "id": {
                                "eq": data?.id
                            }
                        }
                    },
                    "filters": {
                        "client_detail_id": {
                            "id": {
                                "eq": data?.id
                            }
                        }
                    },
                    "filterP": {
                        "client_detail_id": {
                            "id": {
                                "eq": data?.id
                            }
                        }
                    }
                }
            })
            if (response?.data) {
                const copy = { ...data }
                copy.clientAnswerLovsReferences = response?.data?.clientAnswerLovsReferences?.data;
                copy.contactDetail = response?.data?.contactDetails?.data[0];
                copy.preference = response?.data?.preferenceForStaffWorkers?.data[0];
                dispatch(clinet_information(copy))
            }
        } catch (error) {
            console.log(error, "service")
        }
    }

    const [snacks, setSnack] = useState({
        open: false,
        Transition: SlideTransition,
        vertical: 'top',
        horizontal: 'right',
    });

    const [errorSnackMessage, setErrorSnackMessage] = useState({
        title: "",
        message: "",
        severity: "",
        variant: "filled"
    });

    const handleCloseSnacks = () => {
        setSnack({
            ...snacks,
            open: false,
        });
    };

    const handleSnacksApper = (title, message, severity) => {
        setSnack({
            ...snacks,
            open: true,
        });
        // / // // // display message
        setErrorSnackMessage({
            ...errorSnackMessage,
            title,
            message,
            severity
        })
    }

    useEffect(() => {
        if (selectClient?.options.length > 1 && selectClient?.value?.id !== "D1") {
            const findUser = clientList.find((e) => e.id === selectClient.value.id);
            getDetailsFromDB(findUser)
        }
    }, [selectClient]);

    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 5.2
        }
        if (window.screen.width > 600 && window.screen.width < 1600) {
            return window.screen.width / 4
        } else {
            return window.screen.width / 1.4
        }
    }

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const [steps, setSteps] = useState([
        {
            label: 'Information',
            description: '',
            tabActive: false,
            screen: <Information screenWidth={screenWidth} />
        },
        {
            label: "Visit Days",
            description: '',
            tabActive: false,
            screen: <VisitDaysModal />
        },
        {
            label: "Travel",
            description: '',
            tabActive: false,
            screen: <TravelInfo screenWidth={screenWidth} />
        },
        {
            label: "Available Staff Workers Based On Preference",
            description: '',
            tabActive: false,
            screen: <StaffPreference screenWidth={screenWidth} />
        },
    ]);

    const convertDateFormat = (inputDate) => {
        // Split the input date by "-"
        const [month, day, year] = inputDate.split("-");

        // Create a Date object by parsing the input date
        const date = new Date(`${month} ${day}, ${year}`);

        // Get year, month (zero-based index, so add 1), and day from the Date object
        const formattedYear = date.getFullYear();
        const formattedMonth = String(date.getMonth() + 1).padStart(2, '0');
        const formattedDay = String(date.getDate()).padStart(2, '0');

        // Return the formatted date in "YYYY-MM-DD" format
        return `${formattedYear}-${formattedMonth}-${formattedDay}`;
    }

    const createServiceRequestByClient = async () => {
        try {
            // // console.log("_visit_req", _visit_req)
            // // console.log("_visit_days", _visit_days)
            // // console.log("_visit_check_list", _visit_check_list)
            // // console.log("_assign_staff_worker", _assign_staff_worker)
            if (_visit_req && _visit_days?.length && _assign_staff_worker) {
                const response = await apiCreateServiceRequest({
                    variables: {
                        "company_id": _visit_req?.organization_list?.id || null,
                        "client_detail_id": selectClient?.value?.id,
                        "charge_band_rate_id": _visit_req?.service_type?.id,
                        "funding_source": _visit_req?.funding_source,
                        "billable": _visit_req?.billable,
                        // "remove_plan_managers": _visit_req?.remove_plan_managers,
                        "pay_travel_km": _visit_req?.pay_travel,
                    }
                })
                if (response?.data) {
                    const id = response?.data?.createServiceRequest?.data?.id;
                    let count = 0;
                    // // // create visit days as per user selections
                    for (const key in _visit_days) {
                        const object = {
                            recurring_type: _visit_days[key].recurring_type,
                            start_time: _visit_days[key].start_time ? _visit_days[key].start_time + ":00" : "00:00:00",
                            end_time: _visit_days[key].end_time ? _visit_days[key].end_time + ":00" : "00:00:00",
                            start_date: convertDateFormat(_visit_days[key].start_date),
                            end_date: convertDateFormat(_visit_days[key].end_date),
                            week_days: _visit_days[key].week_days,
                            selected_days: _visit_days[key].selected_days,
                            x_days: String(_visit_days[key].x_day),
                            service_request_id: id,
                        }
                        // console.log(object)
                        const response_day = await apiCreateVisitDay({
                            variables: object
                        })
                        if (response_day?.data) {
                            const visit_id = response_day?.data?.createVisitDay?.data?.id
                            const objectsArray = []
                            //  /// handling to create hours and shifts as per date and time
                            for (const as in _visit_days) {
                                if (_visit_days[as].recurring_type.includes("Actual Dates")) {
                                    const days = [..._visit_days[as]?.selected_days]
                                    for (const data of days) {
                                        const modifyMonth = data.month < 10 ? "0" + data.month : data.month;
                                        const modifyDate = data.day < 10 ? "0" + data.day : data.day;
                                        const startShift = data.year + "-" + modifyMonth + "-" + modifyDate + "T" + data.startTime;
                                        const endShift = data.year + "-" + modifyMonth + "-" + modifyDate + "T" + data.endTime;
                                        const supportHours = calculateHours(startShift, endShift);
                                        const objectShift = {
                                            visit_day_id: visit_id,
                                            shift_completed: false,
                                            shift_start: data?.startTime,
                                            shift_end: data?.endTime,
                                            shift_date: data.year + "-" + modifyMonth + "-" + modifyDate,
                                            total_shift_hours: supportHours?.totalHours,
                                            shift_start_flag: supportHours?.shiftStartFlag,
                                            shift_end_flag: supportHours?.shiftEndFlag,
                                            day_time_hours: supportHours?.daytimeHours,
                                            evening_hours: supportHours?.eveningHours,
                                            night_hours: supportHours?.nightHours,
                                            sleepover_hours: supportHours?.sleepHours,
                                            saturday_hours: supportHours?.saturdayHours,
                                            sunday_hours: supportHours?.sundayHours,
                                            holiday_hours: supportHours?.holidayHours,
                                            staff_detail_id: _assign_staff_worker?.id
                                        }
                                        objectsArray.push(objectShift)
                                    }
                                }
                                if (_visit_days[as].recurring_type.includes("Weekly")) {
                                    const days = [..._visit_days[as]?.week_days]
                                    for (const data of days) {
                                        const start_date = data.date + "T" + data.start_time;
                                        const end_date = data.date + "T" + data.end_time;
                                        const supportHours = calculateHours(start_date, end_date);
                                        const objectShift = {
                                            visit_day_id: visit_id,
                                            shift_completed: false,
                                            shift_start: data.start_time,
                                            shift_end: data?.end_time,
                                            shift_date: data?.date,
                                            total_shift_hours: supportHours?.totalHours,
                                            shift_start_flag: supportHours?.shiftStartFlag,
                                            shift_end_flag: supportHours?.shiftEndFlag,
                                            day_time_hours: supportHours?.daytimeHours,
                                            evening_hours: supportHours?.eveningHours,
                                            night_hours: supportHours?.nightHours,
                                            sleepover_hours: supportHours?.sleepHours,
                                            saturday_hours: supportHours?.saturdayHours,
                                            sunday_hours: supportHours?.sundayHours,
                                            holiday_hours: supportHours?.holidayHours,
                                            staff_detail_id: _assign_staff_worker?.id
                                        }
                                        objectsArray.push(objectShift)
                                    }
                                }
                                if (_visit_days[as].recurring_type.includes("X Days")) {
                                    const days = [..._visit_days[as]?.x_days]
                                    for (const data of days) {
                                        const start_date = data.date + "T" + data.startTime;
                                        const end_date = data.date + "T" + data.endTime;
                                        const supportHours = calculateHours(start_date, end_date);
                                        const objectShift = {
                                            visit_day_id: visit_id,
                                            shift_completed: false,
                                            shift_start: data.startTime + ":00",
                                            shift_end: data?.endTime + ":00",
                                            shift_date: data?.date,
                                            total_shift_hours: supportHours?.totalHours,
                                            shift_start_flag: supportHours?.shiftStartFlag,
                                            shift_end_flag: supportHours?.shiftEndFlag,
                                            day_time_hours: supportHours?.daytimeHours,
                                            evening_hours: supportHours?.eveningHours,
                                            night_hours: supportHours?.nightHours,
                                            sleepover_hours: supportHours?.sleepHours,
                                            saturday_hours: supportHours?.saturdayHours,
                                            sunday_hours: supportHours?.sundayHours,
                                            holiday_hours: supportHours?.holidayHours,
                                            staff_detail_id: _assign_staff_worker?.id
                                        }
                                        objectsArray.push(objectShift)
                                    }
                                }
                            }
                            //  /// save data as per array list
                            for (const key in objectsArray) {
                                const response_shift = await apiCreateServiceShift({
                                    variables: objectsArray[key]
                                })
                                if (response_shift?.data) {
                                    count++
                                }
                            }
                            if (count === objectsArray.length) {
                                let count1 = 0;
                                for (const key in _visit_check_list) {
                                    const object = {
                                        shift_title: _visit_check_list[key].title,
                                        shift_comments: _visit_check_list[key].comment,
                                        client_detail_id: selectClient?.value?.id,
                                        service_request_id: id
                                    }
                                    const response_list = await apiCreateVisitShift({
                                        variables: object
                                    })
                                    if (response_list?.data) {
                                        count1++;
                                    }
                                }
                                if (count1 === _visit_check_list?.length) {
                                    const response_plan = await apiServicePlan({
                                        variables: {
                                            staff_id: _assign_staff_worker?.id,
                                            service_request_id: id,
                                            description: _assign_staff_worker?.description,
                                            created_date: moment().format("YYYY-MM-DD")
                                        }
                                    })
                                    if (response_plan?.data) {
                                        handleSnacksApper("Service Request", "Request has been created and planned. Request id is: " + id, "success");
                                        dispatch(edit_information(null));
                                        dispatch(shift_check_list(null));
                                        dispatch(assign_staff_worker(null))
                                        dispatch(clinet_information(null))
                                        dispatch(visit_days([]));
                                    }
                                }
                            }
                        }
                    }

                }
            }
        } catch (error) {
            let message = "";
            if (error?.message || error_service || error_day || error_shift || error_erv || error_plan) {
                const errors = error_service || error_day || error_shift || error_erv || error_plan;
                if (errors?.networkError) {
                    message = "Network error occurred. Please try again later"
                }
                if (errors?.graphQLErrors) {
                    message = errors.graphQLErrors[0]?.message + "or fields are empty.!"
                }
                if (error?.message) {
                    message = error?.message;
                }
                handleSnacksApper("Service Request", message, "error")
            }
        }
    }

    return (
        <Grid container sx={{ p: 2, display: "flex", justifyContent: "center", marginBottom: { xs: "14%", sm: "2%", lg: "2%" }, marginTop: { xs: "10%", sm: "2%", lg: "2%" }, }}>
            {loading || loading_details ? (
                <Box sx={{
                    background: "rgba(255,255,255,0.5)",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <CircularProgress color="secondary" size={45} />
                </Box>
            ) : null}
            <Grid item={true} xs={12} md={12} lg={11} sx={{ display: "flex", justifyContent: "flex-end" }}>

                {/* render client list */}
                <TextField
                    select={true}
                    label={selectClient.title}
                    sx={{ m: 1, width: screenWidth, background: "#fff", }}
                    value={selectClient.value.title}
                    onChange={(e) => {
                        const copy = { ...selectClient };
                        const find = copy?.options?.find((f) => f.title === e.target.value)
                        if (find.id !== "D1") {
                            copy.value = find;
                            setSelectClient(copy);
                        }
                    }}
                >
                    {selectClient.options?.map((value) => (
                        <MenuItem key={value?.title} value={value?.title}>
                            <Typography textAlign="left">{value?.title}</Typography>
                        </MenuItem>
                    ))}
                </TextField>

                {/* <SearchAndFilters placeholder="Seach Client" groupBy={false} favorite={false} /> */}
            </Grid>
            <Grid item={true} xs={12} md={12} sm={12} lg={11} sx={{ flexGrow: 1, }}>
                <StyledPaper
                    sx={{
                        my: 1,
                        mx: 'auto',
                        p: 2,
                    }}
                    elevation={0}
                >
                    <VerticalLinearStepper steps={steps} />
                </StyledPaper>
            </Grid>
            <Grid item={true} xs={12} md={12} lg={11} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Stack>
                    <Button disabled={!_visit_req || !_visit_days || !_assign_staff_worker} onClick={() => createServiceRequestByClient()} variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                        Submit
                        {loading_service || loading_day || loading_shift || loading_serv || loading_plan ? <CircularProgress color="secondary" size={26} /> : null}
                    </Button>
                </Stack>
            </Grid>
            <Snackbar open={snacks.open} autoHideDuration={5000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
                <Alert
                    onClose={handleCloseSnacks}
                    severity={errorSnackMessage.severity}
                    variant={errorSnackMessage.variant}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{errorSnackMessage.title}</AlertTitle>
                    {errorSnackMessage.message}
                </Alert>
            </Snackbar>
        </Grid>
    )
}

export default ServiceForms;



