import { Backdrop, Box, Fade, Paper, Grid, TextField, MenuItem, Typography, Button, Stack, Tooltip, ToggleButtonGroup, ToggleButton, Modal } from '@mui/material';
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import SearchAndFilters from "../../../components/search_filters";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const FeedBackReport = () => {
    const height = window.screen.height;
    const [newFeedback, setNewFeedback] = useState(false);

    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        maxWidth: "calc(70%)",
        minWidth: "calc(60%)",
        boxShadow: 12,
        borderRadius: 1,
        p: 4,
    });

    const width = () => {
        // modal control
        if (window.screen.width < 900) {
            const copy = { ...style };
            copy.maxWidth = "95%";
            setStyle(copy);
        }

        if (window.screen.width > 1600) {
            return window.screen.width / 5
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.4
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const [category, setCategory] = useState([
        {
            title: "Feedback Types",
            value: "Please Select Option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Please Select Option",
                    selected: true
                },
                {
                    title: "Compliment",
                    selected: false
                },
                // {
                //     title: "Complaint",
                //     selected: false
                // },
                {
                    title: "Suggestions",
                    selected: false
                }
            ]
        },
        {
            title: "Source of Feedback",
            value: "Please Select Option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Please Select Option",
                    selected: true
                },
                {
                    title: "Client/Rep",
                    selected: false
                },
                {
                    title: "Staff",
                    selected: false
                },
                {
                    title: "External body",
                    selected: false
                },
                {
                    title: "Other",
                    selected: false
                }
            ]
        },
        {
            title: "Areas you are providing feedback",
            value: "Please Select Option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Please Select Option",
                    selected: true
                },
                {
                    title: "Management/Staff",
                    selected: false
                },
                {
                    title: "Care",
                    selected: false
                },
                {
                    title: "Lifestyle/Activities",
                    selected: false
                },
                {
                    title: "Environment",
                    selected: false
                },
                {
                    title: "Documentation",
                    selected: false
                },
                {
                    title: "Maintenance",
                    selected: false
                },
                {
                    title: "Food",
                    selected: false
                },
                {
                    title: "Assets",
                    selected: false
                },
                {
                    title: "Other",
                    selected: false
                },
            ]
        },
        // {
        //     title: "Category",
        //     value: "Assets",
        //     width: screenWidth,
        //     type: "drop_down",
        //     required: true,
        //     options: [
        //         {
        //             title: "Assets",
        //             selected: true
        //         },
        //         {
        //             title: "Client",
        //             selected: false
        //         },
        //         {
        //             title: "Staff",
        //             selected: false
        //         },
        //         {
        //             title: "Other",
        //             selected: false
        //         }
        //     ]
        // },
        {
            title: "First Name",
            value: "",
            width: screenWidth,
            type: "text",
            required: false,
            multiline: false
        },
        {
            title: "Last Name",
            value: "",
            width: screenWidth,
            type: "text",
            required: false,
            multiline: false
        },
        {
            title: "Contact",
            value: "",
            width: screenWidth,
            type: "text",
            required: false,
            multiline: false
        },
        {
            title: "Email",
            value: "",
            width: screenWidth,
            type: "email",
            required: false,
            multiline: false
        },
        {
            title: "Feedback Description / Comments",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: true
        },
    ]);
    const [staffList, setStaffList] = useState([
        {
            title: "Staff List",
            value: "Select Staff Name",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Select Staff Name",
                    selected: true
                },
                {
                    title: "Dr.Saba",
                    selected: false
                },
                {
                    title: "Dr.Abbas",
                    selected: false
                },
                {
                    title: "Dr.Fayaz",
                    selected: false
                }
            ]
        },
    ]);
    const [comment, setComment] = useState("");

    return (
        <Grid container sx={{ justifyContent: "center", marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, marginTop: { xs: "2%", sm: "2%", lg: "1%" }, }} >
            <Grid item={true} xs={11} sm={11} md={11} lg={11} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap', }}>
                <Grid item={true} xs={11} md={6} sm={11} lg={6}>
                    <Stack spacing={2} direction={window.screen.width < 900 ? "column" : "row"} sx={{ my: 1 }}>
                        <Tooltip title="To Sepecific User">
                            <Button onClick={() => setNewFeedback(true)}
                                variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                Send Email
                            </Button>
                        </Tooltip>
                    </Stack>
                </Grid>
                <Grid item={true} xs={11} md={6} sm={11} lg={6}>
                    <SearchAndFilters groupBy={false} favorite={false} inputWidth={8} />
                </Grid>
            </Grid>
            <Grid item={true} xs={11} md={11} sm={11} lg={11} sx={{ flexGrow: 1, marginBottom: height / 130, }}>
                <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap', }}>
                    <Grid sx={{ m: 2, display: 'flex', flexWrap: 'wrap', }}>
                        <Typography color={"primary"} style={{ fontFamily: "Roboto-Bold", fontSize: 20 }}>
                            Feedback
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} sm={12} lg={12} sx={{ display: 'flex', flexWrap: 'wrap', border: "1px solid #6ECA35", borderRadius: 3, bgcolor: "#fff", p: 3 }}>
                    {category.map((v, i) => {
                        return (
                            <div key={v.title}>
                                {v.type === "drop_down" ? (
                                    <TextField
                                        select={true}
                                        label={v.title}
                                        htmlFor={v.title}
                                        required={v.required}
                                        sx={{ m: 1, width: v.width }}
                                        value={v.value}
                                        onChange={(e) => {
                                            const copy = [...category];
                                            copy[i].value = e.target.value;
                                            setCategory(copy);
                                        }}
                                    >
                                        {v?.options?.map((nV, nI) => (
                                            <MenuItem key={nI} value={nV.title} >
                                                {nV.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                ) : null}
                                {v.type === "text" || v.type === "email" ? (
                                    <TextField
                                        type={v.type}
                                        label={v.title}
                                        multiline={v.multiline}
                                        required={v.required}
                                        htmlFor={v.title}
                                        value={v.value}
                                        sx={{ m: 1, width: v.width }}
                                        onChange={(e) => {
                                            const copy = [...category];
                                            copy[i].value = e.target.value;
                                            setCategory(copy);
                                        }}
                                    />
                                ) : null}

                                {/* {v.value !== "Other" && v.value !== "Staff" ? (
                                    <TextField
                                        style={{ marginBottom: "2%" }}
                                        label={"Search " + v.value}
                                        sx={{ m: 1, width: v.width }}
                                    />
                                ) : null} */}
                                {/* {v.value === "Staff" && staffList.map((vN, iN) => {
                                    return (
                                        <TextField
                                            key={iN}
                                            select={true}
                                            label={vN.title}
                                            htmlFor={vN.title}
                                            required={vN.required}
                                            sx={{ m: 1, width: vN.width }}
                                            value={vN.value}
                                            onChange={(e) => {
                                                const copy = [...staffList];
                                                if (e.target.value !== "Select Staff Name") {
                                                    copy[i].value = e.target.value;
                                                    setStaffList(copy);
                                                }
                                            }}
                                        >
                                            {vN?.options?.map((nV, nI) => (
                                                <MenuItem key={nI} value={nV.title} >
                                                    {nV.title}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )
                                })} */}
                            </div>
                        )
                    })}
                </Grid>
                {/* <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap', }}>
                    <TextField
                        label={"Feedback Comments"}
                        multiline={true}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        sx={{ m: 1, width: screenWidth }}
                    />
                </Grid> */}
                <Grid item={true} xs={11} sm={11} md={12} lg={12} sx={{ p: 2, mx: 'auto', display: 'flex', flexWrap: 'wrap', justifyContent: "flex-end" }}>
                    <Stack direction="row" spacing={2}>
                        <Tooltip title="Save Online Report">
                            <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                Save
                            </Button>
                        </Tooltip>
                    </Stack>
                </Grid>
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={newFeedback}
                onClose={() => setNewFeedback(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={newFeedback}>
                    <Box sx={style}>
                        <Box>
                            <Typography variant="h5" component="h2" sx={{ fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                                Send Email
                            </Typography>
                        </Box>
                        <Box sx={{ my: 2 }}>
                            <TextField
                                label="Email To"
                                value={""}
                                sx={{ m: 1, width: screenWidth }}
                            />
                            <TextField
                                label="Description"
                                value={""}
                                sx={{ m: 1, width: screenWidth }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained" >
                                    Send
                                </Button>
                                <Button variant="contained" onClick={() => setNewFeedback(false)}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Grid>
    )
}