import { Box, IconButton, Menu, MenuItem, Toolbar, Typography, Badge, Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectedServiceReducer } from "../../middleware/reducers/my_services";
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ForumIcon from '@mui/icons-material/Forum';
import { AuthService } from "../../services/auth_service";

const settings = ['Profile', 'Logout'];

export const HeaderToolbar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const _userInfo = useSelector((state) => state._authentication.auth_user)

    const [anchorElUser, setAnchorElUser] = useState(null);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")))
    const [userType, setUserType] = useState(null)

    useEffect(() => {
        if (_userInfo) {
            setUserType(_userInfo?.attributes?.user_type?.data?.attributes?.user_type);
        }
    }, [_userInfo])
    
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    return (
        <Toolbar>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: "center" }}>
                {userType?.toLowerCase() === "staff" && (
                    <IconButton
                        size="large"
                        color="inherit"
                        aria-haspopup="true"
                        // onClick={handleOpenTimeMenu}
                        onClick={() => {
                            // navigate to the service page
                            navigate('/service-dashboard');
                            // dispatch active visit day
                            dispatch(selectedServiceReducer({ objId: 0, serv_code: "ABC123" }));
                        }}
                    >
                        <Badge badgeContent={"1"} color="secondary">
                            <EventRepeatIcon color="primary" />
                        </Badge>
                    </IconButton>
                )}
                <IconButton size="large" aria-label="show 3 new aerts" color="inherit">
                    <Badge badgeContent={3} color="secondary">
                        <ForumIcon color="primary" />
                    </Badge>
                </IconButton>
                <IconButton size="large" aria-label="show 4 new aerts" color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <NotificationsActiveIcon color="primary" />
                    </Badge>
                </IconButton>
                <IconButton
                    size="large"
                    edge="end"
                    // aria-label="account of current user"
                    // aria-controls={menuId}
                    // aria-haspopup="true"
                    onClick={handleOpenUserMenu}
                // color="inherit"
                >
                    {/* <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}> */}
                    <Avatar sx={{ fontFamily: "Roboto-Medium", fontSize: "1em", backgroundColor: "#3A89CC" }}>
                        {user?.username.slice(0, 1)}
                    </Avatar>
                </IconButton>
            </Box>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {settings.map((setting) => (
                    <MenuItem key={setting} onClick={() => {
                        if (setting === "Logout") {
                            AuthService.logout();
                            navigate('/');
                        }
                        if (setting === "Profile") {
                            navigate('/my-profile');
                        }
                    }}>
                        <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Toolbar>
    )
}