import React from "react";
import styled from "@emotion/styled";
import { Box, Grid, Paper, Stack, Step, StepConnector, StepContent, StepLabel, Stepper, stepConnectorClasses } from "@mui/material";
import PropTypes from 'prop-types';
import LocalPharmacyRoundedIcon from '@mui/icons-material/LocalPharmacyRounded';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import AirlineSeatIndividualSuiteIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import { ClientInfoDetails, HomeRiskAssessmentDetails, IndividualRiskAssessmentInfoDetails, MedicalInfodetails, SafteyPlansDetails, ServiceRequestHistory, ServiceSchedual, StaffDetails } from "./screens";
import { DetailsTab } from "./screens/details";
import StaffDetailsViewV2 from "./screens_v2/staff_view";
import CleintDetailsViewV2 from "./screens_v2/client_view";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 24,
        left: 'calc(-50% + 26px)',
        right: 'calc(50% + 26px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#cccccc',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: "#000000",
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor: "rgba(0,0,0,0.5)",
    }),

}));

const ColorlibStepIcon = (props) => {
    const { active, completed, className } = props;

    const icons = {
        1: <MedicalInformationIcon />,
        2: <LocalPharmacyRoundedIcon />,
        3: <AirlineSeatIndividualSuiteIcon />,
        4: <AddHomeWorkIcon />,
        5: <HealthAndSafetyIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const steps = [
    {
        label: 'Client Information',
        screen: <ClientInfoDetails />
    },
    {
        label: 'Medical Information',
        screen: <MedicalInfodetails />
    },
    {
        label: 'Individual Risk Assessment',
        screen: <IndividualRiskAssessmentInfoDetails />
    },
    {
        label: 'Home Risk Assessment',
        screen: <HomeRiskAssessmentDetails />
    },
    {
        label: 'Saftey Plans',
        screen: <SafteyPlansDetails />
    },
    {
        label: 'Service Schedule',
        screen: <ServiceSchedual />
    },
    {
        label: 'Service Request History',
        screen: <ServiceRequestHistory />
    },
    {
        label: 'Incident',
        // screen: <SafteyPlansDetails />
    },
    {
        label: "Complaints",
        // screen: <SafteyPlansDetails />
    },
    {
        label: "meeting history",
        // screen: <SafteyPlansDetails />
    },
];

export const ClientDetailsWithSteps = () => {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = (index) => {
        setActiveStep(index);
    };

    return (

        <CleintDetailsViewV2 />
        // <Grid container sx={{ display: "flex", justifyContent: "center", marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, marginTop: { xs: "15%", sm: "3%", lg: "1%" }, marginRight: { xs: "3%", sm: "3%", lg: "3%" }, }} >
        //     <Grid item={true} xs={11} sm={11} md={11} lg={11}>
        //         <DetailsTab steps={steps} />
        //     </Grid>
        // </Grid >
    )
}

export const StaffDetailsWithSteps = () => {

    const steps = [
        {
            label: 'Staff Details',
            screen: <StaffDetails />
        },
        {
            label: 'Service Schedule',
            screen: <ServiceSchedual />
        },
    ];

    return (
        <StaffDetailsViewV2 />

        // <Grid container sx={{ display: "flex", justifyContent: "center", marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, marginTop: { xs: "15%", sm: "3%", lg: "1%" }, marginRight: { xs: "3%", sm: "3%", lg: "3%" }, }} >
        //     <Grid item={true} xs={11} sm={11} md={11} lg={11}>
        //         <DetailsTab steps={steps} />
        //     </Grid>
        // </Grid >
    )
}
