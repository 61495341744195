import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    client_details_id: null,
    staff_details_id: null,
    client_edit_id: null,
    staff_edit_id: null,

};

export const HistoryReducer = createSlice({
    name: 'history_reducers',
    initialState,
    reducers: {
        clientDetailsId: (state, action) => {
            state.client_details_id = action.payload;
        },
        staffDetailsId: (state, action) => {
            state.staff_details_id = action.payload;
        },
        clientEditId: (state, action) => {
            state.client_edit_id = action.payload;
        },
        staffEditId: (state, action) => {
            state.staff_edit_id = action.payload;
        },
    },
});

export const {
    clientDetailsId,
    staffDetailsId,
    clientEditId,
    staffEditId,
} = HistoryReducer.actions;

export default HistoryReducer.reducer;