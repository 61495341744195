import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    audit_module_list: null,
};

export const MyAuditReducer = createSlice({
    name: 'audit_module_list',
    initialState,
    reducers: {
        auditModuleList: (state, action) => {
            state.audit_module_list = action.payload;
        },
    },
});

export const {
    auditModuleList,
} = MyAuditReducer.actions;

export default MyAuditReducer.reducer;