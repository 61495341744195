import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography, Box } from "@mui/material";

// Updated mock data for clients and staff
const initialClients = Array.from({ length: 14 }, (_, index) => `Client ${index + 1}`);
const initialStaff = ["Staff 1", "Staff 2", "Staff 3", "Staff 4", "Staff 5", "Staff 6", "Staff 7"];

const CalendarView = () => {
  const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [clients, setClients] = useState(initialClients);
  const [staff, setStaff] = useState(initialStaff);

  // Detect screen size changes
  useEffect(() => {
    const handleResize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Calculate card size based on screen size
  const cardSize = { xs: 100, sm: 150, md: 150 }; // Adjust the size for responsive behavior
  const cardWidth = screenSize.width < 600 ? cardSize.xs : screenSize.width < 960 ? cardSize.sm : cardSize.md;
  const cardHeight = screenSize.height < 600 ? cardSize.xs : screenSize.height < 960 ? cardSize.sm : cardSize.md;

  // Scrollable container dimensions (fit the viewport)
  const containerHeight = screenSize.height - 100; // Adjust for padding/margin
  const containerWidth = screenSize.width - 100;

  // Render the calendar grid with scrollable behavior
  const renderCalendarGrid = () => {
    return staff.map((staffMember, rowIndex) => (
      <Grid container key={staffMember} wrap="nowrap" direction="row" alignItems="center">
        {/* Staff column */}
        <Grid item>
          <Paper
            sx={{
              width: cardWidth,
              height: cardHeight,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f5f5f5",
              border: "1px solid #ccc",
            }}
          >
            <Typography>{staffMember}</Typography>
          </Paper>
        </Grid>

        {/* Row cells (for each client) */}
        {clients.map((client, columnIndex) => (
          <Grid item key={`${rowIndex}-${columnIndex}`}>
            <Paper
              sx={{
                width: cardWidth,
                height: cardHeight,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#f5f5f5",
                border: "1px solid #ccc",
              }}
            >
              <Typography>{client}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    ));
  };

  return (
    <Box p={2}>
      {/* Scrollable container */}
      <Box
        sx={{
          width: containerWidth,
          height: containerHeight,
          overflowY: "auto",  // Vertical scrolling for staff rows
          border: "2px solid #ccc",
          padding: "10px",
        }}
      >
        {/* Scrollable horizontal section for clients */}
        <Box
          sx={{
            // Enable horizontal scrolling for clients
            display: "flex",
            flexDirection: "row",
            borderBottom: "2px solid #ccc",
            // paddingBottom: "10px",
            // marginBottom: "10px",
          }}
        >
          <Box sx={{ width: 150, height: cardHeight, border: "1px solid red" }}/>
          <Grid container spacing={0} wrap="nowrap" sx={{ overflowX: "auto", }}>
            {clients.map((client, index) => (
              <Grid item key={index}>
                <Paper
                  sx={{
                    width: cardWidth,
                    height: cardHeight,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f5f5f5",
                    border: "1px solid #ccc",
                  }}
                >
                  <Typography>{client}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Calendar grid with staff rows */}
        {renderCalendarGrid()}
      </Box>
    </Box>
  );
};

export default CalendarView;
