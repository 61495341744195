import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import {
    Grid, Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Paper, Checkbox, IconButton, Tooltip, Button, Stack,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SearchAndFilters from '../../../components/search_filters';

const createData = (id, service_code, service_status, service_name, client_name, date_of_travel, start_time, end_time, start_location, end_location, total_kilometers, purpose_of_travel, vehicle_used, additional_notes, approver_name) => {
    return {
        id,
        service_code,
        service_status,
        service_name,
        client_name,
        date_of_travel,
        start_time,
        end_time,
        start_location, end_location, total_kilometers, purpose_of_travel, vehicle_used, additional_notes, approver_name
    };
}

const rows = [
    createData(1, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Falkan", "feb-08-2024", "10:30", "17:30", "abc address", "abc address", 5, "purchase items", "mira car", "Max Jallili"),
    createData(2, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Falkan", "feb-08-2024", "10:30", "17:30", "abc address", "abc address", 5, "purchase items", "mira car", "Max Jallili"),
    createData(3, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Falkan", "feb-08-2024", "10:30", "17:30", "abc address", "abc address", 5, "purchase items", "mira car", "Max Jallili"),
    createData(4, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Falkan", "feb-08-2024", "10:30", "17:30", "abc address", "abc address", 5, "purchase items", "mira car", "Max Jallili"),
    createData(5, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Falkan", "feb-08-2024", "10:30", "17:30", "abc address", "abc address", 5, "purchase items", "mira car", "Max Jallili"),
    createData(6, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Falkan", "feb-08-2024", "10:30", "17:30", "abc address", "abc address", 5, "purchase items", "mira car", "Max Jallili"),
    createData(7, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Falkan", "feb-08-2024", "10:30", "17:30", "abc address", "abc address", 5, "purchase items", "mira car", "Max Jallili"),
];

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'ID',
        numeric: false,
        disablePadding: true,
        label: 'ID',
    },
    {
        id: 'Service Code',
        numeric: true,
        disablePadding: false,
        label: 'Service Code',
    },
    {
        id: 'Service Status',
        numeric: true,
        disablePadding: false,
        label: 'Service Status',
    },
    {
        id: 'Service Name',
        numeric: true,
        disablePadding: false,
        label: 'Service Name',
    },
    {
        id: 'Client Name',
        numeric: true,
        disablePadding: false,
        label: 'Client Name',
    },
    {
        id: 'Date of Travel',
        numeric: true,
        disablePadding: false,
        label: 'Date of Travel',
    },
    {
        id: 'Start Time',
        numeric: true,
        disablePadding: false,
        label: 'Start Time',
    },
    {
        id: 'End Time',
        numeric: true,
        disablePadding: false,
        label: 'End Time',
    },
    {
        id: 'Start Location',
        numeric: true,
        disablePadding: false,
        label: 'Start Location',
    },
    {
        id: 'End Location',
        numeric: true,
        disablePadding: false,
        label: 'End Location',
    },
    {
        id: 'Total Kilometers',
        numeric: true,
        disablePadding: false,
        label: 'Total Kilometers',
    },
    {
        id: 'Purpose of Travel',
        numeric: true,
        disablePadding: false,
        label: 'Purpose of Travel',
    },
    {
        id: 'Vehicle Used',
        numeric: true,
        disablePadding: false,
        label: 'Vehicle Used',
    },
    {
        id: "Approver Name",
        numeric: true,
        disablePadding: false,
        label: 'Approver Name',
    },
    {
        id: 'Options',
        numeric: true,
        disablePadding: false,
        label: 'Options',
    },
];

const EnhancedTableHead = (props) => {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        style={{ minWidth: headCell.label?.length >= 10 ? 180 : 100 }}
                        // padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            style={{ fontSize: "1.1em", fontFamily: "Roboto-Bold" }}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >

                </Typography>
            )}

            {numSelected > 0 ? (
                <Stack direction="row" spacing={2}>
                    <Tooltip title="Reject">
                        <Button variant='outlined' label="approve" startIcon={<EditIcon />}>
                            Edit
                        </Button>
                    </Tooltip>
                    <Tooltip title="Approved">
                        <Button variant='outlined' label="approve" startIcon={<DeleteIcon />}>
                            Delete
                        </Button>
                    </Tooltip>
                </Stack>
            ) : null}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export const KilometerClaims = ({ view = "" }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Grid container sx={{ display: "flex", justifyContent: "center", my: 2, marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, }}>
            {view === "staff" && (
                <Grid item={true} xs={11} sm={11} md={11} lg={11} component={Paper} elevation={0}>
                    <SearchAndFilters groupBy={false} favorite={false} defaultState={false} />
                </Grid>
            )}
            <Grid item={true} xs={11.5} sm={11.5} md={11.5} lg={11.5} component={Paper} elevation={0} sx={{ border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }}>
                {selected.length > 0 && (
                    <EnhancedTableToolbar numSelected={selected.length} />
                )}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, row.id)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                        >
                                            {row.id}
                                        </TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.service_code}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.service_status}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.service_name}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.client_name}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.date_of_travel}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.start_time}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.end_time}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.start_location}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.end_location}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.total_kilometers}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.purpose_of_travel}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.vehicle_used}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.approver_name}</TableCell>
                                        <TableCell sx={{ py: 1 }}>
                                            <Stack direction={"row"} spacing={2}>
                                                <IconButton>
                                                    <VisibilityIcon />
                                                </IconButton>
                                                <IconButton>
                                                    <FeedbackIcon />
                                                </IconButton>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

            </Grid>
        </Grid>
    );
}

export const KilometerClaimsGrid = ({ screenWidth }) => {
    return (
        <Grid container sx={{ display: "flex", justifyContent: "center", }}>
            {rows.map((v, i) => {
                return (
                    <Paper key={i} elevation={2} sx={{ width: screenWidth, p: 2, m: 1 }}>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Service Code:</span> {v.service_code}
                        </Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Service Status:</span> {v.service_status}
                        </Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Service Name:</span> {v.service_name}
                        </Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Client Name:</span> {v.client_name}
                        </Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Date Of Travel:</span> {v.date_of_travel}
                        </Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Start Time:</span> {v.start_time}
                        </Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>End Time:</span> {v.end_time}
                        </Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Start Location:</span> {v.start_location}
                        </Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>End Location:</span> {v.end_location}
                        </Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Total Kilometers:</span> {v.total_kilometers}
                        </Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Purpose Of Travel:</span> {v.purpose_of_travel}
                        </Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Vehicle Used:</span> {v.vehicle_used}
                        </Typography>
                    </Paper>
                )
            })}
        </Grid>
    )
}