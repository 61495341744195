import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import APIGQL from "./graphql";


export const StaffDetailsList = () => {
    const { data, loading } = useQuery(APIGQL.ApiGetStaffDetailsList, {
        fetchPolicy: "cache-and-network"
    })
    const staffData = data?.staffDetails?.data;
    return [staffData, loading];
}

export const GetStaffDetailsWithId = () => {
    const [fetchDataById, { loading }] = useLazyQuery(APIGQL.ApiGetStaffDetailsWithId, {
        fetchPolicy: "cache-and-network"
    })
    return [fetchDataById, loading];
}


export const StaffDetailsWithRelationalDataId = () => {
    const [fetchDataById, { loading }] = useLazyQuery(APIGQL.ApiGetStaffDetailsWithRelationalData, {
        fetchPolicy: "cache-and-network"
    })
    return [fetchDataById, loading];
}

// // // // --------------------------------------- mutation ---------------------------

export const UploadNewDocument = () => {
    const [createDocs, { loading, error }] = useMutation(APIGQL.ApiUploadNewDocument);
    return [createDocs, loading, error]
}

export const CreateNewStaff = () => {
    const [createStaff, { loading, error }] = useMutation(APIGQL.ApiCreateNewStaff);
    return [createStaff, loading, error]
}

export const CreateStaffAnswerLovsReference = () => {
    const [createStaffAnsLov, { loading, error }] = useMutation(APIGQL.ApiCreateStaffAnswerLovsReference);
    return [createStaffAnsLov, loading, error]
}

export const CreateContactDetailForStaff = () => {
    const [createStaffContact, { loading, error }] = useMutation(APIGQL.ApiCreateContactDetailForStaff);
    return [createStaffContact, loading, error]
}

export const CreateEmergencyContactDetailForStaff = () => {
    const [createStaffEmergency, { loading, error }] = useMutation(APIGQL.ApiCreateEmergencyContactDetailForStaff);
    return [createStaffEmergency, loading, error]
}

export const CreateStaffQuestionSingleAnswerRef = () => {
    const [createStaffQuestion, { loading, error }] = useMutation(APIGQL.ApiCreateStaffQuestionSingleAnswerRef);
    return [createStaffQuestion, loading, error]
}

export const CreateStaffPaymentDetail = () => {
    const [createPayment, { loading, error }] = useMutation(APIGQL.ApiCreateStaffPaymentDetail);
    return [createPayment, loading, error]
}

export const CreateWorkingDay = () => {
    const [createDay, { loading, error }] = useMutation(APIGQL.ApiCreateWorkingDay);
    return [createDay, loading, error]
}

export const CreateDocumentsProfessionalList = () => {
    const [createDocument, { loading, error }] = useMutation(APIGQL.ApiCreateDocumentsProfessionalList);
    return [createDocument, loading, error]
}

export const CreateNewUserForLogin = () => {
    const [createNewLogin, { loading, error }] = useMutation(APIGQL.ApiCreateNewUserForLogin);
    return [createNewLogin, loading, error]
}
