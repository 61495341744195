import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    auth_user: null,
    auth_token: null
};

export const MyAuthReducer = createSlice({
    name: 'auth_user_management',
    initialState,
    reducers: {
        userStateManagement: (state, action) => {
            state.auth_user = action.payload;
        },
        authToken: (state, action) => {
            state.auth_token = action.payload
        },
    },
});

export const {
    userStateManagement,
    authToken,
} = MyAuthReducer.actions;

export default MyAuthReducer.reducer;