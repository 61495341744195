import React, { useEffect, useState } from "react";
import { Paper, Grid, Typography, Box, Stack, IconButton, Button } from '@mui/material';
import styled from "@emotion/styled";
import { VerticalLinearStepper } from "../../../components/form_steps";
// import CircleIcon from '@mui/icons-material/Circle';
import dayjs from "dayjs";
import { useSelector } from "react-redux";


const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
}));

const InfoCard = ({ data }) => {
    return (
        <Grid container sx={{ display: "flex", }}>
            <Grid item={true} xs={12} md={12} sm={12} lg={12} sx={{
                py: 1,
                borderBottom: "1px solid #000000",
                backgroundColor: "#e2e6e8"
            }}></Grid>
            <Grid item={true} xs={12} md={12} sm={12} lg={12} sx={{ display: "flex", flexWrap: "wrap", py: 2, borderBottom: "2px solid #c8c8c8" }} >
                {data?.map((v, i) => {
                    return (
                        <Box key={i} sx={{ px: 2, my: 1, borderLeft: i >= 1 ? "2px solid #c8c8c8" : "none" }}>
                            <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                                {v.title}
                            </Typography>
                            <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                                {v.value}
                            </Typography>
                        </Box>
                    )
                })}
            </Grid>
        </Grid>
    )
}

const InfoCardNested = ({ data }) => {
    return (
        <Grid container >
            {data?.map((v, i) => {
                return (
                    <Grid item={true} xs={12} md={12} sm={12} lg={12} key={i} sx={{ px: 1, }}>
                        {v.options.map((vN, iN) => {
                            return (
                                <Grid item={true} xs={12} md={12} sm={12} lg={12} key={iN} sx={{ p: 1 }}>
                                    <Typography sx={{ fontFamily: "Roboto-Bold", color: "#888888" }}>
                                        {vN.title}
                                    </Typography>
                                    <Grid item={true} xs={12} md={12} sm={12} lg={12} sx={{
                                        py: 1,
                                        borderBottom: "1px solid #000000",
                                        backgroundColor: "#e2e6e8"
                                    }}></Grid>
                                    <Grid item={true} xs={12} md={12} sm={12} lg={12} key={iN} sx={{ px: 1, display: "flex", }}>
                                        {vN.childOptions.map((vN1, iN1) => {
                                            return (
                                                <Box key={iN1} sx={{ px: 2, my: 1, borderLeft: iN1 >= 1 ? "2px solid #c8c8c8" : "none" }}>
                                                    <Typography sx={{ fontFamily: "Roboto-Medium", color: "#505253" }}>
                                                        {vN1.title}
                                                    </Typography>
                                                    <Typography sx={{ fontFamily: "Roboto-Regular", color: "#505253" }}>
                                                        {vN1.value}
                                                    </Typography>
                                                </Box>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                )
            })}
        </Grid>
    )
}

const InfoPlansList = ({ dataLeft, dataRight }) => {
    return (
        <Grid container>
            {dataLeft?.map((v, i) => {
                return (
                    <Grid item={true} key={i} xs={12} md={6} sm={6} lg={6} sx={{ border: "1px solid grey" }}>
                        <Grid item={true} xs={12} md={12} sm={12} lg={12} sx={{ p: 1, borderBottom: "1px solid grey", backgroundColor: v.color }}>
                            <Typography sx={{ fontFamily: "Roboto-Medium", color: "#ffffff" }}>
                                {v.title}
                            </Typography>
                        </Grid>
                        {v.options.map((vN, iN) => {
                            return (
                                <Grid item={true} key={iN} xs={12} md={12} sm={12} lg={12} sx={{ p: 2, }}>
                                    <li style={{ fontFamily: "Roboto-Regular" }}>{vN.text}</li>
                                </Grid>
                            )
                        })}
                    </Grid>
                )
            })}
            {dataRight?.map((v, i) => {
                return (
                    <Grid item={true} key={i} xs={12} md={6} sm={6} lg={6} sx={{ border: "1px solid grey" }}>
                        <Grid item={true} xs={12} md={12} sm={12} lg={12} sx={{ p: 1, borderBottom: "1px solid grey", backgroundColor: v.color }}>
                            <Typography sx={{ fontFamily: "Roboto-Medium", color: "#ffffff" }}>
                                {v.title}
                            </Typography>
                        </Grid>
                        {v.options.map((vN, iN) => {
                            return (
                                <Grid item={true} key={iN} xs={12} md={12} sm={12} lg={12} sx={{ p: 2, }}>
                                    <li style={{ fontFamily: "Roboto-Regular" }}>{vN.text}</li>
                                </Grid>
                            )
                        })}
                    </Grid>
                )
            })}
        </Grid>
    )
}

export const ClientInfoDetails = () => {

    const [client_information, setClientinformation] = useState([
        {
            title: "First Name",
            value: "Joe",
        },
        {
            title: "Last Name",
            value: "Joe",
        },
        {
            title: "Date of Birth",
            value: "1991-08-22",
        },
        {
            title: "Age",
            value: "23",
        },
        {
            title: "Gender",
            value: "Male",
        },
        {
            title: "Statement Preference",
            value: "Email",
        },
        {
            title: 'Language Spoken',
            value: "English",
        },
        {
            title: "NDIS Number",
            value: "123456789",
        },
        {
            title: "Primary Diagnosis",
            value: "abc",
        },
        {
            title: "Type of Services",
            value: "Permanent Care",
        },
    ]);

    const [centerLink, setCenterLink] = useState([
        {
            title: "Medicare Number",
            value: "3215",
        },
        {
            title: "Medicare Expiry Date",
            value: "3215",
        },
        {
            title: "Centrelink / DVA Number",
            value: "3215",
        },
    ]);

    const [contact_Details, setContactDetails] = useState([
        {
            title: "Address 1",
            value: "advs",
        },
        {
            title: "Address 2",
            value: "address 2",
        },
        {
            title: "Country",
            value: "Australia",
        },
        {
            title: "State",
            value: "SA",
        },
        {
            title: "Town / Suburb",
            value: "Aldinga Beach",
        },
        {
            title: "Post Code",
            value: "35698",
        },
        {
            title: "Email",
            value: "admin@belacorp.com",
        },
        {
            title: "Home Phone",
            value: "0234567898",
        },
        {
            title: "Mobile Phone",
            value: "0234567898",
        },
        {
            title: "Work Phone",
            value: "0234567898",
        },
    ]);

    const [demographics, setDemographics] = useState([
        {
            title: "Country of Birth",
            value: "Nill",
        },
        {
            title: "Family Status",
            value: "Married",
        },
        {
            title: "Living Arrangements",
            value: "Family",
        },
        {
            title: "Religion",
            value: "Christian",
        },
        {
            title: "Citizenship",
            value: "Citizen",
        },
        {
            title: "Background",
            value: "Australian",
        },
        {
            title: "Indigenous?",
            value: "Yes",
        },
    ]);

    const [emergency_Contact_Detail, setEmergencyContactDetail] = useState([
        {
            title: "Name",
            value: "wick",
        },
        {
            title: "Relationship",
            value: "Brother",
        },
        {
            title: "Home Phone Number",
            value: "0213543698",

        },
        {
            title: "Mobile Number",
            value: "0213543698",
        },
        {
            title: "Address",
            value: "abc plot,H# 54, abc city, australia",
        },
    ]);

    const [administrative_notes, setAdministrativeNotes] = useState([
        {
            title: "General Notes",
            value: "Emergency",
        },
        // {
        //     title: "Care Notes",
        //     value: "Keep Drinking water",
        // },
        // {
        //     title: "Care Notes (Provided by Client)",
        //     value: "abc",
        // },
    ]);

    const [service_Details, setServiceDetails] = useState([
        {
            title: "Service Required",
            value: "Respite Care",
        },
        {
            title: "Risk Notification",
            value: "Email",
        },
        {
            title: "Notes",
            value: "Abc notes",
        },
    ]);

    const [client_Notes, setClientNotes] = useState([
        {
            title: "Title",
            value: "abc",
        },
        {
            title: "Created By",
            value: "salman",
        },
        {
            title: "Comments",
            value: "Abc Comments details",
        },
    ]);

    const [check_list, setCheckList] = useState([
        {
            title: "Check List Title",
            value: "abc",
        },
        {
            title: "Check List Comments",
            value: "Abc Comments details",
        },
        {
            title: "Nofification Option",
            value: "Portal Nofification",
        },
        {
            title: "Nofification Time",
            value: "01:00",
        },
    ]);

    const [client_Goals, setClientGoals] = useState([
        {
            title: "Goal",
            value: "avc",
        },
        {
            title: "Status",
            value: "Pending",
        },
        {
            title: "Start Date",
            value: new Date().getDate(),
        },
        {
            title: "End Date",
            value: new Date().getDate(),
        },
    ]);

    const [client_budget, setClientBudget] = useState([
        {
            title: "Client Budget",
            value: "600",
        },
    ]);

    const [plan_Review, setPlanReview] = useState([
        {
            title: "Name",
            value: "asdas",
        },
        {
            title: "Created By",
            value: "asdsad",
        },
        {
            title: "Created On",
            value: new Date().getDate(),
        },
    ]);

    const [mainNotesList, setMainNoteList] = useState([]);

    const [preferenceForStaffWorker, setPreferenceForStaffWorker] = useState([
        {
            title: "Gender",
            value: "Male",
        },
        {
            title: "Language Spoken",
            value: "English",
        },
        {
            title: "Religion",
            value: "Islam",
        },
    ]);

    return (
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Information
                </Typography>
                <InfoCard data={client_information} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Centrelink / DVA / Healthcare
                </Typography>
                <InfoCard data={centerLink} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Contact Details
                </Typography>
                <InfoCard data={contact_Details} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Demographics
                </Typography>
                <InfoCard data={demographics} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Emergency Contact Detail
                </Typography>
                <InfoCard data={emergency_Contact_Detail} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Administrative Notes
                </Typography>
                <InfoCard data={administrative_notes} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Service Details
                </Typography>
                <InfoCard data={service_Details} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Preference for Staff Worker
                </Typography>
                <InfoCard data={preferenceForStaffWorker} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Client Notes
                </Typography>
                <InfoCard data={client_Notes} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Client Goals
                </Typography>
                <InfoCard data={client_Goals} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Check List
                </Typography>
                <InfoCard data={check_list} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Plan Review
                </Typography>
                <InfoCard data={plan_Review} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Client Budget
                </Typography>
                <InfoCard data={client_budget} />
            </Grid>
        </Grid>
    )
}

export const MedicalInfodetails = () => {
    const [client_information, setClientInformation] = useState([
        {
            title: "Client",
            value: "ABC",
        },
        {
            title: "NDIS Number",
            value: "123456",
        },
        {
            title: "Condition",
            value: "Autism",
        },
        {
            title: "Condition Description",
            value: "Description",
        },
        {
            title: "Additional Notes",
            value: "Notes",
        },
    ]);
    // // can Multiple
    const [medication, setMedication] = useState([
        {
            title: "Medication",
            value: "Vitamin Injection",
        },
        {
            title: "Dosage",
            value: "Vitamin",
        },
        {
            title: "Route",
            value: "zxc vfr qwe",
        },
        {
            title: "Expiry Date",
            value: "abc",
        },
        {
            title: "Comments",
            value: "zxc vfr qwe",
        },
    ]);
    const [medicalHistoryDetail, setMedicalHistoryDetail] = useState([
        // {
        //     title: "Client ID",
        //     value: "123456987",
        // },
        {
            title: "Physical Diagnosis",
            value: "abc Diagnosis",
        },
        {
            title: "High Blood Pressure",
            value: "Yes",
        },
        {
            title: "Shortness of Breath",
            value: "Yes",
        },
        {
            title: "Heart Pacemaker",
            value: "Yes",
        },
        {
            title: "Anesthetic",
            value: "Yes",
        },
        {
            title: "Heart Disease",
            value: "Yes",
        },
        {
            title: "Heart Disease Comments",
            value: "Yes",
        },
        {
            title: "Lung Disease / Asthma",
            value: "Yes",
        },
        {
            title: "Tuberculosis(T.B)",
            value: "Yes",
        },
        {
            title: "Lung Disease Comments",
            value: "Yes",
        },
        {
            title: "Liver Disease / Gastro Intestinal",
            value: "Yes",
        },
        {
            title: "Liver Disease Comments",
            value: "Yes",
        },
        {
            title: "Diagnosis Mental",
            value: "Yes",
        },
        {
            title: "Allergies",
            value: "Yes",
        },
        {
            title: "Allergies Comments",
            value: "Yes",
        },
        {
            title: "Cancer Diagnosed",
            value: "Yes",
        },
        {
            title: "Cancer Diagnosed Date",
            value: "Nill",
        },
        {
            title: "Cancer Diagnosed Comments",
            value: "Nill",

        },
        {
            title: "Kidney Disease",
            value: "Nill",
        },
        {
            title: "Kidney Comments",
            value: "Nill",
        },
        {
            title: "Muscular / Skeletal Problem",
            value: "Nill",
        },
        {
            title: "Muscular / Skeletal Comments",
            value: "Nill",
        },
        {
            title: "Excessive Smoking",
            value: "Nill",
        },
        {
            title: "Drinking",
            value: "Nill",
        },
        {
            title: "Excessive Smoking / Drinking Comments",
            value: "Nill",
        },
        {
            title: "Disability",
            value: "Nill",
        },
        {
            title: "Diabetes",
            value: "Nill",
        },
        {
            title: "Diabetes Comments",
            value: "Nill",
        },
        {
            title: "Medication",
            value: "Nill",
        },
        {
            title: "Medication Comments",
            value: "Nill",
        },
        {
            title: "Neurological / Mental Problem",
            value: "Nill",
        },
        {
            title: "Diagnosed Dipression",
            value: "Nill",
        },
        {
            title: "Epilepsy",
            value: "Nill",
        },
        {
            title: "Mental Problem But Calm / Alert",
            value: "Nill",
        },
        {
            title: "Withdrawn Behaviour",
            value: "Nill",
        },
        {
            title: "Agressive Behaveiour",
            value: "Nill",
        },
        {
            title: "Diagnosed Dementia.Alzeimer",
            value: "Nill",
        },
        {
            title: "Mental Problem But Calm / Alert",
            value: "Nill",
        },
        {
            title: "Stroke",
            value: "Nill",
        },
        {
            title: "Stroke Date",
            value: "Nill",

        },
        {
            title: "Stroke Comments",
            value: "Nill",
        },
    ]);
    // // can Multiple
    const [referringDoctorInformation, setReferringDoctorInformation] = useState([
        {
            title: "Medical Record Number",
            value: "Nill",
        },
        {
            title: "Referring Doctor",
            value: "Nill",
        },
        {
            title: "Referring Doctor Address",
            value: "Nill",
        },
        {
            title: 'Referring Doctor Phone',
            value: "Nill",
        }
    ]);
    const [admissionInformation, setAdmissionInformation] = useState([
        {
            title: "Principal Diagnosis",
            value: "Nill",
        },
        {
            title: "Provisional Diagnosis",
            value: "Nill",
        },
        {
            title: "Identified Major Procedure",
            value: "Nill",
        },
    ]);
    const [admissionSummary, setAdmissionSummary] = useState([
        {
            title: "Adverse effect antibiotics",
            value: "No",
        },
        {
            title: "Adverse Effects Other Drugs",
            value: "No",
        },
        {
            title: "Pulmonary Embolus",
            value: "No",
        },
        {
            title: "Deep Venous Thrombosis",
            value: "No",
        },

    ]);

    return (
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Medication
                </Typography>
                <InfoCard data={medication} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Medical History Details
                </Typography>
                <InfoCard data={medicalHistoryDetail} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Referring Doctor Information
                </Typography>
                <InfoCard data={referringDoctorInformation} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Admission Information
                </Typography>
                <InfoCard data={admissionInformation} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Admission Summary
                </Typography>
                <InfoCard data={admissionSummary} />
            </Grid>
        </Grid >
    )
}

export const IndividualRiskAssessmentInfoDetails = () => {
    const [participant, setParticipant] = useState([
        {
            title: "Participant Name",
            value: "ABC",
        },
        {
            title: "Address",
            value: "123 street, abc city, zxc",
        },
        {
            title: "Date of Birth",
            value: "1991-08-22",
        },
        {
            title: "Assessment Date",
            value: "1991-08-22",
        },
    ]);
    const [nestedOptions, setNestedOptions] = useState([
        {
            title: "Risk to Individual",
            value: "Green",
        },
        {
            title: "Risk to Staff",
            value: "Green",
        },
        {
            title: "Risk to Others",
            value: "Green",
        },
        {
            title: "Source of Information",
            value: "Source of Information",
        },
        {
            title: "Current Management Plans / Guides",
            value: "Current guides",
        }
    ])
    const [behaviours, setBehaviours] = useState([
        {
            title: "Behaviours",
            options: [
                {
                    title: "Self-harm/Suicide Risk",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Assault - Physical",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Assault - Verbal",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Sexual - Predatory",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Restrictive Practices - Authorisation",
                    childOptions: [...nestedOptions]
                },
            ],
        },
    ]);
    const [accidental, setAccidental] = useState([
        {
            title: "Accidental Movement",
            options: [
                {
                    title: "Startle Reflex",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Panic Behaviour",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Grabbing, Holding, Leaning",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Sudden Body Movements",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Falling, Bumping",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Tripping, Running ",
                    childOptions: [...nestedOptions]
                },
            ],

        },
    ]);
    const [hazard, setHazard] = useState([
        {
            title: "Hazard Exposure",
            options: [
                {
                    title: "Electricity",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Fire Lighting",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Sharps",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Sudden Body Movements",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Water Hazard, Bathing",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Sun Exposure",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Traffic, Roads",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Travelling in Vehicle",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Slamming Doors",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Stovetop",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Oven",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Kettle",
                    childOptions: [...nestedOptions]
                }
            ],
        },
    ]);
    const [recurrent, setRecurrent] = useState([
        {
            title: "Recurrent Medical Conditions ",
            options: [
                {
                    title: "Dysphagia - Choking at Mealtimes",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Choking - From Positioning",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Overnight Feeds Required",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Fractures, Cuts",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Bruising, Abrasions Seizures",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Respiratory Conditions (e.g. Asthma)",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Allergies (response, e.g.anaphylaxis)",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Skin Conditions",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Endocrine Conditions",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Diabetes",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Sleep Disorders",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Incontinence",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Mental Illness",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Hallucinations",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Other",
                    childOptions: [...nestedOptions]
                },
            ],
        },
    ]);
    const [resistance, setResistance] = useState([
        {
            title: "Resistance to Support during Activities of Daily Living",
            options: [
                {
                    title: "Feeding",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Toileting",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Showering / Bathing",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Grooming",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Fire evacuation",
                    childOptions: [...nestedOptions]
                },
            ],

        },
    ]);
    const [manual, setManual] = useState([
        {
            title: "Manual Handling",
            options: [
                {
                    title: "Transfers",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Mobility",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Vehicle Access",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Moving in Bed",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Personal Care Tasks",
                    childOptions: [...nestedOptions]
                },
            ],
        },
    ]);
    const [infectious, setInfectious] = useState([
        {
            title: "Infectious Diseases",
            options: [
                {
                    title: "Chronic",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Recurrent",
                    childOptions: [...nestedOptions]
                },
            ],
        },
    ]);
    const [community, setCommunity] = useState([
        {
            title: "Community Access",
            options: [
                {
                    title: "Community Access without SW - Lost or unable to get home",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Community Access without SW - Day / Night",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Community Access without SW - Night",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Community Access – riding the bus to get home",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Cross at traffic lights",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Cross at zebra crossing",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Follow signs/street names",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Wear a watch and understand the concept of time",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Identification - name, photo, address, phone number of SW",
                    childOptions: [...nestedOptions]
                },
            ],
        }
    ]);
    const [living, setLiving] = useState([
        {
            title: "Living at Home",
            options: [
                {
                    title: "Lives Alone",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Has Other Providers Visiting",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Limited Visits by Family/Friends",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Needs Physical Assistance for Mobility",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Needs Assistance to Communicate",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Uses Communicate Equipment to Communicate with Others",
                    childOptions: [...nestedOptions]
                },
                {
                    title: "Uses Equipment to Move Around Environment",
                    childOptions: [...nestedOptions]
                },
            ],
        },
    ]);

    return (
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Behaviours
                </Typography>
                <InfoCardNested data={behaviours} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Accidental Movement
                </Typography>
                <InfoCardNested data={accidental} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Hazard Exposure
                </Typography>
                <InfoCardNested data={hazard} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Recurrent Medical Conditions
                </Typography>
                <InfoCardNested data={recurrent} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Resistance to Support during Activities of Daily Living
                </Typography>
                <InfoCardNested data={resistance} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Manual Handling
                </Typography>
                <InfoCardNested data={manual} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Infectious Diseases
                </Typography>
                <InfoCardNested data={infectious} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Community Access
                </Typography>
                <InfoCardNested data={community} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Living at Home
                </Typography>
                <InfoCardNested data={living} />
            </Grid>
        </Grid>
    )
}

export const HomeRiskAssessmentDetails = () => {
    const [clientInfo, setClientInfo] = useState([
        {
            title: "Name of Client",
            value: "ABC",
        },
        {
            title: "Address",
            value: "abc street",
        },
        {
            title: "Phone",
            value: "0325659987",
        },
    ]);
    const [representative, setRepresentative] = useState([
        {
            title: "New Client",
            value: "No",
        },
        {
            title: "Existing Client",
            value: "No",
        },
        {
            title: "Does the Client consent to the Home Visit?",
            value: "No",
        },
        {
            title: "Has this Risk Assessment been completed with the Client/Representative present?",
            value: "No",
        },
    ]);
    const [typeOfResidence, setTypeOfResidence] = useState([
        {
            title: "Select Residence",
            value: "Self Owned",
        },
    ]);
    const [optionsFields, setOptionsFields] = useState([
        {
            title: "Answer",
            value: "No",
        },
        {
            title: "DETAILS / ACTION",
            value: "abc street",
        },
        {
            title: "Risk Rating",
            value: "3",
        },
    ])
    const [accessToProperty, setAccessToProperty] = useState([
        {
            title: "ACCESS TO PROPERTY",
            options: [
                {
                    title: "Is the street sign visible?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Is there access to neighbours/passing traffic?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Is there difficulty finding property or number? e.g. descriptive marker",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Is the SDA Dwelling obscured from the street? e.g. question outdoor environment",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Is there difficulty or a distance to a car park? e.g. closest parking",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Is the gate difficult to open? e.g. other access available",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Is there a shared driveway?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Which door is used for entry? Front/Side/Rear If ‘Other’, please define: Is there adequate lighting outside e.g. on driveway and path?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Are there uneven and/or dangerous paths on entry to doors?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Are there any steps/stairs?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Do all steps/stairs have railings?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Is the door clear of obstructions/easy to open?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Is there difficulty with mobile phone reception and/or working landline?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Are there any restrictions to time parameters for the Risk Assessment?",
                    childOptions: [...optionsFields]
                },
            ]
        },
    ]);
    const [HAZARDS, setHAZARDS] = useState([
        {
            title: "HAZARDS",
            options: [
                {
                    title: "Is there adequate lighting inside?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Are there any trip or slip hazards",
                    childOptions: [...optionsFields]
                },
                {
                    title: "e.g. floor surfaces?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Are there steps/stairs? ",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Do all steps/stairs have railings? ",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Is there a fire extinguisher/fire blanket? ",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Is there an emergency exit? ",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Are smoke detectors present and maintained?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Is there adequate drainage? ",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Is there adequate ventilation? ",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Is there adequate workspace e.g. kitchen, laundry and office if required?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Is there adequate furniture e.g. bed, table and chairs?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Are there any design concerns with the furniture e.g. height, position etc.?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Is there adequate space around the furniture e.g. to walk around?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Is the water temperature set to a safe level?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Are there any known infectious illnesses in the SDA Dwelling? e.g. Gastro, Measles, Chicken pox",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Is the SDA Dwelling set out in a manner which is consistent with the COVID-19 Pandemic Management Policy (areas to wash hand, suitable for social distancing)",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Other",
                    childOptions: [...optionsFields]
                },
            ]
        },
    ]);
    const [ELECTRICAL, setELECTRICAL] = useState([
        {
            title: "ELECTRICAL / GAS",
            options: [
                {
                    title: "Are the gas and electric appliances well maintained?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Heating and cooling? ",
                    childOptions: [...optionsFields]
                },
                {
                    title: "RDCs at mains? ",
                    childOptions: [...optionsFields]
                },
                {
                    title: "RDC protection for portable equipment? ",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Electrical leads/extension cords? ",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Are there any fire hazards? ",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Switches/plugs? ",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Power points near water? ",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Gas cylinders e.g. hot water heating/oxygen?",
                    childOptions: [...optionsFields]
                },
            ],
        },
    ]);
    const [EQUIPMENT, setEQUIPMENT] = useState([
        {
            title: "EQUIPMENT",
            options: [
                {
                    title: "Vacuum cleaner?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Carpet sweeper?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Broom e.g. handle length?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Mop / Bucket?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Iron / board? ",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Washing machine / dryer? ",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Hot water service/exposed Pipes?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Step ladder? ",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Food preparation equipment? ",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Clothes line? ",
                    childOptions: [...optionsFields]
                },

            ],
        },
    ]);
    const [HAZARDOUS, setHAZARDOUS] = useState([
        {
            title: "HAZARDOUS SUBSTANCES",
            options: [
                {
                    title: "Substances approved for use?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Labels present and clear?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Original containers in use?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Suitable for use?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Stored in safe position?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Gloves/other protection available?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Health effects/emergency procedures known?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Material Safety Data Sheets (MSDS) available?",
                    childOptions: [...optionsFields]
                },
            ],
        },
    ]);
    const [ANIMALS, setANIMALS] = useState([
        {
            title: "ANIMALS / PETS",
            options: [
                {
                    title: "For home visits, does the Client have any pets/animals around the residence?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Are there any animals with open access to the front of the property?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Can the animals be put in a room or outside during a visit?",
                    childOptions: [...optionsFields]
                }
            ],
        },
    ]);
    const [OCCUPANTS, setOCCUPANTS] = useState([
        {
            title: "OCCUPANTS",
            options: [
                {
                    title: "Does the Client, or do other occupants smoke?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Does the Client have mobility issues e.g wheelchair or other?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Does the Client speak English? Is an interpreter required?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Have communication methods with the Client been established?",
                    childOptions: [...optionsFields]
                },
                // {
                //     title: `
                //                 Are there manual handling risks 
                //                 associated with the following Client 
                //                 transfers and other duties, assessed and 
                //                 controlled: 
                //                     e.g. For transfers ON THE BED: 
                //                     Moving the Client up/ down the bed 
                //                     Sit up/ lie down 
                //                     Rolling the Client in bed 
                //                     Re-positioning the Client in bed 
                //                     Patient moving from lying to sitting in 
                //                     bed 

                //                     e.g. For transfers OFF THE BED: 
                //                     Move from chair to bed or bed to 
                //                     chair 

                //                     Transfer legs onto bed 
                //                     Chair to chair or toilet 
                //                     Move Client off floor

                //                     e.g. For transfers IN/OUT BED: 
                //                     To chair/ commode/ wheelchair 
                //                     From sit to stand 

                //                     e.g. For wheelchairs: 
                //                         The condition of the wheelchair is 
                //                         checked 

                //                         Transferring Client from wheelchair to 
                //                         car 

                //                         Transferring the wheelchair into a car 
                //             `,
                //      childOptions: [...optionsFields] 
                // },
                {
                    title: "Are there particular religious or cultural sensitivities to be aware of?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Have hazards associated with showering, sponging and toileting been considered (as applicable)? e.g. manual handling/ slips trips and falls/ biological hazards/ humidity, etc.)",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Are there any other occupants or visitors likely to be present at the SDA Dwelling?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Are there any known weapons or fire arms in the SDA Dwelling?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "If yes, what sort of weapons? Are they secure?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Is there known substance abuse amongst occupants or visitors? What substances?",
                    childOptions: [...optionsFields]
                },
            ],
        },
    ]);
    const [HISTORY, setHISTORY] = useState([
        {
            title: "HISTORY",
            options: [
                {
                    title: "Does the Client, or other occupants have a history of violent or aggressive behaviour or mental health issues? e.g. domestic violence, elder abuse or family violence?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Resistence to care? ",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Unable to accept instructions?",
                    childOptions: [...optionsFields]
                },
                {
                    title: "Will the violent/ aggressive person be present at the SDA Dwelling (if it is not the Client)?",
                    childOptions: [...optionsFields]
                },
            ],
        },
    ]);

    return (
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Representative Information
                </Typography>
                <InfoCard data={representative} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Type of Residence
                </Typography>
                <InfoCard data={typeOfResidence} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Access To Property
                </Typography>
                <InfoCardNested data={accessToProperty} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Hazards
                </Typography>
                <InfoCardNested data={HAZARDS} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Electrical / Gas
                </Typography>
                <InfoCardNested data={ELECTRICAL} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Hazardous Substances
                </Typography>
                <InfoCardNested data={HAZARDOUS} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Equipment
                </Typography>
                <InfoCardNested data={EQUIPMENT} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Animals / Pets
                </Typography>
                <InfoCardNested data={ANIMALS} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Occupants
                </Typography>
                <InfoCardNested data={OCCUPANTS} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    History
                </Typography>
                <InfoCardNested data={HISTORY} />
            </Grid>
        </Grid>
    )
}

export const SafteyPlansDetails = () => {
    const [green, setGreen] = useState([
        {
            title: "Behaviors",
            color: "#4caf50",
            options: [
                {
                    key: "1",
                    text: "Happy",
                    type: "unordered-list-item"
                },
                {
                    key: "2",
                    text: "Smiling",
                    type: "unordered-list-item"
                },
                {
                    key: "3",
                    text: "Interacting",
                    type: "unordered-list-item"
                },
                {
                    key: "4",
                    text: "Engaging",
                    type: "unordered-list-item"
                },
                {
                    key: "5",
                    text: "Wanting to do things alongside you/ independently for herself",
                    type: "unordered-list-item"
                },
                {
                    key: "6",
                    text: "Positive",
                    type: "unordered-list-item"
                }
            ]
        }
    ]);

    const [strategiestostaywell, setStrategiestostaywell] = useState([
        {
            title: "Strategies to stay well ",
            color: "#4caf50",
            options: [
                {
                    key: "1",
                    text: "Providing choice / options",
                    type: "unordered-list-item"
                },
                {
                    key: "2",
                    text: "Listening to her",
                    type: "unordered-list-item"
                },
                {
                    key: "3",
                    text: "Discussing and working through challenges to come to good outcome",
                    type: "unordered-list-item"
                },
                {
                    key: "4",
                    text: "Allowing for self-determination",
                    type: "unordered-list-item"
                },
                {
                    key: "5",
                    text: "Reassurance",
                    type: "unordered-list-item"
                },
                {
                    key: "6",
                    text: "Praise",
                    type: "unordered-list-item"
                },
                {
                    key: "7",
                    text: "Use respect towards her / her choices",
                    type: "unordered-list-item"
                },
            ]
        }
    ]);

    const [amber, setAmber] = useState([
        {
            title: "Amber",
            color: "#ffb74d",
            options: [
                {
                    key: "1",
                    text: "Voice raises quicker",
                    type: "unordered-list-item"
                },
                {
                    key: "2",
                    text: "Throw out threats/excuses of why things can't happen, or why she can’t do something",
                    type: "unordered-list-item"
                },
                {
                    key: "3",
                    text: "Eyes wider",
                    type: "unordered-list-item"
                },
                {
                    key: "4",
                    text: "Rigid body language",
                    type: "unordered-list-item"
                },
                {
                    key: "5",
                    text: "Avoid people/ tasks e.g. avoid the doctor",
                    type: "unordered-list-item"
                },
            ]
        }
    ]);

    const [strategiestoreturntogreen, setStrategiestoreturntogreen] = useState([
        {
            title: "Add New Point / Edit",
            color: "#ffb74d",
            options: [
                {
                    key: "1",
                    text: "Working through options - consequences e.g. get help now and have fun - or get A LOT worse and no fun.",
                    type: "unordered-list-item"
                },
                {
                    key: "2",
                    text: "Creative options, e.g. asking pharmacy if meds can be crushed",
                    type: "unordered-list-item"
                },
                {
                    key: "3",
                    text: "Red and green choices",
                    type: "unordered-list-item"
                },
                {
                    key: "4",
                    text: "Praise 'where's that strong girl that I know'",
                    type: "unordered-list-item"
                },
                {
                    key: "5",
                    text: "Encourage her values e.g. if she values being independent, then reinforce her to show you that.",
                    type: "unordered-list-item"
                },
                {
                    key: "6",
                    text: "Positive self-image",
                    type: "unordered-list-item"
                },
            ]
        }

    ]);

    const [red, setRed] = useState([
        {
            title: "Add New Point / Edit",
            color: "#f44336",
            options: [
                {
                    key: "1",
                    text: "Will threaten to harm herself",
                    type: "unordered-list-item"
                },
                {
                    key: "2",
                    text: "Will harm herself",
                    type: "unordered-list-item"
                },
                {
                    key: "3",
                    text: "Crying",
                    type: "unordered-list-item"
                },
                {
                    key: "4",
                    text: "Running away",
                    type: "unordered-list-item"
                },
            ]
        }
    ]);

    const [strategiestoreturntogreen1, setStrategiestoreturntogreen1] = useState([
        {
            title: "Add New Point / Edit",
            color: "#f44336",
            options: [
                {
                    key: "1",
                    text: "Redirecting / distraction",
                    type: "unordered-list-item"
                },
                {
                    key: "2",
                    text: "Praise about herself",
                    type: "unordered-list-item"
                },
                {
                    key: "3",
                    text: "Positive self-talk",
                    type: "unordered-list-item"
                },
                {
                    key: "4",
                    text: "Do something fun",
                    type: "unordered-list-item"
                },
                {
                    key: "4",
                    text: "Call triple 000 if Courtney is at immediate risk of harm.",
                    type: "unordered-list-item"
                },
            ]
        }

    ]);

    const [steps, setSteps] = useState([
        {
            label: 'Green',
            tabActive: false,
            screen: <InfoPlansList dataLeft={green} dataRight={strategiestostaywell} />
        },
        {
            label: 'Amber',
            tabActive: false,
            screen: <InfoPlansList dataLeft={amber} dataRight={strategiestoreturntogreen} />
        },
        {
            label: "Red",
            tabActive: false,
            screen: <InfoPlansList dataLeft={red} dataRight={strategiestoreturntogreen1} />
        }
    ]);

    return (
        <Grid container>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Green
                </Typography>
                <InfoPlansList dataLeft={green} dataRight={strategiestostaywell} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Amber
                </Typography>
                <InfoPlansList dataLeft={amber} dataRight={strategiestoreturntogreen} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Red
                </Typography>
                <InfoPlansList dataLeft={red} dataRight={strategiestoreturntogreen1} />
            </Grid>
        </Grid>
    )
}

export const StaffDetails = () => {

    const [employeeInformation, setEmployeeInformation] = useState([
        {
            title: "Full Name",
            value: "Khan",
        },
        {
            title: "Gender",
            value: "Male",
        },
        {
            title: "Date of Birth",
            value: "22-08-1991",
        },
        {
            title: "Home Phone",
            value: "0234567898",
        },
        {
            title: "Mobile Phone",
            value: "0234567898",
        },
        {
            title: "Registration Number",
            value: "22-08-1991",
        },
        {
            title: "Registration Expiry",
            value: "22-08-1991",
        },
        {
            title: "Visa Status",
            value: "avx",
        },
        {
            title: "Designation",
            value: "continue",
        },
        {
            title: "Medicare Information",
            value: "Vaccine",
        },
        {
            title: "Police Check",
            value: "Verified",
        },
        {
            title: "Upload License",
            value: "34567892345",
        },
    ]);

    const [employeeContactDetails, setEmployeeContactDetails] = useState([
        {
            title: "Full Name",
            value: "Khan",
        },
        {
            title: "Email",
            value: "work@belacorp.com",
        },
        {
            title: "Mobile Phone",
            value: "0234567898",
        },
        {
            title: "Address 1",
            value: "abc town, zxc city, byt",
        },
        {
            title: "Address 2",
            value: "abc town, zxc city, byt",
        },
        {
            title: "Town/ Suburb",
            value: "Aldinga Beach",
        },
        {
            title: "Post Code",
            value: "35698",
        },
        // {
        //     title: "Distance",
        //     value: "25km",
        // },
    ]);

    const [emergencyContactDetails, setEmergencyContactDetails] = useState([
        {
            title: "First Name",
            value: "Zxc ret",
        },
        {
            title: "Last Name",
            value: "Zxc ret",
        },
        {
            title: "Relationship",
            value: "Uncle",
        },
        {
            title: "Email",
            value: "admin@belacorp.com",
        },
        {
            title: "Home Phone",
            value: "0326598984",
        },
        {
            title: "Mobile Phone",
            value: "0326598984",
        },
        {
            title: "Address 1",
            value: "avc asd sads",
        },
        {
            title: "Address 2",
            value: "avc asd sads",
        },
    ]);

    const [jobProfileDetails, setJobProfileDetails] = useState([
        {
            title: "Description",
            value: "This is my Resume",
        },
        {
            title: "Category",
            value: "ID / Permit",
        },
        {
            title: "Document Name",
            value: "Resume",
        },
        {
            title: "Expire Date",
            value: "Nill",
        },
        {
            title: "File",
            value: "my-resume.pdf"
        }
    ]);

    const [othersInformation, setOthersInformation] = useState([
        {
            title: "Are you a Smoker/ Drinker?",
            value: "No",
        },
        {
            title: "Do you have any allergies?",
            value: "No",
        },
        {
            title: "Medical Information (If any)",
            value: "asd",
        },
        {
            title: "Do you have any type of conveyance to commute?",
            value: "No",
        },
        {
            title: "Do you have a driving license?",
            value: "No",
        },

    ]);

    const [workDays, setWorkDays] = useState([
        {
            title: "Monday",
            value: dayjs('2022-04-17T15:30').toString() + " To " + dayjs('2022-04-17T15:30').toString(),
        },
        {
            title: "Tuesday",
            value: dayjs('2022-04-17T15:30').toString() + " To " + dayjs('2022-04-17T15:30').toString(),
        },
        {
            title: "Wednesday",
            value: dayjs('2022-04-17T15:30').toString() + " To " + dayjs('2022-04-17T15:30').toString(),
        },
        {
            title: "Thursday",
            value: dayjs('2022-04-17T15:30').toString() + " To " + dayjs('2022-04-17T15:30').toString(),
        },
        {
            title: "Friday",
            value: dayjs('2022-04-17T15:30').toString() + " To " + dayjs('2022-04-17T15:30').toString(),
        },
        {
            title: "Saturday",
            value: dayjs('2022-04-17T15:30').toString() + " To " + dayjs('2022-04-17T15:30').toString(),
        },
        {
            title: "Sunday",
            value: dayjs('2022-04-17T15:30').toString() + " To " + dayjs('2022-04-17T15:30').toString(),
        },
    ]);

    const [payment, setPayment] = useState([
        {
            title: "Account Name",
            value: "Rehan",
        },
        {
            title: "BSB Number",
            value: "FR3658",
        },
        {
            title: "Account Number",
            value: "FR3658365989",
        },
        {
            title: "Supperannuation Fund Name",
            value: "ABC Name",
        },
        {
            title: "Member Number",
            value: "0326598986",
        },
        {
            title: "TFN Number",
            value: "0326598986",
        },
        {
            title: "Do you want to claim a tax free threshold.!",
            value: "No",
        },
        {
            title: "Reason / Notes",
            value: "abc information",
        },
    ]);

    return (
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    General Information
                </Typography>
                <InfoCard data={employeeInformation} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Contact Information
                </Typography>
                <InfoCard data={employeeContactDetails} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Emergency Contact Information
                </Typography>
                <InfoCard data={emergencyContactDetails} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Working Days (Start Time & End Time)
                </Typography>
                <InfoCard data={workDays} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Professional Details
                </Typography>
                <InfoCard data={jobProfileDetails} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Others Information
                </Typography>
                <InfoCard data={othersInformation} />
            </Grid>
            {/* <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                    Documents that need to be renewed
                </Typography>
                <InfoCard data={payment}/>
            </Grid> */}
        </Grid>
    )
}

export const ServiceSchedual = () => {
    // const my_services = useSelector((state) => state._my_services.myServices);
    const serviceList = useSelector((state) => state._service_request.draft);
    const [service, setSerivce] = useState([])
    useEffect(() => {
        if (serviceList) {
            const copy = [...service]
            for (const key in serviceList) {
                if (serviceList[key].information.status === "ongoing") {
                    copy.push(serviceList[key])
                }
            }
            setSerivce(copy)
        }
    }, [])
    return (
        <Grid container>
            {service.length && (
                <Grid item={true} xs={12} sm={12} md={12} lg={12} >
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                        <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                            Service Information
                        </Typography>
                        <Grid container sx={{ display: "flex", }}>
                            <Grid item={true} xs={12} md={12} sm={12} lg={12} sx={{
                                py: 1,
                                borderBottom: "1px solid #000000",
                                backgroundColor: "#e2e6e8"
                            }}></Grid>
                            {service?.map((v, i) => {
                                return (
                                    <Grid key={i} item={true} xs={12} md={12} sm={12} lg={12} sx={{ display: "flex", flexWrap: "wrap", py: 2, borderBottom: "2px solid #c8c8c8" }} >
                                        {Object.keys(v?.information).map((key, index) => {
                                            return (
                                                <Box key={index} sx={{ px: 2, my: 1, borderLeft: 1 ? "2px solid #c8c8c8" : "none" }}>
                                                    <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253", textTransform: "capitalize" }}>
                                                        {key.replace(/_/g, ' ')}
                                                    </Typography>
                                                    <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                                                        {v?.information[key]}
                                                    </Typography>
                                                </Box>
                                            )
                                        })}
                                        <Box sx={{ px: 2, my: 1, borderLeft: 1 ? "2px solid #c8c8c8" : "none" }}>
                                            <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253", textTransform: "capitalize" }}>
                                                {"pay_travel_mileage".replace(/_/g, ' ')}
                                            </Typography>
                                            <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                                                {v?.pay_travel_mileage}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                        <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                            Visit Days
                        </Typography>
                        <Grid container sx={{ display: "flex", }}>
                            <Grid item={true} xs={12} md={12} sm={12} lg={12} sx={{
                                py: 1,
                                borderBottom: "1px solid #000000",
                                backgroundColor: "#e2e6e8"
                            }}></Grid>
                            {service?.map((v, i) => {
                                return (
                                    <Grid key={i} xs={12} md={12} sm={12} lg={12}>
                                        {
                                            v.visit_days?.map((value, index) => {
                                                return (
                                                    <Grid key={index} item={true} xs={12} md={12} sm={12} lg={12} sx={{ display: "flex", flexWrap: "wrap", py: 2, borderBottom: "2px solid #c8c8c8" }} >
                                                        {Object.keys(value).map((value1, index1) => {
                                                            if (value1 !== "check_list") {
                                                                return (
                                                                    <Box key={index1} sx={{ px: 2, my: 1, borderLeft: 1 ? "2px solid #c8c8c8" : "none" }}>
                                                                        <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253", textTransform: "capitalize" }}>
                                                                            {value1.replace(/_/g, ' ')}
                                                                        </Typography>
                                                                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                                                                            {value[value1]}
                                                                        </Typography>
                                                                    </Box>
                                                                )
                                                            }
                                                        })}
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                        <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                            Check List
                        </Typography>
                        <Grid container sx={{ display: "flex", }}>
                            <Grid item={true} xs={12} md={12} sm={12} lg={12} sx={{
                                py: 1,
                                borderBottom: "1px solid #000000",
                                backgroundColor: "#e2e6e8"
                            }}></Grid>
                            {service?.map((v, i) => {
                                return (
                                    <Grid key={i} xs={12} md={12} sm={12} lg={12}>
                                        {
                                            v.visit_days?.map((value, index) => {
                                                return (
                                                    <Grid key={index} item={true} xs={12} md={12} sm={12} lg={12}>
                                                        {Object.keys(value).map((value1, index1) => {
                                                            if (value1 === "check_list") {
                                                                return (
                                                                    <Grid key={index1} item={true} xs={12} md={12} sm={12} lg={12}>
                                                                        {(value[value1]).map((value2, index2) => {
                                                                            return (
                                                                                <Grid key={index2} item={true} xs={12} md={12} sm={12} lg={12} sx={{ display: "flex", flexWrap: "wrap", py: 2, borderBottom: "2px solid #c8c8c8" }}>
                                                                                    {Object.keys(value2).map((value3, index3) => {
                                                                                        return (
                                                                                            <Box key={index3} sx={{ px: 2, my: 1, borderLeft: 1 ? "2px solid #c8c8c8" : "none" }}>
                                                                                                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253", textTransform: "capitalize" }}>
                                                                                                    {value3.replace(/_/g, ' ')}
                                                                                                </Typography>
                                                                                                <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                                                                                                    {value2[value3]}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        )
                                                                                    })}
                                                                                </Grid>
                                                                            )
                                                                        })}
                                                                    </Grid>
                                                                )
                                                            }
                                                        })}
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export const ServiceRequestHistory = () => {
    const serviceList = useSelector((state) => state._service_request.draft);
    const [service, setSerivce] = useState([])
    useEffect(() => {
        if (serviceList) {
            const copy = [...service]
            for (const key in serviceList) {
                if (serviceList[key].information.status !== "ongoing") {
                    copy.push(serviceList[key])
                }
            }
            setSerivce(copy)
        }
    }, [])
    return (
        <Grid container>
            {service.length && (
                <Grid item={true} xs={12} sm={12} md={12} lg={12} >
                    {service?.map((v, i) => {
                        return (
                            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#ff9800" }}>
                                    Service Information
                                </Typography>
                                <Grid container sx={{ display: "flex", }}>
                                    <Grid item={true} xs={12} md={12} sm={12} lg={12} sx={{
                                        py: 1,
                                        borderBottom: "1px solid #000000",
                                        backgroundColor: "#e2e6e8"
                                    }}></Grid>
                                    <Grid key={i} item={true} xs={12} md={12} sm={12} lg={12} sx={{ display: "flex", flexWrap: "wrap", py: 2, borderBottom: "2px solid #c8c8c8" }} >
                                        {Object.keys(v?.information).map((key, index) => {
                                            return (
                                                <Box key={index} sx={{ px: 2, my: 1, borderLeft: 1 ? "2px solid #c8c8c8" : "none" }}>
                                                    <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253", textTransform: "capitalize" }}>
                                                        {key.replace(/_/g, ' ')}
                                                    </Typography>
                                                    <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                                                        {v?.information[key]}
                                                    </Typography>
                                                </Box>
                                            )
                                        })}
                                        <Box sx={{ px: 2, my: 1, borderLeft: 1 ? "2px solid #c8c8c8" : "none" }}>
                                            <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253", textTransform: "capitalize" }}>
                                                {"pay_travel_mileage".replace(/_/g, ' ')}
                                            </Typography>
                                            <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                                                {v?.pay_travel_mileage}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                                        <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                                            Visit Days
                                        </Typography>
                                        <Grid container sx={{ display: "flex", }}>
                                            <Grid item={true} xs={12} md={12} sm={12} lg={12} sx={{
                                                py: 1,
                                                borderBottom: "1px solid #000000",
                                                backgroundColor: "#e2e6e8"
                                            }}></Grid>
                                            {v.visit_days?.map((value, index) => {
                                                return (
                                                    <Grid key={index} item={true} xs={12} md={12} sm={12} lg={12} sx={{ display: "flex", flexWrap: "wrap", py: 2, borderBottom: "2px solid #c8c8c8" }} >
                                                        {Object.keys(value).map((value1, index1) => {
                                                            if (value1 !== "check_list") {
                                                                return (
                                                                    <Box key={index1} sx={{ px: 2, my: 1, borderLeft: 1 ? "2px solid #c8c8c8" : "none" }}>
                                                                        <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253", textTransform: "capitalize" }}>
                                                                            {value1.replace(/_/g, ' ')}
                                                                        </Typography>
                                                                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                                                                            {value[value1]}
                                                                        </Typography>
                                                                    </Box>
                                                                )
                                                            }
                                                        })}
                                                    </Grid>
                                                )
                                            })
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                                        <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253" }}>
                                            Check List
                                        </Typography>
                                        <Grid container sx={{ display: "flex", }}>
                                            <Grid item={true} xs={12} md={12} sm={12} lg={12} sx={{
                                                py: 1,
                                                borderBottom: "1px solid #000000",
                                                backgroundColor: "#e2e6e8"
                                            }}></Grid>
                                            {
                                                v.visit_days?.map((value, index) => {
                                                    return (
                                                        <Grid key={index} item={true} xs={12} md={12} sm={12} lg={12}>
                                                            {Object.keys(value).map((value1, index1) => {
                                                                if (value1 === "check_list") {
                                                                    return (
                                                                        <Grid key={index1} item={true} xs={12} md={12} sm={12} lg={12}>
                                                                            {(value[value1]).map((value2, index2) => {
                                                                                return (
                                                                                    <Grid key={index2} item={true} xs={12} md={12} sm={12} lg={12} sx={{ display: "flex", flexWrap: "wrap", py: 2, borderBottom: "2px solid #c8c8c8" }}>
                                                                                        {Object.keys(value2).map((value3, index3) => {
                                                                                            return (
                                                                                                <Box key={index3} sx={{ px: 2, my: 1, borderLeft: 1 ? "2px solid #c8c8c8" : "none" }}>
                                                                                                    <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253", textTransform: "capitalize" }}>
                                                                                                        {value3.replace(/_/g, ' ')}
                                                                                                    </Typography>
                                                                                                    <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                                                                                                        {value2[value3]}
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                            )
                                                                                        })}
                                                                                    </Grid>
                                                                                )
                                                                            })}
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            )}
        </Grid>
    )
}