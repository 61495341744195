import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Grid, Typography, Paper, Box, Stack, TextField, Chip, Modal, MenuItem, useMediaQuery, Backdrop, Fade } from '@mui/material';
import { EmployeeCard, EmployeeInfoCard, MiniParticipantCard, ClientInfoCard, ShiftCards } from '../../../components/cards';
import { CustomizedMenus } from '../../../components/custom-menu';


export const ClientAssignCard = ({ value, emValue }) => {
    const colorCode = { //  // // color code for auto scheduling
        "pending": {
            "main": '#fff176',
        },
        "plan": {
            "main": '#4fc3f7',
        },
        "approved": {
            "main": '#e1bee7',
        },
        "acknowledged": {
            "main": '#ffb74d',
        },
        "done": {
            "main": '#a9d279',
        },
        "rejected": {
            "main": '#f8bbd0',
        },
    };
    const width = () => {
        // modal control
        if (window.screen.width < 900) {
            const copy = { ...style };
            copy.maxWidth = "95%";
            setStyle(copy);
        }

        if (window.screen.width > 1600) {
            return window.screen.width / 3.8
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.9
        } else {
            return window.screen.width / 1.4
        }
    };
    // const backg = v.serviceRequired.map((e) => colorCode[e.status]);
    const backg = value.serviceRequired.map((e) => colorCode["plan"]);
    const [openShift, setOpenShift] = useState(false);
    const [screenWidth, setScreenWidth] = useState(width);
    const handleOpenShift = () => {
        setOpenShift(true);
    };
    const handleShiftClose = () => {
        setOpenShift(false);
    };
    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        maxWidth: "calc(70%)",
        minWidth: "calc(60%)",
        boxShadow: 12,
        borderRadius: 1,
        p: 4,
    });
    const [availableShift, setAvailableShift] = useState([
        {
            title: "Available Shift",
            value: "Please Select Option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Please Select Option",
                    selected: true
                },
                {
                    title: "Assist with hygiene, medication, and physical therapy",
                    selected: false
                },
                {
                    title: "Dinner, evening walk, and bedtime routine",
                    selected: false
                },
                {
                    title: "Overnight monitoring and morning hygiene",
                    selected: false
                },
            ]

        },
        {
            title: "Group Name",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false
        },
        {
            title: "Shift Date",
            value: "2024-08-08",
            width: screenWidth,
            type: "date",
            required: true,
            multiline: false
        },
        {
            title: "Shift Time",
            value: "10:10",
            width: screenWidth,
            type: "time",
            required: true,
            multiline: false
        },
        {
            title: "Shift Location",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: true
        },
        {
            title: "Shift Message",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: true
        },
    ])
    return (
        <Paper
            sx={{
                width: 170,
                m: 0.5,
                cursor: 'pointer',
                background: backg[0]?.main,
                p: 1,
            }}
            elevation={0}
        >
            <Grid item={true} xs={12} sm={12} md={12} lg={12} >
                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex" }}>
                    <Grid item={true} xs={10} sm={10} md={10} lg={10} >
                        <Typography style={{ fontSize: 12, fontFamily: "Roboto-Medium" }}>
                            {value.name}
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={2} sm={2} md={2} lg={2} >
                        <CustomizedMenus handleOpenShift={handleOpenShift} />
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={12} lg={12} >
                    {value?.serviceRequired.map((vN, i) => {
                        return (
                            <div key={i}>
                                <Typography style={{ fontSize: 11, fontFamily: "Roboto-Regular" }}>
                                    {vN.dateFrom} / {vN.dateTo}
                                </Typography>
                                <Typography style={{ fontSize: 11, fontFamily: "Roboto-Regular" }}>
                                    {vN.startTime} To {vN.endTime}
                                </Typography>
                                {/* <Typography style={{ fontSize: 11, fontFamily: "Roboto-Regular" }}>
                                    Status: {vN.status}
                                </Typography> */}
                                <Typography style={{ fontSize: 11, fontFamily: "Roboto-Regular" }}>
                                    {value.serviceType}
                                </Typography>
                            </div>
                        )
                    })}
                </Grid>
            </Grid>

            <Modal
                open={openShift}
                onClose={handleShiftClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box>
                        <Typography sx={{ fontFamily: "Roboto-Bold", fontSize: "1.5em" }}>
                            Shift Advertisement
                        </Typography>
                    </Box>
                    <Box sx={{ my: 2, px: 2 }}>
                        <Box sx={{ my: 2, display: "flex" }}>
                            <Box sx={{ width: "5%" }}>
                                <Typography sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>
                                    To:
                                </Typography>
                            </Box>
                            <Box sx={{ width: "80%" }}>
                                <Chip label={emValue.name} />
                            </Box>
                        </Box>
                        {availableShift?.map((v, i) => {
                            if (v.type === "drop_down") {
                                return (
                                    <TextField
                                        select={true}
                                        label={v.title}
                                        htmlFor={v.title}
                                        required={v.required}
                                        sx={{ m: 1, width: v.width }}
                                        value={v.value}
                                        onChange={(e) => {
                                            const copy = [...availableShift];
                                            copy[i].value = e.target.value;
                                            setAvailableShift(copy);
                                        }}
                                    >
                                        {v?.options?.map((nV, nI) => (
                                            <MenuItem key={nI} value={nV.title}>
                                                {nV.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )
                            } else {
                                return (
                                    <TextField
                                        type={v.type}
                                        label={v.title}
                                        multiline={v.multiline}
                                        required={v.required}
                                        htmlFor={v.title}
                                        rows={v.title.includes("Message") ? 4 : 1}
                                        value={v.value}
                                        sx={{ m: 1, width: v.title.includes("Location") || v.title.includes("Message") ? "calc(97.5%)" : v.width }}
                                        onChange={(e) => {
                                            const copy = [...availableShift];
                                            copy[i].value = e.target.value;
                                            setAvailableShift(copy);
                                        }}
                                    />
                                )
                            }
                        })}
                    </Box>
                    <Box sx={{ my: 2, px: 4, display: "flex", justifyContent: "flex-end", }}>
                        <Stack direction={"row"} spacing={2}>
                            <Button onClick={handleShiftClose} variant="contained" sx={{ fontFamily: "Roboto-Medium", textTransform: "capitalize" }}>
                                Cancel
                            </Button>
                            <Button onClick={handleShiftClose} variant="contained" sx={{ fontFamily: "Roboto-Medium", textTransform: "capitalize" }}>
                                Send
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Modal>
        </Paper>
    );
}

const AutoSchedulingComponent = ({ waitingForServices, modifiedStaffData, handleStaffsData, getPlansForView }) => {
    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        maxWidth: "calc(70%)",
        maxHeight: "calc(80%)",
        minWidth: "calc(60%)",
        boxShadow: 12,
        borderRadius: 1,
        p: 4,
        overflowY: "auto"
    });
    const [columns, setColumns] = useState(0);
    const [rows, setRows] = useState(0);
    const [editModal, setEditModal] = useState(false);
    const [selectedObject, setSelectedObject] = useState(null);

    const handleDetails = (value) => {
        setEditModal(!editModal)
        setSelectedObject(value)
    }
    const handleCloseDetails = () => {
        setEditModal(!editModal)
        setSelectedObject(null)
    }

    useEffect(() => {
        const updateGrid = () => {
            const boxSize = 150 + 16; // Box size plus padding
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            // Calculate how many columns can fit horizontally
            const newColumns = Math.floor(screenWidth / boxSize);
            // Calculate how many rows can fit vertically (including header row)
            const newRows = modifiedStaffData ? modifiedStaffData.length + 1 : 0 + Math.floor(screenHeight / boxSize);
            setColumns(newColumns);
            setRows(newRows);
        };
        // Initial calculation on component mount
        updateGrid();
        // // // Recalculate when window is resized
        window.addEventListener('resize', updateGrid);
        // // // Cleanup event listener on unmount
        return () => window.removeEventListener('resize', updateGrid);
    }, [modifiedStaffData, waitingForServices]);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                minHeight: Math.round(window.innerHeight / 10) - 10 + "vh",
                overflowX: 'scroll',
                overflowY: 'scroll',
                maxHeight: Math.round(window.innerHeight / 10) - 10 + "vh",
                p: 1,
            }}
        >
            <Grid container spacing={1}>
                {/* Empty top-left corner box */}
                {/* <Grid item sx={{ width: 150, height: 150, border: "1px solid red" }} /> */}
                <Grid item sx={{ pl: 0, pt: 0, width: 150, height: 150, overflow: "hidden" }}>
                    <Box
                        sx={{
                            width: 150,
                            height: 150,
                            position: 'relative',
                        }}
                    >
                        {/* Bottom Box with Staff Text */}
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                width: '50%',
                                height: 35,
                                backgroundColor: '#e0e0e0',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: 'bold',
                            }}
                        >
                            Staff
                        </Box>
                        {/* Right Box with Rotated Client Text */}
                        <Box
                            sx={{
                                position: 'absolute',
                                right: 0,
                                top: '60%',
                                transform: 'translateY(-50%) rotate(90deg)',
                                transformOrigin: 'right top',
                                backgroundColor: '#e0e0e0',
                                padding: '5px',
                                fontWeight: 'bold',
                                width: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            Client
                        </Box>
                    </Box>
                </Grid>
                {/* Column headers (top row) */}
                {columns && Array.from({ length: columns }).map((_, colIndex) => (
                    <Grid
                        key={`col-header-${colIndex}`}
                        sx={{
                            width: 150,
                            height: 150,
                            // backgroundColor: '#f0f0f0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '1px solid lightgrey',
                            p: 1
                        }}
                    >
                        {/* // Render empty box if no header */}
                        {waitingForServices && waitingForServices[colIndex] ? <ClientInfoCard v={waitingForServices[colIndex]} /> : ''}
                    </Grid>
                ))}
                {/* Rows with headers on the left and related boxes in the middle */}
                {Array.from({ length: rows - 1 }).map((_, rowIndex) => (
                    <React.Fragment key={`row-${rowIndex}`}>
                        {/* Row headers (left column) */}
                        <Grid
                            sx={{
                                width: 150,
                                height: 150,
                                // backgroundColor: '#f0f0f0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid lightgrey',
                                p: 1
                            }}
                        >
                            {modifiedStaffData && modifiedStaffData[rowIndex] ? <EmployeeInfoCard v={modifiedStaffData[rowIndex]} /> : ""}
                        </Grid>
                        {/* Middle boxes related to the column and row */}
                        {Array.from({ length: columns }).map((_, colIndex) => (
                            <Grid
                                key={`box-${rowIndex}-${colIndex}`}
                                sx={{
                                    width: 150,
                                    height: 150,
                                    // backgroundColor: '#fff',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    overflowY: "auto",
                                    border: '1px solid lightgrey',
                                }}
                            >
                                {/* Render data if available, otherwise render an empty box */}
                                {waitingForServices && waitingForServices[colIndex] && modifiedStaffData?.map((value, index) => {
                                    const matchedClient = waitingForServices?.find(clientMember =>
                                        clientMember.staff_pref?.some(plan => plan.attributes.staff_detail_id.data.id === value.id)
                                    );
                                    const gender = waitingForServices?.find(clientMember =>
                                        value.attributes.lovs_answers?.some(lovs => lovs.attributes.selected_option_value === clientMember.preference.attributes.gender)
                                    );
                                    const language_spoken = waitingForServices?.find(clientMember =>
                                        value.attributes.lovs_answers?.some(lovs => lovs.attributes.selected_option_value === clientMember.preference.attributes.language_spoken)
                                    );
                                    const religion = waitingForServices?.find(clientMember =>
                                        value.attributes.lovs_answers?.some(lovs => lovs.attributes.selected_option_value === clientMember.preference.attributes.religion)
                                    );

                                    if (matchedClient && gender && language_spoken && religion && rowIndex === index) {
                                        return (
                                            <ShiftCards
                                                v={matchedClient}
                                                handleDetails={handleDetails}
                                                staff={value.id}
                                                handleStaffsData={handleStaffsData}
                                                getPlansForView={getPlansForView}
                                            />
                                        )
                                    }
                                })}
                            </Grid>
                        ))}
                    </React.Fragment>
                ))}
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={editModal}
                onClose={() => handleCloseDetails()}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={editModal}>
                    <Box sx={style}>
                        <Box>
                            <Typography variant="h5" component="h2" sx={{ fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                                {"Service Details"}
                            </Typography>
                        </Box>
                        <Box sx={{ py: 2 }}>
                            <Typography color="primary" style={{ fontSize: "1.2em", fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                                Service Type : {selectedObject?.service_request?.service_type?.service_name}
                            </Typography>
                            <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                Funding Source : {selectedObject?.service_request?.funding_source}
                            </Typography>
                            <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                Billable : {selectedObject?.service_request?.billable}
                            </Typography>
                            <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                Pay Travel Km : {selectedObject?.service_request?.pay_travel_km}
                            </Typography>
                            <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                Remove Plan Managers : {selectedObject?.service_request?.attributes?.remove_plan_managers}
                            </Typography>
                            <Typography color="primary" style={{ fontSize: "1.2em", fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                                Recurring Type : {selectedObject?.visit_data[0]?.attributes?.recurring_type.slice(7)}
                            </Typography>
                            {selectedObject?.visit_data[0]?.attributes?.start_date && (
                                <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                    Start Date : {selectedObject?.visit_data[0]?.attributes?.start_date}
                                </Typography>
                            )}
                            {selectedObject?.visit_data[0]?.attributes?.end_date && (
                                <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                    End Date : {selectedObject?.visit_data[0]?.attributes?.end_date}
                                </Typography>
                            )}
                            {selectedObject?.visit_data[0]?.attributes?.selected_days?.map((value) => (
                                <Box key={value.day} sx={{ display: "flex", flexWrap: "wrap", width: "100%", px: 1, pb: 1 }}>
                                    <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                        <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                            Day: {value.day}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                        <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                            Week Day: {value.weekDay}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                        <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                            Start Time: {value.startTime?.slice(0, 5)}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                        <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                            End Time: {value.endTime?.slice(0, 5)}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                            <Typography color="primary" style={{ fontSize: "1.2em", fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                                Check List
                            </Typography>
                            {selectedObject?.visit_check_list?.map((value) => (
                                <Box key={value?.attributes?.shift_title} sx={{ display: "flex", flexWrap: "wrap", width: "100%", px: 1, pb: 1 }}>
                                    <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                        <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                            Shift Title: {value?.attributes?.shift_title}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                        <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                            Shift comments: {value?.attributes?.shift_comments}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                            <Typography color="primary" style={{ fontSize: "1.2em", fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                                Client Details
                            </Typography>
                            <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%", px: 1, pb: 1 }}>
                                <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                    <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                        First Name: {selectedObject?.client_detail?.first_name}
                                    </Typography>
                                </Box>
                                <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                    <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                        Last Name: {selectedObject?.client_detail?.last_name}
                                    </Typography>
                                </Box>
                            </Box>
                            {/* <Typography color="primary" style={{ fontSize: "1.2em", fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                                Staff Details
                            </Typography>
                            <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%", px: 1, pb: 1 }}>
                                <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                    <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                        First Name: {selectedObject?.attributes?.staff_id?.data?.attributes?.first_name}
                                    </Typography>
                                </Box>
                                <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                    <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                        Last Name: {selectedObject?.attributes?.staff_id?.data?.attributes?.last_name}
                                    </Typography>
                                </Box>
                                <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                    <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                        Email: {selectedObject?.attributes?.staff_id?.data?.attributes?.email_info}
                                    </Typography>
                                </Box>
                            </Box> */}
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
                            <Stack direction="row" spacing={2}>
                                {/* <Button variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Save
                                </Button> */}
                                <Button onClick={() => handleCloseDetails()} variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
};

export default AutoSchedulingComponent;
