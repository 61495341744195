import { Delete, Edit } from "@mui/icons-material";
import { Backdrop, Box, Button, CircularProgress, Divider, Fade, FormControlLabel, FormGroup, Grid, IconButton, List, ListItem, ListItemText, MenuItem, Modal, Paper, Stack, Switch, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const Item = ({ value, data, index, setData }) => {
    return (
        <ListItem
            key={index}
            sx={{ my: 1 }}
            component={Paper}
            elevation={1}
            secondaryAction={
                <Stack direction="row" spacing={2}>
                    <Switch value={value?.attributes?.active} />
                    <IconButton>
                        <Edit />
                    </IconButton>
                    <IconButton>
                        <Delete />
                    </IconButton>
                </Stack>
            }
        >
            <ListItemText primary={value?.attributes?.title} secondary={value?.attributes?.description} />
        </ListItem>
    );
};

export const CustomModal = ({
    handleOptions,
    newModuleModal,
    handleModal,
    module,
    moduleInputs,
    setModule,
    category,
    categoryInputs,
    setCategory,
    setSelectedCategory,
    types,
    setTypes,
    typesInputs,
    setSelectedTypes,
    _auditDocumentList,
    documentDescription,
    setDocumentDescription,
    docFormat,
    setDocFormat,
    createModule,
    setCreateModule,
    createCategory,
    setCreateCategory,
    createTypes,
    setCreateTypes,
    createNewDocument,
    createNewDocumentName,
    createNewModule,
    createNewCategory,
    createNewType,
    createDocName,
    setCreateDocName,
    documentNameInputs,
    setDocumentNameInputs,
    loading_dc_name,
    loading_type,
    loading_doc,
    loading_mod,
    loading_cat,
}) => {

    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        maxWidth: "calc(70%)",
        minWidth: "calc(60%)",
        boxShadow: 12,
        borderRadius: 1,
        p: 4,
    });
    const width = () => {
        // modal control
        if (window.screen.width < 900) {
            const copy = { ...style };
            copy.maxWidth = "95%";
            setStyle(copy);
        }

        if (window.screen.width > 1600) {
            return window.screen.width / 3.7
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.4
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={newModuleModal}
            onClose={() => handleModal()}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={newModuleModal}>
                <Box sx={style}>
                    <Box>
                        <Typography color={"primary"} sx={{ fontFamily: "Roboto-Bold", fontSize: "1.5em" }}>
                            {handleOptions} Classification
                        </Typography>
                    </Box>
                    {handleOptions.includes("Document") && (
                        <Box sx={{ display: "flex", flexWrap: "wrap", py: 2, }}>
                            {moduleInputs?.map((v, i) => {
                                return (
                                    <Box key={i}>
                                        <TextField
                                            select={true}
                                            label={v?.title}
                                            htmlFor={v?.title}
                                            required={v?.required}
                                            sx={{ m: 1, width: screenWidth }}
                                            value={v?.value}
                                            onChange={(e) => {
                                                if (e.target.value !== "Please select category") {
                                                    const copy = [...moduleInputs];
                                                    copy[i].value = e.target.value;
                                                    setModule(copy)
                                                }
                                            }}
                                        >
                                            {v?.options?.map((option) => (
                                                <MenuItem key={option.title} value={option.title}>
                                                    {option.title}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {/* {v?.value?.includes("Core Module") ? v?.options?.map((option, index) => (
                                            <Box key={index}>
                                                {option?.childOption?.map((vN, iN) => (
                                                    <Box key={index}>
                                                        <TextField
                                                            key={iN}
                                                            select={true}
                                                            label={vN?.title}
                                                            htmlFor={vN?.title}
                                                            required={vN?.required}
                                                            sx={{ m: 1, width: "97%" }}
                                                            value={vN?.value}
                                                            onChange={(e) => {
                                                                const copy = [...module];
                                                                copy[i]?.options[index]?.childOption[iN]?.value = e.target.value;
                                                                setModule(copy)
                                                            }}
                                                        >
                                                            {vN?.options?.map((option) => (
                                                                <MenuItem key={option.title} value={option.title}>
                                                                    {option.title}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                        {vN.value !== "Please select option" ? vN.options?.map((option1, index1) => (
                                                            <Box key={index1}>
                                                                {vN.value === option1.title && option1?.childOption?.map((vN1, iN1) => {
                                                                    return (
                                                                        <Box key={iN1} >
                                                                            <TextField
                                                                                key={iN1}
                                                                                select={true}
                                                                                label={vN1?.title}
                                                                                htmlFor={vN1?.title}
                                                                                required={vN1?.required}
                                                                                sx={{ m: 1, width: "97%" }}
                                                                                value={vN1?.value}
                                                                                onChange={(e) => {
                                                                                    const copy = [...module];
                                                                                    copy[i].options[index].childOption[iN].options[index1].childOption[iN1].value = e.target.value;
                                                                                    setModule(copy)
                                                                                }}
                                                                            >
                                                                                {vN1?.options?.map((option) => (
                                                                                    <MenuItem key={option.title} value={option.title}>
                                                                                        {option.title}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>
                                                                            {vN1?.value !== "Please select option" ? vN1.options?.map((option2, index2) => (
                                                                                <Box key={index2} >
                                                                                    {vN1.value === option2.title && (
                                                                                        <TextField
                                                                                            select={true}
                                                                                            label={option2.titleInput}
                                                                                            sx={{ m: 1, width: "97%" }}
                                                                                            value={option2.value}
                                                                                            onChange={(e) => {
                                                                                                const copy = [...module];
                                                                                                copy[i].options[index].childOption[iN].options[index1].childOption[iN1].options[index2].value = e.target.value;
                                                                                                setModule(copy)
                                                                                            }}
                                                                                        >
                                                                                            {option2?.childOption?.map((option) => (
                                                                                                <MenuItem key={option.title} value={option.title}>
                                                                                                    {option.title}
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                        </TextField>
                                                                                    )}
                                                                                </Box>
                                                                            )) : null}
                                                                        </Box>
                                                                    )
                                                                })}
                                                            </Box>
                                                        )) : null}
                                                    </Box>
                                                ))}
                                            </Box>
                                        )) : null} */}
                                    </Box>
                                )
                            })}
                            {categoryInputs.map((v, i) => {
                                return (
                                    <TextField
                                        select={true}
                                        label={v.title}
                                        htmlFor={v.title}
                                        key={i}
                                        required={v.required}
                                        sx={{ m: 1, width: screenWidth }}
                                        value={v.value}
                                        onChange={(e) => {
                                            const copy = [...categoryInputs];
                                            copy[i].value = e.target.value;
                                            setCategory(copy)
                                            // setSelectedCategory(e.target.value)
                                        }}
                                    >
                                        {v?.options?.map((option) => (
                                            <MenuItem key={option.title} value={option.title}>
                                                {option.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )
                            })}
                            {typesInputs.map((v, i) => {
                                return (
                                    <TextField
                                        select={true}
                                        label={v.title}
                                        htmlFor={v.title}
                                        key={i}
                                        required={v.required}
                                        sx={{ m: 1, width: screenWidth }}
                                        value={v.value}
                                        onChange={(e) => {
                                            const copy = [...typesInputs];
                                            copy[i].value = e.target.value;
                                            setTypes(copy)
                                            // setSelectedTypes(e.target.value)
                                        }}
                                    >
                                        {v?.options?.map((option) => (
                                            <MenuItem key={option.title} value={option.title}>
                                                {option.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )
                            })}
                            {documentNameInputs.map((v, i) => {
                                return (
                                    <TextField
                                        select={true}
                                        label={v.title}
                                        htmlFor={v.title}
                                        key={i}
                                        required={v.required}
                                        sx={{ m: 1, width: screenWidth }}
                                        value={v.value}
                                        onChange={(e) => {
                                            const copy = [...documentNameInputs];
                                            copy[i].value = e.target.value;
                                            setDocumentNameInputs(copy)
                                            // setSelectedTypes(e.target.value)
                                        }}
                                    >
                                        {v?.options?.map((option) => (
                                            <MenuItem key={option.title} value={option.title}>
                                                {option.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )
                            })}
                            <TextField
                                label={"Document description"}
                                htmlFor={"Document description"}
                                sx={{ m: 1, width: screenWidth }}
                                required={true}
                                value={documentDescription}
                                onChange={(e) => setDocumentDescription(e.target.value)}
                            />
                        </Box>
                    )}
                    {handleOptions.includes("Module") && (
                        <Box sx={{ py: 2, }}>
                            <Box sx={{ width: "98%", display: "flex", p: 1, flexWrap: "wrap", maxHeight: "calc(60vh)", overflowY: "auto" }}>
                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    {module?.map((v, i) => {
                                        return (
                                            <Item key={i} value={v} index={i} />
                                        )
                                    })}
                                </List>
                            </Box>
                            {createModule.map((v, i) => {
                                if (v.type === "text") {
                                    return (
                                        <TextField
                                            multiline={v.multiline}
                                            type={v.type}
                                            label={v.title}
                                            htmlFor={v.title}
                                            sx={{ m: 1, my: 1, width: screenWidth }}
                                            required={v.required}
                                            value={v.value}
                                            onChange={(e) => {
                                                const copy = [...createModule]
                                                copy[i].value = e.target.value
                                                setCreateModule(copy)
                                            }}
                                        />
                                    )
                                }
                                if (v.type === "drop_down") {
                                    return (
                                        <TextField
                                            select={true}
                                            label={v.title}
                                            htmlFor={v.title}
                                            key={i}
                                            required={v.required}
                                            sx={{ m: 1, my: 1, width: screenWidth }}
                                            value={v.value}
                                            onChange={(e) => {
                                                const copy = [...createModule]
                                                copy[i].value = e.target.value
                                                setCreateModule(copy)
                                            }}
                                        >
                                            {v?.options?.map((option) => (
                                                <MenuItem key={option.title} value={option.title}>
                                                    {option.title}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )
                                }
                            })}
                        </Box>
                    )}
                    {handleOptions.includes("Category") && (
                        <Box sx={{ py: 2, }}>
                            <Box sx={{ width: "98%", display: "flex", p: 1, flexWrap: "wrap", maxHeight: "calc(60vh)", overflowY: "auto" }}>
                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    {category?.map((v, i) => {
                                        return (
                                            <Item key={i} value={v} index={i} />
                                        )
                                    })}
                                </List>
                            </Box>
                            {createCategory.map((v, i) => {
                                if (v.type === "text") {
                                    return (
                                        <TextField
                                            multiline={v.multiline}
                                            type={v.type}
                                            label={v.title}
                                            htmlFor={v.title}
                                            sx={{ m: 1, my: 1, width: screenWidth }}
                                            required={v.required}
                                            value={v.value}
                                            onChange={(e) => {
                                                const copy = [...createCategory]
                                                copy[i].value = e.target.value
                                                setCreateCategory(copy)
                                            }}
                                        />
                                    )
                                }
                                if (v.type === "drop_down") {
                                    return (
                                        <TextField
                                            select={true}
                                            label={v.title}
                                            htmlFor={v.title}
                                            key={i}
                                            required={v.required}
                                            sx={{ m: 1, my: 1, width: screenWidth }}
                                            value={v.value}
                                            onChange={(e) => {
                                                const copy = [...createCategory]
                                                copy[i].value = e.target.value
                                                setCreateCategory(copy)
                                            }}
                                        >
                                            {v?.options?.map((option) => (
                                                <MenuItem key={option.title} value={option.title}>
                                                    {option.title}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )
                                }
                            })}
                        </Box>
                    )}
                    {handleOptions.includes("Types") && (
                        <Box sx={{ py: 2, }}>
                            <Box sx={{ width: "98%", display: "flex", p: 1, flexWrap: "wrap", maxHeight: "calc(60vh)", overflowY: "auto" }}>
                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    {types?.map((v, i) => {
                                        return (
                                            <Item key={i} value={v} index={i} />
                                        )
                                    })}
                                </List>
                            </Box>
                            {createTypes.map((v, i) => {
                                if (v.type === "text") {
                                    return (
                                        <TextField
                                            multiline={v.multiline}
                                            type={v.type}
                                            label={v.title}
                                            htmlFor={v.title}
                                            sx={{ m: 1, my: 1, width: screenWidth }}
                                            required={v.required}
                                            value={v.value}
                                            onChange={(e) => {
                                                const copy = [...createTypes]
                                                copy[i].value = e.target.value
                                                setCreateTypes(copy)
                                            }}
                                        />
                                    )
                                }
                                if (v.type === "drop_down") {
                                    return (
                                        <TextField
                                            select={true}
                                            label={v.title}
                                            htmlFor={v.title}
                                            key={i}
                                            required={v.required}
                                            sx={{ m: 1, my: 1, width: screenWidth }}
                                            value={v.value}
                                            onChange={(e) => {
                                                const copy = [...createTypes]
                                                copy[i].value = e.target.value
                                                setCreateTypes(copy)
                                            }}
                                        >
                                            {v?.options?.map((option) => (
                                                <MenuItem key={option.title} value={option.title}>
                                                    {option.title}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )
                                }
                            })}
                        </Box>
                    )}
                    {handleOptions.includes("Doc Name") && (
                        <Box sx={{ py: 2, }}>
                            <Box sx={{ width: "98%", display: "flex", p: 1, mb: 2, flexWrap: "wrap", maxHeight: "calc(60vh)", overflowY: "auto" }}>
                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    {_auditDocumentList?.map((v, i) => {
                                        return (
                                            <Item key={i} value={v} index={i} />
                                        )
                                    })}
                                </List>
                            </Box>
                            {createDocName.map((v, i) => {
                                if (v.type === "text") {
                                    return (
                                        <TextField
                                            multiline={v.multiline}
                                            type={v.type}
                                            label={v.title}
                                            htmlFor={v.title}
                                            sx={{ m: 1, my: 1, width: screenWidth }}
                                            required={v.required}
                                            value={v.value}
                                            onChange={(e) => {
                                                const copy = [...createDocName]
                                                copy[i].value = e.target.value
                                                setCreateDocName(copy)
                                            }}
                                        />
                                    )
                                }
                                if (v.type === "drop_down") {
                                    return (
                                        <TextField
                                            select={true}
                                            label={v.title}
                                            htmlFor={v.title}
                                            key={i}
                                            required={v.required}
                                            sx={{ m: 1, my: 1, width: screenWidth }}
                                            value={v.value}
                                            onChange={(e) => {
                                                const copy = [...createDocName]
                                                copy[i].value = e.target.value
                                                setCreateDocName(copy)
                                            }}
                                        >
                                            {v?.options?.map((option) => (
                                                <MenuItem key={option.title} value={option.title}>
                                                    {option.title}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )
                                }
                            })}
                        </Box>
                    )}
                    <Box sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
                        <Stack direction="row" spacing={2}>
                            <Button onClick={() => {
                                if (handleOptions.includes("Document")) {
                                    createNewDocument();
                                }
                                if (handleOptions.includes("Module")) {
                                    createNewModule()
                                }
                                if (handleOptions.includes("Category")) {
                                    createNewCategory()
                                }
                                if (handleOptions.includes("Types")) {
                                    createNewType()
                                }
                                if (handleOptions.includes("Doc Name")) {
                                    createNewDocumentName();
                                }
                            }} variant="outlined" sx={{ color: "#000", fontFamily: "Roboto-Medium", textTransform: "capitalize", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                Save {loading_dc_name || loading_type || loading_doc || loading_mod || loading_cat ? <CircularProgress color="secondary" size={26} /> : null}
                            </Button>
                            <Button onClick={() => handleModal()}
                                variant="outlined" sx={{ color: "#000", fontFamily: "Roboto-Medium", textTransform: "capitalize", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                Cancel
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

export const EditListItemModal = ({
    editItem,
    editModal,
    setEditModal,
    module,
    moduleInputs,
    setModule,
    category,
    categoryInputs,
    setCategory,
    setSelectedCategory,
    types,
    setTypes,
    typesInputs,
    setSelectedTypes,
    documentDescription,
    setDocumentDescription,
    saveEditedListItem
}) => {

    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        maxWidth: "calc(70%)",
        minWidth: "calc(60%)",
        boxShadow: 12,
        borderRadius: 1,
        p: 4,
    });
    const width = () => {
        // modal control
        if (window.screen.width < 900) {
            const copy = { ...style };
            copy.maxWidth = "95%";
            setStyle(copy);
        }

        if (window.screen.width > 1600) {
            return window.screen.width / 4.5
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.4
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={editModal}
            onClose={() => setEditModal(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={editModal}>
                <Box sx={style}>
                    <Box>
                        <Typography color={"primary"} sx={{ fontFamily: "Roboto-Bold", fontSize: "1.5em" }}>
                            Customization
                        </Typography>
                    </Box>
                    <Box sx={{ py: 2, }}>
                        {moduleInputs?.map((v, i) => {
                            return (
                                <TextField
                                    select={true}
                                    label={v.title}
                                    htmlFor={v.title}
                                    key={i}
                                    required={v.required}
                                    sx={{ m: 1, width: screenWidth }}
                                    value={v.value}
                                    onChange={(e) => {
                                        const copy = [...moduleInputs];
                                        copy[i].value = e.target.value;
                                        setModule(copy)
                                    }}
                                >
                                    {v?.options?.map((option) => (
                                        <MenuItem key={option.title} value={option.title}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )
                        })}
                        {categoryInputs.map((v, i) => {
                            return (
                                <TextField
                                    select={true}
                                    label={v.title}
                                    htmlFor={v.title}
                                    key={i}
                                    required={v.required}
                                    sx={{ m: 1, width: screenWidth }}
                                    value={v.value}
                                    onChange={(e) => {
                                        const copy = [...categoryInputs];
                                        copy[i].value = e.target.value;
                                        setCategory(copy)
                                        setSelectedCategory(e.target.value)
                                    }}
                                >
                                    {v?.options?.map((option) => (
                                        <MenuItem key={option.title} value={option.title}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )
                        })}
                        {typesInputs.map((v, i) => {
                            return (
                                <TextField
                                    select={true}
                                    label={v.title}
                                    htmlFor={v.title}
                                    key={i}
                                    required={v.required}
                                    sx={{ m: 1, width: screenWidth }}
                                    value={v.value}
                                    onChange={(e) => {
                                        const copy = [...typesInputs];
                                        copy[i].value = e.target.value;
                                        setTypes(copy)
                                        setSelectedTypes(e.target.value)
                                    }}
                                >
                                    {v?.options?.map((option) => (
                                        <MenuItem key={option.title} value={option.title}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )
                        })}
                        <TextField
                            label={"Document Name"}
                            htmlFor={"Document Name"}
                            sx={{ m: 1, width: screenWidth }}
                            required={true}
                            value={documentDescription}
                            onChange={(e) => setDocumentDescription(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
                        <Stack direction="row" spacing={2}>
                            <Button onClick={() => saveEditedListItem(editItem)
                            } variant="outlined" sx={{ color: "#000", fontFamily: "Roboto-Medium", textTransform: "capitalize", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                Save
                            </Button>
                            <Button onClick={() => setEditModal(false)
                            } variant="outlined" sx={{ color: "#000", fontFamily: "Roboto-Medium", textTransform: "capitalize", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                Cancel
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}