import { Delete, Edit } from "@mui/icons-material";
import { Backdrop, Box, Button, Divider, Fade, Grid, IconButton, List, ListItem, ListItemText, MenuItem, Modal, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { InvoicePDFView } from ".";

export const CustomModalPDFFile = ({
    openPdf,
    handleModal,
    selectedData,
    shifts,
}) => {

    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#F5F7F6',
        maxWidth: "calc(70%)",
        minWidth: "calc(60%)",
        overflowY: "scroll",
        maxHeight: "calc(90vh)",
        boxShadow: 12,
        borderRadius: 3,
        p: 4,
    });

    // const disaptch = useDispatch()

    const width = () => {
        // modal control
        if (window.screen.width < 900) {
            const copy = { ...style };
            copy.maxWidth = "95%";
            setStyle(copy);
        }

        if (window.screen.width > 1600) {
            return window.screen.width / 4.5
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.4
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const editOldData = (value) => {
        handleModal()
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openPdf}
            onClose={() => handleModal()}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={openPdf}>
                <Box sx={style}>
                    <Box sx={{ mx: 2 }}>
                        <Typography color={"primary"} style={{ fontFamily: "Roboto-Bold", fontSize: 24 }}>
                            PDF View
                        </Typography>
                    </Box>
                    <Box sx={{ mx: 2, flex: 1, justifyContent: "center", alignItems: "center", }}>
                        <InvoicePDFView data={selectedData()} shifts={shifts} handleModal={handleModal} />
                    </Box>
                </Box>
            </Fade>
        </Modal >
    )
}