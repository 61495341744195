import { Backdrop, Box, Button, Divider, Fade, Grid, IconButton, List, ListItem, Tooltip, MenuItem, Modal, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FeedbackRegister } from "../../form_screens/register_form";
import SearchAndFilters from "../../../components/search_filters";
import { FeedbackList } from "../../../components/pagination_view";


export const MyFeedBack = () => {
    const [newFeedback, setNewFeedback] = useState(false);
    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        maxWidth: "calc(70%)",
        minWidth: "calc(60%)",
        boxShadow: 12,
        borderRadius: 1,
        p: 4,
    });
    const width = () => {
        // modal control
        if (window.screen.width < 900) {
            const copy = { ...style };
            copy.maxWidth = "95%";
            setStyle(copy);
        }

        if (window.screen.width > 1600) {
            return window.screen.width / 4
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.4
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    // const [category, setCategory] = useState([
    //     {
    //         title: "Category",
    //         value: "Assets",
    //         width: screenWidth,
    //         type: "drop_down",
    //         required: true,
    //         options: [
    //             {
    //                 title: "Assets",
    //                 selected: true
    //             },
    //             {
    //                 title: "Client",
    //                 selected: false
    //             },
    //             {
    //                 title: "Staff",
    //                 selected: false
    //             },
    //             {
    //                 title: "Other",
    //                 selected: false
    //             }
    //         ]
    //     },
    // ]);

    const [category, setCategory] = useState([
        {
            title: "Feedback Types",
            value: "Please Select Option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Please Select Option",
                    selected: true
                },
                {
                    title: "Compliment",
                    selected: false
                },
                {
                    title: "Suggestions",
                    selected: false
                }
            ]
        },
        {
            title: "Source of Feedback",
            value: "Please Select Option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Please Select Option",
                    selected: true
                },
                {
                    title: "Client/Rep",
                    selected: false
                },
                {
                    title: "Staff",
                    selected: false
                },
                {
                    title: "External body",
                    selected: false
                },
                {
                    title: "Other",
                    selected: false
                }
            ]
        },
        {
            title: "Areas you are providing feedback",
            value: "Please Select Option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Please Select Option",
                    selected: true
                },
                {
                    title: "Management/Staff",
                    selected: false
                },
                {
                    title: "Care",
                    selected: false
                },
                {
                    title: "Lifestyle/Activities",
                    selected: false
                },
                {
                    title: "Environment",
                    selected: false
                },
                {
                    title: "Documentation",
                    selected: false
                },
                {
                    title: "Maintenance",
                    selected: false
                },
                {
                    title: "Food",
                    selected: false
                },
                {
                    title: "Assets",
                    selected: false
                },
                {
                    title: "Other",
                    selected: false
                },
            ]
        },
        // {
        //     title: "Category",
        //     value: "Assets",
        //     width: screenWidth,
        //     type: "drop_down",
        //     required: true,
        //     options: [
        //         {
        //             title: "Assets",
        //             selected: true
        //         },
        //         {
        //             title: "Client",
        //             selected: false
        //         },
        //         {
        //             title: "Staff",
        //             selected: false
        //         },
        //         {
        //             title: "Other",
        //             selected: false
        //         }
        //     ]
        // },
        {
            title: "First Name",
            value: "",
            width: screenWidth,
            type: "text",
            required: false,
            multiline: false
        },
        {
            title: "Last Name",
            value: "",
            width: screenWidth,
            type: "text",
            required: false,
            multiline: false
        },
        {
            title: "Email",
            value: "",
            width: screenWidth,
            type: "email",
            required: false,
            multiline: false
        },
        {
            title: "Feedback Description / Comments",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: true
        },
    ]);

    // const [staffList, setStaffList] = useState([
    //     {
    //         title: "Staff List",
    //         value: "Select Staff Name",
    //         width: screenWidth,
    //         type: "drop_down",
    //         required: true,
    //         options: [
    //             {
    //                 title: "Select Staff Name",
    //                 selected: true
    //             },
    //             {
    //                 title: "Dr.Saba",
    //                 selected: false
    //             },
    //             {
    //                 title: "Dr.Abbas",
    //                 selected: false
    //             },
    //             {
    //                 title: "Dr.Fayaz",
    //                 selected: false
    //             }
    //         ]
    //     },
    // ]);

    // const [comment, setComment] = useState("");

    const feedback = (id, first_name, last_name, feedback_type, source_feedback, areas_feedback, comments, date_of_report) => {
        return {
            id, first_name, last_name, feedback_type, source_feedback, areas_feedback, comments, date_of_report
        };
    }

    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'First Name',
            numeric: false,
            disablePadding: true,
            label: 'First Name',
        },
        {
            id: 'Last Name',
            numeric: false,
            disablePadding: true,
            label: 'Last Name',
        },
        {
            id: 'Feedback Type',
            numeric: false,
            disablePadding: true,
            label: 'Feedback Type',
        },
        {
            id: 'Source of feedback',
            numeric: false,
            disablePadding: true,
            label: 'Source of feedback',
        },
        {
            id: 'Areas Providing Feedback',
            numeric: false,
            disablePadding: true,
            label: 'Areas Providing Feedback',
        },
        {
            id: 'Description/Comments',
            numeric: false,
            disablePadding: true,
            label: 'Description/Comments',
        },
        {
            id: 'Date of Report',
            numeric: false,
            disablePadding: true,
            label: 'Date Of Report',
        },
        {
            id: 'Options',
            numeric: false,
            disablePadding: true,
            label: 'Options',
        },
    ];

    const [orgs, setOrgs] = useState([
        feedback(1, "salman", "zafar", "Comaplint", "Other", "Assets", "out dated", "04-05-2024"),
        feedback(2, "saba", "salman", "Comaplint", "Other", "Assets", "out dated", "15-05-2024"),
        feedback(3, "rameez", "khan", "Comaplint", "Other", "Assets", "out dated", "08-05-2024"),
        feedback(4, "umar", "baloch", "Comaplint", "Other", "Assets", "out dated", "12-05-2024"),
    ]);

    return (
        <Grid container sx={{ display: "flex", justifyContent: "center", my: 2, marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, }}>
            <Grid item={true} xs={11} md={11} lg={11} sx={{ display: "flex", flexDirection: { lg: "row", xs: "column", md: "row", sm: "column" }, }}>
                <Grid item={true} xs={12} sm={12} md={6} lg={6} sx={{ display: "flex", alignItems: "center", flexWrap: 'wrap', justifyContent: { md: "flex-start", lg: "flex-start", xs: "center" } }}>
                    <Button onClick={() => {
                        setNewFeedback(true);
                    }} variant="outlined" sx={{ m: 1, fontFamily: "Roboto-Medium", textTransform: "capitalize" }}>
                        Add Feedback
                    </Button>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} lg={6} >
                    <SearchAndFilters groupBy={false} favorite={false} inputWidth={8} defaultState={false} />
                </Grid>
            </Grid>
            <Grid item={true} xs={11} sm={11} md={11} lg={11} >
                <FeedbackList rows={orgs} headCells={headCells} />
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={newFeedback}
                onClose={() => setNewFeedback(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={newFeedback}>
                    <Box sx={style}>
                        <Box>
                            <Typography variant="h5" component="h2" sx={{ fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                                Your Feedback
                            </Typography>
                        </Box>
                        <Box sx={{ my: 2 }}>
                            <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap', }}>
                                {category.map((v, i) => {
                                    return (
                                        <div key={v.title}>
                                            {v.type === "drop_down" ? (
                                                <TextField
                                                    select={true}
                                                    label={v.title}
                                                    htmlFor={v.title}
                                                    required={v.required}
                                                    sx={{ m: 1, width: v.width }}
                                                    value={v.value}
                                                    onChange={(e) => {
                                                        const copy = [...category];
                                                        copy[i].value = e.target.value;
                                                        setCategory(copy);
                                                    }}
                                                >
                                                    {v?.options?.map((nV, nI) => (
                                                        <MenuItem key={nI} value={nV.title} >
                                                            {nV.title}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            ) : null}
                                            {v.type === "text" || v.type === "email" ? (
                                                <TextField
                                                    type={v.type}
                                                    label={v.title}
                                                    multiline={v.multiline}
                                                    required={v.required}
                                                    htmlFor={v.title}
                                                    value={v.value}
                                                    sx={{ m: 1, width: v.width }}
                                                    onChange={(e) => {
                                                        const copy = [...category];
                                                        copy[i].value = e.target.value;
                                                        setCategory(copy);
                                                    }}
                                                />
                                            ) : null}
                                            {/* {v.value !== "Other" && v.value !== "Staff" ? (
                                                <TextField
                                                    style={{ marginBottom: "2%" }}
                                                    label={"Search " + v.value}
                                                    sx={{ m: 1, width: v.width }}
                                                />
                                            ) : null}
                                            {v.value === "Staff" && staffList.map((vN, iN) => {
                                                return (
                                                    <TextField
                                                        key={iN}
                                                        select={true}
                                                        label={vN.title}
                                                        htmlFor={vN.title}
                                                        required={vN.required}
                                                        sx={{ m: 1, width: vN.width }}
                                                        value={vN.value}
                                                        onChange={(e) => {
                                                            const copy = [...staffList];
                                                            if (e.target.value !== "Select Staff Name") {
                                                                copy[i].value = e.target.value;
                                                                setStaffList(copy);
                                                            }
                                                        }}
                                                    >
                                                        {vN?.options?.map((nV, nI) => (
                                                            <MenuItem key={nI} value={nV.title} >
                                                                {nV.title}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                )
                                            })} */}
                                        </div>
                                    )
                                })}
                            </Grid>
                            {/* <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap', }}>
                                <TextField
                                    label={"Feedback Comments"}
                                    multiline={true}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    sx={{ m: 1, width: screenWidth }}
                                />
                            </Grid> */}
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained" >
                                    Save
                                </Button>
                                <Button variant="contained" onClick={() => setNewFeedback(false)}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Grid>
    )
}