import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    category_step: null,
};

export const CategoryStepReducer = createSlice({
    name: 'category_step',
    initialState,
    reducers: {
        setCategoryStep: (state, action) => {
            state.category_step = action.payload;
        },
    },
});

export const {
    setCategoryStep,
} = CategoryStepReducer.actions;

export default CategoryStepReducer.reducer;
