import { Grid } from "@mui/material";
import React from "react";

export const HomeAssets = () => {

    return(
        <Grid container spacing={0} sx={{ display: "flex", justifyContent: "center" }}>

        </Grid>
    )
}