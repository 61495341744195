import * as React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Container, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, Toolbar, Tooltip, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { MyServices } from './screen/my_services';
import DescriptionIcon from '@mui/icons-material/Description';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { ReimbursementList } from './screen/reimbursement';
import { KilometerClaims } from './screen/kilometer_claim';
import RateReviewIcon from '@mui/icons-material/RateReview';
import InterestsIcon from '@mui/icons-material/Interests';
import { MyFeedBack } from './screen/my_feedbacks';
import { ConflictInterest } from './screen/conflict_interest';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import { MyTrainings } from './screen/trainings';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { LeaveApplication } from './screen/leave_application';
import { HeaderToolbar } from '../../components/header/header_toolbar';
import { MyServiceDashboard } from './screen/my_dashboard_v1';
// import WarningIcon from '@mui/icons-material/Warning';
// import { MyWarning } from './screen/warnings';

const drawerWidth = 70;

const ServiceDashboard = (props) => {
    const navigate = useNavigate()
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [options, setOptions] = React.useState([
        {
            option: "My Services",
            icon: <VolunteerActivismIcon fontSize={"large"} />,
            active: true,
            screen: <MyServiceDashboard />
        },
        {
            option: "My Feedback",
            icon: <RateReviewIcon fontSize={"large"} />,
            active: false,
            screen: <MyFeedBack />
        },
        {
            option: "Training / Certification",
            icon: <ModelTrainingIcon fontSize={"large"} />,
            active: false,
            screen: <MyTrainings />
        },
        {
            option: "My Conflict Of Interest",
            icon: <InterestsIcon fontSize={"large"} />,
            active: false,
            screen: <ConflictInterest />
        },
        {
            option: "Leave Application",
            icon: <DocumentScannerIcon fontSize={"large"} />,
            active: false,
            screen: <LeaveApplication />
        },
        {
            option: "My Reimbursements",
            icon: <DescriptionIcon fontSize={"large"} />,
            active: false,
            screen: <ReimbursementList view={"staff"} />
        },
        {
            option: "My KM's Claimed",
            icon: <ReceiptIcon fontSize={"large"} />,
            active: false,
            screen: <KilometerClaims view={"staff"} />
        },
        // {
        //     option: "Warnings",
        //     icon: <WarningIcon fontSize={"large"} />,
        //     active: false,
        //     screen: <MyWarning />
        // },
    ]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleMenuItem = (option, i) => {
        const copy = [...options];
        for (const key in copy) {
            if (key === String(i)) {
                copy[key].active = true
            } else {
                copy[key].active = false
            }
        }
        setOptions(copy);
    };

    const drawer = (
        <div style={{ overflow: 'hidden' }}>
            <Toolbar onClick={() => navigate(-1)} sx={{ justifyContent: 'center' }}>
                <img alt="Logo" style={{ width: 40, objectFit: 'fitt' }} src="/assets/icons/logo-without-text.png" />
            </Toolbar>
            <Divider />
            <List sx={{ pt: 0 }}>
                {options.map((v, i) => (
                    <ListItem key={i} disablePadding sx={{ backgroundColor: v.active ? "#013447" : "#ffffff", }}>
                        <ListItemButton onClick={() => handleMenuItem(v.option, i)}>
                            <Tooltip title={v.option} placement="right">
                                <ListItemIcon style={{ color: v.active ? "#ffffff" : "#013447", }}>
                                    {v.icon}
                                </ListItemIcon>
                            </Tooltip>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );
    // Remove this const when copying and pasting into your project.
    const container = window !== undefined ? () => window().document.body : undefined;
    const activeTab = options.find((e) => e.active)
    return (
        <Box sx={{ display: 'flex', overflow: "hidden", bgcolor: "#F5F7F6" }}>
            <CssBaseline />
            <AppBar
                color="primaryHeader"
                position="fixed"
                elevation={0}
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography color={"secondary"} sx={{ fontFamily: "Roboto-Bold", fontSize: 24 }}>
                        {activeTab.option}
                    </Typography>
                    <Box sx={{ flexGrow: 1, justifyContent: "flex-end", display: { xs: 'none', md: 'flex', lg: 'flex' }, }} >
                        <HeaderToolbar />
                    </Box>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, overflowY: "scroll", height: "calc(97vh)",
                }}
            >
                <Toolbar />
                {activeTab.screen}
            </Box>
        </Box>
    );
}

ServiceDashboard.propTypes = {
    window: PropTypes.func,
};

export default ServiceDashboard;