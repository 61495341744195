import { Avatar, Box, Button, Checkbox, Collapse, FormControlLabel, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Switch, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FolderIcon from '@mui/icons-material/Folder';
import { useDispatch, useSelector } from 'react-redux';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import DriveEtaOutlinedIcon from '@mui/icons-material/DriveEtaOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { StaffDetailsWithRelationalDataId } from '../../../services/staff_service';
import { IMAGE } from '../../../config/constant';
import { FilterUserById } from '../../../services/auth_service';

const generateDayTimes = (dateString) => {
    const date = new Date(dateString);
    const dayTimes = [];

    // Set start and end times for the day
    const startTime = new Date(date);
    startTime.setHours(0, 0, 0, 0);

    const endTime = new Date(date);
    endTime.setHours(23, 59, 59, 999);

    while (startTime <= endTime) {
        const hours = startTime.getHours();
        const minutes = startTime.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert to 12-hour format
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        const dateString = startTime.toISOString().split('T')[0]; // Extract date in YYYY-MM-DD format

        dayTimes.push({
            time: `${formattedHours}:${formattedMinutes} ${ampm}`,
            date: dateString,
            meeting: []
        });

        startTime.setMinutes(startTime.getMinutes() + 30); // Increment by 30 minutes
    }

    return dayTimes;
}

const getAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    // Adjust age if the birth date hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }

    return age;
}

export const MyServiceDashboard = () => {

    const [userInfo, setUserInfo] = useState(null)
    const [apiGetUserInfo, loadings, error] = FilterUserById();

    const getMyDetails = async () => {
        try {
            const user = JSON.parse(localStorage.getItem("user"))
            const response = await apiGetUserInfo({
                "variables": {
                    "id": user?.id
                }
            })
            if (response?.data?.usersPermissionsUser?.data) {
                setUserInfo(response?.data?.usersPermissionsUser?.data)
            }
        } catch (error) {
            console.log("error")
        }
    }
    useState(() => {
        getMyDetails()
    }, [])
    console.log("userInfo", userInfo)

    const [activeTab, setActiveTab] = useState("general");
    const [serviceTab, setServiceTab] = useState("ongoing");
    const [incidentsTab, setIncidentsTab] = useState("incidents");
    const [claimsTab, setClaimsTab] = useState("reimbusement");
    const [meetingTab, setMeetingTab] = useState("meeting");
    const _history = useSelector((state) => state._history.staff_details_id);

    const [active, setActive] = useState(true);
    const [fetchStaffDataById, loading] = StaffDetailsWithRelationalDataId();
    const [staffDetailsData, setStaffDetailsData] = useState(null);
    const [workingDaysList, setWorkingDaysList] = useState(null);

    const initCollapse = (data) => {
        return data.map((item) => ({
            ...item,
            collapse: false
        }));
    }

    const getFetchStaffDataById = async (id) => {
        try {
            const response = await fetchStaffDataById({
                variables: {
                    "filter": {
                        "staff_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                    "id": id,
                    "filterDay": {
                        "staff_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    }
                }
            })
            if (response?.data) {
                const copy = response?.data;
                setStaffDetailsData(copy);
                setWorkingDaysList(initCollapse(copy?.workingDays?.data))
            } else {
                throw "Somthing mising in query / network issue"
            }
        } catch (error) {
            console.log("error error", error)
        }
    }

    // const getFetchWorkDayDataById = async (id) => {
    //     try {
    //         const response = await fetchWorkDayDataById({
    //             variables: {
    //                 "filter": {
    //                     "staff_id": {
    //                         "id": {
    //                             "eq": id
    //                         }
    //                     }
    //                 }
    //             }
    //         })
    //         if (response?.data) {
    //             const copy = response?.data?.workingDays?.data;
    //             const fCopy = initCollapse(copy);
    //             setWorkingDaysList(fCopy);
    //         } else {
    //             throw "Somthing mising in query / network issue"
    //         }
    //     } catch (error) {
    //         console.log("error error", error)
    //     }
    // }

    useEffect(() => {
        if (_history) {
            getFetchStaffDataById(_history);
            // getFetchWorkDayDataById(_history);
        }
    }, [_history])

    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const meetingDate = new Date().getDate() + " " + months[new Date().getMonth()] + " " + new Date().getFullYear() + ", " + weekdays[new Date().getDay()]

    const [selectedDate, setSelectedDate] = useState("2024-07-19");
    const [dayTimesMeet, setDayTimesMeet] = useState(null)

    useEffect(() => {
        const objectOfTimes = generateDayTimes(selectedDate);
        const copy = [...objectOfTimes];
        copy[30].meeting.push({ meeting_time: "3:00 PM" })
        copy[31].meeting.push({ meeting_time: "3:30 PM" })
        copy[32].meeting.push({ meeting_time: "4:00 PM" })
        copy[33].meeting.push({ meeting_time: "4:30 PM" })
        setDayTimesMeet(copy);
    }, [])

    const [days, setDays] = useState([
        {
            title: "Monday",
            availability: true,
            time_slots: [
                {
                    start_time: "13:00",
                    end_time: "15:30"
                },
                {
                    start_time: "18:00",
                    end_time: "22:30"
                },
            ],
            availabilityOption: [
                {
                    title: "Unavailable",
                    value: false
                },
                {
                    title: "Whole Day",
                    value: false
                }
            ],
            collapse: true
        },
        {
            title: "Tuesday",
            availability: true,
            time_slots: [
                {
                    start_time: "13:00",
                    end_time: "15:30"
                },
                {
                    start_time: "18:00",
                    end_time: "22:30"
                },
            ],
            availabilityOption: [
                {
                    title: "Unavailable",
                    value: false
                },
                {
                    title: "Whole Day",
                    value: false
                }
            ],
            collapse: false
        },
        {
            title: "Wednesday",
            availability: true,
            time_slots: [
                {
                    start_time: "13:00",
                    end_time: "15:30"
                },
                {
                    start_time: "18:00",
                    end_time: "22:30"
                },
            ],
            availabilityOption: [
                {
                    title: "Unavailable",
                    value: false
                },
                {
                    title: "Whole Day",
                    value: false
                }
            ],
            collapse: false
        },
        {
            title: "Thursday",
            availability: true,
            time_slots: [
                {
                    start_time: "13:00",
                    end_time: "15:30"
                },
                {
                    start_time: "18:00",
                    end_time: "22:30"
                },
            ],
            availabilityOption: [
                {
                    title: "Unavailable",
                    value: false
                },
                {
                    title: "Whole Day",
                    value: false
                }
            ],
            collapse: false
        },
        {
            title: "Friday",
            availability: true,
            time_slots: [
                {
                    start_time: "13:00",
                    end_time: "15:30"
                },
                {
                    start_time: "18:00",
                    end_time: "22:30"
                },
            ],
            availabilityOption: [
                {
                    title: "Unavailable",
                    value: false
                },
                {
                    title: "Whole Day",
                    value: false
                }
            ],
            collapse: false
        },
        {
            title: "Saturday",
            availability: true,
            time_slots: [
                {
                    start_time: "13:00",
                    end_time: "15:30"
                },
                {
                    start_time: "18:00",
                    end_time: "22:30"
                },
            ],
            availabilityOption: [
                {
                    title: "Unavailable",
                    value: false
                },
                {
                    title: "Whole Day",
                    value: false
                }
            ],
            collapse: false
        },
        {
            title: "Sunday",
            availability: true,
            time_slots: [
                {
                    start_time: "13:00",
                    end_time: "15:30"
                },
                {
                    start_time: "18:00",
                    end_time: "22:30"
                },
            ],
            availabilityOption: [
                {
                    title: "Unavailable",
                    value: false
                },
                {
                    title: "Whole Day",
                    value: false
                }
            ],
            collapse: false
        }
    ]);

    const [service, setService] = useState([
        {
            title: "Assistance with self care activity",
            service_type: "Every 3 Days",
            status: "Ongoing",
            pay_travel: "Yes",
            funding_source: "NDIS",
            billable: "Yes",
            collapse: true,
            visit_day: [
                {
                    date: "10 june 2024",
                    day: "Monday",
                    start_time: "13:00",
                    end_time: "15:30",
                    collapse: true,
                    visit_shift_checklist: [
                        {
                            title: "Shopping",
                            comment: "",
                            completed: false,
                            collapse: true,
                        },
                        {
                            title: "Gardening",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Singing",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Running",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Cooking",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                    ],
                },
                {
                    date: "14 june 2024",
                    day: "Monday",
                    start_time: "13:00",
                    end_time: "15:30",
                    collapse: false,
                    visit_shift_checklist: [
                        {
                            title: "Shopping",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Gardening",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Singing",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Running",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Cooking",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                    ],
                }
            ]
        },
        {
            title: "Assistance with self care activity",
            service_type: "Every 3 Days",
            status: "Ongoing",
            pay_travel: "Yes",
            funding_source: "NDIS",
            billable: "Yes",
            collapse: false,
            visit_day: [
                {
                    date: "10 june 2024",
                    day: "Monday",
                    start_time: "13:00",
                    end_time: "15:30",
                    collapse: true,
                    visit_shift_checklist: [
                        {
                            title: "Shopping",
                            comment: "",
                            completed: false,
                            collapse: true,
                        },
                        {
                            title: "Gardening",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Singing",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Running",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Cooking",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                    ],
                },
                {
                    date: "14 june 2024",
                    day: "Monday",
                    start_time: "13:00",
                    end_time: "15:30",
                    collapse: false,
                    visit_shift_checklist: [
                        {
                            title: "Gardening",
                            comment: "",
                            completed: false,
                            collapse: true,
                        },
                        {
                            title: "Singing",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Running",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                    ],
                }
            ]
        }
    ]);

    const [serviceHistory, setServiceHistory] = useState([
        {
            title: "Assistance with self care activity",
            service_type: "Every 3 Days",
            status: "closed",
            pay_travel: "Yes",
            funding_source: "NDIS",
            billable: "Yes",
            collapse: true,
            visit_day: [
                {
                    date: "10 june 2024",
                    day: "Monday",
                    start_time: "13:00",
                    end_time: "15:30",
                    collapse: true,
                    visit_shift_checklist: [
                        {
                            title: "Shopping",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: true,
                        },
                        {
                            title: "Gardening",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Singing",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Running",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Cooking",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                    ],
                },
                {
                    date: "14 june 2024",
                    day: "Monday",
                    start_time: "13:00",
                    end_time: "15:30",
                    collapse: false,
                    visit_shift_checklist: [
                        {
                            title: "Shopping",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Gardening",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Singing",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Running",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Cooking",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                    ],
                }
            ]
        },
        {
            title: "Assistance with self care activity",
            service_type: "Every 3 Days",
            status: "closed",
            pay_travel: "Yes",
            funding_source: "NDIS",
            billable: "Yes",
            collapse: false,
            visit_day: [
                {
                    date: "10 june 2024",
                    day: "Monday",
                    start_time: "13:00",
                    end_time: "15:30",
                    collapse: true,
                    visit_shift_checklist: [
                        {
                            title: "Shopping",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: true,
                        },
                        {
                            title: "Gardening",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Singing",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Running",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Cooking",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                    ],
                },
                {
                    date: "14 june 2024",
                    day: "Monday",
                    start_time: "13:00",
                    end_time: "15:30",
                    collapse: false,
                    visit_shift_checklist: [
                        {
                            title: "Gardening",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: true,
                        },
                        {
                            title: "Singing",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Running",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                    ],
                }
            ]
        }
    ]);

    const [activeService, setActiveService] = useState(null);
    const [activeServiceCheck, setActiveServiceCheck] = useState(null);

    const handleCollapse = (index, data, setData) => {
        const copy = [...data];
        for (const key in copy) {
            copy[key].collapse = false;
        }
        copy[index].collapse = !copy[index].collapse;
        setData(copy)
    }

    const handleActiveService = (index, data, setData) => {
        const copy = { ...data };
        for (const key in copy.visit_day) {
            copy.visit_day[key].collapse = false;
        }
        copy.visit_day[index].collapse = !copy.visit_day[index].collapse;
        setActiveServiceCheck(copy.visit_day[index].visit_shift_checklist)
        setData(copy)
    }

    useEffect(() => {
        const active = service.find((e) => e.collapse === true);
        for (const key in active.visit_day) {
            if (active.visit_day[key].collapse) {
                setActiveServiceCheck(active.visit_day[key].visit_shift_checklist)
            }
        }
        setActiveService(active)
    }, [service])

    const gender = staffDetailsData?.staffAnswerLovsReferences?.data?.find((e) => e.attributes.input_name.toLowerCase() === "gender")
    const designation = staffDetailsData?.staffAnswerLovsReferences?.data?.find((e) => e.attributes.input_name.toLowerCase() === "designation")
    const religion = staffDetailsData?.staffDetail?.data?.attributes.religion
    const language_spoken = staffDetailsData?.staffDetail?.data?.attributes.language_spoken;

    return (
        <Grid container sx={{ bgcolor: "#F5F7F6", }}>
            {/* ============= Main Container =============== */}
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", flexDirection: "row", height: window.screen.width > 1600 ? "90vh" : "92vh" }}>
                {/* ================ left container =============== */}
                <Grid item={true} xs={11} sm={3} md={3} lg={2} >
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", backgroundColor: "#fff", border: "1px solid #6ECA35", mx: 3, my: 2, borderRadius: 4, height: 255 }}>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Avatar sx={{ width: 70, height: 70, boxShadow: "0px 0px 4px 4px rgba(0, 0, 0, 0.14)", }} alt={staffDetailsData?.staffDetail?.data?.attributes?.first_name} src={`${IMAGE}${staffDetailsData?.staffDetail?.data?.attributes?.profile_picture?.data?.attributes?.url}`} />
                            {/* <Avatar sx={{ width: 75, height: 75 }} src={`/assets/images/Leanna-B_thumb.jpg`} /> */}
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 0.5 }}>
                            <Typography color="primaryText" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                {userInfo?.attributes?.username}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 0.5 }}>
                            <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                Client Id: {userInfo?.id}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <Switch color="primary" checked={staffDetailsData?.staffDetail?.data?.attributes?.active} onClick={() => setActive(!staffDetailsData?.staffDetail?.data?.attributes?.active)} />
                            {staffDetailsData?.staffDetail?.data?.attributes?.active ?
                                <Typography color={"primary"} sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                    Active
                                </Typography> :
                                <Typography color={"secondary.text3"} sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                    Inactive
                                </Typography>
                            }
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", py: 1, px: 2, backgroundColor: "#fff", border: "1px solid #6ECA35", mx: 3, my: 2, borderRadius: 4, height: 255 }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "1em" }}>
                                General Information
                            </Typography>
                            <IconButton>
                                <BorderColorOutlinedIcon color='primary' />
                            </IconButton>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: "column" }}>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {staffDetailsData?.staffDetail?.data?.attributes?.date_of_birth}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Date of Birth:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {getAge(staffDetailsData?.staffDetail?.data?.attributes?.date_of_birth)}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Age:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {gender?.attributes?.selected_option_value}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Gender:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {religion}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Religion:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {language_spoken}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Language Spoken:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {staffDetailsData?.contactDetail?.data?.attributes?.home_phone}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Home Phone:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {staffDetailsData?.contactDetail?.data?.attributes?.mobile_phone}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Mobile Phone:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {designation?.attributes?.selected_option_value}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Designation:" />
                            </ListItem>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", py: 1, px: 2, backgroundColor: "#fff", border: "1px solid #6ECA35", mx: 3, my: 2, borderRadius: 4, height: 255 }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "1em" }}>
                                Emergency Contact
                            </Typography>
                            <IconButton>
                                <BorderColorOutlinedIcon color='primary' />
                            </IconButton>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: "column" }}>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {staffDetailsData?.emergencyContactDetail?.data?.attributes?.first_name}{" "}
                                        {staffDetailsData?.emergencyContactDetail?.data?.attributes?.last_name}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Name:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {staffDetailsData?.emergencyContactDetail?.data?.attributes?.relationship}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Relationship:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {staffDetailsData?.emergencyContactDetail?.data?.attributes?.home_phone}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Home Phone:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {staffDetailsData?.emergencyContactDetail?.data?.attributes?.mobile_phone}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Mobile:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {staffDetailsData?.emergencyContactDetail?.data?.attributes?.address_1}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Address_1:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {staffDetailsData?.emergencyContactDetail?.data?.attributes?.address_2}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Address_2:" />
                            </ListItem>
                        </Box>
                    </Box>
                </Grid>
                {/* ================ right container =============== */}
            </Grid>
        </Grid>
    )
}