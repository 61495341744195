import { Grid, } from "@mui/material";
import React, { useState } from "react";
import SearchAndFilters from "../../../components/search_filters";
import { InvoiceLedgerList } from "../../../components/pagination_view";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    maxWidth: "calc(70%)",
    minWidth: "calc(50%)",
    boxShadow: 12,
    borderRadius: 1,
    p: 4,
};

export const InvoiceLedger = () => {
    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'Type',
            numeric: false,
            disablePadding: true,
            label: 'Type',
        },
        {
            id: 'Recipient#',
            numeric: false,
            disablePadding: true,
            label: 'Recipient#',
        },
        {
            id: 'Invoice#',
            numeric: false,
            disablePadding: true,
            label: 'Invoice#',
        },
        {
            id: 'Net',
            numeric: false,
            disablePadding: true,
            label: 'Net',
        },
        {
            id: 'Gst',
            numeric: false,
            disablePadding: true,
            label: 'Gst',
        },
        {
            id: 'Gross',
            numeric: false,
            disablePadding: true,
            label: 'Gross',
        },
        {
            id: 'Date',
            numeric: false,
            disablePadding: true,
            label: 'Date',
        },
        {
            id: 'Due Date',
            numeric: false,
            disablePadding: true,
            label: 'Due Date',
        },
        {
            id: 'Status',
            numeric: false,
            disablePadding: true,
            label: 'Status',
        },
        {
            id: 'Actions',
            numeric: false,
            disablePadding: true,
            label: 'Actions',
        },
    ];
    const invoice = (id, type, recipient, invoice, net, gst, gross, date, due_date, status) => {
        return {
            id,
            type,
            recipient,
            invoice,
            net, gst, gross, date, due_date, status
        };
    }

    const [orgs, setOrgs] = useState([
        invoice(1, "NDIS Funded", "AKj234", "0123001", 300, 7, 307, "20-07-2023", "20-07-2024", "Pending"),
        invoice(2, "NDIS Funded", "AKj234", "0123001", 100, 7, 107, "20-07-2023", "20-07-2024", "Pending"),
    ]);

    return (
        <Grid container sx={{ display: "flex", justifyContent: "center", my: 2, marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, }}>
            <Grid item={true} xs={11} md={11} lg={11} sx={{ display: "flex", flexDirection: { lg: "row", xs: "column", md: "row", sm: "column" }, }}>
                <SearchAndFilters groupBy={false} favorite={false} />
            </Grid>
            <Grid item={true} xs={11} sm={12} md={11} lg={11}>
                <InvoiceLedgerList rows={orgs} headCells={headCells} />
            </Grid>
        </Grid>
    )
}