export const getCurrentWeekNumber = () => {
    const now = new Date();

    // Get the start of the year
    const startOfYear = new Date(now.getFullYear(), 0, 1);

    // Calculate the difference in milliseconds between the current date and the start of the year
    const diff = now - startOfYear;

    // Convert milliseconds into days
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(diff / oneDay);

    // Get the current day of the week (0 = Sunday, 1 = Monday, etc.)
    const startDayOfWeek = startOfYear.getDay();

    // Calculate the current week number
    const weekNumber = Math.ceil((dayOfYear + startDayOfWeek) / 7);

    return weekNumber;
}

export const createString = (firstName, lastName, week, year) => {
    // Get the first three letters of the first and last name
    const firstThreeFirstName = firstName?.slice(0, 3)?.toUpperCase();
    const firstThreeLastName = lastName?.slice(0, 3)?.toUpperCase();

    // Convert week to a 2-digit string
    const weekString = week?.toString()?.padStart(2, '0');

    // Reverse the year and convert it back to string
    const reversedYearString = year?.toString().split('').reverse().join('');

    // Combine everything to form the final string
    return firstThreeFirstName + firstThreeLastName + weekString + "-" + reversedYearString;
}

// // Sample holiday list in ISO format
const holidays = ["2024-12-25", "2024-01-01"];

export const calculateHours = (startDateTime, endDateTime) => {
    // Convert the date times to Date objects
    const start = new Date(startDateTime);
    let end = new Date(endDateTime);

    // Handle the case where endDateTime is before startDateTime (crossing midnight)
    if (end <= start) {
        // Add one day to the endDateTime
        end.setDate(end.getDate() + 1);
    }

    const daytimeStart = 6;  // 6:00 AM
    const daytimeEnd = 20;   // 8:00 PM
    const eveningStart = 20; // 8:00 PM
    const eveningEnd = 24;   // Midnight
    const nightStart = 0;    // Midnight
    const nightEnd = 6;      // 6:00 AM

    // Calculate the total difference in milliseconds
    const diffMs = end - start;

    // Convert milliseconds to hours
    const totalHours = diffMs / (1000 * 60 * 60);

    // Initialize counters for time segments and flags
    let daytimeHours = 0;
    let eveningHours = 0;
    let nightHours = 0;
    let sleepHours = 0;

    let saturdayHours = 0;
    let sundayHours = 0;
    let holidayHours = 0;

    // Track shift start and end flags
    let shiftStartFlag = null;
    let shiftEndFlag = null;

    const shiftFlags = {
        isDay: false,
        isEvening: false,
        isNight: false,
        isSaturday: false,
        isSunday: false,
        isHoliday: false,
        isSleepover: false
    };

    // Check if the time spans over weekends or holidays
    const isWeekend = (date) => {
        const day = date.getDay();  // Sunday is 0, Saturday is 6
        return day === 6 || day === 0;
    };

    const isHoliday = (date) => {
        const dateString = date.toISOString().split('T')[0];
        return holidays.includes(dateString);
    };

    // Set shift start flag
    shiftStartFlag = getShiftFlag(start, holidays);

    // Loop through each hour from start to end
    let remainingHours = totalHours;
    let currentDateTime = start;

    while (remainingHours > 0) {
        let currentHour = currentDateTime.getHours();
        let segmentHours;

        // Determine which segment the current hour falls into and calculate the hours
        if (currentHour >= daytimeStart && currentHour < daytimeEnd) {
            segmentHours = Math.min(remainingHours, daytimeEnd - currentHour);
            daytimeHours += segmentHours;
            shiftFlags.isDay = true;
        } else if (currentHour >= eveningStart && currentHour < eveningEnd) {
            segmentHours = Math.min(remainingHours, eveningEnd - currentHour);
            eveningHours += segmentHours;
            shiftFlags.isEvening = true;
        } else if (currentHour >= nightStart && currentHour < nightEnd) {
            segmentHours = Math.min(remainingHours, nightEnd - currentHour);
            nightHours += segmentHours;
            sleepHours += segmentHours;
            shiftFlags.isNight = true;
            shiftFlags.isSleepover = true;
        } else {
            segmentHours = Math.min(remainingHours, 24 - currentHour);
            nightHours += segmentHours;
            sleepHours += segmentHours;
            shiftFlags.isNight = true;
            shiftFlags.isSleepover = true;
        }

        // Check if it's a holiday or weekend and set the corresponding flags and hours
        if (currentDateTime.getDay() === 6) {
            saturdayHours += segmentHours;
            shiftFlags.isSaturday = true;
        } else if (currentDateTime.getDay() === 0) {
            sundayHours += segmentHours;
            shiftFlags.isSunday = true;
        }

        if (isHoliday(currentDateTime)) {
            holidayHours += segmentHours;
            shiftFlags.isHoliday = true;
        }

        // Move to the next hour and decrease the remaining hours
        remainingHours -= segmentHours;
        currentDateTime.setHours(currentDateTime.getHours() + segmentHours);
    }

    // Set shift end flag
    shiftEndFlag = getShiftFlag(end, holidays);

    return {
        totalHours: totalHours,
        daytimeHours: daytimeHours,
        eveningHours: eveningHours,
        nightHours: nightHours,
        sleepHours: sleepHours,
        saturdayHours: saturdayHours,
        sundayHours: sundayHours,
        holidayHours: holidayHours,
        shiftFlags: shiftFlags,
        shiftStartFlag: shiftStartFlag,
        shiftEndFlag: shiftEndFlag
    };
}

// Helper function to determine the shift flag based on a specific date
const getShiftFlag = (date) => {
    const dayOfWeek = date.getDay();
    const hour = date.getHours();
    const dateString = date.toISOString().split('T')[0];

    const daytimeStart = 6;  // 6:00 AM
    const daytimeEnd = 20;   // 8:00 PM
    const eveningStart = 20; // 8:00 PM
    const eveningEnd = 24;   // Midnight
    const nightStart = 0;    // Midnight
    const nightEnd = 6;      // 6:00 AM

    if (holidays.includes(dateString)) return "Holiday";
    if (dayOfWeek === 6) return "Saturday";
    if (dayOfWeek === 0) return "Sunday";

    if (hour >= daytimeStart && hour < daytimeEnd) return "Day";
    if (hour >= eveningStart && hour < eveningEnd) return "Evening";
    if (hour >= nightStart && hour < nightEnd) return "Night";

    // Early morning hours count as night or sleepover
    if (hour < daytimeStart) return "Night/Sleepover";

    return "Unknown";
}

export const calculateAge = (dateOfBirth) => {
    const birthDate = new Date(dateOfBirth);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    // If the current month is before the birth month or it's the birth month but the current day is before the birth day, subtract one year
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
    }

    return age;
}

export const convertToShortForm = (sentence) => {
    // Split the sentence into words
    const words = sentence.trim().split(/\s+/);

    // Check if there are up to 2 words
    if (words.length > 2) {
        // Create the short form by taking the first letter of each word
        const shortForm = words.map(word => word.charAt(0).toUpperCase()).join('');
        return shortForm;
    }

    // If there are more than 2 words, return the original sentence
    return sentence;
}

export const roundToNearestQuarter = (value) => {
    const floorValue = Math.floor(value); // Get the integer part of the value
    const decimalPart = value - floorValue; // Get the decimal part

    if (decimalPart < 0.25) {
        return floorValue + 0.25;
    } else if (decimalPart >= 0.25 && decimalPart < 0.5) {
        return floorValue + 0.5;
    } else if (decimalPart > 0.5 && decimalPart < 0.75) {
        return floorValue + 0.75;
    } else if (decimalPart > 0.75) {
        return floorValue + 1; // Move to the next integer if decimal part >= 0.75
    } else {
        return floorValue + decimalPart;
    }
}
