import { Box, Container, Grid } from '@mui/material'
import React from 'react'

const ThankyouComponent = ({ content }) => {
  const Header = <Box sx={{ margin: '20px 0px 0px 0px' }}>
    <Grid container>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <img style={{ width: 230, height: 55, }} alt="Logo" src="/assets/icons/logo-landscap.png" />
      </Grid>
    </Grid>
  </Box>
  return (
    <Container sx={{
      maxWidth: { xl: '1600px', lg: '1000px', md: '800px', sm: '500px' },      
    }}>
      <Box container>
        {Header}
        {content}
      </Box>
    </Container>
  )
}

export default ThankyouComponent