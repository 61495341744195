import { Alert, AlertTitle, Autocomplete, Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, MenuItem, Rating, Slide, Snackbar, Stack, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DraftEditor from "../../../components/Editor/DraftEditor";
import { useSelector, useDispatch } from "react-redux";
import { client_onboard_data_lists } from "../../../middleware/reducers/client_onboarding_reducer";
import _ from "lodash"
import { CheckListAlertsNotificationsByFilter, ClientAnswerLovsReferences, ClientDetailsById, ClientGoalsByFilter, AdministrativeNotesByFilter, ContactInfoById, CreateAdministrativeNote, CreateAdmissionInformation, CreateCheckListAlertsNotification, CreateClientAnswerLovsReference, CreateClientDetail, CreateClientGoal, CreateClientNote, CreateClientQuestionAnswerRef, CreateContactDetailForClient, CreateEmergencyContactDetailForClient, CreateMedicationsForClient, CreateOnboardMedicalHistoryDetail, CreatePlanReview, CreatePlanSupportCategory, CreatePreferenceForStaffWorker, CreateReferringDoctorInformation, CreateServiceInformation, EmergencyContactDetailsByFilter, PlanReviewByFilter, PreferenceForStaffWorkers, ServiceInformations, MedicationDetailsById, MedicalInformationById, OnboardMedicalHistoryDetailById, ReferringDoctorInformationById, ClientQuestionAnswerRefById } from "../../../services/client_service";
import { UpdateAdministrativeNote, UpdateCheckListAlertsNotification, UpdateClientAnswerLovsReferenceById, UpdateClientDetail, UpdateClientGoal, UpdateContactDetail, UpdateEmergencyContactDetail, UpdateMedicalInformation, UpdateOnboardMedicalHistoryDetail, UpdatePlanReview, UpdatePlanSupportCategory, UpdatePreferenceForStaffWorker, UpdateServiceInformation, UpdateUpdateClientQuestionAnswerRef, UpdatReferringDoctorInformation } from "../../../services/admin_service";
import moment from "moment";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { setLoadingState } from "../../../middleware/reducers/loading_state_reducer";

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const SlideTransition = (props) => {
    return <Slide {...props} direction="left" />;
}

const Row = (props) => {
    const { row, screenWidth, handleIndividualRiskChanges, pIndex } = props;
    return (
        <React.Fragment>
            <Grid container >
                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-start", }} >
                    <Grid item={true} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", p: 1, }}>
                        <Typography sx={{ fontFamily: "Roboto-Medium", }}>
                            {row?.title}
                        </Typography>
                    </Grid>
                </Grid>
                {row?.childOptions?.map((value, index) => {
                    if (value?.type === "Select") {
                        return (
                            <Grid item={true} key={value.title + index} xs={12} sm={6} md={4} lg={2} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", p: 1, justifyContent: "flex-start", }}>
                                <TextField
                                    select={true}
                                    id={value.title}
                                    htmlFor={value.title}
                                    required={value.required}
                                    label={value.title}
                                    InputLabelProps={{
                                        style: {
                                            textTransform: "capitalize"
                                        }
                                    }}
                                    value={value.value}
                                    sx={{ width: screenWidth }}
                                    onChange={(e) => handleIndividualRiskChanges(e.target.value, pIndex, index)}
                                >
                                    {value?.options?.map((option) => (
                                        <MenuItem key={option?.attributes?.title} value={option?.attributes?.title}>
                                            {option?.attributes?.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        )
                    }
                    if (value?.type === "Text") {
                        return (
                            <Grid item={true} key={value.title + index} xs={12} sm={6} md={6} lg={3} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", p: 1, display: "flex", justifyContent: "flex-start", }}>
                                <TextField
                                    type={"text"}
                                    id={value.title}
                                    htmlFor={value.title}
                                    required={value.required}
                                    label={value.title}
                                    multiline={true}
                                    value={value.value}
                                    sx={{ width: screenWidth }}
                                    onChange={(e) => handleIndividualRiskChanges(e.target.value, pIndex, index)}
                                />
                            </Grid>
                        )
                    }
                })}
            </Grid>
        </React.Fragment >
    );
}

const RowHRA = (props) => {
    const { row, screenWidth, handleIndividualRiskChanges, pIndex } = props;

    return (
        <React.Fragment>
            <Grid container >
                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-start", }} >
                    <Grid item={true} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", p: 1, }}>
                        <Typography sx={{ fontFamily: "Roboto-Medium", }}>
                            {row?.title}
                        </Typography>
                    </Grid>
                </Grid>
                {row?.childOptions?.map((value, index) => {
                    if (value?.type === "Select") {
                        return (
                            <Grid item={true} key={value.title + index} xs={12} sm={6} md={4} lg={2} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", p: 1, justifyContent: "flex-start", }}>
                                <TextField
                                    select={true}
                                    id={value.title}
                                    htmlFor={value.title}
                                    required={value.required}
                                    label={value.title}
                                    InputLabelProps={{
                                        style: {
                                            textTransform: "capitalize"
                                        }
                                    }}
                                    value={value.value}
                                    sx={{ width: screenWidth }}
                                    onChange={(e) => handleIndividualRiskChanges(e.target.value, pIndex, index)}
                                >
                                    {value?.options?.map((option) => (
                                        <MenuItem key={option?.attributes?.title} value={option?.attributes?.title}>
                                            {option?.attributes?.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        )
                    }
                    if (value?.type === "Text") {
                        return (
                            <Grid item={true} key={value.title + index} xs={12} sm={6} md={6} lg={3} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", p: 1, display: "flex", justifyContent: "flex-start", }}>
                                <TextField
                                    type={"text"}
                                    id={value.title}
                                    htmlFor={value.title}
                                    required={value.required}
                                    label={value.title.replaceAll('Details Action', 'Notes')}
                                    multiline={true}
                                    value={value.value}
                                    sx={{ width: screenWidth }}
                                    onChange={(e) => handleIndividualRiskChanges(e.target.value, pIndex, index)}
                                />
                            </Grid>
                        )
                    }
                    if (value?.type === "rating") {
                        return (
                            <Grid item={true} key={value.title + index} xs={12} sm={4} md={4} lg={4} sx={{ mx: 2, display: "flex", justifyContent: "flex-start", flexDirection: 'column' }}>
                                <Grid sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", }}>
                                    <Typography sx={{ fontFamily: "Roboto-Regular", }}>
                                        {value.title}
                                    </Typography>
                                </Grid>
                                <Grid item={true} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", }}>
                                    <Rating sx={{ fontSize: "2.5rem" }} id="number-feedback" value={Number(value.value)} classid='rating-size' onChange={(e) => handleIndividualRiskChanges(e.target.value, pIndex, index)} />
                                </Grid>
                            </Grid>
                        )
                    }
                })}

            </Grid>
        </React.Fragment>
    );
}

const RowSP = (props) => {
    const { row, handleIndividualRiskChanges, pIndex } = props;

    return (
        <Grid item={true} xs={11} md={5} lg={5.8} sx={{ mx: 1 }}>
            <Grid item={true} sx={{ m: 1, display: 'flex', flexWrap: 'wrap', }}>
                <span style={{ fontSize: "1.3em", fontFamily: "Roboto-Bold", }}>
                    {row?.title}
                </span>
            </Grid>
            <Grid item={true} xs={12} md={12} lg={12} >
                <Grid item={true} xs={12} md={12} lg={12} sx={{ m: 1, }} >
                    <DraftEditor data={row?.order_unord_json} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export const EditDynamicInputs = ({
    can_create_multiple,
    inputsList,
    questionList,
    categoryValue,
    module,
    duplicateInputField,
    subCategories,
}) => {
    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 5.2
        }
        if (window.screen.width > 600 && window.screen.width < 1600) {
            return window.screen.width / 4
        } else {
            return window.screen.width / 1.3
        }
    }
    const dispatch = useDispatch()
    const [screenWidth, setScreenWidth] = useState(width);
    const [initialFieldStructure, setInitialFieldStructure] = useState(null);
    const [multipleFields, setMultipleFields] = useState([]);
    const [dynamiInputs, setDynamicInputList] = useState(null);
    const [dynamicQuestions, setDynamicQuestions] = useState(null);
    const [loadingResponse, setLoadingResponse] = useState(false);
    const [supportCategories, setSupportCategories] = useState(null);

    const [snacks, setSnack] = useState({
        open: false,
        Transition: SlideTransition,
        vertical: 'top',
        horizontal: 'right',
    });

    const [errorSnackMessage, setErrorSnackMessage] = useState({
        title: "",
        message: "",
        severity: "",
        variant: "filled"
    });

    // // // // ======================================================================================
    const _saving_list = useSelector((state) => state._client_onboarding_module.client_onboard_data_list);
    const _client_id = useSelector((state) => state._client_onboarding_module.client_id);
    const _history = useSelector((state) => state._history.client_edit_id);

    // /// /// ==========================================================================================================
    const [apiCreateClient, loading_client, error_client] = CreateClientDetail();
    const [apiCreateClientLovs, loading_lovs, error_lovs] = CreateClientAnswerLovsReference();
    const [apiCreateContactDetail, loading_contact, error_contact] = CreateContactDetailForClient();
    const [apiCreateEmergencyContact, loading_emergency, error_emergency] = CreateEmergencyContactDetailForClient();
    const [apiCreateClientNotes, loading_adm, error_adm] = CreateClientNote()
    const [apiCreateServiceInfo, loading_service, error_service] = CreateServiceInformation();
    const [apiCreatePreferenceForStaff, loading_preference, error_preference] = CreatePreferenceForStaffWorker();
    const [apiCreateClientGoal, loading_goal, error_goal] = CreateClientGoal();
    const [apiCreateCheckList, loading_check, error_check] = CreateCheckListAlertsNotification();
    const [apiCreatePlanReview, loading_review, error_review] = CreatePlanReview()
    const [apiCreatePlanSupportCategory, loading_budget, error_budget] = CreatePlanSupportCategory()
    const [apiUpdateClientDetail, loading_update, error_update] = UpdateClientDetail();
    const [apiCreateMedications, loading_medi, error_medi] = CreateMedicationsForClient();
    const [apiCreateMedicationHistory, loading_history, error_history] = CreateOnboardMedicalHistoryDetail();
    const [apiCreateReferringDoctor, loading_doctor, error_doctor] = CreateReferringDoctorInformation();
    const [apiClientQuestionAnswer, loading_question, error_question] = CreateClientQuestionAnswerRef();
    const [apiUpdateClientAnswerLovsReferenceById, load_lov_up, error_lov_up] = UpdateClientAnswerLovsReferenceById();
    const [apiUpdateContactDetail, load_cont_up, error_cont_up] = UpdateContactDetail();
    const [apiUpdateOnboardMedicalHistoryDetail] = UpdateOnboardMedicalHistoryDetail();
    const [apiUpdateUpdateClientQuestionAnswerRef, load_questions] = UpdateUpdateClientQuestionAnswerRef();
    const [apiUpdateMedicalInformation, load_medical, error_medical] = UpdateMedicalInformation();
    const [apiUpdateEmergencyContactDetail, load_emr_up, error_emr_up] = UpdateEmergencyContactDetail();
    const [apiUpdateAdministrativeNote, load_note_up, error_note_up] = UpdateAdministrativeNote();
    const [apiUpdateServiceInformation, load_serv_up, error_serv_up] = UpdateServiceInformation();
    const [apiUpdatePreferenceForStaffWorker, load_pre_up, error_pre_up] = UpdatePreferenceForStaffWorker();
    const [apiUpdateClientGoal, load_goal_up, error_goal_up] = UpdateClientGoal();
    const [apiUpdateCheckListAlertsNotification, load_alert_up, error_alert_up] = UpdateCheckListAlertsNotification();
    const [apiUpdatePlanReview, load_plan_up, error_plan_up] = UpdatePlanReview();
    const [apiUpdatePlanSupportCategory, load_support_up, error_support_up] = UpdatePlanSupportCategory();
    const [apiUpdateReferringDoctor] = UpdatReferringDoctorInformation();

    // // // ============== client edit ======================================================================
    const [editClientDetail, setEditClientDetail] = useState(null)
    const [lovsAnswerData, setLovsAnswerData] = useState(null)
    const [subCategoryHistory, setsubCategoryHistory] = useState(null)
    const [getClientById, loading, error] = ClientDetailsById();
    const [getClientAnswerLovsReferences, loading_l, error_l] = ClientAnswerLovsReferences();
    const [getContactInfoById, loading_c, error_c] = ContactInfoById();
    const [getEmergencyContactDetailsByFilter, loading_e, error_e] = EmergencyContactDetailsByFilter();
    const [getAdministrativeNotesByFilter, loading_n, error_n] = AdministrativeNotesByFilter();
    const [getServiceInformations, loading_s, error_s] = ServiceInformations();
    const [getPreferenceForStaffWorkers, loading_p, error_p] = PreferenceForStaffWorkers();
    const [getClientGoalsByFilter, loading_g, error_g] = ClientGoalsByFilter();
    const [getCheckListAlertsNotificationsByFilter, loading_a, error_a] = CheckListAlertsNotificationsByFilter();
    const [getPlanReviewByFilter, loading_r, error_r] = PlanReviewByFilter();
    const [getMedicalInformationById] = MedicalInformationById();
    const [getOnboardMedicalhistoryDetailById] = OnboardMedicalHistoryDetailById();
    const [getReferringDoctorInformationById] = ReferringDoctorInformationById();
    const [getClientQuestionAnswerRefById] = ClientQuestionAnswerRefById();

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const getClientDetailsById = async (id) => {
        try {
            if (categoryValue === "Basic Information") {
                const respons = await getClientById({
                    variables: {
                        "id": id
                    }
                })
                if (respons?.data) {
                    const data = respons.data.clientDetail.data;
                    const respons_lov = await getClientAnswerLovsReferences({
                        variables: {
                            "filter": {
                                "client_detail_id": {
                                    "id": {
                                        "eq": id
                                    }
                                }
                            }
                        }
                    })
                    if (respons_lov?.data) {
                        const lovs = respons_lov.data.clientAnswerLovsReferences.data;
                        setEditClientDetail(data);
                        setLovsAnswerData(lovs);
                    }
                }
            }
            if (categoryValue === "Contact Information") {
                const respons = await getContactInfoById({
                    variables: {
                        "filter": {
                            "client_detail_id": {
                                "id": {
                                    "eq": id
                                }
                            }
                        }
                    }
                })
                if (respons?.data) {
                    const data = respons.data.contactDetails.data[0];
                    const respons_lov = await getClientAnswerLovsReferences({
                        variables: {
                            "filter": {
                                "client_detail_id": {
                                    "id": {
                                        "eq": id
                                    }
                                }
                            }
                        }
                    })
                    if (respons_lov?.data) {
                        const lovs = respons_lov.data.clientAnswerLovsReferences.data;
                        setEditClientDetail(data);
                        setLovsAnswerData(lovs);
                    }
                }
            }
            if (categoryValue === "Emergency Contact Information") {
                const respons = await getEmergencyContactDetailsByFilter({
                    variables: {
                        "filter": {
                            "client_detail_id": {
                                "id": {
                                    "eq": id
                                }
                            }
                        }
                    }
                })
                if (respons?.data) {
                    const data = respons.data.emergencyContactDetails.data[0];
                    setEditClientDetail(data)
                }
            }
            if (categoryValue === "Notes") {
                const respons = await getAdministrativeNotesByFilter({
                    variables: {
                        "filter": {
                            "client_detail_id": {
                                "id": {
                                    "eq": id
                                }
                            }
                        }
                    }
                })
                if (respons?.data) {
                    const data = respons.data.clientNotes.data[0];
                    setEditClientDetail(data)
                }
            }
            if (categoryValue === "Service Information") {
                const respons = await getServiceInformations({
                    variables: {
                        "filter": {
                            "client_detail_id": {
                                "id": {
                                    "eq": id
                                }
                            }
                        }
                    }
                })
                if (respons?.data) {
                    const data = respons.data.serviceInformations.data[0];
                    setEditClientDetail(data)
                }
            }
            if (categoryValue === "Support Worker Preferences") {
                const respons = await getPreferenceForStaffWorkers({
                    variables: {
                        "filter": {
                            "client_detail_id": {
                                "id": {
                                    "eq": id
                                }
                            }
                        }
                    }
                })
                if (respons?.data) {
                    const data = respons.data.preferenceForStaffWorkers.data;
                    const respons_lov = await getClientAnswerLovsReferences({
                        variables: {
                            "filter": {
                                "client_detail_id": {
                                    "id": {
                                        "eq": id
                                    }
                                }
                            }
                        }
                    })
                    if (respons_lov?.data) {
                        const lovs = respons_lov.data.clientAnswerLovsReferences.data;
                        setEditClientDetail(data);
                        setLovsAnswerData(lovs);
                    }
                }
            }
            if (categoryValue === "Client Goals") {
                const respons = await getClientGoalsByFilter({
                    variables: {
                        "filter": {
                            "client_detail_id": {
                                "id": {
                                    "eq": id
                                }
                            }
                        }
                    }
                })
                if (respons?.data) {
                    const data = respons.data.clientGoals.data[0];
                    setEditClientDetail(data)
                }
            }
            if (categoryValue === "Check List (Alerts / Notifications)") {
                const respons = await getCheckListAlertsNotificationsByFilter({
                    variables: {
                        "filter": {
                            "client_detail_id": {
                                "id": {
                                    "eq": id
                                }
                            }
                        }
                    }
                })
                if (respons?.data) {
                    const data = respons.data.checkListAlertsNotifications.data;
                    setEditClientDetail(data)
                }
            }
            if (categoryValue === "Plan Review") {
                const respons = await getPlanReviewByFilter({
                    variables: {
                        "filter": {
                            "client_detail_id": {
                                "id": {
                                    "eq": id
                                }
                            }
                        },
                        "filterS": {
                            "client_detail_id": {
                                "id": {
                                    "eq": id
                                }
                            }
                        }
                    }
                })
                if (respons?.data) {
                    const data = respons.data.planReviews.data[0];
                    const subdata = respons.data.planSupportCategories.data;
                    setEditClientDetail(data)
                    setsubCategoryHistory(subdata)
                }
            }
            if (categoryValue === "Medication") {
                const respons = await getMedicalInformationById({
                    variables: {
                        "filter": {
                            "client_detail_id": {
                                "id": {
                                    "eq": id
                                }
                            }
                        }
                    }
                })
                if (respons?.data) {
                    const data = respons.data.medicalInformations.data;
                    const respons_lov = await getClientAnswerLovsReferences({
                        variables: {
                            "filter": {
                                "client_detail_id": {
                                    "id": {
                                        "eq": id
                                    }
                                }
                            }
                        }
                    })
                    if (respons_lov?.data) {
                        const lovs = respons_lov.data.clientAnswerLovsReferences.data;
                        setEditClientDetail(data);
                        setLovsAnswerData(lovs);
                    }
                }
            }
            if (categoryValue === "Medical History Details") {
                const respons = await getOnboardMedicalhistoryDetailById({
                    variables: {
                        "filter": {
                            "client_detail_id": {
                                "id": {
                                    "eq": id
                                }
                            }
                        }
                    }
                })
                if (respons?.data) {
                    const data = respons.data.onboardMedicalHistoryDetails.data;
                    const respons_lov = await getClientAnswerLovsReferences({
                        variables: {
                            "filter": {
                                "client_detail_id": {
                                    "id": {
                                        "eq": id
                                    }
                                }
                            }
                        }
                    })
                    if (respons_lov?.data) {
                        const lovs = respons_lov.data.clientAnswerLovsReferences.data;
                        setEditClientDetail(data)
                        setLovsAnswerData(lovs);
                    }
                }
            }
            if (categoryValue === "Referring Doctor Information") {
                const respons = await getReferringDoctorInformationById({
                    variables: {
                        "filter": {
                            "client_detail_id": {
                                "id": {
                                    "eq": id
                                }
                            }
                        }
                    }
                })
                if (respons?.data) {
                    const data = respons?.data?.referringDoctorInformations?.data;
                    setEditClientDetail(data)
                }
            }
            if ((module === "Individual Risk Assessment" || module === "Home Risk Assessment") && categoryValue !== "Type of Residence") {
                const respons = await getClientQuestionAnswerRefById({
                    variables: {
                        "filter": {
                            "client_detail_id": {
                                "id": {
                                    "eq": id
                                }
                            }
                        }
                    }
                });
                if (respons?.data) {
                    const data = respons?.data?.clientQuestionAnswerRefs?.data;
                    setEditClientDetail(data)
                }
            }
            if (categoryValue === "Type of Residence") {
                const respons_lov = await getClientAnswerLovsReferences({
                    variables: {
                        "filter": {
                            "client_detail_id": {
                                "id": {
                                    "eq": id
                                }
                            }
                        }
                    }
                })
                if (respons_lov?.data) {
                    const lovs = respons_lov.data.clientAnswerLovsReferences.data;
                    setLovsAnswerData(lovs);
                }
            }

        } catch (error) {
            console.log("error", error)
        }
    }

    const getPlanReviews = async (id) => {
        try {

            if (categoryValue === "Plan Review") {
                const respons = await getPlanReviewByFilter({
                    variables: {
                        "filter": {
                            "client_detail_id": {
                                "id": {
                                    "eq": id
                                }
                            }
                        },
                        "filterS": {
                            "client_detail_id": {
                                "id": {
                                    "eq": id
                                }
                            }
                        }
                    }
                })
                if (respons?.data) {
                    const data = respons.data.planReviews.data[0];
                    const subdata = respons.data.planSupportCategories.data;
                    setEditClientDetail(data)
                    setsubCategoryHistory(subdata)
                }
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    const isDataAvailable = (data) => {
        if (data === null || data === undefined) {
            return false; // Data is null or undefined
        }

        if (Array.isArray(data)) {
            return data.length > 0; // Check if array has elements
        }

        if (typeof data === 'object') {
            return Object.keys(data).length > 0; // Check if object has properties
        }

        if (typeof data === 'string') {
            return data.trim().length > 0; // Check if string is not empty
        }

        return Boolean(data); // For other types (e.g., numbers, booleans)
    }

    useEffect(() => {
        if (_client_id) {
            getPlanReviews(_client_id?.id)
        }
    }, [_client_id])

    useEffect(() => {
        if (_history) {
            getClientDetailsById(_history)
        }
    }, [_history])

    useEffect(() => {

        if (isDataAvailable(editClientDetail)) {
            if (module === "Home Risk Assessment" && categoryValue !== "Type of Residence") {
                for (const key in editClientDetail) {
                    const copyOfDynamicQuestions = [...dynamicQuestions];
                    for (const index in copyOfDynamicQuestions) {
                        if (editClientDetail[key]?.attributes?.question_title === copyOfDynamicQuestions[index]?.title) {
                            for (const childIndex in copyOfDynamicQuestions[index]?.childOptions) {
                                const matchTitle = copyOfDynamicQuestions[index]?.childOptions[childIndex]?.title?.toLowerCase().split('/ ').join('')?.split(' ').join('_');

                                if (Object.keys(editClientDetail[key]?.attributes?.answer_type[0]).includes(matchTitle)) {
                                    copyOfDynamicQuestions[index].childOptions[childIndex].value = editClientDetail[key].attributes.answer_type[0][matchTitle];
                                }

                                if (copyOfDynamicQuestions[index].childOptions[childIndex]?.type === "Select") {
                                    if (copyOfDynamicQuestions[index] && copyOfDynamicQuestions[index].childOptions[childIndex]) {
                                        copyOfDynamicQuestions[index].childOptions[childIndex].value = editClientDetail[key]?.attributes?.answer_type[0].selected_options.data[0]?.attributes.title;
                                    }
                                }
                            }
                        }
                        setDynamicQuestions(copyOfDynamicQuestions)
                    }
                }
            }
            if (Array.isArray(editClientDetail) && module === "Individual Risk Assessment") {
                for (const key in editClientDetail) {
                    const copyOfDynamicQuestions = [...dynamicQuestions];
                    for (const index in copyOfDynamicQuestions) {
                        if (editClientDetail[key]?.attributes?.question_title === copyOfDynamicQuestions[index]?.title) {
                            for (const childIndex in copyOfDynamicQuestions[index]?.childOptions) {
                                const matchTitle = copyOfDynamicQuestions[index]?.childOptions[childIndex]?.title?.toLowerCase().split('/ ').join('')?.split(' ').join('_');

                                if (Object.keys(editClientDetail[key]?.attributes?.answer_type[0]).includes(matchTitle)) {
                                    copyOfDynamicQuestions[index].childOptions[childIndex].value = editClientDetail[key].attributes.answer_type[0][matchTitle];
                                }

                                if (Object.keys(editClientDetail[key]?.attributes?.answer_type[0]?.risk_options).includes(matchTitle)) {
                                    if (copyOfDynamicQuestions[index] && copyOfDynamicQuestions[index].childOptions[childIndex]) {
                                        copyOfDynamicQuestions[index].childOptions[childIndex].value = editClientDetail[key].attributes.answer_type[0].risk_options[matchTitle].data[0].attributes.title;
                                    }
                                }
                            }
                        }
                    }
                    setDynamicQuestions(copyOfDynamicQuestions)
                }
            }

            if (!Array.isArray(editClientDetail) && !can_create_multiple && categoryValue !== 'Medical History Details') {
                const detail = editClientDetail?.attributes;

                const copy = [...dynamiInputs];
                for (const key in copy) {
                    const matchTitle = copy[key].title?.toLowerCase().split('/ ').join('')?.split(' ').join('_');
                    if (detail && detail[matchTitle] && !copy[key].options) {
                        copy[key].value = detail[matchTitle];
                        copy[key].table_id = editClientDetail?.id;
                    }
                    if (copy[key].options) {
                        const match = lovsAnswerData?.find((e) => e.attributes.input_name === matchTitle);
                        if (match) {
                            copy[key].value = match?.attributes.selected_option_value;
                            copy[key].table_id = match?.id
                        }
                    }
                }

                setDynamicInputList(copy)
                if (subCategoryHistory) {
                    const copy = [...supportCategories];
                    for (const key in copy) {
                        const main = copy[key];
                        const child = main.data;
                        for (const key2 in child) {
                            for (const data of subCategoryHistory) {
                                const obj = data.attributes;
                                const title = obj?.dynamic_support_category_id?.data?.attributes?.title;
                                if (title === child[key2]?.title) {
                                    child[key2].inputListArray[0].value = obj.budget;
                                    child[key2].inputListArray[1].value = obj.date;
                                    child[key2].table_id = data.id;
                                }
                            }
                        }
                    }
                    setSupportCategories(copy)
                }
            }

            if (Array.isArray(editClientDetail) && can_create_multiple) {

                // Array to hold updated fields grouped by each entity in editClientDetail
                const updatedFieldGroups = [];

                for (const item of editClientDetail) {
                    const updatedFields = initialFieldStructure.map(field => {
                        // Convert the title to match the attribute keys in editClientDetail
                        const titleKey = field.title.replaceAll(' ', '_').toLowerCase();

                        // Check if there’s a matching attribute key in the current editClientDetail item
                        for (const index in lovsAnswerData) {
                            if (lovsAnswerData[index]?.attributes?.module_Id === Number(item?.id) && titleKey === lovsAnswerData[index]?.attributes?.input_name) {
                                return {
                                    ...field,
                                    value: lovsAnswerData[index]?.attributes?.selected_option_value,
                                    table_id: lovsAnswerData[index]?.id
                                };
                            }
                        }

                        if (item.attributes[titleKey] !== undefined) {
                            // Update the value with the matching attribute value
                            return { ...field, value: item.attributes[titleKey], table_id: item.id };
                        } else {
                            // Keep the field unchanged if no matching key is found
                            return { ...field, table_id: item.id };
                        }
                    });

                    // Add the updatedFields array to the outer array
                    updatedFieldGroups.push(updatedFields);
                }

                setMultipleFields(updatedFieldGroups);
            }

            if (Array.isArray(editClientDetail) && !can_create_multiple && dynamicQuestions) {
                for (const index in editClientDetail) {

                    const detail = editClientDetail[index]?.attributes;
                    const copy = [...dynamicQuestions];

                    for (const key in copy) {
                        const matchTitle = copy[key].title?.toLowerCase().split('/ ').join('')?.split(' ').join('_');

                        if (matchTitle === detail?.question_title?.toLowerCase().split('/ ').join('')?.split(' ').join('_')) {
                            copy[key].value = detail.answer_value;
                            copy[key].table_id = editClientDetail[index]?.id;
                        }
                    }
                    setDynamicQuestions(copy)

                }
            }
        }
    }, [editClientDetail])

    useEffect(() => {
        const refinedSupportCategories = subCategories?.map(subCategory => {
            return (
                {
                    mainTitle: subCategory.attributes.title,
                    data: subCategory?.attributes?.d_s_c_id.data.map(supportCategory => {
                        return (
                            {
                                id: supportCategory.id,
                                title: supportCategory?.attributes?.title,
                                inputListArray: refinePayload(supportCategory?.attributes.design_frontend_input_names.data)
                            }
                        )
                    })
                }

            )
        });

        setSupportCategories(refinedSupportCategories)
    }, [])

    const refinePayload = (inputsList) => {

        const copy = [];
        const filteredDuplicateInputList = duplicateInputField.map(inputVal => (inputVal?.attributes?.title));
        let required = null;
        let active = null;
        let multiline = null;
        let disabled = null;
        for (const key in inputsList) {
            if (filteredDuplicateInputList.includes(inputsList[key]?.attributes?.input_name)) {
                const filteredInputList = duplicateInputField?.filter(inputVal => inputVal?.attributes?.title === inputsList[key]?.attributes?.input_name)[0];

                disabled = filteredInputList.attributes?.disabled;
                required = filteredInputList.attributes?.required;
                active = filteredInputList.attributes?.active;
                multiline = filteredInputList.attributes?.multiline;
            }
            else {
                disabled = inputsList[key].attributes?.disabled;
                required = inputsList[key].attributes?.required;
                active = inputsList[key].attributes?.active;
                multiline = inputsList[key].attributes?.multiline;
            }

            const type = inputsList[key].attributes?.design_frontend_input_type?.data?.attributes?.title;
            const label = inputsList[key].attributes?.input_name;
            const have_child_option = inputsList[key].attributes?.have_child_option;
            const drop_down_options = inputsList[key].attributes?.drop_down_options?.data;

            const placeholder = inputsList[key].attributes?.placeholder;

            if (active) {
                let obj = {
                    id: key,
                    title: label,
                    value: type?.toLowerCase() === "date" ? moment().format("YYYY-MM-DD") : type?.toLowerCase() === "time" ? moment().format("HH:MM") : "",
                    width: screenWidth,
                    type: type,
                    required: required,
                    multiline: multiline,
                    error: null,
                    category: "text",
                    disabled: disabled,
                    placeholder: placeholder
                }
                if (have_child_option) {
                    obj.options = drop_down_options;
                    obj.value = drop_down_options[0].attributes.title;
                }
                copy.push(obj)
            }
        }
        return copy
    }

    useEffect(() => {
        if (inputsList?.length) {
            const copy = [];
            const filteredDuplicateInputList = duplicateInputField.map(inputVal => (inputVal?.attributes?.title));
            let required = null;
            let active = null;
            let multiline = null;
            let disabled = null;

            for (const key in inputsList) {
                if (filteredDuplicateInputList.includes(inputsList[key]?.attributes?.input_name)) {
                    const filteredInputList = duplicateInputField?.filter(inputVal => inputVal?.attributes?.title === inputsList[key]?.attributes?.input_name)[0];

                    disabled = filteredInputList.attributes?.disabled;
                    required = filteredInputList.attributes?.required;
                    active = filteredInputList.attributes?.active;
                    multiline = filteredInputList.attributes?.multiline;
                }
                else {
                    disabled = inputsList[key].attributes?.disabled;
                    required = inputsList[key].attributes?.required;
                    active = inputsList[key].attributes?.active;
                    multiline = inputsList[key].attributes?.multiline;
                }

                const type = inputsList[key].attributes?.design_frontend_input_type?.data?.attributes?.title;
                const label = inputsList[key].attributes?.input_name;
                const have_child_option = inputsList[key].attributes?.have_child_option;
                const drop_down_options = inputsList[key].attributes?.drop_down_options?.data;

                const placeholder = inputsList[key].attributes?.placeholder;

                if (active) {
                    let obj = {
                        title: label,
                        value: type?.toLowerCase() === "date" ? moment().format("YYYY-MM-DD") : type?.toLowerCase() === "time" ? moment().format("HH:MM") : "",
                        width: screenWidth,
                        type: type,
                        required: required,
                        multiline: multiline,
                        error: null,
                        category: "text",
                        disabled: disabled,
                        placeholder: placeholder
                    }
                    if (have_child_option) {
                        obj.options = drop_down_options;
                        obj.value = drop_down_options[0].attributes.title;
                    }

                    copy.push(obj)
                }
            }
            if (can_create_multiple) {
                setInitialFieldStructure(_.cloneDeep(copy));
                setMultipleFields([copy])
            }
            setDynamicInputList(copy);
            handleStateValueWithDynamicForm(copy);
        }
        if (questionList?.length) {
            const copy = [];
            for (const key in questionList) {
                let object = {}
                object.title = questionList[key]?.attributes?.question;
                object.active = questionList[key]?.attributes?.active;
                object.width = screenWidth;
                object.multiline = false;
                object.error = null;
                object.category = "questions";
                const answer_type = questionList[key]?.attributes?.answer_type[0];
                if (answer_type?.__typename?.includes("Text")) {
                    object.type = "Text";
                    object.value = answer_type?.details_value || "";
                    object.required = false;
                    object.multiline = object.title.includes("Comments") ? true : false
                }
                if (answer_type?.__typename?.includes("Single")) {
                    object.type = "Select";
                    object.value = "No";
                    object.required = true;
                    object.options = answer_type?.drop_down_option_lovs?.data
                }
                if (answer_type?.__typename?.includes("IndividualRisk")) {
                    object.type = "IndividualRisk";
                    object.childOptions = [
                        {
                            type: "Select",
                            title: "Risk To Individual",
                            value: answer_type?.risk_options?.risk_to_individual_value || "Green",
                            options: answer_type?.risk_options?.risk_to_individual?.data,
                            required: true,
                        },
                        {
                            type: "Select",
                            title: "Risk To Staff",
                            value: answer_type?.risk_options?.risk_to_staff_value || "Green",
                            options: answer_type?.risk_options?.risk_to_staff?.data,
                            required: true,
                        },
                        {
                            type: "Select",
                            title: "Risk To Other",
                            value: answer_type?.risk_options?.risk_to_other_value || "Green",
                            options: answer_type?.risk_options?.risk_to_other?.data,
                            required: true,
                        },
                        {
                            type: "Text",
                            title: "Management Guides",
                            value: answer_type?.management_guides_value || "",
                            required: false,
                            multiline: true
                        },
                        {
                            type: "Text",
                            title: "Source Of Information",
                            value: answer_type?.source_of_information_value || "",
                            required: false,
                            multiline: true
                        },
                    ]
                }
                if (answer_type?.__typename?.includes("HomeRisk")) {
                    object.type = "HomeRisk";
                    object.childOptions = [
                        {
                            type: "Select",
                            title: "Selected Option",
                            value: answer_type?.selected_option_value || "No",
                            options: answer_type?.selected_options?.data,
                            required: true,
                        },
                        {
                            type: "Text",
                            title: "Details Action",
                            value: answer_type?.details_action_value || "",
                            required: false,
                            multiline: true
                        },
                        {
                            type: "rating",
                            title: "Rating",
                            value: answer_type?.rating || "0",
                            required: false,
                            multiline: true
                        },
                    ]
                }
                if (answer_type?.__typename?.includes("AnswersList")) {
                    object.type = "AnswersList";
                    object.order_unord_json = answer_type?.order_unord_json
                }
                copy.push(object)
            }
            setDynamicQuestions(copy)
            handleStateValueWithDynamicQuestions(copy);
        }
    }, [])

    const handleCloseSnacks = () => {
        setSnack({
            ...snacks,
            open: false,
        });
    };

    const handleSnacksApper = (title, message, severity) => {
        setSnack({
            ...snacks,
            open: true,
            autoHideDuration: 5000
        });

        setErrorSnackMessage({
            ...errorSnackMessage,
            title,
            message,
            severity
        })
    }

    const calculateDOB = (dateOfBirth) => {
        const today = moment();
        const birthDate = moment(dateOfBirth, "YYYY-MM-DD"); // Parses the date string in the format
        const age = today.diff(birthDate, "years");
        return age;
    }

    const handleChangeValueInputs = (eventOrValue, index) => {
        try {
            const copy = [...dynamiInputs];
            const isEvent = eventOrValue && eventOrValue.target;
            const value = isEvent ? eventOrValue.target.value : eventOrValue;

            if (copy[index].title === 'Date of Birth') {
                const DOB = calculateDOB(value);
                copy[index + 1].value = DOB;
            }
            copy[index].value = value;
            if (copy[index].error !== null) {
                copy[index].error = null;
            }
            setDynamicInputList(copy);
        } catch (error) {
            console.log("error", error);
        }
    }

    const handleCheckbox = (e, index) => {
        const copy = [...dynamiInputs];
        copy[index].value = e.target.checked;
        setDynamicInputList(copy)
    }

    const dispatchDataManual = async (data) => {
        try {
            const copy_module = module?.toLowerCase()?.split(' ').join('_');
            const copy_category = categoryValue?.toLowerCase().split('/ ').join('')?.split(' ').join('_');
            const copy_saving_list = { ..._saving_list };
            const additionText = categoryValue === "Admission Information" && data[0].category === "questions" ? "_question" : ""
            copy_saving_list[copy_module] = {
                ...copy_saving_list[copy_module],
                [copy_category + additionText]: data,
            };
            if (_history) {
                updateDataWithApiByCategoryManual(data, copy_saving_list)
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const handleStateValueWithDynamicForm = (data) => {
        try {
            const copy = [...data];
            const copy_module = module?.toLowerCase()?.split(' ').join('_');
            const copy_category = categoryValue?.toLowerCase().split('/ ').join('')?.split(' ').join('_');
            const copy_saving_list = { ..._saving_list };
            const _dataModule = copy_saving_list[copy_module];
            if (_dataModule) {
                const _dataList = _dataModule[copy_category]
                if (_dataList) {
                    for (const key in copy) {
                        if (copy[key]?.category === "text") {
                            copy[key].value = _dataList[key]?.value || "";
                        }
                    }
                    setDynamicInputList(copy)
                }
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const handleStateValueWithDynamicQuestions = (data) => {
        try {
            const copy = [...data];
            const copy_module = module?.toLowerCase()?.split(' ').join('_');
            const copy_category = categoryValue?.toLowerCase().split('/ ').join('')?.split(' ').join('_');
            const copy_saving_list = { ..._saving_list };
            const _dataModule = copy_saving_list[copy_module];
            const additionText = categoryValue === "Admission Information" && data[0].category === "questions" ? "_question" : ""
            if (_dataModule) {
                const _dataList = _dataModule[copy_category + additionText]
                if (_dataList) {
                    for (const key in copy) {
                        if (copy[key]?.type === "IndividualRisk" || copy[key]?.type === "HomeRisk") {
                            const childOpt = copy[key]?.childOptions
                            for (const keys in childOpt) {
                                childOpt[keys].value = _dataList[key].childOptions[keys].value
                            }
                        }
                        if (copy[key]?.category === "questions") {
                            copy[key].value = _dataList[key]?.value || "";
                        }
                    }
                    setDynamicQuestions(copy)
                }
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const handleIndividualRiskChanges = (value, pIndex, index) => {
        try {
            const copy = [...dynamicQuestions];
            copy[pIndex].childOptions[index].value = value;
            setDynamicQuestions(copy);
        } catch (error) {
            console.log("error", error)
        }
    }

    const convertDataIntoSimplifyObjects = (data) => {
        let inputs = {};
        let selectedOptions = {};
        for (const key in data) {
            if (!data[key]?.options) {
                inputs[data[key].title.toLowerCase().split('/ ').join('')?.split(' ').join('_')] = data[key].value;
            }
            if (data[key]?.options) {
                selectedOptions[data[key].title.toLowerCase().split('/ ').join('')?.split(' ').join('_')] = {
                    title: data[key].value,
                    id: data[key]?.options.find((e) => e.attributes.title === data[key].value)?.id,
                    table_id: data[key]?.table_id
                }
            }
        }
        return { inputs, selectedOptions }
    }

    const savingDataAsPerCategory = () => {
        if (dynamiInputs && !can_create_multiple) {
            const copy = [...dynamiInputs];
            const todayDate = new Date().getMonth() + "/" + new Date().getDate() + "/" + new Date().getFullYear();
            let errorStatus = false;
            for (const key in copy) {
                if (
                    (copy[key].type.toLowerCase() === "email" && copy[key].required && !validateEmail(copy[key].value)) ||
                    (copy[key].type.toLowerCase() === "text" && copy[key].required && (copy[key].value === "" || copy[key].value === " ")) ||
                    (copy[key].type.toLowerCase() === "date" && copy[key].required && (copy[key].value === "" || copy[key].value === todayDate)) ||
                    (copy[key].type.toLowerCase() === "number" && copy[key].required && (copy[key].value === "" || copy[key].value < 10)) ||
                    (copy[key].type.toLowerCase() === "time" && copy[key].required && (copy[key].value === ""))
                ) {
                    copy[key].error = "Required";
                    errorStatus = true;
                    setDynamicInputList(copy);

                }
            }

            if (errorStatus === false) {
                const fCopy = _.cloneDeep(dynamiInputs);
                dispatchDataManual(fCopy);
            }
        }
        if (multipleFields && can_create_multiple) {
            multipleFields.map(val => {
                const fCopy = _.cloneDeep(val);
                dispatchDataManual(fCopy);
            })
        }
        if (dynamicQuestions) {
            const fCopy = _.cloneDeep(dynamicQuestions);
            dispatchDataManual(fCopy);
        }
    }

    const AddMoreFunction = () => {
        setMultipleFields([...multipleFields, initialFieldStructure]);
    }

    const createResolvers = async (data) => {
        try {
            setLoadingResponse(true)
            for (const array of data) {
                // Insert each array into the database, or process them as needed
                await apiCreateMedicationHistory({
                    variables: {
                        "client_detail_id": _history,
                        "question_title": array.title,
                        "answer_value": array.value
                    }
                })
            }
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    const EditResolvers = async (data) => {
        try {
            setLoadingResponse(true)
            for (const array of data) {
                // Insert each array into the database, or process them as needed
                await apiUpdateOnboardMedicalHistoryDetail({
                    variables: {
                        "id": array.table_id,
                        "data": {
                            client_detail_id: _history,
                            question_title: array.title,
                            answer_value: array.value
                        }

                    }
                })
            }
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }


    const updateDataWithApiByCategoryManual = async (data, copy_saving_list) => {

        try {
            if (categoryValue === "Basic Information" && _history) {
                dispatch(setLoadingState('loading'))
                const simplify = convertDataIntoSimplifyObjects(data);

                const respons = await apiUpdateClientDetail({
                    variables: {
                        "id": _history,
                        "data": simplify.inputs
                    }
                })
                if (respons?.data) {
                    let count = 0;
                    for (const keys in simplify.selectedOptions) {
                        const respons_lov = await apiUpdateClientAnswerLovsReferenceById({
                            variables: {
                                "id": simplify.selectedOptions[keys].table_id,
                                "data": {
                                    selected_option_value: simplify.selectedOptions[keys].title
                                }
                            }
                        })
                        if (respons_lov?.data) {
                            count++
                        }
                    }
                    if (count === Object.keys(simplify.selectedOptions).length) {
                        dispatch(setLoadingState('completed'))
                        dispatch(client_onboard_data_lists(copy_saving_list));
                        handleSnacksApper("Client Onboarding", categoryValue + " has been updated", "success")
                    }
                }
            }
            if (categoryValue === "Contact Information" && _history) {
                dispatch(setLoadingState('loading'))
                const simplify = convertDataIntoSimplifyObjects(data);

                if (editClientDetail?.id) {
                    const respons = await apiUpdateContactDetail({
                        variables: {
                            "id": editClientDetail?.id,
                            "data": simplify.inputs
                        }
                    })
                    if (respons?.data) {
                        let count = 0;
                        for (const keys in simplify.selectedOptions) {
                            const respons_lov = await apiUpdateClientAnswerLovsReferenceById({
                                variables: {
                                    "id": simplify.selectedOptions[keys].table_id,
                                    "data": {
                                        selected_option_value: simplify.selectedOptions[keys].title
                                    }
                                }
                            })
                            if (respons_lov?.data) {
                                count++
                            }
                        }
                        if (count === Object.keys(simplify.selectedOptions).length) {
                            dispatch(setLoadingState('completed'))
                            dispatch(client_onboard_data_lists(copy_saving_list));
                            handleSnacksApper("Client Onboarding", categoryValue + " has been updated.!", "success")
                        }
                    }
                }
                else {
                    simplify.inputs.client_detail_id = _history;
                    simplify.inputs.email = simplify.inputs.email === "" ? "demo@belacorp.com.au" : simplify.inputs.email;

                    const response = await apiCreateContactDetail({
                        variables: simplify.inputs
                    })
                    if (response?.data) {
                        let count = 0;
                        for (const keys in simplify.selectedOptions) {
                            const obj = {
                                client_detail_id: _history,
                                module: module,
                                category: categoryValue,
                                input_name: keys,
                                selected_option_value: simplify.selectedOptions[keys].title
                            }

                            const responseLovs = await apiCreateClientLovs({
                                variables: obj
                            })
                            if (responseLovs?.data) {
                                count++
                            }
                        }

                        if (count === Object.keys(simplify.selectedOptions).length) {
                            dispatch(setLoadingState('completed'))
                            dispatch(client_onboard_data_lists(copy_saving_list));
                            handleSnacksApper("Client Onboarding", categoryValue + " has been created.!", "success")
                        }
                    }
                }
            }
            if (categoryValue === "Emergency Contact Information" && _history) {
                dispatch(setLoadingState('loading'))
                const simplify = convertDataIntoSimplifyObjects(data);

                if (editClientDetail?.id) {
                    const respons = await apiUpdateEmergencyContactDetail({
                        variables: {
                            "id": editClientDetail?.id,
                            "data": simplify.inputs
                        }
                    })
                    if (respons?.data) {
                        dispatch(setLoadingState('completed'))
                        dispatch(client_onboard_data_lists(copy_saving_list));
                        handleSnacksApper("Client Onboarding", categoryValue + " has been updated.!", "success")
                    }
                }
                else {
                    simplify.inputs.client_detail_id = _history;
                    simplify.inputs.email = simplify.inputs.email === "" ? "demo@belacorp.com.au" : simplify.inputs.email;

                    const response = await apiCreateEmergencyContact({
                        variables: simplify.inputs
                    })
                    if (response?.data) {
                        dispatch(setLoadingState('completed'))
                        dispatch(client_onboard_data_lists(copy_saving_list));
                        handleSnacksApper("Client Onboarding", categoryValue + " has been created.!", "success")
                    }
                }
            }
            if (categoryValue === "Notes" && _history) {
                dispatch(setLoadingState('loading'))

                const simplify = convertDataIntoSimplifyObjects(data);
                if (editClientDetail?.id) {
                    const respons = await apiUpdateAdministrativeNote({
                        variables: {
                            "id": editClientDetail?.id,
                            "data": simplify.inputs
                        }
                    })
                    if (respons?.data) {
                        dispatch(setLoadingState('completed'))
                        dispatch(client_onboard_data_lists(copy_saving_list));
                        handleSnacksApper("Client Onboarding", categoryValue + " has been updated.!", "success")
                    }
                }
                else {
                    simplify.inputs.client_detail_id = _history;

                    const response = await apiCreateClientNotes({
                        variables: simplify.inputs
                    })
                    if (response?.data) {
                        dispatch(setLoadingState('completed'))
                        dispatch(client_onboard_data_lists(copy_saving_list));
                        handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                    }
                }
            }
            if (categoryValue === "Service Information" && _history) {
                dispatch(setLoadingState('loading'))
                const simplify = convertDataIntoSimplifyObjects(data);

                simplify.inputs.community_participation = simplify.inputs.community_participation || false;
                simplify.inputs.respite_care = simplify.inputs.respite_care || false;
                simplify.inputs.supported_independent_living = simplify.inputs.supported_independent_living || false;

                if (editClientDetail?.id) {
                    const respons = await apiUpdateServiceInformation({
                        variables: {
                            "id": editClientDetail?.id,
                            "data": simplify.inputs
                        }
                    })
                    if (respons?.data) {
                        dispatch(setLoadingState('completed'))
                        dispatch(client_onboard_data_lists(copy_saving_list));
                        handleSnacksApper("Client Onboarding", categoryValue + " has been updated.!", "success")
                    }
                }
                else {
                    simplify.inputs.client_detail_id = _history;

                    const response = await apiCreateServiceInfo({
                        variables: simplify.inputs
                    })

                    if (response?.data) {
                        dispatch(setLoadingState('completed'))
                        dispatch(client_onboard_data_lists(copy_saving_list));
                        handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                    }
                }
            }
            if (categoryValue === "Support Worker Preferences" && _history) {
                dispatch(setLoadingState('loading'))

                const simplify = convertDataIntoSimplifyObjects(data);

                if (editClientDetail.length !== 0) {
                    if (simplify?.selectedOptions.gender.table_id) {
                        simplify.inputs.client_detail_id = _history;

                        const respons = await apiUpdatePreferenceForStaffWorker({
                            variables: {
                                "id": data[3]?.table_id,
                                "data": simplify.inputs
                            }
                        })
                        if (respons?.data) {
                            let count = 0;
                            for (const keys in simplify.selectedOptions) {
                                const respons_lov = await apiUpdateClientAnswerLovsReferenceById({
                                    variables: {
                                        "id": simplify.selectedOptions[keys].table_id,
                                        "data": {
                                            selected_option_value: simplify.selectedOptions[keys].title
                                        }
                                    }
                                })
                                if (respons_lov?.data) {
                                    count++
                                }
                            }
                            if (count === Object.keys(simplify.selectedOptions).length) {
                                dispatch(setLoadingState('completed'))
                                dispatch(client_onboard_data_lists(copy_saving_list));
                                handleSnacksApper("Client Onboarding", categoryValue + " has been updated.!", "success")
                            }
                        }
                    }
                    else {
                        simplify.inputs.client_detail_id = _history;
                        const response = await apiCreatePreferenceForStaff({
                            variables: simplify.inputs
                        })

                        if (response?.data) {
                            let count = 0;
                            for (const keys in simplify.selectedOptions) {
                                const obj = {
                                    client_detail_id: _history,
                                    module: module,
                                    category: categoryValue,
                                    input_name: keys,
                                    selected_option_value: simplify.selectedOptions[keys].title,
                                    module_Id: Number(response?.data?.createPreferenceForStaffWorker?.data?.id)
                                }

                                const responseLovs = await apiCreateClientLovs({
                                    variables: obj
                                })
                                if (responseLovs?.data) {
                                    count++
                                }
                            }
                            if (count === Object.keys(simplify.selectedOptions).length) {
                                dispatch(setLoadingState('completed'))
                                dispatch(client_onboard_data_lists(copy_saving_list));
                                handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                            }
                        }
                    }
                }
                else {
                    simplify.inputs.client_detail_id = _history;
                    const response = await apiCreatePreferenceForStaff({
                        variables: simplify.inputs
                    })

                    if (response?.data) {
                        let count = 0;
                        for (const keys in simplify.selectedOptions) {
                            const obj = {
                                client_detail_id: _history,
                                module: module,
                                category: categoryValue,
                                input_name: keys,
                                selected_option_value: simplify.selectedOptions[keys].title,
                                module_Id: Number(response?.data?.createPreferenceForStaffWorker?.data?.id)
                            }

                            const responseLovs = await apiCreateClientLovs({
                                variables: obj
                            })
                            if (responseLovs?.data) {
                                count++
                            }
                        }
                        if (count === Object.keys(simplify.selectedOptions).length) {
                            dispatch(setLoadingState('completed'))
                            dispatch(client_onboard_data_lists(copy_saving_list));
                            handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                        }
                    }
                }
            }
            if (categoryValue === "Client Goals" && _history) {
                dispatch(setLoadingState('loading'))
                const simplify = convertDataIntoSimplifyObjects(data);

                if (editClientDetail?.id) {
                    const respons = await apiUpdateClientGoal({
                        variables: {
                            "id": editClientDetail?.id,
                            "data": simplify.inputs
                        }
                    })
                    if (respons?.data) {
                        dispatch(setLoadingState('completed'))
                        dispatch(client_onboard_data_lists(copy_saving_list));
                        handleSnacksApper("Client Onboarding", categoryValue + " has been updated.!", "success")
                    }
                }
                else {
                    simplify.inputs.client_detail_id = _history;

                    const response = await apiCreateClientGoal({
                        variables: simplify.inputs
                    });

                    if (response?.data) {
                        dispatch(setLoadingState('completed'))
                        dispatch(client_onboard_data_lists(copy_saving_list));
                        handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                    }
                }
            }
            if (categoryValue === "Check List (Alerts / Notifications)" && _history) {
                dispatch(setLoadingState('loading'));
                const simplify = convertDataIntoSimplifyObjects(data);

                if (editClientDetail.length !== 0) {
                    if (data[0]?.table_id) {
                        simplify.inputs.client_detail_id = _history;

                        const respons = await apiUpdateCheckListAlertsNotification({
                            variables: {
                                "id": data[0]?.table_id,
                                "data": simplify.inputs
                            }
                        })
                        if (respons?.data) {
                            dispatch(setLoadingState('completed'))
                            dispatch(client_onboard_data_lists(copy_saving_list));
                            handleSnacksApper("Client Onboarding", categoryValue + " has been updated", "success")
                        }
                    }
                    else {
                        simplify.inputs.client_detail_id = _history;
                        const response = await apiCreateCheckList({
                            variables: simplify.inputs
                        })
                        if (response?.data) {
                            dispatch(setLoadingState('completed'))
                            dispatch(client_onboard_data_lists(copy_saving_list));
                            handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                        }
                    }
                } else {
                    simplify.inputs.client_detail_id = _history;
                    const response = await apiCreateCheckList({
                        variables: simplify.inputs
                    })
                    if (response?.data) {
                        dispatch(setLoadingState('completed'))
                        dispatch(client_onboard_data_lists(copy_saving_list));
                        handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                    }
                }
            }
            if (categoryValue === "Plan Review" && _history) {

                const simplify = convertDataIntoSimplifyObjects(data);

                const respons = await apiUpdatePlanReview({
                    variables: {
                        "id": editClientDetail?.id,
                        "data": simplify.inputs
                    }
                })
                if (respons?.data) {
                    for (const keys in supportCategories) {
                        const inputs = supportCategories[keys].data;
                        for (const index in inputs) {
                            const tab_id = inputs[index].table_id;
                            const list = inputs[index].inputListArray;
                            if (tab_id) {
                                await apiUpdatePlanSupportCategory({
                                    variables: {
                                        "id": tab_id,
                                        "data": {
                                            "budget": list[0].value,
                                            "date": list[1].value
                                        }
                                    }
                                })
                            } else {
                                const finalPayload = {
                                    budget: list[0].value,
                                    date: list[1].value,
                                    client_detail_id: _history,
                                    dynamic_support_category_id: inputs[index].id,
                                    plan_review_id: editClientDetail?.id,
                                }
                                await apiCreatePlanSupportCategory({
                                    variables: finalPayload
                                });
                            }
                        }
                    }

                    dispatch(client_onboard_data_lists(copy_saving_list));
                    handleSnacksApper("Client Onboarding", categoryValue + " has been updated", "success");
                }
            }

            if (categoryValue === "Medication" && _history) {
                dispatch(setLoadingState('loading'))
                const simplify = convertDataIntoSimplifyObjects(data);

                simplify.inputs.medication_time = moment(simplify.inputs.medication_time, "HH:mm:ss").format("HH:mm:ss") ?? '18:00:00';
                simplify.inputs.every_x_days_where_x_is = Number(simplify.inputs.every_x_days_where_x_is ?? 0);

                if (editClientDetail.length === 0) {
                    simplify.inputs.client_detail_id = _history;
                    const response = await apiCreateMedications({
                        variables: simplify.inputs
                    })

                    if (response?.data) {
                        let count = 0;
                        for (const keys in simplify.selectedOptions) {
                            const obj = {
                                client_detail_id: _history,
                                module: module,
                                category: categoryValue,
                                input_name: keys,
                                selected_option_value: simplify.selectedOptions[keys].title,
                                module_Id: Number(response?.data?.createMedicalInformation?.data?.id)
                            }

                            const responseLovs = await apiCreateClientLovs({
                                variables: obj
                            })
                            if (responseLovs?.data) {
                                count++
                            }
                        }
                        if (count === Object.keys(simplify.selectedOptions).length) {
                            dispatch(setLoadingState('completed'))
                            dispatch(client_onboard_data_lists(copy_saving_list));
                            handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                        }
                    }
                }
                else {
                    if (simplify?.selectedOptions.occurrence.table_id) {
                        simplify.inputs.client_detail_id = _history;

                        const respons = await apiUpdateMedicalInformation({
                            variables: {
                                "id": data[0]?.table_id,
                                "data": simplify.inputs
                            }
                        })

                        if (respons?.data) {
                            let count = 0;
                            for (const keys in simplify.selectedOptions) {
                                const respons_lov = await apiUpdateClientAnswerLovsReferenceById({
                                    variables: {
                                        "id": simplify.selectedOptions[keys].table_id,
                                        "data": {
                                            selected_option_value: simplify.selectedOptions[keys].title
                                        }
                                    }
                                })
                                if (respons_lov?.data) {
                                    count++
                                }
                            }
                            if (count === Object.keys(simplify.selectedOptions).length) {
                                dispatch(setLoadingState('completed'))
                                dispatch(client_onboard_data_lists(copy_saving_list));
                                handleSnacksApper("Client Onboarding", categoryValue + " has been updated.!", "success")
                            }
                        }
                    }
                    else {
                        simplify.inputs.client_detail_id = _history;
                        const response = await apiCreateMedications({
                            variables: simplify.inputs
                        })

                        if (response?.data) {
                            let count = 0;
                            for (const keys in simplify.selectedOptions) {
                                const obj = {
                                    client_detail_id: _history,
                                    module: module,
                                    category: categoryValue,
                                    input_name: keys,
                                    selected_option_value: simplify.selectedOptions[keys].title,
                                    module_Id: Number(response?.data?.createMedicalInformation?.data?.id)
                                }

                                const responseLovs = await apiCreateClientLovs({
                                    variables: obj
                                })
                                if (responseLovs?.data) {
                                    count++
                                }
                            }
                            if (count === Object.keys(simplify.selectedOptions).length) {
                                dispatch(setLoadingState('completed'))
                                dispatch(client_onboard_data_lists(copy_saving_list));
                                handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                            }
                        }
                    }
                }
            }
            if (categoryValue === "Medical History Details" && _history) {
                if (editClientDetail?.length === 0) {
                    dispatch(setLoadingState('loading'))
                    const response = await createResolvers(data);

                    if (response) {
                        setLoadingResponse(false)
                        dispatch(setLoadingState('completed'))
                        dispatch(client_onboard_data_lists(copy_saving_list));
                        handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                    }
                }
                else {
                    dispatch(setLoadingState('loading'))
                    const response = await EditResolvers(data);

                    if (response) {
                        setLoadingResponse(false)
                        dispatch(setLoadingState('completed'))
                        dispatch(client_onboard_data_lists(copy_saving_list));
                        handleSnacksApper("Client Onboarding", categoryValue + " has been updated", "success")
                    }
                }

            }
            if (categoryValue === "Referring Doctor Information" && _history) {
                if (editClientDetail.length !== 0) {
                    dispatch(setLoadingState('loading'))
                    const simplify = convertDataIntoSimplifyObjects(data);
                    simplify.inputs.client_detail_id = _history;
                    
                    if (!data[0]?.table_id) {
                        const response = await apiCreateReferringDoctor({
                            variables: simplify.inputs
                        });

                        if (response?.data) {
                            dispatch(setLoadingState('completed'))
                            dispatch(client_onboard_data_lists(copy_saving_list));
                            handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                        }
                    }
                    else {
                        const response = await apiUpdateReferringDoctor({
                            variables: {
                                "id": data[0]?.table_id,
                                "data": simplify.inputs
                            }
                        });

                        if (response?.data) {
                            dispatch(setLoadingState('completed'))
                            dispatch(client_onboard_data_lists(copy_saving_list));
                            handleSnacksApper("Client Onboarding", categoryValue + " has been updated", "success")
                        }
                    }
                }
                else {
                    dispatch(setLoadingState('loading'))
                    const simplify = convertDataIntoSimplifyObjects(data);
                    simplify.inputs.client_detail_id = _history;

                    const response = await apiCreateReferringDoctor({
                        variables: simplify.inputs
                    });

                    if (response?.data) {
                        dispatch(setLoadingState('completed'))
                        dispatch(client_onboard_data_lists(copy_saving_list));
                        handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                    }
                }

            }

            if (module === "Individual Risk Assessment" && _history) {
                dispatch(setLoadingState('loading'));
                let count = 0;

                if (!data[0]?.table_id) {
                    for (const key in data) {
                        const simplify = convertDataIntoSimplifyObjects(data[key].childOptions)

                        const object = {
                            client_detail_id: _history,
                            module: module,
                            category: categoryValue,
                            question_title: data[key]?.title,
                            answer_type: [
                                {
                                    "__typename": "ComponentAnswersIndividualRiskInputs",
                                    "__component": "answers.individual-risk-inputs",
                                    ...simplify?.inputs,
                                    "risk_options": {
                                        "risk_to_individual": simplify?.selectedOptions?.risk_to_individual?.id,
                                        "risk_to_staff": simplify?.selectedOptions?.risk_to_staff?.id,
                                        "risk_to_other": simplify?.selectedOptions?.risk_to_other?.id,
                                    }
                                }
                            ]
                        }

                        const response = await apiClientQuestionAnswer({
                            variables: object
                        })
                        if (response?.data) {
                            count++;
                        }
                    }
                    if (count === data?.length) {
                        dispatch(setLoadingState('completed'))
                        dispatch(client_onboard_data_lists(copy_saving_list));
                        handleSnacksApper("Client Onboarding", categoryValue + " Questions has been created", "success")
                    }
                }
                else {
                    for (const key in data) {
                        const simplify = convertDataIntoSimplifyObjects(data[key].childOptions);

                        const object = {
                            client_detail_id: _history,
                            module: module,
                            category: categoryValue,
                            question_title: data[key]?.title,
                            answer_type: [
                                {
                                    "__typename": "ComponentAnswersIndividualRiskInputs",
                                    "__component": "answers.individual-risk-inputs",
                                    ...simplify?.inputs,
                                    "risk_options": {
                                        "risk_to_individual": simplify?.selectedOptions?.risk_to_individual?.id,
                                        "risk_to_staff": simplify?.selectedOptions?.risk_to_staff?.id,
                                        "risk_to_other": simplify?.selectedOptions?.risk_to_other?.id,
                                    }
                                }
                            ]
                        }

                        const response = await apiUpdateUpdateClientQuestionAnswerRef({
                            variables: {
                                "id": data[key]?.table_id,
                                "data": object
                            }
                        })
                        if (response?.data) {
                            count++;
                        }
                    }

                    if (count === data?.length) {
                        dispatch(setLoadingState('completed'))
                        dispatch(client_onboard_data_lists(copy_saving_list));
                        handleSnacksApper("Client Onboarding", categoryValue + " Questions has been updated", "success")
                    }
                }

            }
            if (module === "Home Risk Assessment" && _history) {
                if (categoryValue === "Representative Information") {
                    dispatch(setLoadingState('loading'))
                    const simplify = convertDataIntoSimplifyObjects(data)
                    let count = 0;

                    if (!data[0]?.table_id) {
                        for (const keys in simplify.selectedOptions) {
                            const obj = {
                                client_detail_id: _history,
                                module: module,
                                category: categoryValue,
                                question_title: keys,
                                answer_type: [
                                    {
                                        "__typename": "ComponentAnswersSingleAnswer",
                                        "__component": "answers.single-answer",
                                        "active": true,
                                        "drop_down_option_lovs": simplify.selectedOptions[keys].id
                                    }
                                ]
                            }
                            const response = await apiClientQuestionAnswer({
                                variables: obj
                            })
                            if (response?.data) {
                                count++
                            }
                        }
                        if (count === Object.keys(simplify.selectedOptions).length) {
                            dispatch(setLoadingState('completed'))
                            dispatch(client_onboard_data_lists(copy_saving_list));
                            handleSnacksApper("Client Onboarding", categoryValue + " Questions has been created", "success")
                        }
                    }
                    else {
                        for (const key in simplify.selectedOptions) {
                            const obj = {
                                client_detail_id: _history,
                                module: module,
                                category: categoryValue,
                                question_title: key,
                                answer_type: [
                                    {
                                        "__typename": "ComponentAnswersSingleAnswer",
                                        "__component": "answers.single-answer",
                                        "active": true,
                                        "drop_down_option_lovs": simplify.selectedOptions[key].id
                                    }
                                ]
                            }
                            const response = await apiUpdateUpdateClientQuestionAnswerRef({
                                variables: {
                                    "id": simplify.selectedOptions[key]?.table_id,
                                    "data": obj
                                }
                            })
                            if (response?.data) {
                                count++
                            }
                        }
                        if (count === Object.keys(simplify.selectedOptions).length) {
                            dispatch(setLoadingState('completed'))
                            dispatch(client_onboard_data_lists(copy_saving_list));
                            handleSnacksApper("Client Onboarding", categoryValue + " Questions has been updated", "success")
                        }
                    }
                }
                if (categoryValue === "Type of Residence") {
                    dispatch(setLoadingState('loading'))
                    const simplify = convertDataIntoSimplifyObjects(data)
                    let count = 0;
                    if (lovsAnswerData.find(item => (item?.attributes?.category === categoryValue))) {
                        for (const keys in simplify.selectedOptions) {
                            const responseLovs = await apiUpdateClientAnswerLovsReferenceById({
                                variables: {
                                    "id": simplify.selectedOptions[keys].id,
                                    "data": {
                                        client_detail_id: _history,
                                        module: module,
                                        category: categoryValue,
                                        input_name: keys,
                                        selected_option_value: simplify.selectedOptions[keys].title
                                    }
                                }
                            })
                            if (responseLovs?.data) {
                                count++
                            }
                        }
                        if (count === Object.keys(simplify.selectedOptions).length) {
                            dispatch(setLoadingState('completed'))
                            dispatch(client_onboard_data_lists(copy_saving_list));
                            handleSnacksApper("Client Onboarding", categoryValue + " has been updated", "success")
                        }
                    }
                    else {
                        for (const keys in simplify.selectedOptions) {
                            const obj = {
                                client_detail_id: _history,
                                module: module,
                                category: categoryValue,
                                input_name: keys,
                                selected_option_value: simplify.selectedOptions[keys].title
                            }

                            const responseLovs = await apiCreateClientLovs({
                                variables: obj
                            })
                            if (responseLovs?.data) {
                                count++
                            }
                        }
                        if (count === Object.keys(simplify.selectedOptions).length) {
                            dispatch(setLoadingState('completed'))
                            dispatch(client_onboard_data_lists(copy_saving_list));
                            handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                        }
                    }
                }
                if (categoryValue !== "Representative Information" && categoryValue !== "Type of Residence") {
                    dispatch(setLoadingState('loading'))
                    let count = 0;
                    if (!data[0]?.table_id) {
                        for (const key in data) {
                            const simplify = convertDataIntoSimplifyObjects(data[key].childOptions)

                            const object = {
                                client_detail_id: _history,
                                module: module,
                                category: categoryValue,
                                question_title: data[key]?.title,
                                answer_type: [
                                    {
                                        "__typename": "ComponentAnswersHomeRiskInputs",
                                        "__component": "answers.home-risk-inputs",
                                        ...simplify?.inputs,
                                        "selected_options": simplify?.selectedOptions?.selected_option?.id,
                                        "rating": simplify?.rating ?? '1'
                                    }
                                ]
                            }

                            const response = await apiClientQuestionAnswer({
                                variables: object
                            })
                            if (response?.data) {
                                count++;
                            }
                        }
                        if (count === data?.length) {
                            dispatch(setLoadingState('completed'))
                            dispatch(client_onboard_data_lists(copy_saving_list));
                            handleSnacksApper("Client Onboarding", categoryValue + " Questions has been created", "success")
                        }
                    }
                    else {
                        for (const key in data) {
                            const simplify = convertDataIntoSimplifyObjects(data[key].childOptions)

                            const object = {
                                client_detail_id: _history,
                                module: module,
                                category: categoryValue,
                                question_title: data[key]?.title,
                                answer_type: [
                                    {
                                        "__typename": "ComponentAnswersHomeRiskInputs",
                                        "__component": "answers.home-risk-inputs",
                                        ...simplify?.inputs,
                                        "selected_options": simplify?.selectedOptions?.selected_option?.id,
                                        "rating": simplify?.rating ?? '1'
                                    }
                                ]
                            }

                            const response = await apiUpdateUpdateClientQuestionAnswerRef({
                                variables: {
                                    "id": data[key]?.table_id,
                                    "data": object
                                }
                            })
                            if (response?.data) {
                                count++;
                            }
                        }
                        if (count === data?.length) {
                            dispatch(setLoadingState('completed'))
                            dispatch(client_onboard_data_lists(copy_saving_list));
                            handleSnacksApper("Client Onboarding", categoryValue + " Questions has been updated", "success")
                        }
                    }
                }

            }
            getClientDetailsById(_history)

        } catch (error) {
            console.log("error", error)
            dispatch(setLoadingState('error'))
        }
    }

    return (
        <Box sx={{ width: "100%", display: 'flex', flexDirection: "column" }}>
            {load_questions || load_support_up || load_plan_up || load_pre_up || load_goal_up || load_alert_up || load_serv_up || load_note_up || load_emr_up || load_lov_up || load_cont_up || loading_history || loading_budget || loadingResponse || loading_question || loading_client || loading_adm || loading_medi || loading_lovs || loading_review || loading_contact || loading_check || loading_goal || loading_emergency || loading_service || loading_preference || loading_update || loading_doctor ? (
                <Box sx={{
                    background: "rgba(0,0,0,0.3)",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <CircularProgress color="primary" size={35} />
                </Box>
            ) : null}
            {can_create_multiple && (
                <Box sx={{ width: "100%", display: 'flex', flexWrap: 'wrap', }}>
                    {multipleFields?.map((fields, uppperIndex) => {
                        return (
                            <Grid width={'100%'}>
                                {fields.map((value, innerIndex) => {
                                    if (value?.title === 'Every X days where X is' && (fields.find(field => field.title === "Occurrence").value === "Every x Days")) {
                                        return (
                                            <TextField
                                                type={value.type}
                                                key={value.title + uppperIndex}
                                                id={value.title + uppperIndex}
                                                required={value.required}
                                                multiline={value.multiline}
                                                label={value.title}
                                                disabled={value.disabled}
                                                placeholder={value.placeholder}
                                                value={value.value}
                                                sx={{ m: 1, width: value.width }}
                                                helperText={value.error ? value.error : ""}
                                                error={value.error ? true : false}
                                                onChange={(e) => {
                                                    const updatedArray = [...multipleFields];

                                                    // Create a deep copy of the specific entry to avoid reference issues
                                                    updatedArray[uppperIndex] = [...updatedArray[uppperIndex]];

                                                    // Directly update the value of the specific field
                                                    updatedArray[uppperIndex][innerIndex] = {
                                                        ...updatedArray[uppperIndex][innerIndex],
                                                        value: e.target.value
                                                    };

                                                    setMultipleFields(updatedArray);
                                                }}
                                            />
                                        )
                                    }
                                    if ((value?.type?.toLowerCase() === "text" || value?.type?.toLowerCase() === "email" || value?.type?.toLowerCase() === "number" || value?.type?.toLowerCase() === "date" || value?.type?.toLowerCase() === "time") && value?.title !== 'Every X days where X is') {
                                        return (
                                            <TextField
                                                type={value.type}
                                                key={value.title + uppperIndex}
                                                id={value.title + uppperIndex}
                                                required={value.required}
                                                multiline={value.multiline}
                                                label={value.title}
                                                disabled={value.disabled}
                                                placeholder={value.placeholder}
                                                value={value.value}
                                                sx={{ m: 1, width: value.width }}
                                                helperText={value.error ? value.error : ""}
                                                error={value.error ? true : false}
                                                onChange={(e) => {
                                                    const updatedArray = [...multipleFields];

                                                    // Create a deep copy of the specific entry to avoid reference issues
                                                    updatedArray[uppperIndex] = [...updatedArray[uppperIndex]];

                                                    // Directly update the value of the specific field
                                                    updatedArray[uppperIndex][innerIndex] = {
                                                        ...updatedArray[uppperIndex][innerIndex],
                                                        value: e.target.value
                                                    };

                                                    setMultipleFields(updatedArray);
                                                }}
                                            />
                                        )
                                    }
                                    if (value?.type?.toLowerCase() === "checkbox") {
                                        return (
                                            <FormControlLabel
                                                key={value.title + uppperIndex}
                                                onChange={(e) => {
                                                    const updatedArray = [...multipleFields];

                                                    // Create a deep copy of the specific entry to avoid reference issues
                                                    updatedArray[uppperIndex] = [...updatedArray[uppperIndex]];

                                                    // Directly update the value of the specific field
                                                    updatedArray[uppperIndex][innerIndex] = {
                                                        ...updatedArray[uppperIndex][innerIndex],
                                                        value: e.target.value
                                                    };

                                                    setMultipleFields(updatedArray);
                                                }}
                                                control={<Checkbox key={value.title} />}
                                                label={value.title}
                                            />
                                        )
                                    }
                                    if (value?.type?.toLowerCase() === "select") {
                                        return (
                                            <TextField
                                                select={true}
                                                label={value.title}
                                                key={value.title + uppperIndex}
                                                id={value.title + uppperIndex}
                                                required={value.required}
                                                sx={{ m: 1, width: value.width }}
                                                value={value.value}
                                                helperText={value.error ? value.error : ""}
                                                error={value.error ? true : false}
                                                onChange={(e) => {
                                                    // Create a deep copy of multipleFields to avoid direct mutation
                                                    const updatedArray = [...multipleFields];

                                                    // Ensure each nested array is also copied to avoid reference issues
                                                    updatedArray[uppperIndex] = updatedArray[uppperIndex].map((item, index) => {
                                                        // Only update the target item (based on innerIndex) or the specific "Every X days where X is" item
                                                        if (index === innerIndex) {
                                                            return {
                                                                ...item,
                                                                value: e.target.value,
                                                            };
                                                        } else if (item.title === "Every X days where X is") {
                                                            if (e.target.value !== 'Every x Days') {
                                                                return {
                                                                    ...item,
                                                                    value: 0,
                                                                }
                                                            }
                                                        }
                                                        return item;
                                                    });

                                                    // Update the state with the modified array
                                                    setMultipleFields(updatedArray);
                                                }}

                                            >
                                                {value.options.map((option) => (
                                                    <MenuItem key={option?.attributes?.title} value={option?.attributes?.title}>
                                                        {option?.attributes?.title}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )
                                    }
                                })}
                            </Grid>
                        )
                    })}
                </Box>
            )}

            {(dynamiInputs && !can_create_multiple) && (
                <Box sx={{ width: "100%", display: 'flex', flexWrap: 'wrap', }}>
                    {dynamiInputs?.map((value, index) => {
                        if (value?.type?.toLowerCase() === "text" || value?.type?.toLowerCase() === "email" || value?.type?.toLowerCase() === "number" || value?.type?.toLowerCase() === "date" || value?.type?.toLowerCase() === "time") {
                            return (
                                <TextField
                                    type={value.type}
                                    key={index}
                                    id={value.title}
                                    htmlFor={value.title}
                                    required={value.required}
                                    // multiline={value.multiline}
                                    label={value.title}
                                    rows={10}
                                    multiline={(categoryValue === 'Notes' || categoryValue === 'Client Goals') ? true : false}
                                    disabled={value.disabled}
                                    placeholder={value.placeholder}
                                    value={value.value}
                                    sx={{ m: 1, width: (categoryValue === 'Notes' || categoryValue === 'Client Goals') ? '100%' : value.width }}
                                    helperText={value.error ? value.error : ""}
                                    error={value.error ? true : false}
                                    onChange={(e) => handleChangeValueInputs(e, index)}
                                />
                            )
                        }
                        if (value?.type?.toLowerCase() === "checkbox") {
                            return (
                                <FormControlLabel
                                    key={value.title}
                                    control={<Checkbox onChange={(e) => handleCheckbox(e, index)} checked={value.value} />}
                                    label={value.title}
                                />
                            )
                        }
                        if (value?.type?.toLowerCase() === 'autocomplete') {
                            const countryList = value.options.map(val => (val?.attributes?.title))

                            return (
                                <Autocomplete
                                    key="country-autocomplete"
                                    value={value.value}
                                    disablePortal
                                    options={countryList}
                                    sx={{ m: 1, width: value.width }}
                                    onChange={(e, selectedCountry) => handleChangeValueInputs(selectedCountry, index)}
                                    renderInput={(params) => <TextField {...params} label="Country of Birth" />}
                                />
                            )
                        }
                        if (value?.type?.toLowerCase() === "select") {
                            return (
                                <TextField
                                    select={true}
                                    label={value.title}
                                    id={value.title}
                                    htmlFor={value.title}
                                    key={index}
                                    required={value.required}
                                    sx={{ m: 1, width: value.width }}
                                    value={value.value}
                                    helperText={value.error ? value.error : ""}
                                    error={value.error ? true : false}
                                    onChange={(e) => handleChangeValueInputs(e, index)}
                                >
                                    {value.options.map((option) => (
                                        <MenuItem key={option?.attributes?.title} value={option?.attributes?.title}>
                                            {option?.attributes?.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )
                        }
                    })}
                </Box>
            )}

            {dynamicQuestions && (
                <Box sx={{ width: "100%", display: 'flex', flexWrap: 'wrap', }}>
                    {dynamicQuestions?.map((v, index) => {
                        if (v?.active && v?.type?.includes("Text")) {
                            return (
                                <Grid sx={{ width: categoryValue === 'Medical History Details' ?? '100%' }}>
                                    <TextField
                                        type={v?.type?.toLowerCase()}
                                        key={v.title}
                                        id={v.title}
                                        htmlFor={v.title}
                                        required={v.required}
                                        multiline={v.multiline}
                                        label={v.title}
                                        value={v.value}
                                        sx={{
                                            m: 1,
                                            width: categoryValue === 'Medical History Details' ? '50%' : v.width,
                                            mb: categoryValue === 'Medical History Details' ? 5 : 0
                                        }}
                                        onChange={(e) => {
                                            const copy = [...dynamicQuestions];
                                            copy[index].value = e.target.value;
                                            setDynamicQuestions(copy);
                                        }}
                                    />
                                </Grid>
                            )
                        }
                        if (v?.active && v?.type?.includes("Select")) {
                            return (
                                <TextField
                                    select={true}
                                    label={v.title}
                                    key={v.title}
                                    id={v.title}
                                    htmlFor={v.title}
                                    required={v.required}
                                    sx={{ m: 1, width: v.width }}
                                    value={v.value}
                                    onChange={(e) => {
                                        const copy = [...dynamicQuestions]
                                        copy[index].value = e.target.value;
                                        setDynamicQuestions(copy);
                                    }}
                                >
                                    {v?.options.map((option) => (
                                        <MenuItem key={option?.attributes?.title} value={option?.attributes?.title}>
                                            {option?.attributes?.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )
                        }
                        if (v?.active && v?.type?.includes("IndividualRisk")) {
                            return (
                                <Row
                                    key={v?.type + index}
                                    row={v}
                                    screenWidth={v.width}
                                    pIndex={index}
                                    handleIndividualRiskChanges={handleIndividualRiskChanges}
                                />
                            )
                        }
                        if (v?.active && v?.type?.includes("HomeRisk")) {
                            return (
                                <RowHRA
                                    key={v?.type + index}
                                    row={v}
                                    screenWidth={v.width}
                                    pIndex={index}
                                    handleIndividualRiskChanges={handleIndividualRiskChanges}
                                />
                            )
                        }
                        if (v?.active && v?.type?.includes("AnswersList")) {
                            return (
                                <RowSP
                                    key={v?.type + index}
                                    row={v}
                                    pIndex={index}
                                    handleIndividualRiskChanges={handleIndividualRiskChanges}
                                />
                            )
                        }
                    })}
                </Box>
            )}

            {categoryValue === 'Plan Review' && (
                <Box sx={{ width: "100%", display: 'flex', flexWrap: 'wrap', }}>
                    <ul>
                        {supportCategories?.map((supportCategory, mainIndex) => {
                            return (
                                <li>
                                    <Typography sx={{ fontWeight: 700, padding: '20px 0px' }}>
                                        {supportCategory.mainTitle}
                                    </Typography>
                                    <ul style={{ padding: '0px 20px' }}>
                                        {supportCategory?.data?.map((categoryValue, upperIndex) => {
                                            return (
                                                <li>
                                                    <Typography key={upperIndex + categoryValue.title}>{categoryValue.title}</Typography>
                                                    {categoryValue?.inputListArray?.map((value, innerIndex) => {
                                                        return (
                                                            <TextField
                                                                type={value?.type}
                                                                key={upperIndex + value.title}
                                                                id={value.title}
                                                                required={value.required}
                                                                multiline={value.multiline}
                                                                label={value.title}
                                                                disabled={value.disabled}
                                                                placeholder={value.placeholder}
                                                                value={value.value}
                                                                sx={{ m: 1, width: value.width }}
                                                                helperText={value.error ? value.error : ""}
                                                                error={value.error ? true : false}
                                                                onChange={(e) => {
                                                                    const copy = [...supportCategories[mainIndex]?.data[upperIndex]?.inputListArray];
                                                                    copy[innerIndex].value = e.target.value;
                                                                    const updatedArray = [...supportCategories];
                                                                    setSupportCategories(updatedArray)
                                                                }}
                                                            />
                                                        )
                                                    })}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </li>
                            )
                        })}
                    </ul>
                </Box>
            )}

            <Box sx={{ width: "100%", display: 'flex', justifyContent: "flex-end", mr: "2%" }}>
                <Stack direction={"row"} spacing={2}>
                    {can_create_multiple && (
                        <Tooltip title={"Can Add " + categoryValue} placement="left">
                            <Button
                                color={"primary"}
                                startIcon={<AddCircleOutlineOutlinedIcon color="primary" />}
                                variant="outlined"
                                sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}
                                onClick={AddMoreFunction}
                            >
                                Add More
                            </Button>
                        </Tooltip>
                    )}
                    <Tooltip title={"Can Add " + categoryValue} placement="left">
                        <Button
                            color={"primary"}
                            startIcon={<AddCircleOutlineOutlinedIcon color="primary" />}
                            variant="outlined"
                            sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}
                            onClick={() => savingDataAsPerCategory()}
                        >
                            {'Submit'}
                        </Button>
                    </Tooltip>
                </Stack>
            </Box>
            <Snackbar open={snacks.open} autoHideDuration={5000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
                <Alert
                    onClose={handleCloseSnacks}
                    severity={errorSnackMessage.severity}
                    variant={errorSnackMessage.variant}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{errorSnackMessage.title}</AlertTitle>
                    {errorSnackMessage.message}
                </Alert>
            </Snackbar>

        </Box>
    )
}