
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    filters: ["Active"],
    filterByGroups: [],
    filterByFavorite: [],
    customFilters: []
};

export const SearchReducer = createSlice({
    name: 'search_and_filters',
    initialState,
    reducers: {
        filterReducer: (state, action) => {
            state.filters = action.payload;
        },
        filterByGroupReducer: (state, action) => {
            state.filterByGroups = action.payload;
        },
        filterByFavoriteReducer: (state, action) => {
            state.filterByFavorite = action.payload;
        },
        customFilterReducer: (state, action) => {
            state.customFilters = action.payload;
        },
    },
});

export const {
    filterReducer,
    filterByGroupReducer,
    filterByFavoriteReducer,
    customFilterReducer
} = SearchReducer.actions;

export default SearchReducer.reducer;