import { Backdrop, Box, Button, Fade, Grid, MenuItem, Modal, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchAndFilters from "../../../components/search_filters";
import { ApplicationsList } from '../../../components/pagination_view'

export const LeaveApplication = () => {
    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        maxWidth: "calc(70%)",
        maxHeight: "calc(90%)",
        minWidth: "calc(60%)",
        boxShadow: 12,
        borderRadius: 1,
        p: 4,
        overflowY: "auto"
    });

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
    const year = currentDate.getFullYear();
    const monthValue = month < 10 ? "0" + month : month;

    const width = () => {
        // modal control
        if (window.screen.width < 900) {
            const copy = { ...style };
            copy.maxWidth = "95%";
            setStyle(copy);
        }

        if (window.screen.width > 1600) {
            return window.screen.width / 5.6
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.4
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const [application, setApplication] = useState([
        {
            title: "Staff ID",
            value: "",
            width: screenWidth,
            required: true,
            type: "text",
            multiline: false,
            disable: true
        },
        {
            title: "Staff Name",
            value: "",
            width: screenWidth,
            required: true,
            type: "text",
            multiline: false,
            disable: true
        },
        {
            title: "Leave Type",
            value: "",
            width: screenWidth,
            required: true,
            type: "text",
            multiline: false,
            disable: false
        },
        {
            title: "Leave Start Date",
            value: year + "/" + monthValue + "/" + day,
            width: screenWidth,
            required: true,
            type: "date",
            multiline: false,
            disable: false
        },
        {
            title: "Leave End Date",
            value: year + "/" + monthValue + "/" + day,
            width: screenWidth,
            required: true,
            type: "date",
            multiline: false,
            disable: false
        },
        // {
        //     title: "Duration",
        //     value: "",
        //     width: screenWidth,
        //     required: true,
        //     type: "Number",
        //     multiline: false,
        //     disable: false
        // },
        {
            title: "Reason for Leave",
            value: "",
            width: screenWidth,
            required: true,
            type: "text",
            multiline: false,
            disable: false
        },
        {
            title: "Contact Information",
            value: "",
            width: screenWidth,
            required: true,
            type: "text",
            multiline: false,
            disable: false
        },
        {
            title: "Alternate Contact",
            value: "",
            width: screenWidth,
            required: true,
            type: "text",
            multiline: false,
            disable: false
        },
        // {
        //     title: "Approval Status",
        //     value: "",
        //     width: screenWidth,
        //     required: true,
        //     type: "text",
        //     multiline: false,
        //     disable: false
        // },
        {
            title: "Comments / Notes",
            value: "",
            width: screenWidth,
            required: true,
            type: "text",
            multiline: true,
            disable: false
        },
        {
            title: "Department / Team",
            value: "",
            width: screenWidth,
            required: true,
            type: "text",
            multiline: false,
            disable: false
        },
        // {
        //     title: "Leave Balance",
        //     value: "",
        //     width: screenWidth,
        //     required: true,
        //     type: "text",
        //     multiline: false,
        //     disable: false
        // },
        // {
        //     title: "Date of Submission",
        //     value: "",
        //     width: screenWidth,
        //     required: true,
        //     type: "text",
        //     multiline: false,
        //     disable: false
        // },
        // {
        //     title: "Date of Approval / Rejection",
        //     value: "",
        //     width: screenWidth,
        //     required: true,
        //     type: "text",
        //     multiline: false,
        //     disable: false
        // },
        // {
        //     title: "Status",
        //     value: "",
        //     width: screenWidth,
        //     required: true,
        //     type: "text",
        //     multiline: false,
        //     disable: false
        // },
    ]);

    const [newFeedback, setNewFeedback] = useState(false);

    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'Staff Name',
            numeric: false,
            disablePadding: true,
            label: 'Staff Name',
        },
        {
            id: 'Staff ID',
            numeric: false,
            disablePadding: true,
            label: 'Staff ID',
        },
        {
            id: 'Leave Type',
            numeric: false,
            disablePadding: true,
            label: 'Leave Type',
        },
        {
            id: 'Leave Start Date',
            numeric: false,
            disablePadding: true,
            label: 'Leave Start Date',
        },
        {
            id: 'Leave End Date',
            numeric: false,
            disablePadding: true,
            label: 'Leave End Date',
        },
        {
            id: 'Duration',
            numeric: false,
            disablePadding: true,
            label: 'Duration',
        },
        {
            id: 'Reason for Leave',
            numeric: false,
            disablePadding: true,
            label: 'Reason for Leave',
        },
        {
            id: 'Contact Information',
            numeric: false,
            disablePadding: true,
            label: 'Contact Information',
        },
        {
            id: 'Alternate Contact',
            numeric: false,
            disablePadding: true,
            label: 'Alternate Contact',
        },
        {
            id: 'Approval Status',
            numeric: false,
            disablePadding: true,
            label: 'Approval Status',
        },
        {
            id: 'Comments/Notes',
            numeric: false,
            disablePadding: true,
            label: 'Comments/Notes',
        },
        {
            id: 'Department',
            numeric: false,
            disablePadding: true,
            label: 'Department',
        },
        {
            id: 'Leave Balance',
            numeric: false,
            disablePadding: true,
            label: 'Leave Balance',
        },
        {
            id: 'Date of Submission',
            numeric: false,
            disablePadding: true,
            label: 'Date of Submission',
        },
        {
            id: 'Date of Approval/Rejection',
            numeric: false,
            disablePadding: true,
            label: 'Date of Approval/Rejection',
        },
        {
            id: 'Status',
            numeric: false,
            disablePadding: true,
            label: 'Status',
        },
        {
            id: 'Options',
            numeric: false,
            disablePadding: true,
            label: 'Options',
        },
    ];

    const [rows, setRows] = useState([]);

    useEffect(() => {
        const copy = [...rows];
        copy.push({
            id: 1,
            staff_name: "salman",
            staff_id: "345",
            leave_type: "Sick Leave",
            leave_start_date: "04-03-2024",
            leave_end_date: "04-10-2024",
            duration: "7 days",
            reason_for_leave: "fever",
            contact_information: "0956845555",
            alternate_contact: "0956845555",
            approval_status: "In-progress",
            comments_notes: "abc notes",
            department: "Care",
            leave_balance: "22",
            date_of_submission: "04-03-2024",
            date_of_approval_rejection: "nill",
            status: "Pending",
        });
        copy.push({
            id: 2,
            staff_name: "salman",
            staff_id: "345",
            leave_type: "Sick Leave",
            leave_start_date: "04-03-2024",
            leave_end_date: "04-10-2024",
            duration: "7 days",
            reason_for_leave: "fever",
            contact_information: "0956845555",
            alternate_contact: "0956845555",
            approval_status: "In-progress",
            comments_notes: "abc notes",
            department: "Care",
            leave_balance: "22",
            date_of_submission: "04-03-2024",
            date_of_approval_rejection: "nill",
            status: "Pending",
        });
        copy.push({
            id: 3,
            staff_name: "salman",
            staff_id: "345",
            leave_type: "Sick Leave",
            leave_start_date: "04-03-2024",
            leave_end_date: "04-10-2024",
            duration: "7 days",
            reason_for_leave: "fever",
            contact_information: "0956845555",
            alternate_contact: "0956845555",
            approval_status: "In-progress",
            comments_notes: "abc notes",
            department: "Care",
            leave_balance: "22",
            date_of_submission: "04-03-2024",
            date_of_approval_rejection: "nill",
            status: "Pending",
        });
        setRows(copy);
    }, []);

    return (
        <Grid container sx={{ display: "flex", justifyContent: "center", }}>
            <Grid item={true} xs={11.5} sm={11} md={11} lg={11} sx={{
                py: 1, display: "flex", flexDirection: { md: "row", lg: "row", xs: "column" }
            }}>
                <Grid item={true} xs={12} sm={12} md={6} lg={6} sx={{ display: "flex", alignItems: "center", flexWrap: 'wrap', justifyContent: { md: "flex-start", lg: "flex-start", xs: "center" } }}>
                    <Button onClick={() => {
                        setNewFeedback(true)
                    }} variant="outlined" sx={{ m: 1, fontFamily: "Roboto-Medium", textTransform: "capitalize" }}>
                        New Application
                    </Button>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                    <SearchAndFilters groupBy={false} favorite={false} inputWidth={8} />
                </Grid>
            </Grid>
            <Grid item={true} xs={11} sm={11} md={11} lg={11}>
                {rows.length && <ApplicationsList rows={rows} headCells={headCells} />}
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={newFeedback}
                onClose={() => setNewFeedback(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={newFeedback}>
                    <Box sx={style}>
                        <Box>
                            <Typography variant="h5" component="h2" sx={{ fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                                Create New Application
                            </Typography>
                        </Box>
                        <Box sx={{ my: 2 }}>
                            {application.map((v, i) => {
                                if (v.type === "text" || v.type === "number" || v.type === "date") {
                                    return (
                                        <TextField
                                            type={v.type}
                                            multiline={v.multiline}
                                            key={i}
                                            required={v.required}
                                            label={v.title}
                                            htmlFor={v.title}
                                            sx={{ m: 1, width: v.width }}
                                            value={v.value}
                                            onChange={(e) => {
                                                const copy = [...application];
                                                copy[i].value = e.target.value;
                                                setApplication(copy);
                                            }}
                                        />
                                    )
                                }
                            })}
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained" >
                                    Save
                                </Button>
                                <Button variant="contained" onClick={() => setNewFeedback(false)}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Grid>
    )
}