import React from "react";
import styled from "@emotion/styled";
import { Draggable } from "react-beautiful-dnd";
import { Avatar, Typography } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';

const Container = styled("div")`
  width: 170px;
  height: 140px;
  border-radius: 5px;
  margin: 3px;
  padding: 8px;
  box-shadow: 1px 1px 5px 1px #dcdfdd;
  background: ${props => (props.isDragging ? "#b3e5cc" : "white")};
`;

const Item = styled("div")`
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Task = ({ task, index }) => {
  return (
    <Draggable draggableId={String(task.id)} index={index} type="task">
      {(provided, snapshot) => (
        <Container
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          isDragging={snapshot.isDragging}
        >
          <Item>
            <Avatar sx={{ width: 50, height: 50 }}>
              <PersonIcon />
            </Avatar>
          </Item>
          <Item>
            <Typography style={{ fontSize: 12, fontFamily: "Roboto-Medium" }}>
              {task.name}
            </Typography>
          </Item>
          {task?.serviceRequired.map((v, i) => {
            return (
              <div key={i}>
                <Item>
                  <Typography style={{ fontSize: 11, fontFamily: "Roboto-Regular" }}>
                    {v.dateFrom} / {v.dateTo}
                  </Typography>
                </Item>
                <Item>
                  <Typography style={{ fontSize: 11, fontFamily: "Roboto-Regular" }}>
                    {v.startTime} To {v.endTime}
                  </Typography>
                </Item>
                <Item>
                  <Typography style={{ fontSize: 11, fontFamily: "Roboto-Regular" }}>
                    Status: {v.status}
                  </Typography>
                </Item>
              </div>
            )
          })}
        </Container>
      )}
    </Draggable>
  );
};

export default Task;
