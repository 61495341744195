import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import APIGQL from "./graphql";


/// // // // =========================Query======================================================

export const GetServiceRequests = () => {
    const [getById, { loading, error }] = useLazyQuery(APIGQL.ApiGetServiceRequests, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading, error];
}


export const GetFilterVisitDayAndCheckList = () => {
    const [getById, { loading, error }] = useLazyQuery(APIGQL.ApiGetFilterVisitDayAndCheckList, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading, error];
}

export const GetServicePlans = () => {
    const { data, loading } = useQuery(APIGQL.ApiGetServicePlans, {
        fetchPolicy: "cache-and-network"
    })
    const services = data?.servicePlans?.data;
    return [services, loading];
}


// // // /// =========================Mutation ==================================================

export const CreateServiceRequest = () => {
    const [createService, { loading, error }] = useMutation(APIGQL.ApiCreateServiceRequest);
    return [createService, loading, error]
}

export const CreateVisitDay = () => {
    const [createService, { loading, error }] = useMutation(APIGQL.ApiCreateVisitDay);
    return [createService, loading, error]
}

export const CreateVisitShiftCheckList = () => {
    const [createService, { loading, error }] = useMutation(APIGQL.ApiCreateVisitShiftCheckList);
    return [createService, loading, error]
}

export const CreateServiceShift = () => {
    const [createService, { loading, error }] = useMutation(APIGQL.ApiCreateServiceShift);
    return [createService, loading, error]
}

export const CreateServicePlan = () => {
    const [createService, { loading, error }] = useMutation(APIGQL.ApiCreateServicePlan);
    return [createService, loading, error]
}

export const UpdateServicePlan = () => {
    const [updateService, { loading, error }] = useMutation(APIGQL.ApiUpdateServicePlan);
    return [updateService, loading, error]
}