import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

export default function DataGridComp({ rows, columns, loading }) {
    return (
        <Box sx={{ width: '100%' }}>
            <DataGrid
                loading={loading}
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                pageSizeOptions={[5, 10, 25, { value: -1, label: 'All' }]}
            />
        </Box>
    );
}