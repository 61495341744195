import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Grid, Typography, Paper, Box, Stack, TextField, Chip, Modal, Fade, Backdrop } from '@mui/material';
import { EmployeeCard, MiniParticipantCard, ParticipantCard } from '../../../components/cards';

const randomIds = () => {
    return Math.floor((1 + Math.random()) * 0x10e0e00)
        .toString(16)
        .substring(1);
}

const CalendarViewWeek = ({ list }) => {
    return (
        <Grid item={true} xs={12} sm={12} md={10.7} lg={10.7} sx={{ display: "flex", }} >
            {
                list.map((dateInfo) => {
                    return (
                        <Grid item={true} key={dateInfo.date} xs={12} sm={12} md={12} lg={12} sx={{
                            border: "1px solid #35BE00",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            backgroundColor: "#ffffff"
                        }}>
                            <Paper
                                elevation={0}
                                sx={{
                                    borderRadius: 0,
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
                                    <Typography style={{ fontSize: 16, fontFamily: "Roboto-Bold", color: "gray" }}>
                                        {dateInfo.day.slice(0, 3)}
                                    </Typography>
                                </Box>
                                <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
                                    <Typography style={{ fontSize: 16, fontFamily: "Roboto-Bold", color: "gray" }}>
                                        {dateInfo.date.slice(-2)}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    )
                })
            }
        </Grid >
    )
}

const CalendarViewMonth = ({ list }) => {
    return (
        <Grid item={true} xs={6} sm={12} md={10.7} lg={10.7} sx={{ display: "flex", overflowX: { xs: "auto", md: "none", lg: "none" } }} >
            {
                list.map((dateInfo) => {
                    return (
                        <Grid item={true} key={dateInfo.date} xs={12} sm={12} md={12} lg={12} sx={{
                            border: "1px solid #35BE00",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            p: 2
                        }}>
                            <Paper
                                elevation={0}
                                sx={{
                                    // width: "calc(100%)" / dateList.length,
                                    borderRadius: 0,
                                }}
                            >
                                <Box sx={{ width: '100%', display: "flex", justifyContent: "center", }}>
                                    <Typography style={{ fontSize: 14, fontFamily: "Roboto-Bold", color: "gray" }}>
                                        {dateInfo.day.slice(0, 3)}
                                    </Typography>
                                </Box>
                                <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
                                    <Typography style={{ fontSize: 14, fontFamily: "Roboto-Bold", color: "gray" }}>
                                        {dateInfo.date.slice(-2)}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    )
                })
            }
        </Grid >
    )
}

const TimeViewRow = ({ list }) => {
    return (
        <Grid item={true} xs={12} sm={12} md={10.7} lg={10.7} sx={{ display: "flex", height: "inherit" }}>
            {
                list.map((v, i) => {
                    return (
                        <Grid item={true} key={i} xs={12} sm={12} md={12} lg={12} sx={{ height: "inherit" }}>
                            <Paper
                                elevation={0}
                                sx={{
                                    display: "flex",
                                    borderRadius: 0,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "1px solid #35BE00",
                                    height: "inherit"
                                }}
                            >
                                <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
                                    <Typography style={{ fontSize: "1em", fontFamily: "Roboto-Medium", color: "gray" }}>
                                        {v?.hour}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    )
                })
            }
        </Grid >
    )
}

const CalendarOccupiedDate = ({ list, option, nerow }) => {
    return (
        <Grid item={true} xs={12} sm={12} md={10.7} lg={10.7} sx={{ display: "flex" }} >
            {list.map((dateInfo) => {
                return (
                    <Grid item={true} key={dateInfo.date} xs={12} sm={12} md={12} lg={12} sx={{
                        border: "1px solid #35BE00",
                        overflowY: { xs: "auto", md: "auto", lg: "auto", },
                        height: { xs: "inherit", md: "inherit", lg: "inherit" }
                    }}>
                        {option?.shifts_data?.map((v, i) => {
                            if (v.attributes.shift_date === dateInfo.date) {
                                return (
                                    <Paper
                                        key={i}
                                        elevation={0}
                                        sx={{
                                            borderLeft: `2px solid ${v.status === "ongoing" ? "green" : "blue"}`,
                                            backgroundColor: v.status === "ongoing" ? "lightgreen" : "lightblue",
                                            borderRadius: 0,
                                            p: 1,
                                            overflowY: 'auto',
                                            borderRadius: 1
                                        }}
                                    >
                                        <Box sx={{ display: 'flex' }}>
                                            <Typography style={{ fontSize: 12, fontFamily: "Roboto-Medium", color: "#000" }}>
                                                Shift Start:
                                            </Typography>
                                            <Typography style={{ fontSize: 12, fontFamily: "Roboto-Regular", color: "#000" }}>
                                                {v.attributes.shift_start.slice(0, 8)}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex' }}>
                                            <Typography style={{ fontSize: 12, fontFamily: "Roboto-Medium", color: "#000" }}>
                                                Shift End:
                                            </Typography>
                                            <Typography style={{ fontSize: 12, fontFamily: "Roboto-Regular", color: "#000" }}>
                                                {v.attributes.shift_end.slice(0, 8)}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex' }}>
                                            <Typography style={{ fontSize: 12, fontFamily: "Roboto-Medium", color: "#000" }}>
                                                Total Shift Hours
                                            </Typography>
                                            <Typography style={{ fontSize: 12, fontFamily: "Roboto-Regular", color: "#000" }}>
                                                {v.attributes.total_shift_hours + " hrs"}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex' }}>
                                            <Typography style={{ fontSize: 12, fontFamily: "Roboto-Medium", color: "#000" }}>
                                                Staff Worker:
                                            </Typography>
                                            <Typography style={{ fontSize: 12, fontFamily: "Roboto-Regular", color: "#000" }}>
                                                {option.staff_detail.first_name}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography style={{ fontSize: 12, fontFamily: "Roboto-Medium", color: "#000" }}>
                                                Service Type:
                                            </Typography>
                                            <Typography style={{ fontSize: 12, fontFamily: "Roboto-Regular", color: "#000" }}>
                                                {option.service_request.service_type.service_name}
                                            </Typography>
                                        </Box>
                                        <Stack direction={'row'} spacing={2} >
                                            <Chip label="PLAN" color="primary" size="small" />
                                            <Chip label="Advertise" color="primary" size="small" />
                                        </Stack>
                                    </Paper>
                                )
                            }
                        })}
                    </Grid>
                )
            })}
        </Grid >
    )
}

const CalendarOccupiedTime = ({ list, option, nerow }) => {

    return (
        <Grid item={true} xs={12} sm={12} md={10.7} lg={10.7} sx={{ display: "flex", }}>
            {list.map((v, i) => {
                return (
                    <Grid key={i + v.date} item={true} xs={12} sm={12} md={12} lg={12} sx={{
                        border: "1px solid #35BE00",
                        overflowY: 'auto'
                    }}>
                        {option?.shifts_data?.map((vN, iN) => {
                            const start_time = vN?.attributes?.shift_start.slice(0, 5)
                            const end_time = vN?.attributes?.shift_end.slice(0, 5)
                            if (vN?.attributes?.shift_date === v.date && v.hour >= start_time && v.hour <= end_time) {
                                return (
                                    <Paper
                                        key={iN + start_time}
                                        elevation={0}
                                        sx={{
                                            backgroundColor: vN?.service_request_status?.toLowerCase() === "ongoing" ? "lightgreen" : "lightblue",
                                            borderRadius: 0,
                                            p: 1,
                                            overflow: 'hidden',
                                            borderRadius: 1,
                                        }}
                                    >
                                        <Box>
                                            <Typography style={{ fontSize: 12, fontFamily: "Roboto-Regular", color: "#000" }}>
                                                {option?.service_request?.service_type?.service_name}
                                                {/* {vN?.attributes?.shift_start} - {vN?.attributes?.shift_end} */}
                                            </Typography>
                                        </Box>
                                        {/* {nerow && (
                                            <Box>
                                                <Typography style={{ fontSize: 12, fontFamily: "Roboto-Regular", color: "#000" }}>
                                                    {option.serviceType}
                                                </Typography>
                                            </Box>
                                        )}
                                        {nerow && (
                                            <Box>
                                                <Typography style={{ fontSize: 12, fontFamily: "Roboto-Medium", color: "#000" }}>
                                                    {option.serviceProvider}
                                                </Typography>
                                            </Box>
                                        )} */}
                                        <Box>
                                            <Chip label="PLAN" color="primary" size="small" />
                                        </Box>
                                    </Paper>
                                )
                            } else { return <div key={i + randomIds()}></div> }
                        })}
                    </Grid >
                )
            })}
        </Grid >
    )
}

const CustomCalendar = ({ view, weekList, dateList, participant, viewExpose, employee, hourList, servicePlans }) => {
    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        maxWidth: "calc(70%)",
        maxHeight: "calc(80%)",
        minWidth: "calc(60%)",
        boxShadow: 12,
        borderRadius: 1,
        p: 4,
        overflowY: "auto"
    });
    const [screenHeight, setScreenHeight] = useState(window.screen.height);
    const [editModal, setEditModal] = useState(false);
    const [selectedObject, setSelectedObject] = useState(null);

    const handleDetails = (value) => {
        setEditModal(!editModal)
        setSelectedObject(value)
    }

    const handleCloseDetails = () => {
        setEditModal(!editModal)
        setSelectedObject(null)
    }
    return (
        <Grid container>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", height: screenHeight > 900 ? "calc(6vh)" : "calc(8vh)", overflowY: { xs: "auto", md: "scroll", lg: "scroll", } }}>
                <Grid item={true} xs={1.3} sm={1.3} md={1.3} lg={1.3} sx={{ display: { xs: "none", md: "flex", sm: "none", lg: "flex" }, alignItems: "center", justifyContent: "center" }}>
                </Grid>
                {view === "week" && (
                    <CalendarViewWeek list={weekList} />
                )}
                {view === "month" && (
                    <CalendarViewMonth list={dateList} />
                )}
                {view === "day" && (
                    <TimeViewRow list={hourList} />
                )}
            </Grid>
            {viewExpose === "participant" ? (
                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ height: { lg: screenHeight > 900 ? "calc(77.5vh)" : "calc(71vh)", xs: "auto" }, overflowY: "auto", }}>
                    {servicePlans?.map((v, i) => {
                        return (
                            <Grid item={true} key={i} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", }}>
                                <Grid item={true} xs={1.3} sm={1.3} md={1.3} lg={1.3} sx={{ display: { xs: "none", md: "flex", sm: "none", lg: "flex" }, flexDirection: "column", }}>
                                    <ParticipantCard v={v} handleDetails={handleDetails} />
                                </Grid>
                                {view === "week" && (
                                    <CalendarOccupiedDate list={weekList} option={v} nerow={true} />
                                )}
                                {view === "month" && (
                                    <CalendarOccupiedDate list={dateList} option={v} nerow={false} />
                                )}
                                {view === "day" && (
                                    <CalendarOccupiedTime list={hourList} option={v} nerow={false} />
                                )}
                            </Grid>
                        )
                    })}
                </Grid>
            ) : null}
            {viewExpose === "employee" ? (
                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ height: { lg: screenHeight > 900 ? "calc(77.5vh)" : "calc(71vh)", xs: "auto" }, overflowY: "auto", }}>
                    {servicePlans?.map((v, i) => {
                        return (
                            <Grid item={true} key={i} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", }}>
                                <Grid item={true} xs={1.3} sm={1.3} md={1.3} lg={1.3} sx={{ display: { xs: "none", md: "flex", sm: "none", lg: "flex" }, flexDirection: "column", }}>
                                    <EmployeeCard v={v} />
                                </Grid>
                                {view === "week" && (
                                    <CalendarOccupiedDate list={weekList} option={v} nerow={true} />
                                )}
                                {view === "month" && (
                                    <CalendarOccupiedDate list={dateList} option={v} nerow={false} />
                                )}
                                {view === "day" && (
                                    <CalendarOccupiedTime list={hourList} option={v} nerow={false} />
                                )}
                            </Grid>
                        )
                    })}
                </Grid>
            ) : null}
            {viewExpose === "groupby" ? (
                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ height: { lg: screenHeight > 900 ? "calc(77.5vh)" : "calc(71vh)", xs: "auto" }, overflowY: "auto", }}>
                    {servicePlans?.map((v, i) => {
                        return (
                            <Grid item={true} key={i} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", }}>
                                <Grid item={true} xs={1.3} sm={1.3} md={1.3} lg={1.3} sx={{ display: { xs: "none", md: "flex", sm: "none", lg: "flex" }, flexDirection: "column", }}>
                                    <ParticipantCard v={v} />
                                    {/* <EmployeeCard v={v} /> */}
                                </Grid>
                                {view === "week" && (
                                    <CalendarOccupiedDate list={weekList} option={v} nerow={true} />
                                )}
                                {view === "month" && (
                                    <CalendarOccupiedDate list={dateList} option={v} nerow={false} />
                                )}
                                {view === "day" && (
                                    <CalendarOccupiedTime list={hourList} option={v} nerow={false} />
                                )}
                            </Grid>
                        )
                    })}
                </Grid>
            ) : null}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={editModal}
                onClose={() => handleCloseDetails()}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={editModal}>
                    <Box sx={style}>
                        <Box>
                            <Typography variant="h5" component="h2" sx={{ fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                                {"Service Details"}
                            </Typography>
                        </Box>
                        <Box sx={{ py: 1 }}>
                            <Typography color="primary" style={{ fontSize: "1.2em", fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                                Service Type : {selectedObject?.service_request?.service_type?.service_name}
                            </Typography>
                        </Box>
                        <Box sx={{ px: 2 }}>
                            <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                Funding Source : {selectedObject?.service_request?.funding_source}
                            </Typography>
                            <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                Billable : {selectedObject?.service_request?.billable}
                            </Typography>
                            <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                Pay Travel Km : {selectedObject?.service_request?.pay_travel_km}
                            </Typography>
                        </Box>
                        <Box sx={{ py: 1 }}>
                            <Typography color="primary" style={{ fontSize: "1.2em", fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                                Recurring Type : {selectedObject?.visit_data[0]?.attributes?.recurring_type.slice(7)}
                            </Typography>
                        </Box>
                        <Box sx={{ px: 2 }}>
                            {selectedObject?.visit_data[0]?.attributes?.start_date && (
                                <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                    Start Date : {selectedObject?.visit_data[0]?.attributes?.start_date}
                                </Typography>
                            )}
                            {selectedObject?.visit_data[0]?.attributes?.end_date && (
                                <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                    End Date : {selectedObject?.visit_data[0]?.attributes?.end_date}
                                </Typography>
                            )}
                            {selectedObject?.shifts_data?.map((value) => (
                                <Box key={value.day} sx={{ display: "flex", flexWrap: "wrap", width: "100%", py: 1 }}>
                                    <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                        <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                            Day Time Hours: {value.attributes.day_time_hours}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                        <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                            Shift Start: {value.attributes.shift_start_flag}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                        <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                            Start Time: {value.attributes.shift_start.slice(0, 5)}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                        <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                            End Time: {value.attributes.shift_end.slice(0, 5)}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                        <Box sx={{ py: 1 }}>
                            <Typography color="primary" style={{ fontSize: "1.2em", fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                                Check List
                            </Typography>
                            {selectedObject?.visit_check_list?.map((value) => (
                                <Box key={value?.attributes?.shift_title} sx={{ display: "flex", flexWrap: "wrap", width: "100%", px: 2, py: 1 }}>
                                    <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                        <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                            Shift Title: {value?.attributes?.shift_title}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                        <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                            Shift comments: {value?.attributes?.shift_comments}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                            <Typography color="primary" style={{ fontSize: "1.2em", fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                                Client Details
                            </Typography>
                            <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%", px: 2, py: 1 }}>
                                <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                    <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                        First Name: {selectedObject?.client_detail?.first_name}
                                    </Typography>
                                </Box>
                                <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                    <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                        Last Name: {selectedObject?.client_detail?.last_name}
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography color="primary" style={{ fontSize: "1.2em", fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                                Staff Details
                            </Typography>
                            <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%", px: 2, py: 1 }}>
                                <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                    <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                        First Name: {selectedObject?.staff_detail?.first_name}
                                    </Typography>
                                </Box>
                                <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                    <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                        Last Name: {selectedObject?.staff_detail?.last_name}
                                    </Typography>
                                </Box>
                                <Box sx={{ width: "25%", border: "1px solid lightgrey", p: 1, }}>
                                    <Typography color="secondary" style={{ fontFamily: "Roboto-Regular" }}>
                                        Email: {selectedObject?.staff_detail?.email_info}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
                            <Stack direction="row" spacing={2}>
                                {/* <Button variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Save
                                </Button> */}
                                <Button onClick={() => handleCloseDetails()} variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Grid >
    );
};

export default CustomCalendar;
