import { createSlice } from '@reduxjs/toolkit';
import data from '../../const/data.json'

const initialState = {
    myServices: [...data?.my_services],
    selectedService: null,
};

export const MyServicesReducer = createSlice({
    name: 'search_and_filters',
    initialState,
    reducers: {
        myServicesReducer: (state, action) => {
            state.myServices = action.payload;
        },
        selectedServiceReducer: (state, action) => {
            state.selectedService = action.payload;
        },
    },
});

export const {
    myServicesReducer,
    selectedServiceReducer
} = MyServicesReducer.actions;

export default MyServicesReducer.reducer;