import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading_state: 'stale',
};

export const LoadingStateReducer = createSlice({
    name: 'loading_state',
    initialState,
    reducers: {
        setLoadingState: (state, action) => {
            state.loading_state = action.payload;
        },
    },
});

export const { setLoadingState } = LoadingStateReducer.actions;

export default LoadingStateReducer.reducer;
