import { Alert, AlertTitle, Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, FormGroup, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Slide, Snackbar, Stack, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DatePickerUI, TimePickerUI } from "../../../components/Inputs";
import styled from "@emotion/styled";
// import AddIcon from '@mui/icons-material/Add';
import { DatePicker, LocalizationProvider, MobileTimePicker, mk } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from "dayjs";
import AddIcon from '@mui/icons-material/Add';
import { Cancel } from "@mui/icons-material";
import zIndex from "@mui/material/styles/zIndex";
import { useDispatch, useSelector } from "react-redux";
import { ContactInformation, createdId, DynamicStaffOnboard, EmergencyContactInformation, GeneralInformation, OthersInformation, PaymentDetailsM, WorkingDays } from "../../../middleware/reducers/staff_onboarding_reducer";
import { CreateWorkingDay } from "../../../services/staff_service";
import { useNavigate } from "react-router-dom";

const SlideTransition = (props) => {
    return <Slide {...props} direction="left" />;
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    color: theme.palette.text.secondary,
    alignContent: 'center',
}));

export const EmployeeInformation = ({ screenWidth }) => {
    const _draft_data = useSelector((state) => state._staff_onboarding_module.selectedDraft);
    const dispatch = useDispatch();

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
    const year = currentDate.getFullYear();
    const monthValue = month < 10 ? "0" + month : month;

    const [employeeInformations, setEmployeeInformation] = useState([
        {
            title: "First Name",
            value: "Asif",
            width: screenWidth,
            type: "text",
            required: true,
        },
        {
            title: "Last Name",
            value: "Khan",
            width: screenWidth,
            type: "text",
            required: true,
        },
        {
            title: "Gender",
            value: "Male",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: 'Male',
                    selected: true
                },
                {
                    title: 'Female',
                    selected: false
                },
                {
                    title: 'Others',
                    selected: false
                }
            ]
        },
        {
            title: "Date of Birth",
            value: year + "-" + monthValue + "-" + day,
            width: screenWidth,
            type: "date",
            required: true,
        },
        {
            title: "NDIS / Registration Number",
            value: "22-08-1991",
            width: screenWidth,
            type: "number",
            required: true,
        },
        {
            title: "Registration Expiry Date",
            value: year + "-" + monthValue + "-" + day,
            width: screenWidth,
            type: "date",
            required: true,
        },
        {
            title: "Visa Status",
            value: "Please Select",
            width: screenWidth,
            required: true,
            type: "drop_down",
            options: [
                {
                    title: 'Please Select',
                    selected: true
                },
                {
                    title: 'Australian Citizen',
                    selected: false
                },
                {
                    title: 'Temporary',
                    selected: false
                },
                {
                    title: 'Permanent',
                    selected: false
                },
                {
                    title: 'Other',
                    selected: false
                },
            ]
        },
        {
            title: "Designation",
            value: "Nurse",
            width: screenWidth,
            required: true,
            type: "drop_down",
            options: [
                {
                    title: 'Nurse',
                    selected: true
                },
                {
                    title: 'Support Worker',
                    selected: false
                },
                {
                    title: 'Admin',
                    selected: false
                }
            ]
        },
        {
            title: "Do you have Medical Card",
            value: "No",
            width: screenWidth,
            required: true,
            type: "drop_down",
            options: [
                {
                    title: 'Yes',
                    selected: true
                },
                {
                    title: 'No',
                    selected: false
                }
            ],
            childOption: {
                title: "Medicare Card",
                value: "12345678-1",
                width: screenWidth,
                type: "text",
                required: true,
            },
        },
        {
            title: "Police Check",
            value: "Verified",
            width: screenWidth,
            required: true,
            type: "drop_down",
            options: [
                {
                    title: 'Verified',
                    selected: true
                },
                {
                    title: 'Non-Verified',
                    selected: false
                }
            ]
        },
        {
            title: "Driving License ",
            value: "",
            width: screenWidth,
            type: "text",
            required: false,
        },
    ]);

    useEffect(() => {
        const copy1 = [...employeeInformations];
        if (_draft_data) {
            copy1[0].value = _draft_data?.general_information?.first_name;
            copy1[1].value = _draft_data?.general_information?.last_name;
            copy1[2].value = _draft_data?.general_information?.gender;
            copy1[3].value = _draft_data?.general_information?.date_of_birth;
            copy1[4].value = _draft_data?.general_information?.ndis_registration_number;
            copy1[5].value = _draft_data?.general_information?.registration_expiry_date;
            copy1[6].value = _draft_data?.general_information?.visa_status;
            copy1[7].value = _draft_data?.general_information?.designation;
            copy1[8].value = _draft_data?.general_information?.have_medical_card;
            // copy1[9].value = _draft_data?.general_information.medicare_card;
            copy1[9].value = _draft_data?.general_information?.police_check;
            copy1[10].value = _draft_data?.general_information?.driving_license;
        } else {
            copy1[0].value = "";
            copy1[1].value = "";
            copy1[2].value = "";
            copy1[3].value = "1990-05-05";
            copy1[4].value = "";
            copy1[5].value = "1990-05-05";
            copy1[6].value = "";
            copy1[7].value = "";
            copy1[8].value = "";
            copy1[9].value = "";
            copy1[10].value = "";
        }
        setEmployeeInformation(copy1);
    }, [_draft_data])

    useEffect(() => {
        if (employeeInformations) {
            const copy = [...employeeInformations];
            const obj = {
                first_name: copy[0].value,
                last_name: copy[1].value,
                gender: copy[2].value,
                date_of_birth: copy[3].value,
                ndis_registration_number: copy[4].value,
                registration_expiry_date: copy[5].value,
                visa_status: copy[6].value,
                designation: copy[7].value,
                have_medical_card: copy[8].value,
                // medicare_card: copy[8].value,
                police_check: copy[9].value,
                driving_license: copy[10].value,
            }
            dispatch(GeneralInformation(obj))
        }
    }, [employeeInformations]);


    return (
        <>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {employeeInformations.map((v, i) => {
                    return (
                        <div key={i}>
                            {v.type === "text" || v.type === "date" || v.type === "email" ? (
                                <TextField
                                    key={i}
                                    type={v.type}
                                    required={v.required}
                                    label={v.title}
                                    value={v.value}
                                    sx={{ m: 1, width: v.width, }}
                                    autoComplete="false"
                                    onChange={(e) => {
                                        const copy = [...employeeInformations];
                                        copy[i].value = e.target.value;
                                        setEmployeeInformation(copy);
                                    }}
                                />
                            ) : null}
                            {v.type === "drop_down" ? (
                                <div>
                                    <TextField
                                        select={true}
                                        label={v.title}
                                        key={i}
                                        required={v.required}
                                        sx={{ m: 1, width: v.width, }}
                                        value={v.value}
                                        autoComplete="false"
                                        onChange={(e) => {
                                            const copy = [...employeeInformations];
                                            copy[i].value = e.target.value;
                                            setEmployeeInformation(copy);
                                        }}
                                    >
                                        {v?.options?.map((option) => (
                                            <MenuItem key={option.title} value={option.title}>
                                                {option.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {v.value === "Yes" && (
                                        <TextField
                                            key={i}
                                            type={v.childOption.type}
                                            required={v.childOption.required}
                                            label={v.childOption.title}
                                            value={v.childOption.value}
                                            sx={{ m: 1, width: v.childOption.width }}
                                            autoComplete="false"
                                            onChange={(e) => {
                                                const copy = [...employeeInformations];
                                                copy[i].childOption.value = e.target.value;
                                                setEmployeeInformation(copy);
                                            }}
                                        />
                                    )}
                                </div>
                            ) : null}
                            {v.type === "number" || v.type === "call_number" ? (
                                <TextField
                                    key={i}
                                    type={v.type}
                                    required={v.required}
                                    label={v.title}
                                    value={v.value}
                                    sx={{ m: 1, width: v.width }}
                                    autoComplete="false"
                                    onChange={(e) => {
                                        const copy = [...employeeInformations];
                                        copy[i].value = e.target.value;
                                        setEmployeeInformation(copy);
                                    }}
                                />
                            ) : null}
                        </div>
                    )
                })}
            </Box>
        </>
    )
}

export const EmployeeContactDetails = ({ screenWidth }) => {
    const _draft_data = useSelector((state) => state._staff_onboarding_module.selectedDraft);
    const dispatch = useDispatch();

    const [employeeContactDetails, setEmployeeContactDetails] = useState([
        {
            title: "Email",
            value: "work@belacorp.com",
            width: screenWidth,
            type: "email",
            required: true,
            multiline: false
        },
        {
            title: "Home Phone",
            value: "0234567898",
            width: screenWidth,
            type: "call_number",
            required: true,
            multiline: false
        },
        {
            title: "Mobile Phone",
            width: screenWidth,
            value: "0234567898",
            type: "call_number",
            required: true,
            multiline: false
        },
        {
            title: "Work Phone",
            width: screenWidth,
            value: "0234567898",
            type: "call_number",
            required: true,
            multiline: false
        },
        {
            title: "Address 1",
            value: "abc town, zxc city, byt",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: true
        },
        {
            title: "Address 2",
            value: "abc town, zxc city, byt",
            width: screenWidth,
            type: "text",
            required: false,
            multiline: true
        },
        {
            title: "Country",
            value: "Australia",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Australia",
                    selected: true
                }
            ]
        },
        {
            title: "State",
            width: screenWidth,
            value: "SA",
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "SA",
                    selected: true
                },
                {
                    title: "WA",
                    selected: false
                }
            ]
        },
        {
            title: "Town / Suburb",
            value: "Aldinga Beach",
            width: screenWidth,
            type: "drop_down",
            required: true,
            multiline: false,
            options: [
                {
                    title: "Aldinga Beach",
                    selected: true,
                },
                {
                    title: "Blakeview",
                    selected: false,
                },
                {
                    title: "Davoren Park",
                    selected: false,
                },
                {
                    title: "Flagstaff Hill",
                    selected: false,
                },
                {
                    title: "Glenelg",
                    selected: false,
                },
                {
                    title: "Mawson Lakes",
                    selected: false,
                },
                {
                    title: "McLaren Flat",
                    selected: false,
                },
                {
                    title: "Mount Gambier",
                    selected: false,
                },
                {
                    title: "Myrtle Bank",
                    selected: false,
                },
                {
                    title: "North Adelaide",
                    selected: false,
                },
                {
                    title: "North Haven",
                    selected: false,
                },
                {
                    title: "Plympton Park",
                    selected: false,
                },
                {
                    title: "Prospect",
                    selected: false,
                },
                {
                    title: "Ridgehaven",
                    selected: false,
                },
                {
                    title: "Royal Park",
                    selected: false,
                },
                {
                    title: "Seaford Rise",
                    selected: false,
                },
            ]
        },
        {
            title: "Post Code",
            width: screenWidth,
            value: "35698",
            type: "text",
            multiline: false,
            required: true
        },
    ]);

    useEffect(() => {
        const copy1 = [...employeeContactDetails];
        if (_draft_data) {
            copy1[0].value = _draft_data?.contact_information.email;
            copy1[1].value = _draft_data?.contact_information.home_phone;
            copy1[2].value = _draft_data?.contact_information.mobile_phone;
            copy1[3].value = _draft_data?.contact_information.work_phone;
            copy1[4].value = _draft_data?.contact_information.address_1;
            copy1[5].value = _draft_data?.contact_information.address_2;
            copy1[6].value = _draft_data?.contact_information.country;
            copy1[7].value = _draft_data?.contact_information.state;
            copy1[8].value = _draft_data?.contact_information.town_suburb;
            copy1[9].value = _draft_data?.contact_information.post_code;
        } else {
            copy1[0].value = "";
            copy1[1].value = "";
            copy1[2].value = "";
            copy1[3].value = "";
            copy1[4].value = "";
            copy1[5].value = "";
            copy1[6].value = "";
            copy1[7].value = "";
            copy1[8].value = "";
            copy1[9].value = "";
        }
        setEmployeeContactDetails(copy1);
    }, [_draft_data])

    useEffect(() => {
        if (employeeContactDetails) {
            const copy = [...employeeContactDetails];
            const obj = {
                email: copy[0].value,
                home_phone: copy[1].value,
                mobile_phone: copy[2].value,
                work_phone: copy[3].value,
                address_1: copy[4].value,
                address_2: copy[5].value,
                country: copy[6].value,
                state: copy[7].value,
                town_suburb: copy[8].value,
                post_code: copy[9].value,
            }
            dispatch(ContactInformation(obj))
        }
    }, [employeeContactDetails]);
    return (
        <>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {employeeContactDetails.map((v, i) => {
                    return (
                        <div key={i}>
                            {v.type === "text" || v.type === "email" || v.type === "number" || v.type === "call_number" || v.type === "time" ? (
                                <TextField
                                    key={i}
                                    type={v.type}
                                    required={v.required}
                                    label={v.title}
                                    value={v.value}
                                    sx={{ m: 1, width: v.width, }}
                                    multiline={v.multiline}
                                    autoComplete="false"
                                    onChange={(e) => {
                                        const copy = [...employeeContactDetails];
                                        copy[i].value = e.target.value;
                                        setEmployeeContactDetails(copy);
                                    }}
                                />
                            ) : null}

                            {v.type === "drop_down" ? (
                                <TextField
                                    select={true}
                                    label={v.title}
                                    key={i}
                                    required={v.required}
                                    sx={{ m: 1, width: v.width }}
                                    value={v.value}
                                    onChange={(e) => {
                                        const copy = [...employeeContactDetails];
                                        copy[i].value = e.target.value;
                                        setEmployeeContactDetails(copy);
                                    }}
                                >
                                    {v?.options?.map((option) => (
                                        <MenuItem key={option.title} value={option.title}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            ) : null}
                        </div>
                    )
                })}
            </Box>
        </>
    )
}

export const EmergencyContactDetails = ({ screenWidth }) => {
    const _draft_data = useSelector((state) => state._staff_onboarding_module.selectedDraft);
    const dispatch = useDispatch();

    const [emergencyContactDetails, setEmergencyContactDetails] = useState([
        {
            title: "First Name",
            value: "Asif",
            width: screenWidth,
            type: "text",
            required: true,
        },
        {
            title: "Last Name",
            value: "Khan",
            width: screenWidth,
            type: "text",
            required: true,
        },
        {
            title: "Relationship",
            value: "Uncle",
            width: screenWidth,
            type: "text",
            required: true,
        },
        {
            title: "Email",
            value: "admin@belacorp.com",
            width: screenWidth,
            type: "email",
            required: true,
        },
        {
            title: "Home Phone",
            value: "0234567898",
            width: screenWidth,
            type: "call_number",
            required: true
        },
        {
            title: "Mobile Phone",
            width: screenWidth,
            value: "0234567898",
            type: "call_number",
            required: true
        },
        {
            title: "Address 1",
            value: "abc town, zxc city, byt",
            width: screenWidth,
            type: "text",
            required: true,
        },
        {
            title: "Address 2",
            value: "abc town, zxc city, byt",
            width: screenWidth,
            type: "text",
            required: false,
        },
    ]);

    useEffect(() => {
        const copy1 = [...emergencyContactDetails];
        if (_draft_data) {
            copy1[0].value = _draft_data?.emergency_contact_information.first_name;
            copy1[1].value = _draft_data?.emergency_contact_information.last_name;
            copy1[2].value = _draft_data?.emergency_contact_information.relationship;
            copy1[3].value = _draft_data?.emergency_contact_information.email;
            copy1[4].value = _draft_data?.emergency_contact_information.home_phone;
            copy1[5].value = _draft_data?.emergency_contact_information.mobile_phone;
            copy1[6].value = _draft_data?.emergency_contact_information.address_1;
            copy1[7].value = _draft_data?.emergency_contact_information.address_2;
        } else {
            copy1[0].value = "";
            copy1[1].value = "";
            copy1[2].value = "";
            copy1[3].value = "";
            copy1[4].value = "";
            copy1[5].value = "";
            copy1[6].value = "";
            copy1[7].value = "";
        }
        setEmergencyContactDetails(copy1);
    }, [_draft_data])

    useEffect(() => {
        if (emergencyContactDetails) {
            const copy = [...emergencyContactDetails];
            const obj = {
                first_name: copy[0].value,
                last_name: copy[1].value,
                relationship: copy[2].value,
                email: copy[3].value,
                home_phone: copy[4].value,
                mobile_phone: copy[5].value,
                address_1: copy[6].value,
                address_2: copy[7].value,
            }
            dispatch(EmergencyContactInformation(obj))
        }
    }, [emergencyContactDetails]);

    return (
        <>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {emergencyContactDetails.map((v, i) => {
                    return (
                        <div key={i}>
                            <TextField
                                type={v.type}
                                key={i}
                                required={v.required}
                                label={v.title}
                                multiline={v.multiline}
                                value={v.value}
                                sx={{ m: 1, width: v.width }}
                                autoComplete="false"
                                onChange={(e) => {
                                    const copy = [...emergencyContactDetails];
                                    copy[i].value = e.target.value;
                                    setEmergencyContactDetails(copy);
                                }}
                            />
                        </div>
                    )
                })}
            </Box>
        </>
    )
}

export const PayRates = ({ payRates }) => {
    return (
        <>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {payRates.map((v, i) => {
                    return (
                        <div key={i}>
                            {v.type === "text" || v.type === "number" ? (
                                <TextField
                                    style={{ marginBottom: "2%" }}
                                    type={v.type}
                                    key={i}
                                    required={v.required}
                                    label={v.title}
                                    defaultValue={v.value}
                                    placeholder=""
                                    sx={{ m: 1, width: v.width }}
                                />
                            ) : null}
                            {v.type === "drop_down" ? (
                                <TextField
                                    select={true}
                                    label={v.title}
                                    key={i}
                                    required={v.required}
                                    sx={{ m: 1, width: v.width }}
                                    defaultValue={v.value}
                                // helperText={"Please select your " + v.title}
                                >
                                    {v?.options?.map((option) => (
                                        <MenuItem key={option.title} value={option.title}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            ) : null}
                        </div>
                    )
                })}
            </Box>
        </>
    )
}

export const PersnalDocsUploadForStaff = ({ screenWidth, jobProfileDetails }) => {
    const [training, setTraining] = useState([
        {
            title: "Select Option to Upload Documents",
            value: "Select option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Select option",
                    selected: true
                },
                {
                    title: "NDIS New Worker NDIS Induction Module",
                    selected: false
                },
                {
                    title: "NDIS Communication Module",
                    selected: false
                },
                {
                    title: "NDIS Mealtime module",
                    selected: false
                },
                {
                    title: "Manual Handling Training",
                    selected: false
                },
                {
                    title: "Medication management training as appropriate",
                    selected: false
                },
                {
                    title: "PPE Training",
                    selected: false
                },
            ]
        }
    ]);

    const [documentName, setDocumentName] = useState("");

    const [miscellaneous, setMiscellaneous] = useState([
        {
            title: "Select Option to Upload Documents",
            value: "Select option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Select option",
                    selected: true
                },
                {
                    title: "Minutes of meetings where complaints are discussed and recorded",
                    selected: false
                },
                {
                    title: "Organization chart",
                    selected: false
                },
            ]
        },
        {
            title: "Document Name",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
        }
    ]);

    const [certificates, setCertificates] = useState([
        {
            title: "Select Option to Upload Documents",
            value: "Select option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Select option",
                    selected: true
                },
                {
                    title: "NDIS Worker Orientation Module Certificate",
                    selected: false
                },
                {
                    title: "NDIS Supporting Effective Communication Certificate",
                    selected: false
                },
                {
                    title: "Infection Control Certificate",
                    selected: false
                },
                {
                    title: "PPE Training Certificate",
                    selected: false
                },
                {
                    title: "CPR Training Certificate",
                    selected: false
                },
            ]
        }
    ]);

    const [iDPermit, setiDPermit] = useState([
        {
            title: "Select Option to Upload Documents",
            value: "Select option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                { title: "Select option" },
                { title: 'Profile Picture' },
                { title: '100 points of ID' },
                { title: 'Letter of Engagement' },
                { title: 'NDIS Mandatory Workers Orientation Certificate' },
                { title: 'Ahpra registration(if applicable)' },
                { title: 'Resume' },
                { title: 'Driving License' },
                { title: 'Qualification certificates' },
                { title: 'Vaccination Records' },
                { title: 'Next of Kin contact' },
                { title: 'Induction records' },
                { title: 'Right to work in Australia visa check via DFAT(if applicable)' },
                { title: 'Police Check' },
                { title: "Working with Children's Check" },
                { title: 'NDIS Screening Check(when available)' },
            ]
        }
    ]);

    const [uploadDocs, setUploadDocs] = useState([
        {
            title: "Select Category",
            value: "Select option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Select option",
                    selected: true
                },
                {
                    title: "Training",
                    selected: false,
                },
                {
                    title: "Certificates",
                    selected: false,
                },
                {
                    title: "ID / Permit",
                    selected: false,
                },
                {
                    title: "Miscellaneous",
                    selected: false,
                }
            ],
        }
    ]);

    const uploadDocsInArray = (event, index) => {
        const copy = [...uploadDocs];
        const { optionkey, parentkey } = index.props;
        const value = event.target.value;
        copy[parentkey].value = value;
        for (const keyLop in copy[parentkey].options) {
            if (copy[parentkey].options[keyLop].title === value) {
                copy[parentkey].options[keyLop].selected = true;
            } else {
                copy[parentkey].options[keyLop].selected = false;
            }
        }
        setUploadDocs(copy);
        setDocumentName("");
    };

    const handleTrainingInputs = (index, event) => {
        const copy = [...training];
        copy[index].value = event.target.value;
        setTraining(copy);
        setDocumentName(event.target.value)
    };

    const handlecertificatesInputs = (index, event) => {
        const copy = [...certificates];
        copy[index].value = event.target.value;
        setCertificates(copy);
        setDocumentName(event.target.value)
    };

    const handleiDPermitInputs = (index, event) => {
        const copy = [...iDPermit];
        copy[index].value = event.target.value;
        setiDPermit(copy);
        setDocumentName(event.target.value)
    };

    const handleMiscellaneousInputs = (index, event) => {
        const copy = [...miscellaneous];
        copy[index].value = event.target.value;
        setMiscellaneous(copy);
        setDocumentName(event.target.value)
    };

    return (
        <>
            <Box sx={{ mx: 1, display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                <List sx={{ width: '70%', bgcolor: 'background.paper' }} >
                    <ListItem
                        components={Paper}
                        elevation={1}
                        secondaryAction={
                            <Stack direction={"row"} spacing={2}>
                                <IconButton disabled edge="end" aria-label="delete">
                                    {"ID / Permit"}
                                </IconButton>
                                <IconButton disabled edge="end" aria-label="delete">
                                    {"03-20-2024"}
                                </IconButton>
                                <IconButton edge="end" aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                        }
                    >
                        <ListItemIcon>
                            <Typography sx={{ fontSize: "1em", fontFamily: "Roboto-Medium" }}>{"1"}</Typography>
                        </ListItemIcon>
                        <ListItemText
                            primary="my-resume.pdf"
                            secondary={"abc description"}
                        />
                    </ListItem>
                </List>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', }}>
                {jobProfileDetails.map((v, i) => {
                    return (
                        <div key={i}>
                            {v.type === "text" ? (
                                <TextField
                                    style={{ marginBottom: "2%" }}
                                    type={v.type}
                                    key={i}
                                    required={v.required}
                                    label={v.title}
                                    defaultValue={v.value}
                                    placeholder=""
                                    sx={{ m: 1, width: v.width }}
                                />
                            ) : null}
                        </div>
                    )
                })}
                {uploadDocs.map((v, i) => {
                    return (
                        <div key={i}>
                            <TextField
                                select={true}
                                label={v.title}
                                key={i}
                                required={v.required}
                                sx={{ m: 1, width: v.width }}
                                value={v.value}
                                onChange={uploadDocsInArray}
                            >
                                {v?.options?.map((option, nI) => (
                                    <MenuItem key={nI} optionkey={nI} parentkey={i} value={option.title}>
                                        {option.title}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {v.value.includes("Training") && training.map((vN, iN) => (
                                <TextField
                                    select={true}
                                    label={vN.title}
                                    key={iN}
                                    required={vN.required}
                                    sx={{ m: 1, width: vN.width }}
                                    value={vN.value}
                                    onChange={(e) => handleTrainingInputs(iN, e)}
                                >
                                    {vN?.options?.map((option, nI) => (
                                        <MenuItem key={nI} optionkey={nI} parentkey={i} value={option.title}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            ))}
                            {v.value.includes("Certificates") && certificates.map((vN, iN) => (
                                <TextField
                                    select={true}
                                    label={vN.title}
                                    key={iN}
                                    required={vN.required}
                                    sx={{ m: 1, width: vN.width }}
                                    value={vN.value}
                                    onChange={(e) => handlecertificatesInputs(iN, e)}
                                >
                                    {vN?.options?.map((option, nI) => (
                                        <MenuItem key={nI} optionkey={nI} parentkey={i} value={option.title}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            ))}
                            {v.value.includes("Permit") && iDPermit.map((vN, iN) => (
                                <TextField
                                    select={true}
                                    label={vN.title}
                                    key={iN}
                                    required={vN.required}
                                    sx={{ m: 1, width: vN.width }}
                                    value={vN.value}
                                    onChange={(e) => handleiDPermitInputs(iN, e)}
                                >
                                    {vN?.options?.map((option, nI) => (
                                        <MenuItem key={nI} optionkey={nI} parentkey={i} value={option.title}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            ))}
                            {v.value.includes("Miscellaneous") && miscellaneous.map((vN, iN) => (
                                <>
                                    {vN.type === "text" && (
                                        <TextField
                                            type={vN.type}
                                            key={i}
                                            required={vN.required}
                                            label={vN.title}
                                            defaultValue={vN.value}
                                            sx={{ m: 1, width: vN.width }}
                                            onChange={(e) => handleMiscellaneousInputs(iN, e)}
                                        />
                                    )}
                                </>
                                // <TextField
                                //     select={true}
                                //     label={vN.title}
                                //     key={iN}
                                //     required={vN.required}
                                //     sx={{ m: 1, width: vN.width }}
                                //     value={vN.value}
                                //     onChange={(e) => handleMiscellaneousInputs(iN, e)}
                                // >
                                //     {vN?.options?.map((option, nI) => (
                                //         <MenuItem key={nI} optionkey={nI} parentkey={i} value={option.title}>
                                //             {option.title}
                                //         </MenuItem>
                                //     ))}
                                // </TextField>
                            ))}
                        </div>
                    )
                })}
                {jobProfileDetails.map((v, i) => {
                    return (
                        <div key={i}>
                            {v.type === "date" ? (
                                <DatePickerUI label={v.title} defaultValue={v.value} width={v.width} />
                            ) : null}
                        </div>
                    )
                })}
                {documentName && documentName.toLocaleLowerCase() !== "select option" && (
                    <Button sx={{ m: 1, width: screenWidth, padding: "15px 16px" }} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                        {documentName.length > 30 ? documentName.slice(0, 30) + "..." : documentName}
                        <VisuallyHiddenInput type="file" onChange={(e) => console.log(e.target.files)} />
                    </Button>
                )}
            </Box >
        </>
    );
}

export const QualificationsAndNotes = ({ qualifications, qualificationNotes }) => {
    return (
        <>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {qualifications.map((v, i) => {
                    return (
                        <div key={i}>
                            {v.type === "text" ? (
                                <TextField
                                    // style={{ marginBottom: "2%" }}
                                    type={v.type}
                                    key={i}
                                    required={v.required}
                                    label={v.title}
                                    defaultValue={v.value}
                                    placeholder=""
                                    sx={{ m: 1, width: v.width }}
                                />
                            ) : null}
                            {v.type === "date" ? (
                                <DatePickerUI label={v.title} defaultValue={v.value} width={v.width} />
                            ) : null}
                        </div>
                    )
                })}
            </Box>
            <div className="form-heading2">
                <span>Qualification Notes</span>
            </div>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {qualificationNotes.map((v, i) => {
                    return (
                        <div key={i}>
                            {v.type === "text" ? (
                                <TextField
                                    style={{ marginBottom: "2%" }}
                                    type={v.type}
                                    key={i}
                                    required={v.required}
                                    label={v.title}
                                    defaultValue={v.value}
                                    placeholder=""
                                    sx={{ m: 1, width: v.width }}
                                />
                            ) : null}
                        </div>
                    )
                })}
            </Box>
        </>
    )
}

export const EmployeeStatus = ({ employeeStatus }) => {
    return (
        <>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {employeeStatus.map((v, i) => {
                    return (
                        <div key={i}>
                            {v.type === "date" ? (
                                <DatePickerUI label={v.title} defaultValue={v.value} width={v.width} />
                            ) : null}
                            {v.type === "long_text" ? (
                                <TextField
                                    style={{ marginBottom: "2%" }}
                                    multiline={true}
                                    type={v.type}
                                    key={i}
                                    required={v.required}
                                    label={v.title}
                                    defaultValue={v.value}
                                    placeholder=""
                                    sx={{ m: 1, width: v.width }}
                                />
                            ) : null}

                        </div>
                    )
                })}
            </Box>
        </>
    )
};

export const NDISUploadDocumentsForStaff = ({ screenWidth, nDISWorkerInformation }) => {
    const [uploadDocs, setUploadDocs] = useState([
        {
            title: "Selecte Option to Upload Documents",
            value: "NDIS Worker Orientation Module Certificate",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Select option",
                    selected: false
                },
                {
                    title: "NDIS Worker Orientation Module Certificate",
                    selected: true
                },
                {
                    title: "NDIS Supporting Effective Communication Certificate",
                    selected: false
                },
                {
                    title: "NDIS New Worker NDIS Induction Module",
                    selected: false
                },
                {
                    title: "Others",
                    selected: false
                }
            ],
            files: []
        }
    ])
    const uploadDocsInArray = (event, index) => {
        const copy = [...uploadDocs];
        const { optionkey, parentkey } = index.props;
        const value = event.target.value;
        copy[parentkey].value = value;
        for (const keyLop in copy[parentkey].options) {
            if (copy[parentkey].options[keyLop].title === value) {
                copy[parentkey].options[keyLop].selected = true;
            } else {
                copy[parentkey].options[keyLop].selected = false;
            }
        }
        setUploadDocs(copy)
    };
    return (
        <>
            <Box sx={{ m: 1, display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                <List
                    sx={{ width: '50%', bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    <ListItem
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        }
                    >
                        <ListItemIcon>
                            {"1"}
                        </ListItemIcon>
                        <ListItemText
                            primary="NDIS-Worker-Orientation-Module-Certificate.pdf"
                        // secondary={secondary ? 'Secondary text' : null}
                        />
                    </ListItem>
                </List>
            </Box>
            {/* <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {nDISWorkerInformation.map((v, i) => {
                    return (
                        <div key={i}>
                            {v.type === "text" ? (
                                <TextField
                                    style={{ marginBottom: "2%" }}
                                    type={v.type}
                                    key={i}
                                    required={v.required}
                                    label={v.title}
                                    defaultValue={v.value}
                                    placeholder=""
                                    sx={{ m: 1, width: v.width }}
                                />
                            ) : null}

                        </div>
                    )
                })}
            </Box> */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', }}>
                {uploadDocs.map((v, i) => {
                    return (
                        <TextField
                            select={true}
                            label={v.title}
                            key={i}
                            required={v.required}
                            sx={{ m: 1, width: v.width }}
                            value={v.value}
                            // onChange={(e) => uploadDocsInArray(e.target.value, i)}
                            onChange={uploadDocsInArray}
                        >
                            {v?.options?.map((option, nI) => (
                                <MenuItem key={nI} optionkey={nI} parentkey={i} value={option.title}>
                                    {option.title}
                                </MenuItem>
                            ))}
                        </TextField>
                    )
                })}
            </Box >
            {uploadDocs.map((v, i) => {
                return (
                    <Box key={i} sx={{ m: 1, display: 'flex', flexWrap: 'wrap', }}>
                        {v.options.map((nV, nI) => {
                            if (nV.selected && nI !== 0) {
                                return (
                                    <Button key={nI} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                                        {nV.title}
                                        <VisuallyHiddenInput type="file" onChange={(e) => console.log(e.target.files)} />
                                    </Button>
                                )
                            }
                        })}
                    </Box>
                )
            })}
        </>
    );
}

export const UploadDocumentsForStaff = ({ screenWidth, documentsthatneedtoberenewed }) => {
    const [uploadDocs, setUploadDocs] = useState([
        {
            title: "Selecte option to upload documents",
            value: "Infection Control Certificate",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Select option",
                    selected: false
                },
                {
                    title: "Infection Control Certificate",
                    selected: true
                },
                {
                    title: "PPE Training Certificate",
                    selected: false
                },
                {
                    title: "Manual Handling Training",
                    selected: false
                },
                {
                    title: "Medication Administration",
                    selected: false
                },
                {
                    title: "CPR Training Certificate",
                    selected: false
                },
                {
                    title: "Others",
                    selected: false
                }
            ],
            files: []
        }
    ])
    const uploadDocsInArray = (event, index) => {
        const copy = [...uploadDocs];
        const { optionkey, parentkey } = index.props;
        const value = event.target.value;
        copy[parentkey].value = value;
        for (const keyLop in copy[parentkey].options) {
            if (copy[parentkey].options[keyLop].title === value) {
                copy[parentkey].options[keyLop].selected = true;
            } else {
                copy[parentkey].options[keyLop].selected = false;
            }
        }
        setUploadDocs(copy)
    };
    return (
        <>
            <Box sx={{ m: 1, display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                <List sx={{ width: '100%', maxWidth: window.screen.width, bgcolor: 'background.paper' }}>
                    <ListItem
                        component={Paper}
                        elevation={1}
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        }
                    >
                        <ListItemIcon>
                            {"1"}
                        </ListItemIcon>
                        <ListItemText
                            primary="Infection Control Certificate.pdf"
                        // secondary={secondary ? 'Secondary text' : null}
                        />
                    </ListItem>
                </List>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {documentsthatneedtoberenewed.map((v, i) => {
                    return (
                        <div key={i}>
                            {v.type === "drop_down" ? (
                                <TextField
                                    select={true}
                                    label={v.title}
                                    key={i}
                                    required={v.required}
                                    sx={{ m: 1, width: v.width, marginRight: v.title === "Title" ? 0 : 1 }}
                                    defaultValue={v.value}
                                // helperText={"Please select your " + v.title}
                                >
                                    {v?.options?.map((option) => (
                                        <MenuItem key={option.title} value={option.title}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            ) : null}
                        </div>
                    )
                })}
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', }}>
                {uploadDocs.map((v, i) => {
                    return (
                        <TextField
                            select={true}
                            label={v.title}
                            key={i}
                            required={v.required}
                            sx={{ m: 1, width: v.width }}
                            value={v.value}
                            // onChange={(e) => uploadDocsInArray(e.target.value, i)}
                            onChange={uploadDocsInArray}
                        >
                            {v?.options?.map((option, nI) => (
                                <MenuItem key={nI} optionkey={nI} parentkey={i} value={option.title}>
                                    {option.title}
                                </MenuItem>
                            ))}
                        </TextField>
                    )
                })}
            </Box >
            {uploadDocs.map((v, i) => {
                return (
                    <Box key={i} sx={{ m: 1, display: 'flex', flexWrap: 'wrap', }}>
                        {v.options.map((nV, nI) => {
                            if (nV.selected && nI !== 0) {
                                return (
                                    <Button key={nI} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                                        {nV.title}
                                        <VisuallyHiddenInput type="file" onChange={(e) => console.log(e.target.files)} />
                                    </Button>
                                )
                            }
                        })}
                    </Box>
                )
            })}
        </>
    );
}

export const OthersInformationForm = ({ screenWidth }) => {

    const _draft_data = useSelector((state) => state._staff_onboarding_module.selectedDraft);
    const dispatch = useDispatch();

    const [othersInformation, setOthersInformation] = useState([
        {
            title: "Are you a Smoker/ Drinker?",
            value: "No",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Yes",
                    selected: true
                },
                {
                    title: "No",
                    selected: false
                }
            ]
        },
        {
            title: "Do you have any allergies?",
            value: "No",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Yes",
                    selected: true
                },
                {
                    title: "No",
                    selected: false
                }
            ]
        },
        {
            title: "Do you have any type of conveyance to commute?",
            value: "No",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Yes",
                    selected: true
                },
                {
                    title: "No",
                    selected: false
                }
            ]
        },
        {
            title: "Do you have a driving license?",
            value: "No",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Yes",
                    selected: true
                },
                {
                    title: "No",
                    selected: false
                }
            ]
        },
    ]);

    useEffect(() => {
        if (_draft_data) {
            const copy = [...othersInformation];
            copy[0].value = _draft_data?.others_information.are_you_a_smoker_drinker
            copy[1].value = _draft_data?.others_information.do_you_have_any_allergies
            copy[2].value = _draft_data?.others_information.do_you_have_any_type_of_conveyance_to_commute
            copy[3].value = _draft_data?.others_information.do_you_have_a_driving_license
            setOthersInformation(copy);
        }
    }, [_draft_data])

    useEffect(() => {
        if (othersInformation) {
            const copy = [...othersInformation];
            const obj = {
                are_you_a_smoker_drinker: copy[0].value,
                do_you_have_any_allergies: copy[1].value,
                do_you_have_any_type_of_conveyance_to_commute: copy[2].value,
                do_you_have_a_driving_license: copy[3].value,
            }

            dispatch(OthersInformation(obj));
        }
    }, [othersInformation])

    return (
        <>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {othersInformation.map((v, i) => {
                    return (
                        <div key={i}>
                            {v.type === "drop_down" ? (
                                <TextField
                                    select={true}
                                    label={v.title}
                                    key={i}
                                    required={v.required}
                                    sx={{ m: 1, width: v.width }}
                                    value={v.value}
                                    onChange={(e) => {
                                        const copy = [...othersInformation];
                                        copy[i].value = e.target.value;
                                        setOthersInformation(copy);
                                    }}
                                >
                                    {v?.options?.map((option) => (
                                        <MenuItem key={option.title} value={option.title}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            ) : null}
                            {v.type === "file" ? (
                                <Tooltip title={v.tip}>
                                    <Button sx={{ m: 1, width: v.width, padding: "15px 16px" }} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                                        {v.title}
                                        <VisuallyHiddenInput type="file" onChange={(e) => console.log(e.target.files)} />
                                    </Button>
                                </Tooltip>
                            ) : null}
                            {v.type === "text" ? (
                                <TextField
                                    style={{ marginBottom: "2%" }}
                                    type={v.type}
                                    key={i}
                                    required={v.required}
                                    label={v.title}
                                    defaultValue={v.value}
                                    placeholder=""
                                    sx={{ m: 1, width: v.width }}
                                />
                            ) : null}
                        </div>
                    )
                })}
            </Box>
        </>
    )
}

export const WorkDaysAndTimeSlots = () => {
    const _draft_data = useSelector((state) => state._staff_onboarding_module.selectedDraft);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const _database_id = useSelector((state) => state._staff_onboarding_module.created_id);
    const [apiCreateStaffDay, loading_day, error_day] = CreateWorkingDay(); // create staff question

    const [workDays, setWorkDays] = useState([
        {
            title: "Monday",
            value: "Monday",
            type: "checkbox",
            required: true,
            timeSlot: [
                {
                    date: new Date(),
                    startTime: dayjs('2022-04-17T12:00'),
                    endTime: dayjs('2022-04-17T12:00'),
                    required: true,
                    type: "timePicker"
                }
            ],
            availbaleOption: [
                {
                    title: 'Unavailable',
                    selected: false
                },
                {
                    title: 'Whole Day',
                    selected: false
                },
            ]
        },
        {
            title: "Tuesday",
            value: "Tuesday",
            type: "checkbox",
            required: true,
            timeSlot: [
                {
                    date: new Date(),
                    startTime: dayjs('2022-04-17T12:00'),
                    endTime: dayjs('2022-04-17T12:00'),
                    required: true,
                    type: "timePicker"
                }
            ],
            availbaleOption: [
                {
                    title: 'Unavailable',
                    selected: false
                },
                {
                    title: 'Whole Day',
                    selected: false
                },
            ]
        },
        {
            title: "Wednesday",
            value: "Wednesday",
            type: "checkbox",
            required: true,
            timeSlot: [
                {
                    date: new Date(),
                    startTime: dayjs('2022-04-17T12:00'),
                    endTime: dayjs('2022-04-17T12:00'),
                    required: true,
                    type: "timePicker"
                }
            ],
            availbaleOption: [
                {
                    title: 'Unavailable',
                    selected: false
                },
                {
                    title: 'Whole Day',
                    selected: false
                },
                // {
                //     title: 'Available',
                //     selected: false
                // }
            ]
        },
        {
            title: "Thursday",
            value: "Thursday",
            type: "checkbox",
            required: true,
            timeSlot: [
                {
                    date: new Date(),
                    startTime: dayjs('2022-04-17T12:00'),
                    endTime: dayjs('2022-04-17T12:00'),
                    required: true,
                    type: "timePicker"
                }
            ],
            availbaleOption: [
                {
                    title: 'Unavailable',
                    selected: false
                },
                {
                    title: 'Whole Day',
                    selected: false
                },
            ]
        },
        {
            title: "Friday",
            value: "Friday",
            type: "checkbox",
            required: true,
            timeSlot: [
                {
                    date: new Date(),
                    startTime: dayjs('2022-04-17T12:00'),
                    endTime: dayjs('2022-04-17T12:00'),
                    required: true,
                    type: "timePicker"
                }
            ],
            availbaleOption: [
                {
                    title: 'Unavailable',
                    selected: false
                },
                {
                    title: 'Whole Day',
                    selected: false
                },
                // {
                //     title: 'Available',
                //     selected: false
                // }
            ]
        },
        {
            title: "Saturday",
            value: "Saturday",
            type: "checkbox",
            required: true,
            timeSlot: [
                {
                    date: new Date(),
                    startTime: dayjs('2022-04-17T12:00'),
                    endTime: dayjs('2022-04-17T12:00'),
                    required: true,
                    type: "timePicker"
                }
            ],
            availbaleOption: [
                {
                    title: 'Unavailable',
                    selected: false
                },
                {
                    title: 'Whole Day',
                    selected: false
                },
                // {
                //     title: 'Available',
                //     selected: false
                // }
            ]
        },
        {
            title: "Sunday",
            value: "Sunday",
            type: "checkbox",
            required: true,
            timeSlot: [
                {
                    date: new Date(),
                    startTime: dayjs('2022-04-17T12:00'),
                    endTime: dayjs('2022-04-17T12:00'),
                    required: true,
                    type: "timePicker"
                }
            ],
            availbaleOption: [
                {
                    title: 'Unavailable',
                    selected: false
                },
                {
                    title: 'Whole Day',
                    selected: false
                },
                // {
                //     title: 'Available',
                //     selected: false
                // }
            ]
        },
    ]);

    const changeAvailability = (mKey, nKey, value) => {
        const copy = [...workDays];
        for (const key in copy[mKey].availbaleOption) {
            if (key === String(nKey)) {
                copy[mKey].availbaleOption[nKey].selected = !copy[mKey].availbaleOption[nKey].selected;
            } else {
                copy[mKey].availbaleOption[key].selected = false;
            }
        }
        setWorkDays(copy)
    }
    const addNewTimeSlot = (mKey, value) => {
        const copy = [...workDays];
        if (copy[mKey].timeSlot.length < 3) {
            copy[mKey].timeSlot.push({
                date: new Date(),
                startTime: dayjs('2022-04-17T12:00'),
                endTime: dayjs('2022-04-17T12:00'),
                required: true,
                type: "timePicker"
            })
        }
        setWorkDays(copy)
    }
    const changeStartTimeSlot = (mKey, nKey, value) => {
        const copy = [...workDays];
        // Start Time
        copy[mKey].timeSlot[nKey].startTime = value;
        setWorkDays(copy)
    }
    const changeEndTimeSlot = (mKey, nKey, value) => {
        const copy = [...workDays];
        // End Time
        copy[mKey].timeSlot[nKey].endTime = value;
        setWorkDays(copy)
    }

    const deleteTimeSlot = (index, nIndex) => {
        const copy = [...workDays];
        const copyZ = { ...copy[index] }
        console.log(copyZ)
        console.log(copyZ[nIndex])
    }

    useEffect(() => {
        const copy = [...workDays];
        if (_draft_data) {
            const data = _draft_data?.working_days;
            for (const key in data) {
                if (data[key].day === copy[key].value) {
                    console.log(data[key])
                    console.log(copy[key])
                    const avail = copy[key].availbaleOption;
                    avail[0].selected = data[key].availability.unavailable;
                    avail[1].selected = data[key].availability.whole_day;
                    const slots = data[key].slots;
                    // for (const k in slots) {
                    //     copy[key].timeSlot[k].startTime.$H = 8
                    //     copy[key].timeSlot[k].startTime.$m = 30
                    //     // copy[key].timeSlot[k].startTime.$m = slots[k].start_time.M
                    // }
                }
            }
        }
        setWorkDays(copy)
    }, [_draft_data])

    // useEffect(() => {
    //     if (workDays) {
    //         const copy = [...workDays];
    //         dispatch(WorkingDays(copy))
    //     }
    // }, [workDays])

    const [snacks, setSnack] = useState({
        open: false,
        Transition: SlideTransition,
        vertical: 'top',
        horizontal: 'right',
    });

    const [errorSnackMessage, setErrorSnackMessage] = useState({
        title: "",
        message: "",
        severity: "",
        variant: "filled"
    });
    const handleCloseSnacks = () => {
        setSnack({
            ...snacks,
            open: false,
        });
    };

    let count = 0;

    const handleSnacksApper = (title, message, severity) => {
        setSnack({
            ...snacks,
            open: true,
        });
        // / // // // display message
        setErrorSnackMessage({
            ...errorSnackMessage,
            title,
            message,
            severity
        })
    }

    const createWorkingDay = async (data, totalCount) => {
        try {
            const response = await apiCreateStaffDay({
                variables: data
            });
            if (response.data) {
                ++count
            }
            if (totalCount == count) {
                handleSnacksApper("Staff Onboarding", "Working days has been created", "success")
            }
        } catch (error) {
            let message = "Something went wrong.!";
            if (error_day) {
                const errors = error_day;
                if (errors?.networkError) {
                    message = "Network error occurred. Please try again later"
                }
                if (errors?.graphQLErrors) {
                    message = errors.graphQLErrors[0]?.message
                }
                handleSnacksApper("Staff Onboarding", message, "error")
            }
        }
    }

    const addZerro = (value) => {
        if (value <= 9) {
            return "0" + value
        } else {
            return value
        }
    }

    function debounce(func, timeout = 2000) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    const redirectToDashboard = debounce(() => {
        dispatch(DynamicStaffOnboard(null));
        dispatch(createdId(null));
        navigate("/staff-dashboard", {
            state: {
                activeOption: 0
            }
        });
    });

    const savingDataInToDb = () => {
        count = 0;
        for (const key in workDays) {
            const slots = workDays[key].timeSlot;
            const option = workDays[key].availbaleOption;
            for (const key2 in slots) {
                const object = {
                    "select_day": workDays[key].title,
                    "day_start_time": addZerro(slots[key2]?.startTime?.$H) + ":" + addZerro(slots[key2]?.startTime?.$m) + ":" + "00",
                    "day_end_time": addZerro(slots[key2]?.endTime?.$H) + ":" + addZerro(slots[key2]?.endTime?.$m) + ":" + "00",
                    "unavailable": option[0].selected,
                    "whole_day": option[1].selected,
                    "staff_detail_id": _database_id?.id,
                }
                createWorkingDay(object, workDays?.length);
                redirectToDashboard()
            }
        }
    }
    return (
        <Box sx={{ width: "100%" }}>
            <Grid container>
                <Grid item={true} xs={12} sm={1} md={1} lg={1} sx={{ p: 1, border: "1px solid lightgrey", display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontFamily: "Roboto-Bold", }}>
                        Day
                    </Typography>
                </Grid>
                <Grid item={true} xs={12} sm={8} md={8} lg={8} sx={{ p: 1, border: "1px solid lightgrey", display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontFamily: "Roboto-Bold", }}>
                        Available Time slot
                    </Typography>
                </Grid>
                <Grid item={true} xs={12} sm={3} md={3} lg={3} sx={{ p: 1, border: "1px solid lightgrey", display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontFamily: "Roboto-Bold", }}>
                        Availability Options
                    </Typography>
                </Grid>
            </Grid>
            {workDays.map((v, i) => {
                return (
                    <Grid key={i} container>
                        <Grid item={true} xs={12} sm={1} md={1} lg={1} sx={{ p: 1, border: "1px solid lightgrey", display: "flex", alignItems: "center" }}>
                            <Typography sx={{ fontFamily: "Roboto-Medium", }}>
                                {v.value}
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12} sm={8} md={8} lg={8} sx={{ border: "1px solid lightgrey", display: "flex", alignItems: "center", }}>
                            {/* list of slots */}
                            <Grid item={true} xs={12} sm={11} md={11} lg={11} sx={{ p: 1, display: "flex", alignItems: "center", overflowX: "auto" }}>
                                {v?.timeSlot.map((nV, nI) => {
                                    return (
                                        <Grid key={nI} item={true} xs={12} sm={4} md={4} lg={4} sx={{ px: 1, display: "flex", }}>
                                            <Grid item={true} xs={12} sm={5} md={5} lg={5} >
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileTimePicker
                                                        label="Start Time"
                                                        value={nV.startTime}
                                                        sx={{ width: 100 }}
                                                        minutesStep={60}
                                                        onChange={(e) => changeStartTimeSlot(i, nI, e)}
                                                        disabled={workDays[i].availbaleOption[1].selected}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={2} md={2} lg={2} sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                                <Typography sx={{ fontFamily: "Roboto-Medium", }}>
                                                    {"To"}
                                                </Typography>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={5} md={5} lg={5}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileTimePicker
                                                        label="End Time"
                                                        value={nV.endTime}
                                                        minutesStep={60}
                                                        sx={{ width: 100 }}
                                                        onChange={(e) => changeEndTimeSlot(i, nI, e)}
                                                        disabled={workDays[i].availbaleOption[1].selected}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={2} md={2} lg={1} sx={{ display: "flex", alignItems: 'center' }}>
                                                <IconButton disabled={workDays[i].availbaleOption[1].selected} onClick={() => deleteTimeSlot(i, nI)}>
                                                    <Cancel />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            {/* create button */}
                            <Grid item={true} xs={12} sm={1} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", }}>
                                <Button
                                    sx={{
                                        minWidth: "auto",
                                        padding: "5px 10px"
                                    }} variant="outlined"
                                    onClick={() => addNewTimeSlot(i, v.value)}
                                    disabled={workDays[i].availbaleOption[1].selected}
                                >
                                    <AddIcon />
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item={true} xs={12} sm={3} md={3} lg={3} sx={{ p: 1, border: "1px solid lightgrey", display: "flex", alignItems: "center" }}>
                            {v?.availbaleOption.map((nV, nI) => {
                                return (
                                    <FormControlLabel
                                        disabled={nV.title.toLocaleLowerCase() === "unavailable" ? workDays[i].availbaleOption[1].selected : false}
                                        spacing={0}
                                        key={nI}
                                        control={
                                            <Checkbox
                                                checked={nV.selected}
                                                onChange={(e) => changeAvailability(i, nI, e.target.value)}
                                            />
                                        }
                                        label={nV.title}
                                    />
                                )
                            })}
                        </Grid>
                    </Grid>
                )
            })}
            <Box sx={{ width: "100%", display: 'flex', justifyContent: "flex-end", mt: "1%" }}>
                <Stack direction={"row"} spacing={2}>
                    <Tooltip title="Can Change After Save" placement="left">
                        <Button color={"primary"} disabled={loading_day} onClick={() => savingDataInToDb()}
                            variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Save
                            {loading_day ? <CircularProgress color="secondary" size={26} /> : null}
                        </Button>
                    </Tooltip>
                </Stack>
            </Box>

            <Snackbar open={snacks.open} autoHideDuration={5000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
                <Alert
                    onClose={handleCloseSnacks}
                    severity={errorSnackMessage.severity}
                    variant={errorSnackMessage.variant}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{errorSnackMessage.title}</AlertTitle>
                    {errorSnackMessage.message}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export const PaymentDetails = ({ screenWidth }) => {
    const _draft_data = useSelector((state) => state._staff_onboarding_module.selectedDraft);
    const dispatch = useDispatch();

    const [tfnValue, setTFNValue] = useState("")
    const [superannuation, setSuperannuation] = useState([
        {
            title: "Fund Name",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
        },
        {
            title: "Member Number",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
        },
    ])

    const [payment, setPayment] = useState([
        {
            title: "Account Name",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
        },
        {
            title: "BSB Number",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
        },
        {
            title: "Account Number",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
        },

    ])

    const [threshold, setThreshold] = useState([
        {
            title: "Do you want to claim a tax free threshold.!",
            value: "Yes",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: 'Yes',
                    selected: true
                },
                {
                    title: 'No',
                    selected: false
                },
            ],
            reason: [
                {
                    title: "Reason / Notes",
                    value: "",
                    width: screenWidth,
                    type: "text",
                    required: true,
                    multiline: true,
                },
            ]
        },
    ])

    useEffect(() => {
        const copy1 = [...superannuation]
        const copy2 = [...payment]
        const copy3 = [...threshold]
        const obj = {
            account_name: copy2[0].value,
            bsb_number: copy2[1].value,
            account_number: copy2[2].value,
            fund_name: copy1[0].value,
            member_name: copy1[1].value,
            tfn_number: tfnValue,
            have_tax_free_threshold: copy3[0].value,
            reason_notes: copy3[0].reason[0].value
        }

        dispatch(PaymentDetailsM(obj))

    }, [tfnValue, superannuation, payment, threshold])

    return (
        <>
            <Box sx={{ mx: 1, my: 1, display: 'flex', width: "100%" }}>
                <Typography sx={{ fontSize: "1em", fontFamily: "Roboto-Medium" }}>Account Details</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {payment.map((v, i) => {
                    return (
                        <TextField
                            type={v.type}
                            key={i}
                            required={v.required}
                            label={v.title}
                            value={v.value}
                            sx={{ m: 1, width: v.width }}
                            autoComplete="false"
                            onChange={(e) => {
                                const copy = [...payment];
                                copy[i].value = e.target.value;
                                setPayment(copy);
                            }}
                        />
                    )
                })}
            </Box>
            <Box sx={{ mx: 1, my: 1, display: 'flex', width: "100%" }}>
                <Typography sx={{ fontSize: "1em", fontFamily: "Roboto-Medium" }}>Supperannuation Details</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {superannuation.map((v, i) => {
                    return (
                        <TextField
                            type={v.type}
                            key={i}
                            required={v.required}
                            label={v.title}
                            value={v.value}
                            sx={{ m: 1, width: v.width }}
                            onChange={(e) => {
                                const copy = [...superannuation];
                                copy[i].value = e.target.value;
                                setSuperannuation(copy);
                            }}
                        />
                    )
                })}
            </Box>
            <Box sx={{ mx: 1, my: 1, display: 'flex', width: "100%" }}>
                <Typography sx={{ fontSize: "1em", fontFamily: "Roboto-Medium" }}>Tax File Number</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <TextField
                    type={"text"}
                    label={"TFN"}
                    value={tfnValue}
                    sx={{ m: 1, width: screenWidth }}
                    onChange={(e) => setTFNValue(e.target.value)}
                />
                {threshold.map((v, i) => {
                    return (
                        <div key={i}>
                            <TextField
                                select={true}
                                label={v.title}
                                key={i}
                                required={v.required}
                                sx={{ m: 1, width: v.width }}
                                value={v.value}
                                onChange={(e) => {
                                    const copy = [...threshold];
                                    copy[i].value = e.target.value;
                                    setThreshold(copy)
                                }}
                            >
                                {v?.options?.map((option) => (
                                    <MenuItem key={option.title} value={option.title}>
                                        {option.title}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {v.value === "No" && v?.reason?.map((vN, iN) => (
                                <TextField
                                    type={vN.type}
                                    key={iN}
                                    required={vN.required}
                                    label={vN.title}
                                    value={vN.value}
                                    sx={{ m: 1, width: vN.width }}
                                    onChange={(e) => {
                                        const copy = [...threshold];
                                        copy[i].reason[iN].value = e.target.value;
                                        setThreshold(copy);
                                    }}
                                />
                            ))}
                        </div>
                    )
                })}
            </Box>
        </>
    )
}
