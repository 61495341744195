import { Avatar, Box, CardMedia, Divider, Grid, Paper, Rating, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { FilterUserById } from "../../../services/auth_service";
import PropTypes from 'prop-types';
import { blue, grey } from "@mui/material/colors";

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const Profile = () => {

    const [me, setUserMe] = FilterUserById()
    const [userInfo, setUserInfo] = useState(null)
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getMyDetails = async () => {
        try {
            const user = JSON.parse(localStorage.getItem("user"))
            const response = await setUserMe({
                "variables": {
                    "id": user?.id
                }
            })
            if (response?.data?.usersPermissionsUser?.data) {
                setUserInfo(response?.data?.usersPermissionsUser?.data)
            }
        } catch (error) {
            console.log("error")
        }
    }

    useState(() => {
        getMyDetails()
    }, [])

    return (
        <Grid container sx={{ display: "flex", justifyContent: "center", py: 2 }}>
            <Grid item={true} xs={11} sm={11} md={11} lg={11} sx={{ display: "flex", justifyContent: "center" }}>
                <Grid item={true} xs={11} sm={11} md={3} lg={3} sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    flexDirection: "column",
                }}>
                    <Box component={Paper} elevation={1} sx={{
                        width: "50%",
                        borderRadius: 2,
                        overflow: "hidden",
                        my: 1,
                    }}>
                        <CardMedia
                            component="img"
                            width={"100%"}
                            height={"100%"}
                            image="/assets/images/doctor2.jpg"
                            alt="Paella dish"
                        />
                    </Box>
                    <Box sx={{ my: 2, width: "100%" }}>
                        <Typography sx={{ fontSize: "1.2em", fontFamily: "Roboto-Medium", color: "grey" }}>{"Work"}</Typography>
                        <Divider />
                    </Box>
                    <Box sx={{ my: 2, width: "100%" }}>
                        <Typography sx={{ fontSize: "1.2em", fontFamily: "Roboto-Medium", color: "grey" }}>{"Skills"}</Typography>
                        <Divider />
                    </Box>
                </Grid>
                <Grid item={true} xs={11} sm={11} md={8} lg={8} sx={{ px: 4, }}>
                    <Box sx={{ p: 2, }}>
                        <Typography sx={{ fontSize: "1.3em", fontFamily: "Roboto-Medium", textTransform: "uppercase" }}>{userInfo?.attributes.username}</Typography>
                        <Typography sx={{ fontSize: "1em", fontFamily: "Roboto-Regular", textTransform: "capitalize", color: blue.A400 }}>{userInfo?.attributes?.user_type?.data?.attributes?.User_Type}</Typography>
                    </Box>
                    <Box sx={{ p: 2, }}>
                        <Typography sx={{ fontSize: "1em", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: grey.A400 }}>{"Rankings"}</Typography>
                        <Stack spacing={2} direction={"column"}>
                            <Rating name="size-large" defaultValue={2} size="large" />
                        </Stack>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="info tabs">
                                <Tab label="About" {...a11yProps(0)} />
                                <Tab label="Timeline" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <Box>
                                <Typography sx={{ fontSize: "1.2em", fontFamily: "Roboto-Medium", color: "grey" }}>{"Contact Information"}</Typography>
                            </Box>
                            <Box sx={{ display: "flex", p: 1 }}>
                                <Box sx={{ width: "20%", }}>Phone:</Box>
                                <Box sx={{ width: "80%", }}>+0689898996</Box>
                            </Box>
                            <Box sx={{ display: "flex", p: 1 }}>
                                <Box sx={{ width: "20%", }}>Address:</Box>
                                <Box sx={{ width: "80%", }}>54 E 68th Street, abc city, Australia</Box>
                            </Box>
                            <Box sx={{ display: "flex", p: 1 }}>
                                <Box sx={{ width: "20%", }}>Email:</Box>
                                <Box sx={{ width: "80%", }}>abc_admin@gmail.com</Box>
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: "1.2em", fontFamily: "Roboto-Medium", color: "grey" }}>{"Basic Information"}</Typography>
                            </Box>
                            <Box sx={{ display: "flex", p: 1 }}>
                                <Box sx={{ width: "20%", }}>Birthday:</Box>
                                <Box sx={{ width: "80%", }}>June, 5, 1992</Box>
                            </Box>
                            <Box sx={{ display: "flex", p: 1 }}>
                                <Box sx={{ width: "20%", }}>Gender:</Box>
                                <Box sx={{ width: "80%", }}>Male</Box>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            Timeline
                        </CustomTabPanel>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}