import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import {
    Grid, Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Paper, Checkbox, IconButton, Tooltip, Button, Stack, TextField, MenuItem,
} from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { TimePickerUI } from '../../../components/Inputs';

const createData = (id, visit_detail, start_schedule_time, end_schedule_time, actual_start_time, actual_end_time, calculation_of_time, adjust_hours) => {
    return {
        id,
        visit_detail,
        start_schedule_time,
        end_schedule_time,
        actual_start_time,
        actual_end_time,
        calculation_of_time,
        adjust_hours
    };
}

const rows = [
    createData(1, 'abc', "10:30", "18:00", "10:30", "17:30", "8.5 hours"),
    createData(2, 'abc', "10:30", "18:00", "10:30", "17:30", "8.5 hours"),
    createData(3, 'abc', "10:30", "18:00", "10:30", "17:30", "8.5 hours"),
    createData(4, 'abc', "10:30", "18:00", "10:30", "17:30", "8.5 hours"),
    createData(5, 'abc', "10:30", "18:00", "10:30", "17:30", "8.5 hours"),
    createData(6, 'abc', "10:30", "18:00", "10:30", "17:30", "8.5 hours"),
];

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'ID',
        numeric: false,
        disablePadding: true,
        label: 'ID',
    },
    {
        id: 'Visit Detail',
        numeric: true,
        disablePadding: false,
        label: 'Visit Detail',
    },
    {
        id: 'Start Schedule Time',
        numeric: true,
        disablePadding: false,
        label: 'Start Schedule Time',
    },
    {
        id: 'End Schedule Time',
        numeric: true,
        disablePadding: false,
        label: 'End Schedule Time',
    },
    {
        id: 'Actual Start Time',
        numeric: true,
        disablePadding: false,
        label: 'Actual Start Time',
    },
    {
        id: 'Actual End time',
        numeric: true,
        disablePadding: false,
        label: 'Actual End time',
    },
    {
        id: 'Calculation of time',
        numeric: true,
        disablePadding: false,
        label: 'Calculation of time',
    },
    // {
    //     id: 'Adjust Hours',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Adjust Hours',
    // },
    {
        id: 'Options',
        numeric: true,
        disablePadding: false,
        label: 'Options',
    },
];

const EnhancedTableHead = (props) => {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell> */}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        style={{ minWidth: headCell.label?.length >= 10 ? 180 : 100 }}
                        // align={headCell.numeric ? 'right' : 'left'}
                        // padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            style={{ fontSize: "1.1em", fontFamily: "Roboto-Bold" }}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >

                </Typography>
            )}

            {numSelected > 0 ? (
                <Stack direction="row" spacing={2}>
                    <Tooltip title="Reject">
                        <Button variant='contained' label="approve" startIcon={<ReceiptLongIcon />}>
                            Reject
                        </Button>
                    </Tooltip>
                    <Tooltip title="Approved">
                        <Button variant='contained' label="approve" startIcon={<ReceiptLongIcon />}>
                            Approved
                        </Button>
                    </Tooltip>
                </Stack>
            ) : null}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export const AdjustHoursUpdate = () => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('ID');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Grid container sx={{ display: "flex", justifyContent: "center", my: 2, marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, }}>
            <Grid item={true} xs={11.5} sm={12} md={12} lg={11} component={Paper} elevation={0}>
                {selected.length > 0 && (
                    <EnhancedTableToolbar numSelected={selected.length} />
                )}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        // onClick={(event) => handleClick(event, row.id)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        {/* <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell> */}
                                        <TableCell id={labelId} scope="row">{row.id}</TableCell>
                                        <TableCell align="left">{row.visit_detail}</TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                type='time'
                                                value={row.start_schedule_time}
                                                onChange={(e) => { console.log(e.target.value) }}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                type='time'
                                                value={row.end_schedule_time}
                                                onChange={(e) => { console.log(e.target.value) }}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                type='time'
                                                value={row.actual_start_time}
                                                disabled
                                                onChange={(e) => { console.log(e.target.value) }}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                type='time'
                                                disabled
                                                value={row.actual_end_time}
                                                onChange={(e) => { console.log(e.target.value) }}
                                            />
                                        </TableCell>
                                        <TableCell align="left">{row.calculation_of_time}</TableCell>
                                        <TableCell align="left">
                                            <Stack direction="row" spacing={1}>
                                                <IconButton aria-label="delete" size="large">
                                                    <SaveIcon fontSize="inherit" />
                                                </IconButton>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

            </Grid>
        </Grid>
    );
}