import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

function SpinnerBackdrop({ showSpinner }) {
    return (
        <div>
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={showSpinner}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export default SpinnerBackdrop;