import { Alert, AlertTitle, Autocomplete, Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, MenuItem, Rating, Slide, Snackbar, Stack, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DraftEditor from "../../../components/Editor/DraftEditor";
import { useSelector, useDispatch } from "react-redux";
import { client_id, client_onboard_data_lists } from "../../../middleware/reducers/client_onboarding_reducer";
import _ from "lodash"
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { CheckListAlertsNotificationsByFilter, ClientDetailsById, CreateAdministrativeNote, CreateAdmissionInformation, CreateCheckListAlertsNotification, CreateClientAnswerLovsReference, CreateClientDetail, CreateClientGoal, CreateClientNote, CreateClientQuestionAnswerRef, CreateContactDetailForClient, CreateEmergencyContactDetailForClient, CreateMedicationsForClient, CreateOnboardMedicalHistoryDetail, CreatePlanReview, CreatePreferenceForStaffWorker, CreateReferringDoctorInformation, CreateServiceInformation, ClientAnswerLovsReferences, ContactInfoById, EmergencyContactDetailsByFilter, AdministrativeNotesByFilter, ServiceInformations, PreferenceForStaffWorkers, ClientGoalsByFilter, PlanReviewByFilter, MedicalInformationById } from "../../../services/client_service";
import { UpdateClientDetail } from "../../../services/admin_service";
import moment from "moment";
import { GetChargeBandDetails } from "../../../services/finance_service";
import { setCategoryStep } from "../../../middleware/reducers/category_step_reducer";
import { useNavigate } from "react-router-dom";
import { setLoadingState } from "../../../middleware/reducers/loading_state_reducer";


const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const SlideTransition = (props) => {
  return <Slide {...props} direction="left" />;
}

const Row = (props) => {
  const { row, screenWidth, handleIndividualRiskChanges, pIndex } = props;
  return (
    <React.Fragment>
      <Grid container >
        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-start", }} >
          <Grid item={true} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", p: 1, }}>
            <Typography sx={{ fontFamily: "Roboto-Medium", }}>
              {row?.title}
            </Typography>
          </Grid>
        </Grid>
        {row?.childOptions?.map((value, index) => {
          if (value?.type === "Select") {
            return (
              <Grid item={true} key={value.title + index} xs={12} sm={6} md={4} lg={2} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", p: 1, justifyContent: "flex-start", }}>
                <TextField
                  select={true}
                  id={value.title}
                  htmlFor={value.title}
                  required={value.required}
                  label={value.title}
                  InputLabelProps={{
                    style: {
                      textTransform: "capitalize"
                    }
                  }}
                  value={value.value}
                  sx={{ width: screenWidth }}
                  onChange={(e) => handleIndividualRiskChanges(e.target.value, pIndex, index)}
                >
                  {value?.options?.map((option) => (
                    <MenuItem key={option?.attributes?.title} value={option?.attributes?.title}>
                      {option?.attributes?.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )
          }

          if (value?.type === "Text") {
            return (
              <Grid item={true} key={value.title + index} xs={12} sm={6} md={6} lg={3} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", p: 1, display: "flex", justifyContent: "flex-start", }}>
                <TextField
                  type={"text"}
                  id={value.title}
                  htmlFor={value.title}
                  required={value.required}
                  label={value.title}
                  multiline={true}
                  value={value.value}
                  sx={{ width: screenWidth }}
                  onChange={(e) => handleIndividualRiskChanges(e.target.value, pIndex, index)}
                />
              </Grid>
            )
          }
        })}
      </Grid>
    </React.Fragment >
  );
}

const RowHRA = (props) => {
  const { row, screenWidth, handleIndividualRiskChanges, pIndex } = props;

  return (
    <React.Fragment>
      <Grid container >
        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-start", }} >
          <Grid item={true} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", p: 1, }}>
            <Typography sx={{ fontFamily: "Roboto-Medium", }}>
              {row?.title}
            </Typography>
          </Grid>
        </Grid>
        {row?.childOptions?.map((value, index) => {
          if (value?.type === "Select") {
            return (
              <Grid item={true} key={value.title + index} xs={12} sm={6} md={4} lg={2} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", p: 1, justifyContent: "flex-start", }}>
                <TextField
                  select={true}
                  id={value.title}
                  htmlFor={value.title}
                  required={value.required}
                  label={value.title}
                  InputLabelProps={{
                    style: {
                      textTransform: "capitalize"
                    }
                  }}
                  value={value.value}
                  sx={{ width: screenWidth }}
                  onChange={(e) => handleIndividualRiskChanges(e.target.value, pIndex, index)}
                >
                  {value?.options?.map((option) => (
                    <MenuItem key={option?.attributes?.title} value={option?.attributes?.title}>
                      {option?.attributes?.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )
          }
          if (value?.type === "Text") {
            return (
              <Grid item={true} key={value.title + index} xs={12} sm={6} md={6} lg={3} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", p: 1, display: "flex", justifyContent: "flex-start", }}>
                <TextField
                  type={"text"}
                  id={value.title}
                  htmlFor={value.title}
                  required={value.required}
                  label={value.title}
                  multiline={true}
                  value={value.value}
                  sx={{ width: screenWidth }}
                  onChange={(e) => handleIndividualRiskChanges(e.target.value, pIndex, index)}
                />
              </Grid>
            )
          }
          if (value?.type === "rating") {
            return (
              <Grid item={true} key={value.title + index} xs={12} sm={4} md={4} lg={4} sx={{ mx: 2, display: "flex", justifyContent: "flex-start", flexDirection: 'column' }}>
                <Grid sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", }}>
                  <Typography sx={{ fontFamily: "Roboto-Regular", }}>
                    {value.title}
                  </Typography>
                </Grid>
                <Grid item={true} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", }}>
                  <Rating
                    sx={{
                      fontSize: "2.5rem"
                    }}
                    id="number-feedback"
                    value={Number(value.value)}
                    classid='rating-size'
                    onChange={(e) => handleIndividualRiskChanges(e.target.value, pIndex, index)}
                  />
                </Grid>
              </Grid>
            )
          }
        })}

      </Grid>
    </React.Fragment>
  );
}

const RowSP = (props) => {
  const { row, handleIndividualRiskChanges, pIndex } = props;
  return (
    <Grid item={true} xs={11} md={5} lg={5.8} sx={{ mx: 1 }}>
      <Grid item={true} sx={{ m: 1, display: 'flex', flexWrap: 'wrap', }}>
        <span style={{ fontSize: "1.3em", fontFamily: "Roboto-Bold", }}>
          {row?.title}
        </span>
      </Grid>
      <Grid item={true} xs={12} md={12} lg={12} >
        <Grid item={true} xs={12} md={12} lg={12} sx={{ m: 1, }} >
          <DraftEditor data={row?.order_unord_json} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export const DynamicInputs = ({
  can_create_multiple,
  inputsList,
  inputType,
  questionList,
  categoryValue,
  module,
  setError,
  showSaveButtonOnLastStep,
  horizontalNextStepIndex,
  setNextHorizontalStepActive,
  duplicateInputField,
}) => {
  const width = () => {
    if (window.screen.width > 1600) {
      return window.screen.width / 5.2
    }
    if (window.screen.width > 600 && window.screen.width < 1600) {
      return window.screen.width / 4
    } else {
      return window.screen.width / 1.3
    }
  }
  const dispatch = useDispatch()
  const [screenWidth, setScreenWidth] = useState(width);
  const [initialFieldStructure, setInitialFieldStructure] = useState(null);
  const [multipleFields, setMultipleFields] = useState([]);
  const [dynamiInputs, setDynamicInputList] = useState(null);
  const [dynamicQuestions, setDynamicQuestions] = useState(null);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [chargeBandsOption, setChargeBandsOption] = useState({
    title: "Charge Range",
    value: {
      title: "Please select charge range",
      id: "DF1"
    },
    width: screenWidth,
    type: "drop_down",
    required: false,
    multiline: false,
    error: null,
    options: [
      {
        title: "Please select charge range",
        id: "DF1"
      },
    ]
  })
  const [subCategoryHistory, setsubCategoryHistory] = useState(null)
  const [supportCategories, setSupportCategories] = useState(null);

  const [snacks, setSnack] = useState({
    open: false,
    Transition: SlideTransition,
    vertical: 'top',
    horizontal: 'right',
  });

  const [errorSnackMessage, setErrorSnackMessage] = useState({
    title: "",
    message: "",
    severity: "",
    variant: "filled"
  });
  const [showSubmitFormBtn, setShowSubmitFormBtn] = useState(false);
  const [editClientDetail, setEditClientDetail] = useState(null)
  const [lovsAnswerData, setLovsAnswerData] = useState(null)


  // // // // ======================================================================================
  const _saving_list = useSelector((state) => state._client_onboarding_module.client_onboard_data_list);
  const _client_id = useSelector((state) => state._client_onboarding_module.client_id);
  const _category_step = useSelector((state) => state._category_step_state.category_step);
  // /// /// ==========================================================================================================

  const [getAvailableChargeOption, loading_band, error_band] = GetChargeBandDetails()
  // /// /// ==========================================================================================================
  const [apiCreateClient, loading_client, error_client] = CreateClientDetail();
  const [apiCreateClientLovs, loading_lovs, error_lovs] = CreateClientAnswerLovsReference();
  const [apiCreateContactDetail, loading_contact, error_contact] = CreateContactDetailForClient();
  const [apiCreateEmergencyContact, loading_emergency, error_emergency] = CreateEmergencyContactDetailForClient();
  const [apiCreateAdministrative, loading_adm, error_adm] = CreateAdministrativeNote()
  const [apiCreateServiceInfo, loading_service, error_service] = CreateServiceInformation();
  const [apiCreatePreferenceForStaff, loading_preference, error_preference] = CreatePreferenceForStaffWorker();
  const [apiCreateClientNote, loading_note, error_note] = CreateClientNote();
  const [apiCreateClientGoal, loading_goal, error_goal] = CreateClientGoal();
  const [apiCreateCheckList, loading_check, error_check] = CreateCheckListAlertsNotification();
  const [apiCreatePlanReview, loading_review, error_review] = CreatePlanReview()
  const [apiUpdateClientDetail, loading_update, error_update] = UpdateClientDetail();
  const [apiCreateMedications, loading_medi, error_medi] = CreateMedicationsForClient();
  const [apiCreateMedicationHistory, loading_history, error_history] = CreateOnboardMedicalHistoryDetail();
  const [apiCreateReferringDoctor, loading_doctor, error_doctor] = CreateReferringDoctorInformation();
  const [apiCreateAdmission, loading_admission, error_admission] = CreateAdmissionInformation();
  const [apiClientQuestionAnswer, loading_question, error_question] = CreateClientQuestionAnswerRef();
  const [getClientById, loading, error] = ClientDetailsById();
  const [getCheckListAlertsNotificationsByFilter, loading_a, error_a] = CheckListAlertsNotificationsByFilter();
  const [getClientAnswerLovsReferences, loading_l, error_l] = ClientAnswerLovsReferences();
  const [getAdministrativeNotesByFilter, loading_n, error_n] = AdministrativeNotesByFilter();
  const [getServiceInformations, loading_s, error_s] = ServiceInformations();
  const [getContactInfoById, loading_c, error_c] = ContactInfoById();
  const [getEmergencyContactDetailsByFilter, loading_e, error_e] = EmergencyContactDetailsByFilter();
  const [getPreferenceForStaffWorkers, loading_p, error_p] = PreferenceForStaffWorkers();
  const [getClientGoalsByFilter, loading_g, error_g] = ClientGoalsByFilter();
  const [getPlanReviewByFilter, loading_r, error_r] = PlanReviewByFilter();
  const [getMedicalInformationById] = MedicalInformationById();

  const navigate = useNavigate();

  useEffect(() => {
    if (window.screen) {
      setScreenWidth(width);
    }
  }, [window.screen]);

  const getAvailableChargeList = async () => {
    try {
      const response = await getAvailableChargeOption();
      if (response?.data) {
        addChargeToState(response?.data?.chargeBandDetails?.data);
      }
    } catch (error) {
      console.error("error", error, error_band)
    }
  }

  const addChargeToState = (chargeBand) => {
    if (chargeBand?.length) {
      const copy = { ...chargeBandsOption }
      for (const key in chargeBand) {
        const data = chargeBand[key]?.attributes
        const find = copy.options.find((e) => e?.id === chargeBand[key]?.id)
        if (!find) {
          const obj = {
            title: data.charge_band_name,
            id: chargeBand[key]?.id
          }
          copy.options.push(obj)
        }
      }
      setChargeBandsOption(copy)
    }
  }

  useEffect(() => {
    if (inputsList?.length) {
      const copy = [];
      const filteredDuplicateInputList = duplicateInputField.map(inputVal => (inputVal?.attributes?.title));
      let required = null;
      let active = null;
      let multiline = null;
      let disabled = null;

      for (const key in inputsList) {

        if (filteredDuplicateInputList.includes(inputsList[key]?.attributes?.input_name)) {
          const filteredInputList = duplicateInputField?.filter(inputVal => inputVal?.attributes?.title === inputsList[key]?.attributes?.input_name)[0];

          disabled = filteredInputList.attributes?.disabled;
          required = filteredInputList.attributes?.required;
          active = filteredInputList.attributes?.active;
          multiline = filteredInputList.attributes?.multiline;
        }
        else {
          disabled = inputsList[key].attributes?.disabled;
          required = inputsList[key].attributes?.required;
          active = inputsList[key].attributes?.active;
          multiline = inputsList[key].attributes?.multiline;
        }

        const type = inputsList[key].attributes?.design_frontend_input_type?.data?.attributes?.title;
        const label = inputsList[key].attributes?.input_name;
        const have_child_option = inputsList[key].attributes?.have_child_option;
        const drop_down_options = inputsList[key].attributes?.drop_down_options?.data;

        const placeholder = inputsList[key].attributes?.placeholder;

        if (active) {
          let obj = {
            title: label,
            value: type?.toLowerCase() === "date" ? moment().format("YYYY-MM-DD") : type?.toLowerCase() === "time" ? moment().format("HH:MM") : "",
            width: screenWidth,
            type: type,
            required: required,
            multiline: multiline,
            error: null,
            category: "text",
            disabled: disabled,
            placeholder: placeholder
          }
          if (have_child_option) {
            obj.options = drop_down_options;
            obj.value = drop_down_options[0].attributes.title;
          }

          copy.push(obj)
        }
      }
      if (can_create_multiple) {
        setInitialFieldStructure(_.cloneDeep(copy));
        setMultipleFields([copy])
      }
      setDynamicInputList(copy);
      handleStateValueWithDynamicForm(copy);
    }
    if (questionList?.length) {
      const copy = [];
      for (const key in questionList) {
        let object = {}
        object.title = questionList[key]?.attributes?.question;
        object.active = questionList[key]?.attributes?.active;
        object.width = screenWidth;
        object.multiline = false;
        object.error = null;
        object.category = "questions";
        const answer_type = questionList[key]?.attributes?.answer_type[0];
        if (answer_type?.__typename?.includes("Text")) {
          object.type = "Text";
          object.value = answer_type?.details_value || "";
          object.required = false;
          object.multiline = object.title.includes("Comments") ? true : false
        }
        if (answer_type?.__typename?.includes("Single")) {
          object.type = "Select";
          object.value = "No";
          object.required = true;
          object.options = answer_type?.drop_down_option_lovs?.data
        }
        if (answer_type?.__typename?.includes("IndividualRisk")) {
          object.type = "IndividualRisk";
          object.childOptions = [
            {
              type: "Select",
              title: "Risk To Individual",
              value: answer_type?.risk_options?.risk_to_individual_value || "Green",
              options: answer_type?.risk_options?.risk_to_individual?.data,
              required: true,
            },
            {
              type: "Select",
              title: "Risk To Staff",
              value: answer_type?.risk_options?.risk_to_staff_value || "Green",
              options: answer_type?.risk_options?.risk_to_staff?.data,
              required: true,
            },
            {
              type: "Select",
              title: "Risk To Other",
              value: answer_type?.risk_options?.risk_to_other_value || "Green",
              options: answer_type?.risk_options?.risk_to_other?.data,
              required: true,
            },
            {
              type: "Text",
              title: "Management Guides",
              value: answer_type?.management_guides_value || "",
              required: false,
              multiline: true
            },
            {
              type: "Text",
              title: "Source Of Information",
              value: answer_type?.source_of_information_value || "",
              required: false,
              multiline: true
            },
          ]
        }
        if (answer_type?.__typename?.includes("HomeRisk")) {
          object.type = "HomeRisk";
          object.childOptions = [
            {
              type: "Select",
              title: "Selected Option",
              value: answer_type?.selected_option_value || "No",
              options: answer_type?.selected_options?.data,
              required: true,
            },
            {
              type: "Text",
              title: "Details Action",
              value: answer_type?.details_action_value || "",
              required: false,
              multiline: true
            },
            {
              type: "rating",
              title: "Rating",
              value: answer_type?.rating || "0",
              required: false,
              multiline: true
            },
          ]
        }
        if (answer_type?.__typename?.includes("AnswersList")) {
          object.type = "AnswersList";
          object.order_unord_json = answer_type?.order_unord_json
        }
        copy.push(object)
      }
      setDynamicQuestions(copy)
      handleStateValueWithDynamicQuestions(copy);
    }
    if (chargeBandsOption.options.length <= 1) {
      getAvailableChargeList()
    }
  }, [])

  const saveData = (moveToNextStep) => {
    if (dynamiInputs === null && dynamicQuestions?.length > 0) {
      const fCopy = _.cloneDeep(dynamicQuestions);
      dispatchData(fCopy, moveToNextStep);
    }
    if (dynamiInputs && !can_create_multiple) {
      const copy = [...dynamiInputs];
      const todayDate = new Date().getMonth() + "/" + new Date().getDate() + "/" + new Date().getFullYear();
      let errorStatus = false;
      for (const key in copy) {
        if (
          (copy[key].type.toLowerCase() === "email" && copy[key].required && !validateEmail(copy[key].value)) ||
          // (copy[key].type.toLowerCase() === "email" && !validateEmail(copy[key].value)) ||
          (copy[key].type.toLowerCase() === "text" && copy[key].required && (copy[key].value === "" || copy[key].value === " ")) ||
          (copy[key].type.toLowerCase() === "date" && copy[key].required && (copy[key].value === "" || copy[key].value === todayDate)) ||
          (copy[key].type.toLowerCase() === "number" && copy[key].required && (copy[key].value === "" || copy[key].value < 10)) ||
          (copy[key].type.toLowerCase() === "time" && copy[key].required && (copy[key].value === ""))
        ) {
          copy[key].error = "Required";
          errorStatus = true;
          setError(errorStatus)
          setDynamicInputList(copy);
          dispatch(setLoadingState('error'))
        }
      }

      if (errorStatus === false) {
        setError(false);
        const fCopy = _.cloneDeep(dynamiInputs);
        dispatchData(fCopy, moveToNextStep);
      }
      else {
        dispatch(setCategoryStep(null));
      }
    }
    if (multipleFields && can_create_multiple) {
      multipleFields.map(val => {
        const fCopy = _.cloneDeep(val);
        dispatchData(fCopy);
      })
    }
  }

  const getClientDetailsById = async (data) => {
    const { id } = data;
    try {
      if (categoryValue === "Basic Information") {
        const respons = await getClientById({
          variables: {
            "id": id
          }
        })
        if (respons?.data) {
          const data = respons.data.clientDetail.data;
          const respons_lov = await getClientAnswerLovsReferences({
            variables: {
              "filter": {
                "client_detail_id": {
                  "id": {
                    "eq": id
                  }
                }
              }
            }
          })
          if (respons_lov?.data) {
            const lovs = respons_lov.data.clientAnswerLovsReferences.data;
            setEditClientDetail(data);
            setLovsAnswerData(lovs);
          }
        }
      }
      if (categoryValue === "Contact Information") {
        const respons = await getContactInfoById({
          variables: {
            "filter": {
              "client_detail_id": {
                "id": {
                  "eq": id
                }
              }
            }
          }
        })
        if (respons?.data) {
          const data = respons.data.contactDetails.data[0];
          const respons_lov = await getClientAnswerLovsReferences({
            variables: {
              "filter": {
                "client_detail_id": {
                  "id": {
                    "eq": id
                  }
                }
              }
            }
          })
          if (respons_lov?.data) {
            const lovs = respons_lov.data.clientAnswerLovsReferences.data;
            setEditClientDetail(data);
            setLovsAnswerData(lovs);
          }
        }
      }
      if (categoryValue === "Emergency Contact Information") {
        const respons = await getEmergencyContactDetailsByFilter({
          variables: {
            "filter": {
              "client_detail_id": {
                "id": {
                  "eq": id
                }
              }
            }
          }
        })
        if (respons?.data) {
          const data = respons.data.emergencyContactDetails.data[0];
          setEditClientDetail(data)
        }
      }
      if (categoryValue === "Notes") {
        const respons = await getAdministrativeNotesByFilter({
          variables: {
            "filter": {
              "client_detail_id": {
                "id": {
                  "eq": id
                }
              }
            }
          }
        })
        if (respons?.data) {
          const data = respons.data.administrativeNotes.data[0];
          setEditClientDetail(data)
        }
      }
      if (categoryValue === "Service Information") {
        const respons = await getServiceInformations({
          variables: {
            "filter": {
              "client_detail_id": {
                "id": {
                  "eq": id
                }
              }
            }
          }
        })
        if (respons?.data) {
          const data = respons.data.serviceInformations.data[0];
          setEditClientDetail(data)
        }
      }
      if (categoryValue === "Support Worker Preferences") {
        const respons = await getPreferenceForStaffWorkers({
          variables: {
            "filter": {
              "client_detail_id": {
                "id": {
                  "eq": id
                }
              }
            }
          }
        })
        if (respons?.data) {
          const data = respons.data.preferenceForStaffWorkers.data;
          const respons_lov = await getClientAnswerLovsReferences({
            variables: {
              "filter": {
                "client_detail_id": {
                  "id": {
                    "eq": id
                  }
                }
              }
            }
          })
          if (respons_lov?.data) {
            const lovs = respons_lov.data.clientAnswerLovsReferences.data;
            setEditClientDetail(data);
            setLovsAnswerData(lovs);
          }
        }
      }
      if (categoryValue === "Client Goals") {
        const respons = await getClientGoalsByFilter({
          variables: {
            "filter": {
              "client_detail_id": {
                "id": {
                  "eq": id
                }
              }
            }
          }
        })
        if (respons?.data) {
          const data = respons.data.clientGoals.data[0];
          setEditClientDetail(data)
        }
      }
      if (categoryValue === "Check List (Alerts / Notifications)") {
        const respons = await getCheckListAlertsNotificationsByFilter({
          variables: {
            "filter": {
              "client_detail_id": {
                "id": {
                  "eq": id
                }
              }
            }
          }
        })
        if (respons?.data) {
          const data = respons.data.checkListAlertsNotifications.data;
          setEditClientDetail(data)
        }
      }
      if (categoryValue === "Plan Review") {
        const respons = await getPlanReviewByFilter({
          variables: {
            "filter": {
              "client_detail_id": {
                "id": {
                  "eq": id
                }
              }
            },
            "filterS": {
              "client_detail_id": {
                "id": {
                  "eq": id
                }
              }
            }
          }
        })
        if (respons?.data) {
          const data = respons.data.planReviews.data[0];
          const subdata = respons.data.planSupportCategories.data;
          setEditClientDetail(data)
          setsubCategoryHistory(subdata)
        }
      }
      if (categoryValue === "Medication") {
        const respons = await getMedicalInformationById({
          variables: {
            "filter": {
              "client_detail_id": {
                "id": {
                  "eq": id
                }
              }
            }
          }
        })

        if (respons?.data) {
          const data = respons.data.medicalInformations.data;
          const lovs_data = [];
          for (const element of data) {
            const respons_lov = await getClientAnswerLovsReferences({
              variables: {
                "filter": {
                  "client_detail_id": {
                    "id": {
                      "eq": id
                    }
                  },
                  "module_Id": {
                    "eq": Number(element.id)
                  }
                }
              }
            })
            lovs_data.push(...respons_lov.data.clientAnswerLovsReferences.data);
          }
          
          if (lovs_data.length > 0) {
            setEditClientDetail(data);
            setLovsAnswerData(lovs_data);
          }
        }
      }
    } catch (error) {
      console.error("error", error)
    }
  }

  useEffect(() => {
    if (_category_step &&
      !Object.keys(_saving_list?.client_information ?? '').includes(_category_step?.toLowerCase().replaceAll(' ', '_')) &&
      !Object.keys(_saving_list?.medical_information ?? '').includes(_category_step?.toLowerCase().replaceAll(' ', '_'))
    ) {
      saveData(false);
    }
  }, [_category_step]);

  useEffect(() => {
    const isDataAvailable = (data) => {
      if (data === null || data === undefined) {
        return false; // Data is null or undefined
      }

      if (Array.isArray(data)) {
        return data.length > 0; // Check if array has elements
      }

      if (typeof data === 'object') {
        return Object.keys(data).length > 0; // Check if object has properties
      }

      if (typeof data === 'string') {
        return data.trim().length > 0; // Check if string is not empty
      }

      return Boolean(data); // For other types (e.g., numbers, booleans)
    }
    if (isDataAvailable(editClientDetail)) {
      if (!Array.isArray(editClientDetail) && !can_create_multiple) {
        const detail = editClientDetail?.attributes;
        const copy = [...dynamiInputs];
        for (const key in copy) {
          const matchTitle = copy[key].title?.toLowerCase().split('/ ').join('')?.split(' ').join('_');
          if (detail && detail[matchTitle] && !copy[key].options) {
            copy[key].value = detail[matchTitle];
            copy[key].table_id = editClientDetail?.id;
          }
          if (copy[key].options) {
            const match = lovsAnswerData?.find((e) => e.attributes.input_name === matchTitle);
            if (match) {
              copy[key].value = match?.attributes.selected_option_value;
              copy[key].table_id = match?.id
            }
          }
        }
        setDynamicInputList(copy)
        if (subCategoryHistory) {
          const copy = [...supportCategories];
          for (const key in copy) {
            const main = copy[key];
            const child = main.data;
            for (const key2 in child) {
              for (const data of subCategoryHistory) {
                const obj = data.attributes;
                const title = obj?.dynamic_support_category_id?.data?.attributes?.title;
                if (title === child[key2]?.title) {
                  child[key2].inputListArray[0].value = obj.budget;
                  child[key2].inputListArray[1].value = obj.date;
                  child[key2].table_id = data.id;
                }
              }
            }
          }
          setSupportCategories(copy)
        }
      }
      
      if (Array.isArray(editClientDetail) && (editClientDetail.length > 0 || can_create_multiple)) {       
        
        // Array to hold updated fields grouped by each entity in editClientDetail
        const updatedFieldGroups = [];

        for (const item of editClientDetail) {
          const updatedFields = initialFieldStructure.map(field => {
            // Convert the title to match the attribute keys in editClientDetail
            const titleKey = field.title.replaceAll(' ', '_').toLowerCase();

            // Check if there’s a matching attribute key in the current editClientDetail item
            for (const index in lovsAnswerData) {              
              if (lovsAnswerData[index]?.attributes?.module_Id === Number(item?.id) && titleKey === lovsAnswerData[index]?.attributes?.input_name) {
                return {
                  ...field,
                  value: lovsAnswerData[index]?.attributes?.selected_option_value,
                  table_id: lovsAnswerData[index]?.id
                };
              }
            }

            if (item.attributes[titleKey] !== undefined) {
              // Update the value with the matching attribute value
              return { ...field, value: item.attributes[titleKey] };
            } else {
              // Keep the field unchanged if no matching key is found
              return { ...field };
            }
          });
          
          // Add the updatedFields array to the outer array
          updatedFieldGroups.push(updatedFields);
        }        

        setMultipleFields(updatedFieldGroups);
      }
    }
  }, [editClientDetail])

  useEffect(() => {
    if (_client_id) {
      getClientDetailsById(_client_id)
    }
  }, [_client_id])


  const handleCloseSnacks = () => {
    setSnack({
      ...snacks,
      open: false,
    });
  };

  const handleSnacksApper = (title, message, severity) => {
    setSnack({
      ...snacks,
      open: true,
    });
    // / // // // display message
    setErrorSnackMessage({
      ...errorSnackMessage,
      title,
      message,
      severity
    })
  }

  const calculateDOB = (dateOfBirth) => {
    const today = moment();
    const birthDate = moment(dateOfBirth, "YYYY-MM-DD");
    let age = today.diff(birthDate, "years");

    // Adjust if the current date hasn't reached the birth date this year
    if (today.isBefore(birthDate.add(age, 'years'))) {
      age--;
    }

    return age;
  }

  const handleChangeValueInputs = (eventOrValue, index) => {
    try {
      const copy = [...dynamiInputs];
      const isEvent = eventOrValue && eventOrValue.target;
      const value = isEvent ? eventOrValue.target.value : eventOrValue;

      if (copy[index].title === 'Date of Birth') {
        const DOB = calculateDOB(value);
        copy[index + 1].value = DOB;
      }
      copy[index].value = value;
      if (copy[index].error !== null) {
        copy[index].error = null;
      }
      setDynamicInputList(copy);
    } catch (error) {
      console.error("error", error);
    }
  }

  const dispatchData = async (data, moveToNextStep) => {
    try {
      const copy_module = module?.toLowerCase()?.split(' ').join('_');
      const copy_category = categoryValue?.toLowerCase().split('/ ').join('')?.split(' ').join('_');
      const copy_saving_list = { ..._saving_list };
      const additionText = categoryValue === "Admission Information" && data[0].category === "questions" ? "_question" : ""
      copy_saving_list[copy_module] = {
        ...copy_saving_list[copy_module],
        [copy_category + additionText]: data,
      };
      savingDataWithApiByCategory(data, moveToNextStep, copy_saving_list)
    } catch (error) {
      console.error("error", error);
    }
  }

  const handleStateValueWithDynamicForm = (data) => {
    try {
      const copy = [...data];
      const copy_module = module?.toLowerCase()?.split(' ').join('_');
      const copy_category = categoryValue?.toLowerCase().split('/ ').join('')?.split(' ').join('_');
      const copy_saving_list = { ..._saving_list };
      const _dataModule = copy_saving_list[copy_module];
      if (_dataModule) {
        const _dataList = _dataModule[copy_category]
        if (_dataList) {
          for (const key in copy) {
            if (copy[key]?.category === "text") {
              copy[key].value = _dataList[key]?.value || "";
            }
          }
          setDynamicInputList(copy)
        }
      }
    } catch (error) {
      console.error("error", error);
    }
  }

  const handleStateValueWithDynamicQuestions = (data) => {
    try {
      const copy = [...data];
      const copy_module = module?.toLowerCase()?.split(' ').join('_');
      const copy_category = categoryValue?.toLowerCase().split('/ ').join('')?.split(' ').join('_');
      const copy_saving_list = { ..._saving_list };
      const _dataModule = copy_saving_list[copy_module];
      const additionText = categoryValue === "Admission Information" && data[0].category === "questions" ? "_question" : ""
      if (_dataModule) {
        const _dataList = _dataModule[copy_category + additionText]
        if (_dataList) {
          for (const key in copy) {
            if (copy[key]?.type === "IndividualRisk" || copy[key]?.type === "HomeRisk") {
              const childOpt = copy[key]?.childOptions
              for (const keys in childOpt) {
                childOpt[keys].value = _dataList[key].childOptions[keys].value
              }
            }
            if (copy[key]?.category === "questions") {
              copy[key].value = _dataList[key]?.value || "";
            }
          }
          setDynamicQuestions(copy)
        }
      }
    } catch (error) {
      console.error("error", error);
    }
  }

  const handleIndividualRiskChanges = (value, pIndex, index) => {
    try {
      const copy = [...dynamicQuestions];
      copy[pIndex].childOptions[index].value = value;
      setDynamicQuestions(copy);
    } catch (error) {
      console.error("error", error)
    }
  }

  const convertDataIntoSimplifyObjects = (data) => {
    let inputs = {};
    let selectedOptions = {};
    for (const key in data) {
      if (!data[key]?.options) {
        inputs[data[key].title.toLowerCase().split('/ ').join('')?.split(' ').join('_')] = data[key].value;
      }
      if (data[key]?.options) {
        selectedOptions[data[key].title.toLowerCase().split('/ ').join('')?.split(' ').join('_')] = {
          title: data[key].value,
          id: data[key]?.options.find((e) => e.attributes.title === data[key].value)?.id
        }
      }
    }
    return { inputs, selectedOptions }
  }

  const resolvers = async (data) => {
    try {
      setLoadingResponse(true)
      for (const array of data) {
        // Insert each array into the database, or process them as needed
        await apiCreateMedicationHistory({
          variables: {
            "client_detail_id": _client_id?.id,
            "question_title": array.title,
            "answer_value": array.value
          }
        })
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  const AddMoreFunction = () => {
    setMultipleFields([...multipleFields, initialFieldStructure]);
  }

  const savingDataWithApiByCategory = async (data, moveToNextStep, copy_saving_list) => {
    try {
      let clientId = _client_id?.id;
      if (categoryValue === "Basic Information") {
        const simplify = convertDataIntoSimplifyObjects(data);
        dispatch(setLoadingState('loading'))
        simplify.inputs.age = simplify.inputs.age || "1";
        simplify.inputs.date_of_birth = simplify.inputs.date_of_birth || "2000-05-05";
        simplify.inputs.medicare_expiry_date = simplify.inputs.medicare_expiry_date || "2000-05-05";
        simplify.inputs.active = true;
        simplify.inputs.rankings = 5;
        const response = await apiCreateClient({
          variables: simplify.inputs
        });

        if (response?.data) {
          let count = 0;
          const id = response?.data?.createClientDetail?.data?.id;
          clientId = id;
          dispatch(client_id({ id: id, ndis_number: simplify.inputs.ndis_number }))

          for (const keys in simplify.selectedOptions) {
            let module_Id = response.data.createClientDetail.data.id;
            const obj = {
              client_detail_id: id,
              module_Id: Number(module_Id),
              module: module,
              category: categoryValue,
              input_name: keys,
              selected_option_value: simplify.selectedOptions[keys].title
            }
            const responseLovs = await apiCreateClientLovs({
              variables: obj
            })
            if (responseLovs?.data) {
              count++
            }
          }
          if (count === Object.keys(simplify.selectedOptions).length) {
            dispatch(setLoadingState('completed'))
            dispatch(client_onboard_data_lists(copy_saving_list));
            handleSnacksApper("Client Onboarding", categoryValue + " has been created, client id is: " + id, "success")
          }
        };
      }

      if (categoryValue === "Contact Information" && _client_id?.id) {
        const simplify = convertDataIntoSimplifyObjects(data);
        dispatch(setLoadingState('loading'))
        simplify.inputs.client_detail_id = _client_id?.id;
        simplify.inputs.email = simplify.inputs.email === "" ? "demo@belacorp.com.au" : simplify.inputs.email;
        const response = await apiCreateContactDetail({
          variables: simplify.inputs
        })
        if (response?.data) {
          let count = 0;
          for (const keys in simplify.selectedOptions) {
            let module_Id = response.data.createContactDetail.data.id;
            const obj = {
              client_detail_id: _client_id?.id,
              module_Id: Number(module_Id),
              module: module,
              category: categoryValue,
              input_name: keys,
              selected_option_value: simplify.selectedOptions[keys].title
            }
            const responseLovs = await apiCreateClientLovs({
              variables: obj
            })
            if (responseLovs?.data) {
              count++
            }
          }
          if (count === Object.keys(simplify.selectedOptions).length) {
            dispatch(setLoadingState('completed'))
            dispatch(client_onboard_data_lists(copy_saving_list));
            handleSnacksApper("Client Onboarding", categoryValue + " has been created.!", "success")
          }
        }
      }

      if (categoryValue === "Emergency Contact Information" && _client_id?.id) {
        const simplify = convertDataIntoSimplifyObjects(data);
        dispatch(setLoadingState('loading'))
        simplify.inputs.client_detail_id = _client_id?.id;
        simplify.inputs.email = simplify.inputs.email === "" ? "demo@belacorp.com.au" : simplify.inputs.email;
        const response = await apiCreateEmergencyContact({
          variables: simplify.inputs
        })
        if (response?.data) {
          dispatch(setLoadingState('completed'))
          dispatch(client_onboard_data_lists(copy_saving_list));
          handleSnacksApper("Client Onboarding", categoryValue + " has been created.!", "success")
        }
      }

      if (categoryValue === "Support Worker Preferences" && _client_id?.id) {
        const simplify = convertDataIntoSimplifyObjects(data);
        dispatch(setLoadingState('loading'))
        simplify.inputs.client_detail_id = _client_id?.id;
        simplify.inputs.age = simplify.inputs.age || 1;

        for (const keys in simplify.selectedOptions) {
          simplify.inputs[keys] = simplify.selectedOptions[keys].title;
        }
        const response = await apiCreatePreferenceForStaff({
          variables: simplify.inputs
        })
        if (response?.data) {
          let count = 0;
          for (const keys in simplify.selectedOptions) {
            let module_Id = response.data.createPreferenceForStaffWorker.data.id;
            const obj = {
              client_detail_id: _client_id?.id,
              module: module,
              module_Id: Number(module_Id),
              category: categoryValue,
              input_name: keys,
              selected_option_value: simplify.selectedOptions[keys].title
            }
            const responseLovs = await apiCreateClientLovs({
              variables: obj
            })
            if (responseLovs?.data) {
              count++
            }
          }
          if (count === Object.keys(simplify.selectedOptions).length) {
            dispatch(setLoadingState('completed'))
            dispatch(client_onboard_data_lists(copy_saving_list));
            handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
          }
        }
      }

      if (categoryValue === "Client Goals" && _client_id?.id) {
        const simplify = convertDataIntoSimplifyObjects(data);
        dispatch(setLoadingState('loading'))
        simplify.inputs.client_detail_id = _client_id?.id;
        const response = await apiCreateClientGoal({
          variables: simplify.inputs
        });
        if (response?.data) {
          dispatch(setLoadingState('completed'))
          dispatch(client_onboard_data_lists(copy_saving_list));
          handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
          if (moveToNextStep && horizontalNextStepIndex !== -1) {
            setNextHorizontalStepActive(horizontalNextStepIndex)
          }
        }
      }

      if (categoryValue === "Medication" && _client_id?.id) {
        const simplify = convertDataIntoSimplifyObjects(data);
        dispatch(setLoadingState('loading'))
        simplify.inputs.client_detail_id = _client_id?.id;
        simplify.inputs.medication_time = simplify.inputs.medication_time + ':00' ?? '18:00:00';
        simplify.inputs.every_x_days_where_x_is = Number(simplify.inputs.every_x_days_where_x_is);

        const response = await apiCreateMedications({
          variables: simplify.inputs
        })
        if (response?.data) {
          let module_Id = response.data.createMedicalInformation.data.id;
          let count = 0;
          for (const keys in simplify.selectedOptions) {
            const obj = {
              client_detail_id: _client_id?.id,
              module: module,
              module_Id: Number(module_Id),
              category: categoryValue,
              input_name: keys,
              selected_option_value: simplify.selectedOptions[keys].title
            }
            const responseLovs = await apiCreateClientLovs({
              variables: obj
            })
            if (responseLovs?.data) {
              count++
            }
          }
          if (count === Object.keys(simplify.selectedOptions).length) {
            dispatch(setLoadingState('completed'))
            dispatch(client_onboard_data_lists(copy_saving_list));
            handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
          }
        }
      }

      if (categoryValue === "Medical History Details" && _client_id?.id) {
        dispatch(setLoadingState('loading'))
        const response = await resolvers(data)
        if (response) {
          setLoadingResponse(false)
          dispatch(setLoadingState('completed'))
          dispatch(client_onboard_data_lists(copy_saving_list));
          handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
          navigate('/thankyou')
        }
      }

      if (!clientId) {
        dispatch(setLoadingState('error'))
        throw { message: "Cannot proceed. Basic information is missing." }
      }
    }
    catch (error) {
      let message = "";
      if (error?.message || error_doctor || error_adm || error_admission || error_question || error_history || error_client || error_lovs || error_contact || error_emergency || error_service || error_preference || error_note || error_goal || error_check || error_review || error_update || error_medi) {
        const errors = error_doctor || error_adm || error_admission || error_question || error_history || error_client || error_lovs || error_contact || error_emergency || error_service || error_preference || error_note || error_goal || error_check || error_review || error_update || error_medi;
        if (errors?.networkError) {
          message = "Network error occurred. Please try again later"
        }
        if (errors?.graphQLErrors) {
          message = errors.graphQLErrors[0]?.message + "or fields are empty.!"
        }
        if (error?.message) {
          message = error?.message;
        }
        dispatch(setLoadingState('error'))
        handleSnacksApper(categoryValue?.toUpperCase(), message, "error")
      }
    }
  }
  const handleCheckbox = (e, index) => {
    const copy = [...dynamiInputs];
    copy[index].value = e.target.checked;
  }
  return (
    <Box sx={{ width: "100%", display: 'flex', flexDirection: "column" }}>
      {loadingResponse || loading_question || loading_client || loading_adm || loading_admission || loading_medi || loading_lovs || loading_review || loading_contact || loading_check || loading_goal || loading_emergency || loading_service || loading_note || loading_preference || loading_update || loading_doctor ? (
        <Box sx={{
          background: "rgba(0,0,0,0.3)",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: window.screen.height,
          zIndex: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <CircularProgress color="primary" size={35} />
        </Box>
      ) : null}

      {can_create_multiple && (
        <Box sx={{ width: "100%", display: 'flex', flexWrap: 'wrap', }}>
          {multipleFields?.map((fields, uppperIndex) => {
            return (
              <Grid width={'100%'}>
                {fields.map((value, innerIndex) => {
                  if (value?.title === 'Every X days where X is' && (fields.find(field => field.title === "Occurrence").value === "Every x Days")) {
                    return (
                      <TextField
                        type={value.type}
                        key={value.title + uppperIndex}
                        id={value.title + uppperIndex}
                        required={value.required}
                        multiline={value.multiline}
                        label={value.title}
                        disabled={value.disabled}
                        placeholder={value.placeholder}
                        value={value.value}
                        sx={{ m: 1, width: value.width }}
                        helperText={value.error ? value.error : ""}
                        error={value.error ? true : false}
                        onChange={(e) => {
                          const updatedArray = [...multipleFields];

                          // Create a deep copy of the specific entry to avoid reference issues
                          updatedArray[uppperIndex] = [...updatedArray[uppperIndex]];

                          // Directly update the value of the specific field
                          updatedArray[uppperIndex][innerIndex] = {
                            ...updatedArray[uppperIndex][innerIndex],
                            value: e.target.value
                          };
                          setMultipleFields(updatedArray);
                        }}
                      />
                    )
                  }
                  if ((value?.type?.toLowerCase() === "text" || value?.type?.toLowerCase() === "email" || value?.type?.toLowerCase() === "number" || value?.type?.toLowerCase() === "date" || value?.type?.toLowerCase() === "time") && value?.title !== 'Every X days where X is') {
                    return (
                      <TextField
                        type={value.type}
                        key={value.title + uppperIndex}
                        id={value.title + uppperIndex}
                        required={value.required}
                        multiline={value.multiline}
                        label={value.title}
                        disabled={value.disabled}
                        placeholder={value.placeholder}
                        value={value.value}
                        sx={{ m: 1, width: value.width }}
                        helperText={value.error ? value.error : ""}
                        error={value.error ? true : false}
                        onChange={(e) => {
                          const updatedArray = [...multipleFields];

                          // Create a deep copy of the specific entry to avoid reference issues
                          updatedArray[uppperIndex] = [...updatedArray[uppperIndex]];

                          // Directly update the value of the specific field
                          updatedArray[uppperIndex][innerIndex] = {
                            ...updatedArray[uppperIndex][innerIndex],
                            value: e.target.value
                          };

                          setMultipleFields(updatedArray);
                        }}
                      />
                    )
                  }
                  if (value.type.toLowerCase() === "checkbox") {
                    return (
                      <FormControlLabel
                        key={value.title + uppperIndex}
                        onChange={(e) => {
                          const updatedArray = [...multipleFields];

                          // Create a deep copy of the specific entry to avoid reference issues
                          updatedArray[uppperIndex] = [...updatedArray[uppperIndex]];

                          // Directly update the value of the specific field
                          updatedArray[uppperIndex][innerIndex] = {
                            ...updatedArray[uppperIndex][innerIndex],
                            value: e.target.value
                          };
                          setMultipleFields(updatedArray);
                        }}
                        control={<Checkbox key={value.title} />}
                        label={value.title}
                      />
                    )
                  }
                  if (value?.type?.toLowerCase() === "select") {
                    return (
                      <TextField
                        select={true}
                        label={value.title}
                        key={value.title + uppperIndex}
                        id={value.title + uppperIndex}
                        required={value.required}
                        sx={{ m: 1, width: value.width }}
                        value={value.value}
                        helperText={value.error ? value.error : ""}
                        error={value.error ? true : false}
                        onChange={(e) => {
                          // Create a deep copy of multipleFields to avoid direct mutation
                          const updatedArray = [...multipleFields];

                          // Ensure each nested array is also copied to avoid reference issues
                          updatedArray[uppperIndex] = updatedArray[uppperIndex].map((item, index) => {
                            // Only update the target item (based on innerIndex) or the specific "Every X days where X is" item
                            if (index === innerIndex) {
                              return {
                                ...item,
                                value: e.target.value,
                              };
                            } else if (item.title === "Every X days where X is") {
                              if (e.target.value !== 'Every x Days') {
                                return {
                                  ...item,
                                  value: 0,
                                }
                              }
                            }
                            return item;
                          });

                          // Update the state with the modified array                          
                          setMultipleFields(updatedArray);
                        }}

                      >
                        {value.options.map((option) => (
                          <MenuItem key={option?.attributes?.title} value={option?.attributes?.title}>
                            {option?.attributes?.title}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }
                })}
              </Grid>
            )
          })}
        </Box>
      )}

      {(dynamiInputs && !can_create_multiple) && (
        <Box sx={{ width: "100%", display: 'flex', flexWrap: 'wrap', }}>
          {dynamiInputs?.map((value, index) => {
            if (value?.type?.toLowerCase() === "text" || value?.type?.toLowerCase() === "email" || value?.type?.toLowerCase() === "number" || value?.type?.toLowerCase() === "date" || value?.type?.toLowerCase() === "time") {
              return (
                <TextField
                  type={value.type}
                  key={index}
                  id={value.title}
                  htmlFor={value.title}
                  required={value.required}
                  // multiline={value.multiline}
                  label={value.title}
                  rows={10}
                  multiline={(categoryValue === 'Notes' || categoryValue === 'Client Goals') ? true : false}
                  disabled={value.disabled}
                  placeholder={value.placeholder}
                  value={value.value}
                  sx={{ m: 1, width: (categoryValue === 'Notes' || categoryValue === 'Client Goals') ? '100%' : value.width }}
                  helperText={value.error ? value.error : ""}
                  error={value.error ? true : false}
                  onChange={(e) => handleChangeValueInputs(e, index)}
                />
              )
            }
            if (value?.type?.toLowerCase() === "checkbox") {
              return (
                <FormControlLabel
                  key={value.title}
                  onChange={(e) => handleCheckbox(e, index)}
                  control={<Checkbox key={value.title} checked={value.value} />}
                  label={value.title}
                />
              )
            }
            if (value?.type?.toLowerCase() === 'autocomplete') {
              const countryList = value.options.map(val => (val?.attributes?.title))

              return (
                <Autocomplete
                  key="country-autocomplete"
                  value={value.value}
                  disablePortal
                  options={countryList}
                  sx={{ m: 1, width: value.width }}
                  onChange={(e, selectedCountry) => handleChangeValueInputs(selectedCountry, index)}
                  renderInput={(params) => <TextField {...params} label="Country of Birth" />}
                />
              )
            }
            if (value?.type?.toLowerCase() === "select") {
              return (
                <TextField
                  select={true}
                  label={value.title}
                  id={value.title}
                  htmlFor={value.title}
                  key={index}
                  required={value.required}
                  sx={{ m: 1, width: value.width }}
                  value={value.value}
                  helperText={value.error ? value.error : ""}
                  error={value.error ? true : false}
                  onChange={(e) => handleChangeValueInputs(e, index)}
                >
                  {value.options.map((option) => (
                    <MenuItem key={option?.attributes?.title} value={option?.attributes?.title}>
                      {option?.attributes?.title}
                    </MenuItem>
                  ))}
                </TextField>
              )
            }
          })}
          {categoryValue === "Client Budget" && (
            <TextField
              select={true}
              label={chargeBandsOption.title}
              key={chargeBandsOption.title}
              htmlFor={chargeBandsOption.title}
              required={chargeBandsOption.required}
              sx={{ m: 1, width: screenWidth }}
              value={chargeBandsOption.value.title}
              onChange={(e) => {
                const copy = { ...chargeBandsOption };
                const find = copy?.options?.find((f) => f.title === e.target.value)
                if (find.id !== "DF1") {
                  copy.value = find;
                  setChargeBandsOption(copy);
                }
              }}
            >
              {chargeBandsOption?.options.map((option) => (
                <MenuItem key={option?.title} value={option?.title}>
                  {option?.title}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Box>
      )}

      {dynamicQuestions && (
        <Box sx={{ width: "100%", display: 'flex', flexWrap: 'wrap', }}>
          {dynamicQuestions?.map((v, index) => {
            if (v?.active && v?.type?.includes("Text")) {
              return (
                <Grid sx={{ width: categoryValue === 'Medical History Details' ?? '100%' }}>
                  <TextField
                    type={v?.type?.toLowerCase()}
                    key={v.title}
                    id={v.title}
                    htmlFor={v.title}
                    required={v.required}
                    multiline={v.multiline}
                    label={v.title}
                    value={v.value}
                    sx={{
                      m: 1,
                      width: categoryValue === 'Medical History Details' ? '50%' : v.width,
                      mb: categoryValue === 'Medical History Details' ? 5 : 0
                    }}
                    onChange={(e) => {
                      const copy = [...dynamicQuestions];
                      copy[index].value = e.target.value;
                      setDynamicQuestions(copy);
                    }}
                  />
                </Grid>
              )
            }
            if (v?.active && v?.type?.includes("Select")) {
              return (
                <TextField
                  select={true}
                  label={v.title}
                  key={v.title}
                  id={v.title}
                  htmlFor={v.title}
                  required={v.required}
                  sx={{ m: 1, width: v.width }}
                  value={v.value}
                  onChange={(e) => {
                    const copy = [...dynamicQuestions]
                    copy[index].value = e.target.value;
                    setDynamicQuestions(copy);
                  }}
                >
                  {v?.options.map((option) => (
                    <MenuItem key={option?.attributes?.title} value={option?.attributes?.title}>
                      {option?.attributes?.title}
                    </MenuItem>
                  ))}
                </TextField>
              )
            }
            if (v?.active && v?.type?.includes("IndividualRisk")) {
              return (
                <Row
                  key={v?.type + index}
                  row={v}
                  screenWidth={v.width}
                  pIndex={index}
                  handleIndividualRiskChanges={handleIndividualRiskChanges}
                />
              )
            }
            if (v?.active && v?.type?.includes("HomeRisk")) {
              return (
                <RowHRA
                  key={v?.type + index}
                  row={v}
                  screenWidth={v.width}
                  pIndex={index}
                  handleIndividualRiskChanges={handleIndividualRiskChanges}
                />
              )
            }
            if (v?.active && v?.type?.includes("AnswersList")) {
              return (
                <RowSP
                  key={v?.type + index}
                  row={v}
                  pIndex={index}
                  handleIndividualRiskChanges={handleIndividualRiskChanges}
                />
              )
            }
          })}
        </Box>
      )}

      <Box sx={{ width: "100%", display: 'flex', justifyContent: "flex-end", mr: "2%" }}>
        <Stack direction={"row"} spacing={2}>
          {can_create_multiple && (
            <Tooltip title={"Can Add " + categoryValue} placement="left">
              <Button
                color={"primary"}
                startIcon={<AddCircleOutlineOutlinedIcon color="primary" />}
                variant="outlined"
                sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}
                onClick={AddMoreFunction}
              >
                Add More
              </Button>
            </Tooltip>
          )}
        </Stack>
      </Box>
      {/* saving values as per category */}
      {showSaveButtonOnLastStep &&
        <Box sx={{ width: "100%", display: 'flex', justifyContent: "flex-end", margin: '15px 0', }}>
          <Stack direction={"row"} spacing={2}>
            <Tooltip title="Can Change After Save" placement="left">
              <Button color={"primary"} onClick={() => saveData(true)}
                variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                {categoryValue === "Medical History Details" ? `Submit Form` : horizontalNextStepIndex !== -1 ? `Submit & Move To Next Step` : `Save`}
                {loading_client || loading_admission || loading_adm || loading_medi || loading_lovs || loading_review || loading_contact || loading_check || loading_goal || loading_emergency || loading_service || loading_note || loading_preference || loading_update || loading_doctor ? <CircularProgress color="secondary" size={26} /> : null}
              </Button>
            </Tooltip>
          </Stack>
        </Box>}

      <Snackbar open={snacks.open} autoHideDuration={5000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
        <Alert
          onClose={handleCloseSnacks}
          severity={errorSnackMessage.severity}
          variant={errorSnackMessage.variant}
          sx={{ width: '100%' }}
        >
          <AlertTitle>{errorSnackMessage.title}</AlertTitle>
          {errorSnackMessage.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}