import { Alert, AlertTitle, Button, CircularProgress, Grid, Slide, Snackbar, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import PaymentProcessingSteps from "./payroll_process";
import moment from "moment";
import { GetKilometersClaimsByServicePlan, GetReimbursementsByServicePlan, ServicePlansFilter } from "../../../services/finance_service";
import { useDispatch } from "react-redux";
import { completedServicePlan, completedShifts, kilometersClaimState, processStates, reimbursementListState } from "../../../middleware/reducers/payment_proccess_reducer";
import { ServiceShiftFilters } from "../../../services/schedule_service";

const SlideTransition = (props) => {
    return <Slide {...props} direction="left" />;
}

export const PaymentProcessing = () => {
    const dispatch = useDispatch()
    const [startDateRange, setStartDateRange] = useState(moment().format("YYYY-MM-DD"));
    const [endDateRange, setEndDateRange] = useState(moment().format("YYYY-MM-DD"));
    // const height = window.screen.height;
    const width = () => {
        // modal control
        if (window.screen.width > 1600) {
            return window.screen.width / 4.5
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.4
        }
    };
    const [screenWidth, setScreenWidth] = useState(width);
    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);
    const [snacks, setSnack] = useState({
        open: false,
        Transition: SlideTransition,
        vertical: 'top',
        horizontal: 'right',
    });

    const [errorSnackMessage, setErrorSnackMessage] = useState({
        title: "",
        message: "",
        severity: "",
        variant: "filled"
    });

    const handleCloseSnacks = () => {
        setSnack({
            ...snacks,
            open: false,
        });
    };

    const handleSnacksApper = (title, message, severity) => {
        setSnack({
            ...snacks,
            open: true,
        });
        // / // // // display message
        setErrorSnackMessage({
            ...errorSnackMessage,
            title,
            message,
            severity
        })
    }
    useEffect(() => {
        dispatch(reimbursementListState(null))
        dispatch(kilometersClaimState(null))
    }, [])
    // // //call api======================================================================
    const [getReimbursementsByServicePlan, loading_re, error_re] = GetReimbursementsByServicePlan();
    const [getKilometersClaimsByServicePlan, loading_km, error_km] = GetKilometersClaimsByServicePlan()
    const [getServicePlansFilter, loading_plan, error_plan] = ServicePlansFilter()
    const [getServiceShiftFilters, loading_shift, error_shif] = ServiceShiftFilters();

    const getDateRangeReimbursmentList = async () => {
        try {
            const response_re = await getReimbursementsByServicePlan({
                variables: {
                    "filters": {
                        // "approved_status": { "eq": false },
                        // "rejected_status": { "eq": false },
                        "service_shift_id": {
                            "shift_status": { "eq": "Completed" },
                            "shift_date": { "gte": startDateRange, "lte": endDateRange }
                        }
                    }
                }
            })
            if (response_re?.data) {
                const list = response_re?.data?.reimbursements?.data
                if (list?.length) {
                    dispatch(reimbursementListState(list))
                } else {
                    dispatch(reimbursementListState([]))
                    handleSnacksApper("Payment Processing", "The Reimbursement are not available in the selected date range.!", "warning")
                }
            }
            const response_km = await getKilometersClaimsByServicePlan({
                variables: {
                    "filters": {
                        // "approved_status": { "eq": false },
                        // "rejected_status": { "eq": false },
                        "service_shift_id": {
                            "shift_status": { "eq": "Completed" },
                            "shift_date": { "gte": startDateRange, "lte": endDateRange }
                        }
                    }
                }
            });
            if (response_km?.data) {
                const list = response_km?.data?.kilometersClaims?.data
                if (list?.length) {
                    dispatch(kilometersClaimState(list))
                } else {
                    dispatch(kilometersClaimState([]))
                    handleSnacksApper("Payment Processing", "The Kilometers Claim are not available in the selected date range.!", "warning")
                }
            }
            const response_shift = await getServiceShiftFilters({
                variables: {
                    "filter": {
                        "shift_status": { "eq": "Completed" },
                        "shift_date": { "gte": startDateRange, "lte": endDateRange }
                    }
                }
            });
            if (response_shift?.data) {
                const shifts = response_shift?.data?.serviceShifts?.data
                if (shifts?.length) {
                    dispatch(completedShifts(shifts))
                } else {
                    dispatch(completedShifts([]))
                    handleSnacksApper("Payment Processing", "The Completed Shifts are not available in the selected date range.!", "warning")
                }
            }
            const response_plan = await getServicePlansFilter()
            // {
            //     variables: {
            //         "filter": {
            //             "created_date": { "gte": startDateRange, "lte": endDateRange },
            //             "service_request_status": {
            //                 "eq": "Completed"
            //             }
            //         }
            //     }
            // }

            if (response_plan?.data) {
                const list = response_plan?.data?.servicePlans?.data
                if (list?.length) {
                    dispatch(completedServicePlan(list))
                } else {
                    dispatch(completedServicePlan([]))
                    handleSnacksApper("Payment Processing", "No data available for the selected range.!", "warning")
                }
            }
            dispatch(processStates({
                start_date: startDateRange,
                end_date: endDateRange
            }))
        } catch (error) {
            console.log(error, error_re, error_km, error_plan, error_shif)
        }
    }

    const submitDateRange = () => {
        getDateRangeReimbursmentList()
    }

    return (
        <Grid container sx={{ display: "flex", justifyContent: "center", my: 2, marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, }}>
            <Grid item={true} xs={11.5} sm={11.5} md={11.5} lg={11} sx={{ display: "flex", justifyContent: "center", }}>
                <TextField
                    type={"date"}
                    required={true}
                    label={"Start Date"}
                    value={startDateRange}
                    disabled={false}
                    sx={{ mx: 1, width: screenWidth }}
                    onChange={(e) => {
                        setStartDateRange(e.target.value)
                    }}
                />
                <TextField
                    type={"date"}
                    required={true}
                    label={"End Date"}
                    value={endDateRange}
                    disabled={false}
                    sx={{ mx: 1, width: screenWidth }}
                    onChange={(e) => {
                        setEndDateRange(e.target.value)
                    }}
                />
                <Button onClick={() => submitDateRange()} color="primary" variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                    Submit
                    {loading_re || loading_km || loading_plan || loading_shift && (<CircularProgress color="secondary" size={26} />)}
                </Button>
            </Grid>
            <Grid item={true} xs={11.5} sm={12} md={12} lg={11}>
                <PaymentProcessingSteps screenWidth={screenWidth} />
            </Grid>
            {/* handle notification */}
            <Snackbar open={snacks.open} autoHideDuration={5000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
                <Alert
                    onClose={handleCloseSnacks}
                    severity={errorSnackMessage.severity}
                    variant={errorSnackMessage.variant}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{errorSnackMessage.title}</AlertTitle>
                    {errorSnackMessage.message}
                </Alert>
            </Snackbar>
        </Grid>
    )
}