import React from 'react'
import { Box, Divider, Grid, Typography } from '@mui/material'
import ThankyouComponent from '../../../components/Thankyou/ThankyouComponent'
const ThankyouPageForGuardianForm = () => {
  const content = <Box sx={{
    margin: '45px 0px',
    height: 'calc(100vh - 220px)',
    display: 'flex',
    alignItems: 'center',
  }}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} sx={{display:'flex'}}>
        <img style={{ width: '90%', height: 'auto', }} alt="Logo" src="/assets/images/Thankyou-form-vector-image.png" />
      </Grid>
      <Grid item xs={12} md={6} sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop:{xs:'20px', md:'0px'}
      }}>
        <Box>
          <Typography variant="h2" sx={{
            textAlign: 'center',
            fontSize: '2rem',
            color: '#333333',
          }}> Thankyou for Submitting the Onboarding Form</Typography>
        </Box>
        <Divider sx={{
          width: '70%',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          margin: '10px 0px',
        }} />
        <Box>
          <Typography variant="h6" sx={{ textAlign: 'center' }}>Our Team will contact you shortly </Typography>
        </Box>
      </Grid>
    </Grid>
  </Box>
  return (
    <ThankyouComponent
      content={content}
    />
  )
}

export default ThankyouPageForGuardianForm