import React, { useEffect, useState } from "react";
import { Backdrop, Box, Fade, Paper, Grid, TextField, MenuItem, Typography, Button, Stack, Tooltip, ToggleButtonGroup, ToggleButton, Modal } from '@mui/material';
import styled from "@emotion/styled";
import { DatePickerUI, TimePickerUI } from "../../../components/Inputs";
import { ComplaintRegisterGrid, ComplaintRegisterList, ConflictInterestGrid, ConflictInterestList, FeedbackGrid, FeedbackList, IncedentRegisterList, IncidentRegisterGrid, MeetingGrid, MeetingList, MyServicesList, OrgnizationsGrid, OrgnizationsList, RegisterClinetsGrid, RegisterClinetsList, RegisterContinousImprovmentGrid, RegisterContinousImprovmentList, RegisterMedicationGrid, RegisterMedicationList, RegisterStaffGrid, RegisterStaffList } from "../../../components/pagination_view";
import SearchAndFilters from "../../../components/search_filters";
import data from '../../../const/data.json'
import { ReimbursementGrid, ReimbursementList } from "../../service_dashboard/screen/reimbursement";
import { KilometerClaims, KilometerClaimsGrid } from "../../service_dashboard/screen/kilometer_claim";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';

const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
}));

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const AddNewIncidentReport = () => {
    const [newFeedback, setNewFeedback] = useState(false);
    const height = window.screen.height;

    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        maxWidth: "calc(70%)",
        minWidth: "calc(60%)",
        boxShadow: 12,
        borderRadius: 1,
        p: 4,
    });
    const width = () => {
        // modal control
        if (window.screen.width < 900) {
            const copy = { ...style };
            copy.maxWidth = "95%";
            setStyle(copy);
        }
        if (window.screen.width > 1600) {
            return window.screen.width / 5
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.4
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen])

    const [clientDetails, setClientDetails] = useState([
        {
            title: "Client ID",
            value: "",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                3265,
                6569,
                5456,
                9875,
                2587,
                1258,
                7598,
                2597
            ]
        },
    ]);
    const [incident, setIncident] = useState([
        {
            title: "First Name",
            value: "Abc",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false
        },
        {
            title: "Last Name",
            value: "Abc",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false
        },
        {
            title: "Gender",
            value: "Male",
            width: screenWidth,
            type: "drop_down",
            multiline: false,
            required: true,
            options: [
                {
                    title: 'Male',
                    selected: true
                },
                {
                    title: 'Female',
                    selected: false
                },
                {
                    title: 'Others',
                    selected: false
                }
            ]
        },
        {
            title: "Address_1",
            value: "abc town, zxc city, byt",
            width: screenWidth,
            type: "long_text",
            required: true,
            multiline: false,
        },
        {
            title: "Address_2",
            value: "abc town, zxc city, byt",
            width: screenWidth,
            type: "long_text",
            multiline: false,
            required: false,
        },
        {
            title: "Home Phone",
            value: "0234567898",
            width: screenWidth,
            type: "call_number",
            multiline: false,
            required: true
        },
        {
            title: "Date of Birth",
            value: "1991-08-22",
            type: "date",
            width: screenWidth,
            multiline: false,
            required: true
        },
        {
            title: "Service Name/s",
            value: "Abc",
            width: screenWidth,
            type: "text",
            multiline: false,
            required: true,
        }
    ]);
    const [detailsOfSupportWorker, setDetailsOfSupportWorker] = useState([
        {
            title: "Name",
            value: "Abc",
            width: screenWidth,
            type: "text",
            required: true,
        },
        {
            title: "Position",
            value: "Abc",
            width: screenWidth,
            type: "text",
            required: true,
        },
        {
            title: "Location of Incident",
            value: "in on it or they he she will are and",
            width: screenWidth,
            type: "text",
            multiline: true,
            required: true,
        },
        {
            title: "Date and Time",
            value: "2001-08-20 10:50",
            type: "datetime-local",
            width: screenWidth,
            required: true,
            multiline: false,
        },
        {
            title: "Duration",
            value: "2.3",
            type: "number",
            width: screenWidth,
            required: true,
            multiline: false,
        },
    ]);
    // // /// // Intensity Scale Rating (Circle applicable intensity rating) 
    const [intensityScaleRating, setIntensityScaleRating] = useState([
        {
            title: "Physical Aggression/Self-harm (e.g. punch, kick, slap)",
            width: screenWidth,
            value: '',
            type: "check_list",
            required: false,
            options: [
                {
                    title: 'Physical aggression/Self-harm, no harm',
                    selected: false
                },
                {
                    title: 'Physical/Self harm, minor harm, such as redness or scratch',
                    selected: false
                },
                {
                    title: 'Physical/Self harm, first aid required',
                    selected: false
                },
                {
                    title: 'Physical harm/Self-harm, critical incident (hospitalization, serious injury or death)',
                    selected: false
                },
            ]
        },
        {
            title: "Verbal Aggression",
            width: screenWidth,
            value: '',
            type: "check_list",
            required: false,
            options: [
                {
                    title: 'Yelling/Screaming at another person, one off',
                    selected: false
                },
                {
                    title: 'Direct continuous yelling at another person less than 1 minute',
                    selected: false
                },
                {
                    title: 'Direct continuous yelling greater than 1 minute',
                    selected: false
                },
                {
                    title: 'Yelling and threats to another persons safety',
                    selected: false
                },
            ]
        },
        {
            title: "Property Damage",
            width: screenWidth,
            value: 'No property damage',
            type: "check_list",
            required: false,
            options: [
                {
                    title: 'No property damage',
                    selected: true
                },
                {
                    title: 'Disruption to property, mild damage, objects thrown, item does not require repair',
                    selected: false
                },
                {
                    title: 'Moderate damage to property. Damage occurs but still usable',
                    selected: false
                },
                {
                    title: 'Severe damage to property. Item requires repair and cannot be used',
                    selected: false
                },
                {
                    title: 'Critical Incident Severe damage and emergency services involved',
                    selected: false
                },
            ]
        },

    ]);
    // // // other data
    const [happened, sethappened] = useState([
        {
            title: "Location of Incident",
            value: "in on it or they he she will are and",
            width: screenWidth,
            type: "text",
            multiline: true,
            required: true,
        },
        {
            title: "Date and Time",
            value: "2001-08-20",
            type: "date",
            width: screenWidth,
            required: true,
            multiline: false,
        },
        {
            title: "Duration",
            value: "02:30",
            type: "time",
            width: screenWidth,
            required: true,
            multiline: false,
        },
    ]);
    const [antecedent, setAntecedent] = useState([
        {
            title: "What happened before?",
            width: screenWidth,
            type: "drop_down",
            helperText: "A - Antecedent",
            required: true,
            value: "Please Selece Option",
            options: [
                {
                    title: "Please Selece Option",
                    selected: true,
                },
                {
                    title: 'Asked to do something',
                    selected: false
                },
                {
                    title: 'Said No / denied access',
                    selected: false
                },
                {
                    title: 'Location (i.e. too many people, too noisy)',
                    selected: false
                },
                {
                    title: 'Not being acknowledged',
                    selected: false
                },
                {
                    title: "Transitioning between activities",
                    selected: false
                },
                {
                    title: "People talking about them",
                    selected: false
                },
                {
                    title: "Difficulties Communicating",
                    selected: false
                },
                {
                    title: "Given no choice",
                    selected: false
                },
                {
                    title: "Unwell/Physical Pain",
                    selected: false
                },
                {
                    title: "Change to routine",
                    selected: false
                },
                {
                    title: "Having to wait",
                    selected: false
                },
                {
                    title: "Loud environment",
                    selected: false
                },
                {
                    title: "Being corrected",
                    selected: false
                },
                {
                    title: "Seizure",
                    selected: false
                },
                {
                    title: "Other",
                    selected: false
                }
            ],
        },
        {
            title: "What inappropriate behaviour happened?",
            value: "Abc",
            helperText: "B - Behaviour",
            width: screenWidth,
            type: "text",
            required: true,
        },
        {
            title: "What happened after?",
            value: "Abc",
            helperText: "C - Consequence",
            width: screenWidth,
            type: "text",
            required: true,
        },
        // {
        //     title: "Notes (include other people involved, setting event such as sickness, on medication, temperature too hot?)",
        //     value: "in on it or they he she will are and",
        //     width: screenWidth,
        //     type: "long_text",
        //     required: true,
        // },
    ]);
    const [notes, setNotes] = useState([
        {
            title: "Notes (include other people involved, setting event such as sickness, on medication, temperature too hot?)",
            value: "good",
            width: screenWidth,
            required: false,
            multiline: true
        }
    ])
    return (
        <Grid container sx={{ justifyContent: "center", marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, marginTop: { xs: "2%", sm: "2%", lg: "1%" }, }}>
            <Grid item={true} xs={11} md={12} sm={12} lg={11} sx={{ flexGrow: 1, }}>
                <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap', }}>
                    <Grid item={true} xs={11} md={6} sm={11} lg={6}>
                        <Stack spacing={2} direction={window.screen.width < 900 ? "column" : "row"} sx={{ my: 1 }}>
                            <Tooltip title="To Sepecific User">
                                <Button onClick={() => setNewFeedback(true)}
                                    variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Send Email
                                </Button>
                            </Tooltip>
                            <Tooltip title="Export an empty form.!">
                                <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Export
                                </Button>
                            </Tooltip>
                            <Tooltip title="Upload an incedent report, make sure its properly filled.!">
                                <Button component="label" startIcon={<CloudUploadOutlinedIcon color={"primary"} />}
                                    variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Upload
                                    <VisuallyHiddenInput type="file" onChange={(e) => { console.log(e.target.value) }} />
                                </Button>
                            </Tooltip>
                        </Stack>
                    </Grid>
                    <Grid item={true} xs={11} md={6} sm={11} lg={6}>
                        <SearchAndFilters groupBy={false} favorite={false} inputWidth={8} />
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap', }}>
                    <Grid sx={{ my: 2, display: 'flex', flexWrap: 'wrap', }}>
                        <Typography color={"primary"} style={{ fontFamily: "Roboto-Bold", fontSize: 20 }}>
                            Details of Client
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} sm={12} lg={12} sx={{ display: 'flex', flexWrap: 'wrap', border: "1px solid #6ECA35", borderRadius: 3, bgcolor: "#fff", p: 3 }}>
                    {incident.map((v, i) => {
                        return (
                            <div key={i}>
                                {v.type === "text" || v.type === "date" ? (
                                    <TextField
                                        type={v.type}
                                        required={v.required}
                                        label={v.title}
                                        value={v.value}
                                        sx={{ m: 1, width: v.width }}
                                        onChange={(e) => {
                                            const copy = [...incident]
                                            copy[i].value = e.target.value;
                                            setIncident(copy)
                                        }}
                                    />
                                ) : null}
                                {v.type === "drop_down" ? (
                                    <TextField
                                        select={true}
                                        label={v.title}
                                        required={v.required}
                                        sx={{ m: 1, width: v.width }}
                                        value={v.value}
                                        onChange={(e) => {
                                            const copy = [...incident]
                                            copy[i].value = e.target.value;
                                            setIncident(copy)
                                        }}
                                    >
                                        {v?.options?.map((option) => (
                                            <MenuItem key={option.title} value={option.title}>
                                                {option.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                ) : null}
                                {v.type === "call_number" ? (
                                    <TextField
                                        required={v.required}
                                        label={v.title}
                                        value={v.value}
                                        sx={{ m: 1, width: v.width }}
                                        onChange={(e) => {
                                            const copy = [...incident]
                                            copy[i].value = e.target.value;
                                            setIncident(copy)
                                        }}
                                    />
                                ) : null}
                                {v.type === "long_text" ? (
                                    <TextField
                                        multiline={true}
                                        required={v.required}
                                        label={v.title}
                                        value={v.value}
                                        sx={{ m: 1, width: v.width }}
                                        onChange={(e) => {
                                            const copy = [...incident]
                                            copy[i].value = e.target.value;
                                            setIncident(copy)
                                        }}
                                    />
                                ) : null}
                            </div>
                        )
                    })}
                </Grid>
                <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap', }}>
                    <Grid sx={{ my: 2, display: 'flex', flexWrap: 'wrap', }}>
                        <Typography color={"primary"} style={{ fontFamily: "Roboto-Bold", fontSize: 20 }}>
                            Details of Incident Reporter
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} sm={12} lg={12} sx={{ display: 'flex', flexWrap: 'wrap', border: "1px solid #6ECA35", borderRadius: 3, bgcolor: "#fff", p: 3 }}>
                    {detailsOfSupportWorker.map((v, i) => {
                        return (
                            <TextField
                                type={v.type}
                                key={i}
                                required={v.required}
                                label={v.title}
                                value={v.value}
                                sx={{ m: 1, width: v.width }}
                                onChange={(e) => {
                                    const copy = [...detailsOfSupportWorker]
                                    copy[i].value = e.target.value;
                                    setDetailsOfSupportWorker(copy)
                                }}
                            />
                        )
                    })}
                </Grid>
                <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap', }}>
                    <Grid sx={{ my: 2, display: 'flex', flexWrap: 'wrap', }}>
                        <Typography color={"primary"} style={{ fontFamily: "Roboto-Bold", fontSize: 20 }}>
                            Intensity Scale Rating (Circle applicable intensity rating)
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} sm={12} lg={12} sx={{ display: 'flex', flexWrap: 'wrap', border: "1px solid #6ECA35", borderRadius: 3, bgcolor: "#fff", p: 3 }}>
                    {intensityScaleRating.map((v, i) => {
                        return (
                            <TextField
                                select={true}
                                label={v.title}
                                key={i}
                                required={v.required}
                                sx={{ m: 1, width: v.width }}
                                value={v.value}
                                onChange={(e) => {
                                    const copy = [...intensityScaleRating]
                                    copy[i].value = e.target.value;
                                    setIntensityScaleRating(copy);
                                }}
                            >
                                {v?.options?.map((option) => (
                                    <MenuItem key={option.title} value={option.title}>
                                        {option.title}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )
                    })}

                    {antecedent.map((v, i) => {
                        return (
                            <div key={i}>
                                {v.type === "drop_down" ? (
                                    <TextField
                                        select={true}
                                        label={v.title}
                                        key={i}
                                        required={v.required}
                                        sx={{ m: 1, width: v.width }}
                                        value={v.value}
                                        helperText={v.helperText}
                                        onChange={(e) => {
                                            const copy = [...antecedent]
                                            copy[i].value = e.target.value;
                                            setAntecedent(copy);
                                        }}
                                    >
                                        {v?.options?.map((option) => (
                                            <MenuItem key={option.title} value={option.title}>
                                                {option.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                ) : null}
                                {v.type === "text" ? (
                                    <TextField
                                        key={i}
                                        required={v.required}
                                        label={v.title}
                                        value={v.value}
                                        helperText={v.helperText}
                                        sx={{ m: 1, width: v.width }}
                                        onChange={(e) => {
                                            const copy = [...antecedent]
                                            copy[i].value = e.target.value;
                                            setAntecedent(copy);
                                        }}
                                    />
                                ) : null}
                                {v.type === "long_text" ? (
                                    <TextField
                                        key={i}
                                        required={v.required}
                                        label={v.title}
                                        value={v.value}
                                        sx={{ m: 1, width: v.width }}
                                        onChange={(e) => {
                                            const copy = [...antecedent]
                                            copy[i].value = e.target.value;
                                            setAntecedent(copy);
                                        }}
                                    />
                                ) : null}
                            </div>
                        )
                    })}

                    {notes.map((v, i) => {
                        return (
                            <div key={i}>
                                <TextField
                                    key={i}
                                    multiline={v.multiline}
                                    required={v.required}
                                    label={v.title}
                                    value={v.value}
                                    onChange={(e) => {
                                        const copy = [...notes]
                                        copy[i].value = e.target.value;
                                        setNotes(copy);
                                    }}
                                    sx={{
                                        m: 1,
                                        width: {
                                            xs: v.width,
                                            sm: v.width,
                                            md: window.screen.width / 1.17,
                                            lg: window.screen.width < 1600 ? window.screen.width / 1.23 : window.screen.width / 1.21
                                        }
                                    }}
                                />
                            </div>
                        )
                    })}
                </Grid>
                <Grid item={true} xs={12} sm={12} lg={12} sx={{ p: 2, mx: 'auto', display: 'flex', flexWrap: 'wrap', justifyContent: "flex-end" }}>
                    <Stack direction="row" spacing={2}>
                        <Tooltip title="Save Online Report">
                            <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                Save
                            </Button>
                        </Tooltip>
                    </Stack>
                </Grid>
            </Grid>
            <EmailModal newFeedback={newFeedback} setNewFeedback={setNewFeedback} screenWidth={screenWidth} style={style} />
        </Grid >
    )
};

export const AddComplaintReport = () => {
    const [newFeedback, setNewFeedback] = useState(false);
    const height = window.screen.height;
    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        maxWidth: "calc(70%)",
        minWidth: "calc(60%)",
        boxShadow: 12,
        borderRadius: 1,
        p: 4,
    });
    const width = () => {
        // modal control
        if (window.screen.width < 900) {
            const copy = { ...style };
            copy.maxWidth = "95%";
            setStyle(copy);
        }

        if (window.screen.width > 1600) {
            return window.screen.width / 5
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.4
        }
    };
    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen])

    const [category, setCategory] = useState([
        {
            title: "Category",
            value: "Home",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Home",
                    selected: true
                },
                {
                    title: "Client",
                    selected: false
                },
                {
                    title: "Staff",
                    selected: false
                },
                {
                    title: "Other",
                    selected: false
                }
            ]
        },
    ])

    const [yourDetails, setYourDetails] = useState([
        {
            title: "Date of Complaint",
            value: "Abc",
            width: screenWidth,
            type: "date",
            multiline: false,
            required: true,
        },
        {
            title: "First Name",
            value: "Abc",
            width: screenWidth,
            type: "text",
            multiline: false,
            required: true,
        },
        {
            title: "Last Name",
            value: "Abc",
            width: screenWidth,
            type: "text",
            multiline: false,
            required: true,
        },
        {
            title: "Telephone (e.g. 0299999999)",
            value: "0299999999",
            width: screenWidth,
            type: "text",
            multiline: false,
            required: true,
        },
        {
            title: "Email address (e.g. name@company.com)",
            value: "admin@belacorp.com",
            width: screenWidth,
            type: "text",
            multiline: false,
            required: true,
        },
        {
            title: "I am a",
            width: screenWidth,
            type: "drop_down",
            required: true,
            multiline: false,
            value: "Client",
            options: [
                {
                    title: 'Client',
                    selected: true
                },
                {
                    title: 'Family member or friend',
                    selected: false
                },
                {
                    title: 'Advocate',
                    selected: false
                },
                {
                    title: 'Carer',
                    selected: false
                },
                {
                    title: 'Staff Member',
                    selected: false
                },
                {
                    title: "Other",
                    selected: false
                }
            ]
        },
        {
            title: "Are you making this complaint on behalf of a person with a disability?",
            width: screenWidth,
            type: "drop_down",
            required: true,
            multiline: false,
            value: "Yes",
            options: [
                {
                    title: 'Yes',
                    selected: true
                },
                {
                    title: 'No',
                    selected: false
                },
            ]
        },
        {
            title: "If you require help, please provide details of the help you need (eg: Interpreter)",
            value: "details",
            width: screenWidth,
            type: "text",
            required: false,
            multiline: true
        },
        {
            title: "Please provide details of your complaint.",
            value: "complaint",
            width: screenWidth,
            type: "text",
            required: false,
            multiline: true
        },
    ])

    const [continuousImprov, setContinuousImprov] = useState([
        {
            title: "Notes",
            value: "good",
            width: screenWidth,
            required: false,
            multiline: true
        }
    ])

    return (
        <Grid container sx={{ justifyContent: "center", marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, marginTop: { xs: "2%", sm: "2%", lg: "1%" }, }} >
            <Grid item={true} xs={11} md={12} sm={12} lg={11} sx={{ flexGrow: 1, marginBottom: height / 130, }}>
                <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap', }}>
                    <Grid item={true} xs={11} md={6} sm={11} lg={6}>
                        <Stack spacing={2} direction={window.screen.width < 900 ? "column" : "row"} sx={{ my: 1 }}>
                            <Tooltip title="To Sepecific User">
                                <Button onClick={() => setNewFeedback(true)}
                                    variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Send Email
                                </Button>
                            </Tooltip>
                            <Tooltip title="Export an empty form.!">
                                <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Export
                                </Button>
                            </Tooltip>
                            <Tooltip title="Upload an incedent report, make sure its properly filled.!">
                                <Button component="label" startIcon={<CloudUploadOutlinedIcon color="primary" />}
                                    variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Upload
                                    <VisuallyHiddenInput type="file" onChange={(e) => { console.log(e.target.value) }} />
                                </Button>
                            </Tooltip>
                        </Stack>
                    </Grid>
                    <Grid item={true} xs={11} md={6} sm={11} lg={6}>
                        <SearchAndFilters groupBy={false} favorite={false} inputWidth={8} />
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap', }}>
                    <Grid sx={{ my: 2, display: 'flex', flexWrap: 'wrap', }}>
                        <Typography color={"primary"} style={{ fontFamily: "Roboto-Bold", fontSize: 20 }}>
                            Complaint Classification
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} sm={12} lg={12} sx={{ display: 'flex', flexWrap: 'wrap', border: "1px solid #6ECA35", borderRadius: 3, bgcolor: "#fff", p: 3 }}>
                    {category.map((v, i) => {
                        return (
                            <div key={v.title}>
                                <TextField
                                    select={true}
                                    label={v.title}
                                    htmlFor={v.title}
                                    required={v.required}
                                    sx={{ m: 1, width: v.width }}
                                    value={v.value}
                                    onChange={(e) => {
                                        const copy = [...category];
                                        copy[i].value = e.target.value;
                                        setCategory(copy);
                                    }}
                                >
                                    {v?.options?.map((nV, nI) => (
                                        <MenuItem key={nI} value={nV.title} >
                                            {nV.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {v.value !== "Other" ? (
                                    <TextField
                                        multiline={v.multiline}
                                        required={v.required}
                                        label={"Search " + v.value}
                                        value={v.value}
                                        sx={{ m: 1, width: v.width }}
                                        onChange={(e) => {
                                            const copy = [...category];
                                            copy[i].value = e.target.value;
                                            setCategory(copy);
                                        }}
                                    />
                                ) : null}
                            </div>
                        )
                    })}
                </Grid>
                <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap', }}>
                    <Grid sx={{ my: 2, display: 'flex', flexWrap: 'wrap', }}>
                        <Typography color={"primary"} style={{ fontFamily: "Roboto-Bold", fontSize: 20 }}>
                            Complaint Details
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} sm={12} lg={12} sx={{ display: 'flex', flexWrap: 'wrap', border: "1px solid #6ECA35", borderRadius: 3, bgcolor: "#fff", p: 3 }}>
                    {yourDetails.map((v, i) => {
                        return (
                            <div key={v.title}>
                                {v.type === "text" || v.type === "email" || v.type === "date" ? (
                                    <TextField
                                        type={v.type}
                                        required={v.required}
                                        label={v.title}
                                        multiline={v.multiline}
                                        value={v.value}
                                        sx={{ m: 1, width: v.width }}
                                        onChange={(e) => {
                                            const copy = [...yourDetails];
                                            copy[i].value = e.target.value;
                                            setYourDetails(copy);
                                        }}
                                    />
                                ) : null}
                                {v.type === "drop_down" ? (
                                    <TextField
                                        select={true}
                                        label={v.title}
                                        required={v.required}
                                        sx={{ m: 1, width: v.width }}
                                        value={v.value}
                                        onChange={(e) => {
                                            const copy = [...yourDetails];
                                            copy[i].value = e.target.value;
                                            setYourDetails(copy);
                                        }}
                                    >
                                        {v?.options?.map((option) => (
                                            <MenuItem key={option.title} value={option.title}>
                                                {option.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                ) : null}
                            </div>
                        )
                    })}
                    {continuousImprov.map((v, i) => {
                        return (
                            <div key={v.title}>
                                <TextField
                                    multiline={v.multiline}
                                    required={v.required}
                                    label={v.title}
                                    value={v.value}
                                    helperText={v.helperText}
                                    onChange={(e) => {
                                        const copy = [...continuousImprov];
                                        copy[i].value = e.target.value;
                                        setContinuousImprov(copy);
                                    }}
                                    sx={{
                                        m: 1,
                                        width: {
                                            xs: v.width,
                                            sm: v.width,
                                            md: window.screen.width / 1.17,
                                            lg: window.screen.width < 1600 ? window.screen.width / 1.23 : window.screen.width / 1.21
                                        }
                                    }}
                                />
                            </div>
                        )
                    })}
                </Grid>
                <Grid item={true} xs={12} sm={12} lg={12} sx={{ p: 2, mx: 'auto', display: 'flex', flexWrap: 'wrap', justifyContent: "flex-end" }}>
                    <Stack direction="row" spacing={2}>
                        <Tooltip title="Save Online Report">
                            <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                Save
                            </Button>
                        </Tooltip>
                    </Stack>
                </Grid>
            </Grid>
            <EmailModal newFeedback={newFeedback} setNewFeedback={setNewFeedback} screenWidth={screenWidth} style={style} />
        </Grid>
    )
};

const EmailModal = ({ newFeedback, setNewFeedback, screenWidth, style }) => {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={newFeedback}
            onClose={() => setNewFeedback(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={newFeedback}>
                <Box sx={style}>
                    <Box>
                        <Typography variant="h5" component="h2" sx={{ fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                            Send Email
                        </Typography>
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <TextField
                            label="Email To"
                            value={""}
                            sx={{ m: 1, width: screenWidth }}
                        />
                        <TextField
                            label="Description"
                            value={""}
                            sx={{ m: 1, width: screenWidth }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" >
                                Send
                            </Button>
                            <Button variant="contained" onClick={() => setNewFeedback(false)}>
                                Cancel
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

const ContinousImprovements = ({ openState, setOpenState, screenWidth, style }) => {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openState}
            onClose={() => setOpenState(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={openState}>
                <Box sx={style}>
                    <Box>
                        <Typography variant="h5" component="h2" sx={{ fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                            Continous Improvements
                        </Typography>
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <TextField
                            label="Actions"
                            value={""}
                            sx={{ m: 1, width: screenWidth }}
                        />
                        <TextField
                            select={true}
                            label="Status"
                            value={""}
                            sx={{ m: 1, width: screenWidth }}
                        >
                            <MenuItem>Pending</MenuItem>
                            <MenuItem>In Progress</MenuItem>
                            <MenuItem>Completed</MenuItem>
                            <MenuItem>Rejected</MenuItem>
                        </TextField>
                        <TextField
                            label="Continuous Improvements"
                            value={""}
                            sx={{ m: 1, width: screenWidth }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" >
                                Save
                            </Button>
                            <Button variant="contained" onClick={() => setOpenState(false)}>
                                Cancel
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

const clientData = (id, Name, Gender, Age, IsNdisFunded, IsPackageFunded, DivisionName, PrimaryPhone, SecondaryPhone, PrimaryEmailAddress, State, IsActive, ContactId, ContactCode) => {
    return {
        id,
        Name,
        Gender,
        Age,
        IsNdisFunded,
        IsPackageFunded,
        DivisionName,
        PrimaryPhone,
        SecondaryPhone,
        PrimaryEmailAddress,
        State,
        IsActive,
        ContactId,
        ContactCode
    };
};

const staffData = (id, Name, GenderName, Age, IsCarer, PrimaryPhone, SecondaryPhone, PrimaryEmail, FormattedAddress, StartDate, EmergencyContactName, EmergencyContactPrimaryNumber, Email, TaxFileNumber, TaxResident, TaxClaimThreshold, SuperFundName, SuperFundNumber, WagesBsb, WagesAccount, WasgesAccountName, ContractTypeName, PayBandName, IsActive) => {
    return {
        id,
        Name,
        GenderName,
        Age,
        IsCarer,
        PrimaryPhone,
        SecondaryPhone,
        PrimaryEmail,
        FormattedAddress,
        StartDate,
        EmergencyContactName,
        EmergencyContactPrimaryNumber,
        Email,
        TaxFileNumber,
        TaxResident,
        TaxClaimThreshold,
        SuperFundName,
        SuperFundNumber,
        WagesBsb,
        WagesAccount,
        WasgesAccountName,
        ContractTypeName,
        PayBandName,
        IsActive,
    };
};

const MedicaData = (id, client_id, first_name, last_name, medication, purpose_medication, dose_route, expiry_date, comments, medical_practitioner, medical_contact) => {
    return {
        id,
        client_id,
        first_name,
        last_name,
        medication,
        purpose_medication,
        dose_route,
        expiry_date,
        comments,
        medical_practitioner,
        medical_contact
    };
}

const Incident = (id, first_name, last_name, gender, date_of_birth, address1, address2, home_phone, service_name, incident_reporter_name, position, physical_aggression, verbal_aggression, property_damage, location_of_incident, date_and_time, duration, what_happened_before, what_inappropriate_behavior_happened, what_happened_after, notes,) => {
    return {
        id,
        first_name,
        last_name,
        gender,
        date_of_birth,
        address1,
        address2,
        home_phone,
        service_name,
        incident_reporter_name,
        position,
        physical_aggression,
        verbal_aggression,
        property_damage,
        location_of_incident,
        date_and_time,
        duration,
        what_happened_before,
        what_inappropriate_behavior_happened,
        what_happened_after,
        notes,
    };
}

const Complaint = (id, category, option, date_of_complaint, first_name, last_name, telephone, email, i_am_a, disability, details_needed, details_complaint, notes) => {
    return {
        id,
        category,
        option,
        date_of_complaint,
        first_name,
        last_name, telephone, email, i_am_a, disability, details_needed, details_complaint, notes
    };
}

const orgsData = (id, email, phone, website, Abn, Description, invoice_to, primary_invoice, secondary_invoice, primary_schedule_email, secondary_schedule_email) => {
    return {
        id,
        email,
        phone,
        website,
        Abn,
        Description,
        invoice_to,
        primary_invoice,
        secondary_invoice,
        primary_schedule_email,
        secondary_schedule_email,
    };
}

const ContinousRData = (id, type, date, category, description, actions, continous_improvment, status, date_addressed) => {
    return {
        id,
        type,
        date,
        category,
        description,
        actions,
        continous_improvment,
        status,
        date_addressed
    };
}

const feedback = (id, first_name, last_name, contact, email, feedback_type, source_feedback, areas_feedback, comments, date_of_report) => {
    return {
        id, first_name, last_name, contact, email, feedback_type, source_feedback, areas_feedback, comments, date_of_report
    };
}

const meetings = (id, meetingDate, meetingTime, meetingTitle, attendeesName, agendaItems, notes, follow_up_actions, NextMeetingDate, Options) => {
    return {
        id, meetingDate, meetingTime, meetingTitle, attendeesName, agendaItems,
        notes,
        follow_up_actions,
        NextMeetingDate,
        Options
    };
}

export const ClientRegister = () => {

    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 4.5
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.2
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const [viewExpose, setViewExpose] = useState("list");

    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'Name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'Gender',
            numeric: false,
            disablePadding: true,
            label: 'Gender',
        },
        {
            id: 'Age',
            numeric: true,
            disablePadding: false,
            label: 'Age',
        },
        {
            id: 'Is NDIS Funded',
            numeric: true,
            disablePadding: false,
            label: 'Is NDIS Funded',
        },
        {
            id: 'Is Package Funded',
            numeric: true,
            disablePadding: false,
            label: 'Is Package Funded',
        },
        {
            id: 'Division Name',
            numeric: true,
            disablePadding: false,
            label: 'Division Name',
        },
        // {
        //     id: 'Primary Phone',
        //     numeric: true,
        //     disablePadding: false,
        //     label: 'Primary Phone',
        // },
        // {
        //     id: 'Secondary Phone',
        //     numeric: true,
        //     disablePadding: false,
        //     label: 'Secondary Phone',
        // },
        {
            id: 'Primary Email Address',
            numeric: true,
            disablePadding: false,
            label: 'Primary Email Address',
        },
        {
            id: 'State',
            numeric: true,
            disablePadding: false,
            label: 'State',
        },
        {
            id: 'IsActive',
            numeric: true,
            disablePadding: false,
            label: 'IsActive',
        },
        // {
        //     id: 'ContactId',
        //     numeric: true,
        //     disablePadding: false,
        //     label: 'ContactId',
        // },
        // {
        //     id: 'ContactCode',
        //     numeric: true,
        //     disablePadding: false,
        //     label: 'ContactCode',
        // },
        {
            id: "Options",
            numeric: false,
            disablePadding: false,
            label: 'Options',
        }
    ];

    const rows = [
        clientData(1, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(2, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(3, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(4, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(5, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(6, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(7, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(8, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(9, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(10, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(11, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(12, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(13, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(14, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(15, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(16, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(17, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
    ];

    const handleChange = (event, newAlignment) => {
        setViewExpose(newAlignment);
    };

    return (
        <Grid container sx={{ justifyContent: "center", marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, marginTop: "1%", }} >
            <Grid item xs={12} md={12} lg={11.5} sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", lg: "row", md: "row" } }}>
                <Grid item={true} xs={12} md={5} lg={4} sx={{ display: "flex", justifyContent: { lg: "flex-start", xs: "center" }, alignItems: "center" }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to CSV
                        </Button>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to PDF
                        </Button>
                    </Stack>
                </Grid>
                <Grid item={true} xs={12} md={4} lg={7} >
                    <SearchAndFilters topMargin={2} inputWidth={6} userType={"client"} />
                </Grid>
                <Grid item={true} xs={11} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={viewExpose}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="grid"><GridViewOutlinedIcon /></ToggleButton>
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="list"><ReorderOutlinedIcon /></ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Grid item xs={11.5} md={12} lg={11.5} >
                {/* {viewExpose === "list" ?
                    <RegisterClinetsList rows={rows} headCells={headCells} />
                    :
                    <RegisterClinetsGrid rows={rows} headCells={headCells} screenWidth={screenWidth} />
                } */}
            </Grid>
        </Grid>
    )
}

export const ContinousRegister = () => {


    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        maxWidth: "calc(70%)",
        minWidth: "calc(60%)",
        boxShadow: 12,
        borderRadius: 1,
        p: 4,
    });

    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 4
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.2
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);
    const [editOpen, setEditOpen] = useState(false);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);


    const [viewExpose, setViewExpose] = useState("list");
    const handleChange = (event, newAlignment) => {
        setViewExpose(newAlignment);
    };
    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'Type',
            numeric: false,
            disablePadding: true,
            label: 'Type',
        },
        {
            id: 'Date',
            numeric: false,
            disablePadding: true,
            label: 'Date',
        },
        {
            id: 'Category',
            numeric: false,
            disablePadding: true,
            label: 'Category',
        },
        {
            id: 'Description',
            numeric: true,
            disablePadding: false,
            label: 'Description',
        },
        {
            id: 'Actions',
            numeric: true,
            disablePadding: false,
            label: 'Actions',
        },
        {
            id: 'Continuous Improvements',
            numeric: true,
            disablePadding: false,
            label: 'Continuous Improvements',
        },
        {
            id: 'Status',
            numeric: true,
            disablePadding: false,
            label: 'Status',
        },
        {
            id: 'Date Addressed',
            numeric: true,
            disablePadding: false,
            label: 'Date Addressed',
        },
        {
            id: 'Options',
            numeric: true,
            disablePadding: false,
            label: 'Options',
        },
    ];

    const rows = [
        ContinousRData("1", "Complaint", "19-08-2024", "The house smells bad", "The house smells bad", "Implemented", "We have implemented regular cleaning to be done every mondays", "Done", "05-29-2024"),
    ];

    return (
        <Grid container sx={{ justifyContent: "center", marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, marginTop: "1%", }} >
            <Grid item xs={12} md={12} lg={11.5} sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", lg: "row", md: "row" } }}>
                <Grid item={true} xs={12} md={5} lg={4} sx={{ display: "flex", justifyContent: { lg: "flex-start", xs: "center" }, alignItems: "center" }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to CSV
                        </Button>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to PDF
                        </Button>
                    </Stack>
                </Grid>
                <Grid item={true} xs={12} md={4} lg={7} >
                    <SearchAndFilters topMargin={2} inputWidth={6} userType={"client"} />
                </Grid>
                <Grid item={true} xs={11} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={viewExpose}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="grid"><GridViewOutlinedIcon /></ToggleButton>
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="list"><ReorderOutlinedIcon /></ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Grid item xs={11.5} md={12} lg={11.5} >
                {viewExpose === "list" ?
                    <RegisterContinousImprovmentList rows={rows} headCells={headCells} editOpen={editOpen} setEditOpen={setEditOpen} />
                    :
                    <RegisterContinousImprovmentGrid rows={rows} headCells={headCells} screenWidth={screenWidth} />
                }
            </Grid>

            <ContinousImprovements screenWidth={screenWidth} openState={editOpen} setOpenState={setEditOpen} style={style} />
        </Grid>
    )
}

export const StaffRegister = () => {

    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 4.5
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.2
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const [viewExpose, setViewExpose] = useState("list");

    const handleChange = (event, newAlignment) => {
        setViewExpose(newAlignment);
    };

    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'Name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'Gender',
            numeric: false,
            disablePadding: true,
            label: 'Gender',
        },
        {
            id: 'Age',
            numeric: false,
            disablePadding: true,
            label: 'Age',
        },
        {
            id: 'Is Carer',
            numeric: false,
            disablePadding: true,
            label: 'Is Carer',
        },
        // {
        //     id: 'Primary Phone',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Primary Phone',
        // },
        // {
        //     id: 'Secondary Phone',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Secondary Phone',
        // },
        {
            id: 'Primary Email',
            numeric: false,
            disablePadding: true,
            label: 'Primary Email',
        },
        {
            id: 'Formatted Address',
            numeric: false,
            disablePadding: true,
            label: 'Formatted Address',
        },
        {
            id: 'Start Date',
            numeric: false,
            disablePadding: true,
            label: 'Start Date',
        },
        // {
        //     id: 'Emergency Contact Name',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Emergency Contact Name',
        // },
        // {
        //     id: 'Emergency Contact Primary Number',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Emergency Contact Primary Number',
        // },
        {
            id: 'Email',
            numeric: false,
            disablePadding: true,
            label: 'Email',
        }, 
        {
            id: 'Tax File Number',
            numeric: false,
            disablePadding: true,
            label: 'Tax File Number',
        },
        {
            id: 'Tax Resident',
            numeric: false,
            disablePadding: true,
            label: 'Tax Resident',
        },
        {
            id: 'Tax Claim Threshold',
            numeric: false,
            disablePadding: true,
            label: 'Tax Claim Threshold',
        },
        {
            id: 'Super Fund Number',
            numeric: false,
            disablePadding: true,
            label: 'Super Fund Number',
        },
        {
            id: 'WagesBsb',
            numeric: false,
            disablePadding: true,
            label: 'WagesBsb',
        },
        {
            id: 'Wages Account',
            numeric: false,
            disablePadding: true,
            label: 'Wages Account',
        },
        {
            id: 'Wasges Account Name',
            numeric: false,
            disablePadding: true,
            label: 'Wasges Account Name',
        },
        {
            id: 'Contract Type Name',
            numeric: false,
            disablePadding: true,
            label: 'Contract Type Name',
        },
        {
            id: 'Pay Band Name',
            numeric: false,
            disablePadding: true,
            label: 'Pay Band Name',
        },
        {
            id: 'Is Active',
            numeric: false,
            disablePadding: true,
            label: 'Is Active',
        },
        {
            id: 'Options',
            numeric: false,
            disablePadding: true,
            label: 'Options',
        }
    ];

    const rows = [
        staffData(1, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(2, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(3, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(4, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(5, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(6, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(7, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(8, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(9, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(10, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(11, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(12, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(13, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(14, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(15, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(16, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(17, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(18, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(19, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(20, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(21, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(22, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", new Date().getDate(), "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
    ];

    return (
        <Grid container sx={{ justifyContent: "center", marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, marginTop: "1%", }} >
            <Grid item xs={12} md={12} lg={11.5} sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", lg: "row", md: "row" } }}>
                <Grid item={true} xs={12} md={5} lg={4} sx={{ display: "flex", justifyContent: { lg: "flex-start", xs: "center" }, alignItems: "center" }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to CSV
                        </Button>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to PDF
                        </Button>
                    </Stack>
                </Grid>
                <Grid item={true} xs={12} md={4} lg={7} >
                    <SearchAndFilters topMargin={2} inputWidth={6} userType={"client"} />
                </Grid>
                <Grid item={true} xs={11} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={viewExpose}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="grid"><GridViewOutlinedIcon /></ToggleButton>
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="list"><ReorderOutlinedIcon /></ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Grid item xs={11.5} md={12} lg={11.5} >
                {viewExpose === "list" ?
                    <RegisterStaffList rows={rows} headCells={headCells} />
                    :
                    <RegisterStaffGrid rows={rows} headCells={headCells} screenWidth={screenWidth} />
                }
            </Grid>
        </Grid>
    )
}

export const MedicationRegister = () => {

    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 4.5
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.2
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const [viewExpose, setViewExpose] = useState("list");
    const handleChange = (event, newAlignment) => {
        setViewExpose(newAlignment);
    };

    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'Client Id',
            numeric: false,
            disablePadding: true,
            label: 'Client Id',
        },
        {
            id: 'First Name',
            numeric: false,
            disablePadding: true,
            label: 'First Name',
        },
        {
            id: 'Last Name',
            numeric: false,
            disablePadding: true,
            label: 'Last Name',
        },
        {
            id: 'Medication',
            numeric: false,
            disablePadding: true,
            label: 'Medication',
        },
        {
            id: 'Purpose of Medication',
            numeric: false,
            disablePadding: true,
            label: 'Purpose of Medication',
        },
        {
            id: 'Dose Route',
            numeric: false,
            disablePadding: true,
            label: 'Dose Route',
        },
        {
            id: 'Expiry date',
            numeric: false,
            disablePadding: true,
            label: 'Expiry date',
        },
        {
            id: 'Comments',
            numeric: false,
            disablePadding: true,
            label: 'Comments',
        },
        {
            id: "Medical Practitioner",
            numeric: false,
            disablePadding: true,
            label: 'Medical Practitioner',
        },
        {
            id: "Practitioners Contact",
            numeric: false,
            disablePadding: true,
            label: 'Practitioners Contact',
        },
    ];

    const rows = [
        MedicaData(1, "CL324", "Rameez", "Khan", "Panadol", "Fever", "Mouth", "05/2025", "Normal", "Salman", "2365896598"),
        MedicaData(2, "CL324", "Rameez", "Khan", "Panadol", "Fever", "Mouth", "05/2025", "Normal", "Salman", "2365896598"),
        MedicaData(3, "CL324", "Rameez", "Khan", "Panadol", "Fever", "Mouth", "05/2025", "Normal", "Salman", "2365896598"),
        MedicaData(4, "CL324", "Rameez", "Khan", "Panadol", "Fever", "Mouth", "05/2025", "Normal", "Salman", "2365896598"),
    ];

    return (
        <Grid container sx={{ justifyContent: "center", marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, marginTop: "1%", }} >
            <Grid item xs={12} md={12} lg={11.5} sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", lg: "row", md: "row" } }}>
                <Grid item={true} xs={12} md={5} lg={4} sx={{ display: "flex", justifyContent: { lg: "flex-start", xs: "center" }, alignItems: "center" }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to CSV
                        </Button>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to PDF
                        </Button>
                    </Stack>
                </Grid>
                <Grid item={true} xs={12} md={4} lg={7} >
                    <SearchAndFilters topMargin={2} inputWidth={6} userType={"client"} />
                </Grid>
                <Grid item={true} xs={11} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={viewExpose}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="grid"><GridViewOutlinedIcon /></ToggleButton>
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="list"><ReorderOutlinedIcon /></ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Grid item xs={11.5} md={12} lg={11.5} >
                {viewExpose === "list" ?
                    <RegisterMedicationList rows={rows} headCells={headCells} />
                    :
                    <RegisterMedicationGrid rows={rows} headCells={headCells} screenWidth={screenWidth} />
                }
            </Grid>
        </Grid>
    )
}

export const IncedentRegister = () => {

    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 4.5
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.2
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const [viewExpose, setViewExpose] = useState("list");
    const handleChange = (event, newAlignment) => {
        setViewExpose(newAlignment);
    };

    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'First Name',
            numeric: false,
            disablePadding: true,
            label: 'First Name',
        },
        {
            id: 'Last  Name',
            numeric: false,
            disablePadding: true,
            label: 'Last  Name',
        },
        {
            id: 'Gender',
            numeric: false,
            disablePadding: true,
            label: 'Gender',
        },
        {
            id: 'DOB',
            numeric: false,
            disablePadding: true,
            label: 'DOB',
        },
        {
            id: 'Address 1',
            numeric: false,
            disablePadding: true,
            label: 'Address 1',
        },
        {
            id: 'Address 2',
            numeric: false,
            disablePadding: true,
            label: 'Address 2',
        },
        {
            id: 'Home Phone',
            numeric: false,
            disablePadding: true,
            label: 'Home Phone',
        },
        {
            id: 'Service Name(s)',
            numeric: false,
            disablePadding: true,
            label: 'Service Name(s)',
        },
        {
            id: 'Incident Reporter Name',
            numeric: false,
            disablePadding: true,
            label: 'Incident Reporter Name',
        },
        {
            id: 'Position',
            numeric: false,
            disablePadding: true,
            label: 'Position',
        },
        {
            id: 'Physical Aggression',
            numeric: false,
            disablePadding: true,
            label: 'Physical Aggression',
        },
        {
            id: 'Verbal Aggression',
            numeric: false,
            disablePadding: true,
            label: 'Verbal Aggression',
        },
        {
            id: 'Property Damage',
            numeric: false,
            disablePadding: true,
            label: 'Property Damage',
        },
        {
            id: 'Location of incident',
            numeric: false,
            disablePadding: true,
            label: 'Location of incident',
        },
        {
            id: 'Date and Time',
            numeric: false,
            disablePadding: true,
            label: 'Date and Time',
        },
        {
            id: 'Duration',
            numeric: false,
            disablePadding: true,
            label: 'Duration',
        },
        {
            id: 'What Happened Before',
            numeric: false,
            disablePadding: true,
            label: 'What Happened Before',
        },
        {
            id: "What Inappropriate Behavior Happened ?",
            numeric: false,
            disablePadding: true,
            label: 'What Inappropriate Behavior Happened ?',
        },
        {
            id: "What happened after ?",
            numeric: false,
            disablePadding: true,
            label: 'What happened after ?',
        },
        {
            id: "Notes",
            numeric: false,
            disablePadding: true,
            label: 'Notes',
        },
        {
            id: 'Options',
            numeric: false,
            disablePadding: true,
            label: 'Options',
        },
    ];

    const rows = [
        Incident(1, "Salman", "zafar", "male", "19-04-24", "abc address", "abc address", "0265989798", "abc service", "Rameez", "staff", "Physical/self harm, first aid required", "Yelling and threats to another persons safety", "No property damage", "in home", "5-29-2024To9:19", "1 hour", "Change to routine", "abc", "abc", "property damage"),
        Incident(2, "Salman", "zafar", "male", "19-04-24", "abc address", "abc address", "0265989798", "abc service", "Rameez", "staff", "Physical/self harm, first aid required", "Yelling and threats to another persons safety", "No property damage", "in home", "5-29-2024To9:19", "1 hour", "Change to routine", "abc", "abc", "property damage"),
        Incident(3, "Salman", "zafar", "male", "19-04-24", "abc address", "abc address", "0265989798", "abc service", "Rameez", "staff", "Physical/self harm, first aid required", "Yelling and threats to another persons safety", "No property damage", "in home", "5-29-2024To9:19", "1 hour", "Change to routine", "abc", "abc", "property damage"),
        Incident(4, "Salman", "zafar", "male", "19-04-24", "abc address", "abc address", "0265989798", "abc service", "Rameez", "staff", "Physical/self harm, first aid required", "Yelling and threats to another persons safety", "No property damage", "in home", "5-29-2024To9:19", "1 hour", "Change to routine", "abc", "abc", "property damage"),
    ];

    return (
        <Grid container sx={{ justifyContent: "center", marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, marginTop: "1%", }} >
            <Grid item xs={12} md={12} lg={11.5} sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", lg: "row", md: "row" } }}>
                <Grid item={true} xs={12} md={5} lg={4} sx={{ display: "flex", justifyContent: { lg: "flex-start", xs: "center" }, alignItems: "center" }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to CSV
                        </Button>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to PDF
                        </Button>
                    </Stack>
                </Grid>
                <Grid item={true} xs={12} md={4} lg={7} >
                    <SearchAndFilters topMargin={2} inputWidth={6} userType={"client"} />
                </Grid>
                <Grid item={true} xs={11} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={viewExpose}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="grid"><GridViewOutlinedIcon /></ToggleButton>
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="list"><ReorderOutlinedIcon /></ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Grid item xs={11.5} md={12} lg={11.5} >
                {viewExpose === "list" ?
                    <IncedentRegisterList rows={rows} headCells={headCells} />
                    :
                    <IncidentRegisterGrid rows={rows} headCells={headCells} screenWidth={screenWidth} />
                }
            </Grid>
        </Grid>
    )
}

export const ComplaintRegister = () => {

    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 4.5
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.2
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const [viewExpose, setViewExpose] = useState("list");
    const handleChange = (event, newAlignment) => {
        setViewExpose(newAlignment);
    };

    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'Category',
            numeric: false,
            disablePadding: true,
            label: 'Category',
        },
        {
            id: 'Category Name (eg staff name, client name, home name)',
            numeric: false,
            disablePadding: true,
            label: 'Category Name',
        },
        {
            id: 'Date Of Complaint',
            numeric: false,
            disablePadding: true,
            label: 'Date Of Complaint',
        },
        {
            id: 'First Name',
            numeric: false,
            disablePadding: true,
            label: 'First Name',
        },
        {
            id: 'Last Name',
            numeric: false,
            disablePadding: true,
            label: 'Last Name',
        },
        {
            id: 'Telephone',
            numeric: false,
            disablePadding: true,
            label: 'Telephone',
        },
        {
            id: 'Email Address',
            numeric: false,
            disablePadding: true,
            label: 'Email Address',
        },
        {
            id: 'I am a',
            numeric: false,
            disablePadding: true,
            label: 'I am a',
        },
        {
            id: "Are you making this complaint on behalf of a person with disability",
            numeric: false,
            disablePadding: true,
            label: "Person with disability",
        },
        {
            id: "Details (of help needed)",
            numeric: false,
            disablePadding: true,
            label: "Details (of help needed)",
        },
        {
            id: "Details of complaint",
            numeric: false,
            disablePadding: true,
            label: "Details of complaint",
        },
        {
            id: "Notes",
            numeric: false,
            disablePadding: true,
            label: "Notes",
        },
        {
            id: 'Options',
            numeric: false,
            disablePadding: true,
            label: 'Options',
        },
    ];

    const rows = [
        Complaint(1, "Staff", "Abc Staff", "19-05-2024", "salman", "zafar", "0265986466", "admin@gmail.com", "Client", "Yes", "details", "complaint", "good"),
        Complaint(2, "Staff", "Abc Staff", "19-05-2024", "salman", "zafar", "0265986466", "admin@gmail.com", "Client", "Yes", "details", "complaint", "good"),
        Complaint(3, "Staff", "Abc Staff", "19-05-2024", "salman", "zafar", "0265986466", "admin@gmail.com", "Client", "Yes", "details", "complaint", "good"),
        Complaint(4, "Staff", "Abc Staff", "19-05-2024", "salman", "zafar", "0265986466", "admin@gmail.com", "Client", "Yes", "details", "complaint", "good"),
        Complaint(5, "Staff", "Abc Staff", "19-05-2024", "salman", "zafar", "0265986466", "admin@gmail.com", "Client", "Yes", "details", "complaint", "good"),
        Complaint(6, "Staff", "Abc Staff", "19-05-2024", "salman", "zafar", "0265986466", "admin@gmail.com", "Client", "Yes", "details", "complaint", "good"),
        Complaint(7, "Staff", "Abc Staff", "19-05-2024", "salman", "zafar", "0265986466", "admin@gmail.com", "Client", "Yes", "details", "complaint", "good"),
        Complaint(8, "Staff", "Abc Staff", "19-05-2024", "salman", "zafar", "0265986466", "admin@gmail.com", "Client", "Yes", "details", "complaint", "good"),
        Complaint(9, "Staff", "Abc Staff", "19-05-2024", "salman", "zafar", "0265986466", "admin@gmail.com", "Client", "Yes", "details", "complaint", "good"),
        Complaint(10, "Staff", "Abc Staff", "19-05-2024", "salman", "zafar", "0265986466", "admin@gmail.com", "Client", "Yes", "details", "complaint", "good"),
        Complaint(11, "Staff", "Abc Staff", "19-05-2024", "salman", "zafar", "0265986466", "admin@gmail.com", "Client", "Yes", "details", "complaint", "good"),
    ];

    return (
        <Grid container sx={{ justifyContent: "center", marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, marginTop: "1%", }} >
            <Grid item xs={12} md={12} lg={11.5} sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", lg: "row", md: "row" } }}>
                <Grid item={true} xs={12} md={5} lg={4} sx={{ display: "flex", justifyContent: { lg: "flex-start", xs: "center" }, alignItems: "center" }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to CSV
                        </Button>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to PDF
                        </Button>
                    </Stack>
                </Grid>
                <Grid item={true} xs={12} md={4} lg={7} >
                    <SearchAndFilters topMargin={2} inputWidth={6} userType={"client"} />
                </Grid>
                <Grid item={true} xs={11} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={viewExpose}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="grid"><GridViewOutlinedIcon /></ToggleButton>
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="list"><ReorderOutlinedIcon /></ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Grid item xs={11.5} md={12} lg={11.5} >
                {viewExpose === "list" ?
                    <ComplaintRegisterList rows={rows} headCells={headCells} />
                    :
                    <ComplaintRegisterGrid rows={rows} headCells={headCells} screenWidth={screenWidth} />
                }
            </Grid>
        </Grid>
    )
}

export const OrgnizationRegister = () => {

    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 4.5
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.2
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const dataList = [...data?.orgnization];
    const [viewExpose, setViewExpose] = useState("list");
    const handleChange = (event, newAlignment) => {
        setViewExpose(newAlignment);
    };
    const [orgs, setOrgs] = useState([]);
    const headCells = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'id',
        },
        {
            id: 'Email',
            numeric: false,
            disablePadding: true,
            label: 'Email',
        },
        {
            id: 'phone',
            numeric: false,
            disablePadding: true,
            label: 'phone',
        },
        {
            id: 'website',
            numeric: false,
            disablePadding: true,
            label: 'website',
        },
        {
            id: 'Abn',
            numeric: false,
            disablePadding: true,
            label: 'Abn',
        },
        {
            id: 'Description',
            numeric: false,
            disablePadding: true,
            label: 'Description',
        },
        // {
        //     id: 'Invoice to',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Invoice to',
        // },
        {
            id: 'Primary Invoice',
            numeric: false,
            disablePadding: true,
            label: 'Primary Invoice',
        },
        {
            id: 'Secondary Invoice',
            numeric: false,
            disablePadding: true,
            label: 'Secondary Invoice',
        },
        {
            id: 'Primary Schedule Email',
            numeric: false,
            disablePadding: true,
            label: 'Primary Schedule Email',
        },
        {
            id: 'Secondary Schedule Email',
            numeric: false,
            disablePadding: true,
            label: 'Secondary Schedule Email',
        },
        {
            id: 'Options',
            numeric: false,
            disablePadding: true,
            label: 'Options',
        }
    ];

    useEffect(() => {
        const copy = [...orgs];
        for (const key in dataList) {
            const inData = dataList[key];
            copy.push(orgsData(
                inData.organization_detail.id,
                inData.organization_detail.email,
                inData.organization_detail.phone,
                inData.organization_detail.website,
                inData.organization_detail.Abn,
                inData.organization_detail.Description,
                inData.brokerage_detail.invoice_to,
                inData.brokerage_detail.primary_invoice,
                inData.brokerage_detail.secondary_invoice,
                inData.brokerage_detail.primary_schedule_email,
                inData.brokerage_detail.secondary_schedule_email,
            ));
        };
        setOrgs(copy);
    }, []);

    return (
        <Grid container sx={{ justifyContent: "center", marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, marginTop: "1%", }} >
            <Grid item xs={12} md={12} lg={11.5} sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", lg: "row", md: "row" } }}>
                <Grid item={true} xs={12} md={5} lg={4} sx={{ display: "flex", justifyContent: { lg: "flex-start", xs: "center" }, alignItems: "center" }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to CSV
                        </Button>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to PDF
                        </Button>
                    </Stack>
                </Grid>
                <Grid item={true} xs={12} md={4} lg={7} >
                    <SearchAndFilters topMargin={2} inputWidth={6} userType={"client"} />
                </Grid>
                <Grid item={true} xs={11} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={viewExpose}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="grid"><GridViewOutlinedIcon /></ToggleButton>
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="list"><ReorderOutlinedIcon /></ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            {viewExpose === "list" ?
                <Grid item xs={11.5} md={12} lg={11.5} >
                    {orgs.length && <OrgnizationsList rows={orgs} headCells={headCells} />}
                </Grid>
                :
                <Grid item xs={11.5} md={12} lg={11.5} >
                    {orgs.length && <OrgnizationsGrid rows={orgs} headCells={headCells} screenWidth={screenWidth} />}
                </Grid>
            }
        </Grid>
    )
}

export const FeedbackRegister = () => {

    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 4.5
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.2
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const [viewExpose, setViewExpose] = useState("list");
    const handleChange = (event, newAlignment) => {
        setViewExpose(newAlignment);
    };

    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'First Name',
            numeric: false,
            disablePadding: true,
            label: 'First Name',
        },
        {
            id: 'Last Name',
            numeric: false,
            disablePadding: true,
            label: 'Last Name',
        },
        {
            id: 'Contact',
            numeric: false,
            disablePadding: true,
            label: 'Contact',
        },
        {
            id: 'Email',
            numeric: false,
            disablePadding: true,
            label: 'Email',
        },
        {
            id: 'Feedback Type',
            numeric: false,
            disablePadding: true,
            label: 'Feedback Type',
        },
        {
            id: 'Source of feedback',
            numeric: false,
            disablePadding: true,
            label: 'Source of feedback',
        },
        {
            id: 'Areas Providing Feedback',
            numeric: false,
            disablePadding: true,
            label: 'Areas Providing Feedback',
        },
        {
            id: 'Description/Comments',
            numeric: false,
            disablePadding: true,
            label: 'Description/Comments',
        },
        {
            id: 'Date of Report',
            numeric: false,
            disablePadding: true,
            label: 'Date Of Report',
        },
        {
            id: 'Options',
            numeric: false,
            disablePadding: true,
            label: 'Options',
        },
    ];

    const [orgs, setOrgs] = useState([
        feedback(1, "salman", "zafar", "2365989898", "admin@belacorp.com", "Comaplint", "Other", "Assets", "out dated", "04-05-2024"),
        feedback(2, "saba", "salman", "2365989898", "admin@belacorp.com", "Comaplint", "Other", "Assets", "out dated", "15-05-2024"),
        feedback(3, "rameez", "khan", "2365989898", "admin@belacorp.com", "Comaplint", "Other", "Assets", "out dated", "08-05-2024"),
        feedback(4, "umar", "baloch", "2365989898", "admin@belacorp.com", "Comaplint", "Other", "Assets", "out dated", "12-05-2024"),
    ]);


    return (
        <Grid container sx={{ justifyContent: "center", marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, marginTop: "1%", }} >
            <Grid item xs={12} md={12} lg={11.5} sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", lg: "row", md: "row" } }}>
                <Grid item={true} xs={12} md={5} lg={4} sx={{ display: "flex", justifyContent: { lg: "flex-start", xs: "center" }, alignItems: "center" }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to CSV
                        </Button>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to PDF
                        </Button>
                    </Stack>
                </Grid>
                <Grid item={true} xs={12} md={4} lg={7} >
                    <SearchAndFilters topMargin={2} inputWidth={6} userType={"client"} />
                </Grid>
                <Grid item={true} xs={11} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={viewExpose}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="grid"><GridViewOutlinedIcon /></ToggleButton>
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="list"><ReorderOutlinedIcon /></ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            {viewExpose === "list" ?
                <Grid item xs={11.5} md={12} lg={11.5} >
                    {orgs.length && <FeedbackList rows={orgs} headCells={headCells} />}
                </Grid>
                :
                <Grid item xs={11.5} md={12} lg={11.5} >
                    {orgs.length && <FeedbackGrid rows={orgs} headCells={headCells} screenWidth={screenWidth} />}
                </Grid>
            }
        </Grid>
    )
}

export const ConflictOfInterestRegister = () => {

    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 4.5
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.2
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const [viewExpose, setViewExpose] = useState("list");
    const handleChange = (event, newAlignment) => {
        setViewExpose(newAlignment);
    };

    const interest = (id, first_name, last_name, phone, email, role, related_to, nature_of_interest, conflict_description, date_of_disclosure, actions_taken, approval_status, follow_up_actions, notes, approver_name, outcome, link_to_document) => {
        return {
            id, first_name, last_name, phone, email, role, related_to, nature_of_interest, conflict_description, date_of_disclosure, actions_taken, approval_status, follow_up_actions, notes, approver_name, outcome, link_to_document
        };
    }

    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'First Name',
            numeric: false,
            disablePadding: true,
            label: 'First Name',
        },
        {
            id: 'Last Name',
            numeric: false,
            disablePadding: true,
            label: 'Last Name',
        },
        {
            id: 'Phone',
            numeric: false,
            disablePadding: true,
            label: 'Phone',
        },
        {
            id: 'Email',
            numeric: false,
            disablePadding: true,
            label: 'Email',
        },
        {
            id: 'Role',
            numeric: false,
            disablePadding: true,
            label: 'Role',
        },
        {
            id: "Related To",
            numeric: false,
            disablePadding: true,
            label: 'Related To',
        },
        {
            id: 'Nature Of Interest',
            numeric: false,
            disablePadding: true,
            label: 'Nature Of Interest',
        },
        {
            id: 'Conflict Description',
            numeric: false,
            disablePadding: true,
            label: 'Conflict Description',
        },
        {
            id: 'Date Of Disclosure',
            numeric: false,
            disablePadding: true,
            label: 'Date Of Disclosure',
        },
        {
            id: 'Actions Taken',
            numeric: false,
            disablePadding: true,
            label: 'Actions Taken',
        },
        {
            id: 'Approval Status',
            numeric: false,
            disablePadding: true,
            label: 'Approval Status',
        },
        // {
        //     id: 'Follow Up Actions',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Follow Up Actions',
        // },
        // {
        //     id: 'Notes',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Notes',
        // },
        // {
        //     id: 'Approver Name',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Approver Name',
        // },
        // {
        //     id: 'Outcome',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Outcome',
        // },
        {
            id: 'Link To Document',
            numeric: false,
            disablePadding: true,
            label: 'Link To Document',
        },
        {
            id: 'Options',
            numeric: false,
            disablePadding: true,
            label: 'Options',
        },
    ];

    const [orgs, setOrgs] = useState([
        interest(1, "Abc Name", "Zxc Name", "0293659870", "admin@cennasupport.com", "Staff", "salman", "abc", "abc", "11-04-2024", "abc", "abc", "abc", "abc", "abc", "abc", "abc"),
        interest(2, "Abc Name", "Zxc Name", "0293659870", "admin@cennasupport.com", "Staff", "other", "abc", "abc", "11-04-2024", "abc", "abc", "abc", "abc", "abc", "abc", "abc"),
        interest(3, "Abc Name", "Zxc Name", "0293659870", "admin@cennasupport.com", "Staff", "rehan", "abc", "abc", "11-04-2024", "abc", "abc", "abc", "abc", "abc", "abc", "abc"),
        interest(4, "Abc Name", "Zxc Name", "0293659870", "admin@cennasupport.com", "Staff", "shahid", "abc", "abc", "11-04-2024", "abc", "abc", "abc", "abc", "abc", "abc", "abc"),
        interest(5, "Abc Name", "Zxc Name", "0293659870", "admin@cennasupport.com", "Staff", "supervisor", "abc", "abc", "11-04-2024", "abc", "abc", "abc", "abc", "abc", "abc", "abc"),
    ]);

    return (
        <Grid container sx={{ justifyContent: "center", marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, marginTop: "1%", }} >
            <Grid item xs={12} md={12} lg={11.5} sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", lg: "row", md: "row" } }}>
                <Grid item={true} xs={12} md={5} lg={4} sx={{ display: "flex", justifyContent: { lg: "flex-start", xs: "center" }, alignItems: "center" }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to CSV
                        </Button>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to PDF
                        </Button>
                    </Stack>
                </Grid>
                <Grid item={true} xs={12} md={4} lg={7} >
                    <SearchAndFilters topMargin={2} inputWidth={6} userType={"client"} />
                </Grid>
                <Grid item={true} xs={11} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={viewExpose}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="grid"><GridViewOutlinedIcon /></ToggleButton>
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="list"><ReorderOutlinedIcon /></ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            {viewExpose === "list" ?
                <Grid item xs={11.5} md={12} lg={11.5} >
                    {orgs.length && <ConflictInterestList rows={orgs} headCells={headCells} />}
                </Grid>
                :
                <Grid item xs={11.5} md={12} lg={11.5} >
                    {orgs.length && <ConflictInterestGrid rows={orgs} headCells={headCells} screenWidth={screenWidth} />}
                </Grid>
            }
        </Grid>
    )
}

export const StaffMeetingRegister = () => {
    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 4.5
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.2
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const [viewExpose, setViewExpose] = useState("list");
    const handleChange = (event, newAlignment) => {
        setViewExpose(newAlignment);
    };

    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'Meeting Date',
            numeric: false,
            disablePadding: true,
            label: 'Meeting Date',
        },
        {
            id: 'Meeting Time',
            numeric: false,
            disablePadding: true,
            label: 'Meeting Time',
        },
        {
            id: 'Meeting Title',
            numeric: false,
            disablePadding: true,
            label: 'Meeting Title',
        },
        {
            id: 'Attendees Name',
            numeric: false,
            disablePadding: true,
            label: 'Attendees Name',
        },
        {
            id: 'Agenda Items',
            numeric: false,
            disablePadding: true,
            label: 'Agenda Items',
        },
        {
            id: 'Notes',
            numeric: false,
            disablePadding: true,
            label: 'Notes',
        },
        {
            id: 'Follow-up Actions',
            numeric: false,
            disablePadding: true,
            label: 'Follow-up Actions',
        },
        {
            id: 'Next Meeting Date',
            numeric: false,
            disablePadding: true,
            label: 'Next Meeting Date',
        },
        {
            id: 'Options',
            numeric: false,
            disablePadding: true,
            label: 'Options',
        },
    ];

    const [rows, setRows] = useState([
        meetings(1, "21-03-2024", "12:00 PM", "Cenna Support QA", "Salman", "Abc Items", "Abc Notes", "abc follow action", "22-03-2024"),
        meetings(2, "21-03-2024", "12:00 PM", "Cenna Support QA", "Salman", "Abc Items", "Abc Notes", "abc follow action", "22-03-2024"),
    ]);


    return (
        <Grid container sx={{ justifyContent: "center", marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, marginTop: "1%", }} >
            <Grid item xs={12} md={12} lg={11.5} sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", lg: "row", md: "row" } }}>
                <Grid item={true} xs={12} md={5} lg={4} sx={{ display: "flex", justifyContent: { lg: "flex-start", xs: "center" }, alignItems: "center" }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to CSV
                        </Button>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to PDF
                        </Button>
                    </Stack>
                </Grid>
                <Grid item={true} xs={12} md={4} lg={7} >
                    <SearchAndFilters topMargin={2} inputWidth={6} userType={"client"} />
                </Grid>
                <Grid item={true} xs={11} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={viewExpose}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="grid"><GridViewOutlinedIcon /></ToggleButton>
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="list"><ReorderOutlinedIcon /></ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Grid item xs={11.5} md={12} lg={11.5} >
                {viewExpose === "list" ?
                    <MeetingList rows={rows} headCells={headCells} />
                    :
                    <MeetingGrid rows={rows} headCells={headCells} screenWidth={screenWidth} />
                }
            </Grid>
        </Grid>
    )
}

export const ReimbursementsRegister = () => {
    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 4.5
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.2
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const [viewExpose, setViewExpose] = useState("list");
    const handleChange = (event, newAlignment) => {
        setViewExpose(newAlignment);
    };

    return (
        <Grid container sx={{ justifyContent: "center", marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, marginTop: "1%", }} >
            <Grid item xs={12} md={12} lg={11.5} sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", lg: "row", md: "row" } }}>
                <Grid item={true} xs={12} md={5} lg={4} sx={{ display: "flex", justifyContent: { lg: "flex-start", xs: "center" }, alignItems: "center" }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to CSV
                        </Button>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to PDF
                        </Button>
                    </Stack>
                </Grid>
                <Grid item={true} xs={12} md={4} lg={7} >
                    <SearchAndFilters topMargin={2} inputWidth={6} userType={"client"} />
                </Grid>
                <Grid item={true} xs={11} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={viewExpose}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="grid"><GridViewOutlinedIcon /></ToggleButton>
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="list"><ReorderOutlinedIcon /></ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Grid item xs={11.5} md={12} lg={12} >
                {viewExpose === "list" ?
                    <ReimbursementList />
                    :
                    <ReimbursementGrid screenWidth={screenWidth} />
                }
            </Grid>
        </Grid>
    )
}

export const KilometerRegister = () => {
    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 4.5
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.2
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const [viewExpose, setViewExpose] = useState("list");
    const handleChange = (event, newAlignment) => {
        setViewExpose(newAlignment);
    };

    return (
        <Grid container sx={{ justifyContent: "center", marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, marginTop: "1%", }} >
            <Grid item xs={12} md={12} lg={11.5} sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", lg: "row", md: "row" } }}>
                <Grid item={true} xs={12} md={5} lg={4} sx={{ display: "flex", justifyContent: { lg: "flex-start", xs: "center" }, alignItems: "center" }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to CSV
                        </Button>
                        <Button variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Export to PDF
                        </Button>
                    </Stack>
                </Grid>
                <Grid item={true} xs={12} md={4} lg={7} >
                    <SearchAndFilters topMargin={2} inputWidth={6} userType={"client"} />
                </Grid>
                <Grid item={true} xs={11} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={viewExpose}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="grid"><GridViewOutlinedIcon /></ToggleButton>
                        <ToggleButton sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="list"><ReorderOutlinedIcon /></ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Grid item xs={11.5} md={12} lg={12} >
                {viewExpose === "list" ?
                    <KilometerClaims />
                    :
                    <KilometerClaimsGrid screenWidth={screenWidth} />
                }
            </Grid>
        </Grid>
    )
}