import React, { useEffect, useState } from "react"
import { Backdrop, Box, Button, Fade, Grid, Modal, Stack, TextField, Paper, Typography, MenuItem, List, ListItem, ListItemText, Checkbox, ListItemButton, ListItemIcon, ButtonGroup, Tabs, Tab, Collapse } from "@mui/material";
import PropTypes from 'prop-types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MyServicesList } from "../../../components/pagination_view";
import SaveIcon from '@mui/icons-material/Save';
import { DatePickerUI, TimePickerUI } from "../../../components/Inputs";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { StaffReviewSelection } from "./staff_review";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectedServiceReducer } from "../../../middleware/reducers/my_services";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styled from "@emotion/styled";
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
import EventIcon from '@mui/icons-material/Event';
import { MyShifts } from "./my_shift";
import AddLocationIcon from '@mui/icons-material/AddLocation';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const InfoCardService = ({ data }) => {
    return (
        <Grid container sx={{ display: "flex", }}>
            <Grid item={true} xs={12} md={12} sm={12} lg={12} sx={{
                py: 1,
                borderBottom: "2px solid #000000",
                backgroundColor: "#e2e6e8"
            }}></Grid>
            <Grid item={true} xs={12} md={12} sm={12} lg={12} sx={{ display: "flex", flexDirection: { xs: "column", lg: "row", md: "row" }, py: 2, borderBottom: "2px solid #c8c8c8" }} >
                {Object.entries(data)?.map((v, i) => {
                    console.log()
                    if (v[0] !== "service_status") {
                        return (
                            <Grid item={true} xs={12} md={3} sm={2} lg={2} key={i} sx={{ py: 1, px: 2, borderLeft: window.screen.width < 900 ? "2px solid #c8c8c8" : i >= 1 ? "2px solid #c8c8c8" : "none", }}>
                                <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253", textTransform: "capitalize" }}>
                                    {v[0].replace(/_/g, " ")}
                                </Typography>
                                <Typography sx={{ fontFamily: "Roboto-Regular", color: "#505253" }}>
                                    {v[1]}
                                </Typography>
                            </Grid>
                        )
                    }
                })}
            </Grid>
        </Grid>
    )
}

const InfoCardDetails = ({ data, editable, setModalOpen, setNewEntryDataTitle }) => {
    return (
        <Grid container sx={{ display: "flex", }}>
            <Grid item={true} xs={12} md={12} sm={12} lg={12} sx={{
                py: 1,
                borderBottom: "2px solid #000000",
                backgroundColor: "#e2e6e8"
            }}></Grid>
            <Grid item={true} xs={12} md={12} sm={12} lg={12} sx={{ display: "flex", flexDirection: { xs: "column", lg: "row", md: "row" }, py: 2, borderBottom: "2px solid #c8c8c8" }} >
                {Object.entries(data)?.map((v, i) => {
                    if (typeof v[1] === "string") {
                        return (
                            <Grid item={true} xs={12} md={3} sm={2} lg={2} key={i} sx={{ py: 1, px: 2, borderLeft: window.screen.width < 900 ? "2px solid #c8c8c8" : i >= 1 ? "2px solid #c8c8c8" : "none", }}>
                                <Typography sx={{ fontFamily: "Roboto-Medium", color: "#505253", textTransform: "capitalize" }}>
                                    {v[0].replace(/_/g, " ")}
                                </Typography>
                                <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                                    {v[1]}
                                </Typography>
                            </Grid>
                        )
                    }
                })}
            </Grid>
            <Grid item={true} xs={12} md={12} sm={12} lg={12} >
                {Object.entries(data)?.map((v, i) => {
                    if (typeof v[1] === "object") {
                        return (
                            <Grid item={true} xs={12} md={12} sm={12} lg={12} key={i} sx={{ my: 2, borderBottom: "2px solid #e2e6e8" }}>
                                <Grid item={true} xs={12} md={12} sm={12} lg={12} >
                                    <Typography sx={{ fontFamily: "Roboto-Bold", color: "#505253", textTransform: "capitalize" }}>
                                        {v[0].replace(/_/g, " ")}
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} md={12} sm={12} lg={12} sx={{
                                    py: 1,
                                    borderBottom: "2px solid #000000",
                                    backgroundColor: "#e2e6e8"
                                }}></Grid>
                                {v[1]?.map((vN, iN) => {
                                    return (
                                        <Grid item={true} xs={12} md={12} sm={12} lg={12} sx={{ display: "flex", flexDirection: { xs: "column", lg: "row", md: "row" }, py: 2, borderBottom: "2px solid #c8c8c8" }} >
                                            {Object.entries(vN)?.map((vNN, iNN) => {
                                                return (
                                                    <Grid item={true} xs={12} md={3} sm={2} lg={2} key={i} sx={{ py: 1, px: 2, borderLeft: window.screen.width < 900 ? "2px solid #c8c8c8" : i >= 1 ? "2px solid #c8c8c8" : "none", }}>
                                                        <Typography sx={{ fontFamily: "Roboto-Medium", color: "#505253", textTransform: "capitalize" }}>
                                                            {vNN[0].replace(/_/g, " ")}
                                                        </Typography>
                                                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                                                            {vNN[1] ? vNN[1] : "Nill"}
                                                        </Typography>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        )
                    }
                })}
            </Grid>
        </Grid>
    )
}

const CheckList = ({ data }) => {
    const [checked, setChecked] = React.useState([0]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };
    return (
        <Grid container sx={{ display: "flex", }}>
            <List sx={{ width: '100%', maxWidth: 560, bgcolor: 'background.paper' }}>
                {data?.map((v, i) => {
                    const labelId = `checkbox-list-label-${i}`;
                    return (
                        <ListItem
                            key={i}
                            disablePadding
                        >
                            <ListItemButton role={undefined} onClick={handleToggle(i)} dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checked.indexOf(i) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={v.title} secondary={v.comment} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </Grid>
    )
}

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const MyServices = () => {
    const dispatch = useDispatch()
    const my_services = useSelector((state) => state._my_services.myServices);
    const selected_service = useSelector((state) => state._my_services.selectedService);
    // const navigate = useNavigate();
    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        maxWidth: "calc(70%)",
        minWidth: "calc(60%)",
        boxShadow: 12,
        borderRadius: 1,
        p: 4,
    });

    const width = () => {
        // modal control
        if (window.screen.width < 900) {
            const copy = { ...style };
            copy.maxWidth = "95%";
            setStyle(copy);
        }

        if (window.screen.width > 1600) {
            return window.screen.width / 4
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.4
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const [tabs, setTabs] = useState(0);

    const [dataList, setDataList] = useState(my_services);
    const [selectedItem, setSelectedItem] = useState();
    const [service, setService] = useState([]);
    const [detailsItem, setDetailsItem] = useState(null);
    const [steps, setSteps] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenKm, setModalOpenKm] = useState(false);
    const [overTime, setOverTime] = useState(false);
    const [feedBacks, setFeedBacks] = useState(false);
    const [newEntryDataTitle, setNewEntryDataTitle] = useState(null);

    const [kilometers, setKilometers] = useState([
        {
            title: "Date of Travel",
            value: "",
            width: screenWidth,
            type: "date",
            required: true,
            multiline: false
        },
        {
            title: "Start Time",
            value: "",
            width: screenWidth,
            type: "time",
            required: false,
            multiline: false
        },
        {
            title: "End Time",
            value: "",
            width: screenWidth,
            type: "time",
            required: false,
            multiline: false
        },
        {
            title: "Start Location",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false
        },
        {
            title: "End Location",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false
        },
        {
            title: "Total Kilometers",
            value: "",
            width: screenWidth,
            type: "number",
            required: true,
            multiline: false
        },
        {
            title: "Purpose of Travel",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false
        },
        {
            title: "Vehicle Used",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false
        },
        // {
        //     title: "Fuel Receipt",
        //     value: "",
        //     width: screenWidth,
        //     type: "file",
        //     required: true,
        //     multiline: false
        // },
        {
            title: "Additional Notes",
            value: "",
            width: screenWidth,
            type: "text",
            required: false,
            multiline: true
        },
    ]);

    const [reimbursement, setReimbursement] = useState([
        {
            title: "Amount",
            value: "",
            width: screenWidth,
            type: "number",
            required: true,
            multiline: false
        },
        {
            title: "Purpose / Note",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: true
        },
        // {
        //     title: "Address of Payment",
        //     value: "",
        //     width: screenWidth,
        //     type: "text",
        //     required: false,
        //     multiline: true
        // },
        {
            title: "Picture of Receipt",
            value: "",
            width: screenWidth,
            type: "file",
            required: false,
            // multiline: true
        },
    ]);

    const [category, setCategory] = useState([
        {
            title: "Feedback Types",
            value: "Please Select Option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Please Select Option",
                    selected: true
                },
                {
                    title: "Compliment",
                    selected: false
                },
                {
                    title: "Suggestions",
                    selected: false
                }
            ]
        },
        {
            title: "Source of Feedback",
            value: "Please Select Option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Please Select Option",
                    selected: true
                },
                {
                    title: "Client/Rep",
                    selected: false
                },
                {
                    title: "Staff",
                    selected: false
                },
                {
                    title: "External body",
                    selected: false
                },
                {
                    title: "Other",
                    selected: false
                }
            ]
        },
        {
            title: "Areas you are providing feedback",
            value: "Please Select Option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Please Select Option",
                    selected: true
                },
                {
                    title: "Management/Staff",
                    selected: false
                },
                {
                    title: "Care",
                    selected: false
                },
                {
                    title: "Lifestyle/Activities",
                    selected: false
                },
                {
                    title: "Environment",
                    selected: false
                },
                {
                    title: "Documentation",
                    selected: false
                },
                {
                    title: "Maintenance",
                    selected: false
                },
                {
                    title: "Food",
                    selected: false
                },
                {
                    title: "Assets",
                    selected: false
                },
                {
                    title: "Other",
                    selected: false
                },
            ]
        },
        // {
        //     title: "Category",
        //     value: "Assets",
        //     width: screenWidth,
        //     type: "drop_down",
        //     required: true,
        //     options: [
        //         {
        //             title: "Assets",
        //             selected: true
        //         },
        //         {
        //             title: "Client",
        //             selected: false
        //         },
        //         {
        //             title: "Staff",
        //             selected: false
        //         },
        //         {
        //             title: "Other",
        //             selected: false
        //         }
        //     ]
        // },
        {
            title: "First Name",
            value: "",
            width: screenWidth,
            type: "text",
            required: false,
            multiline: false
        },
        {
            title: "Last Name",
            value: "",
            width: screenWidth,
            type: "text",
            required: false,
            multiline: false
        },
        {
            title: "Email",
            value: "",
            width: screenWidth,
            type: "email",
            required: false,
            multiline: false
        },
        {
            title: "Feedback Description / Comments",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: true
        },
    ]);

    const [comment, setComment] = useState("");

    const detailsHandle = () => {
        dispatch(selectedServiceReducer(null));
    };

    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'Service code',
            numeric: false,
            disablePadding: true,
            label: 'Service code',
        },
        {
            id: 'Service Date',
            numeric: false,
            disablePadding: true,
            label: 'Service Date',
        },
        {
            id: 'Service Status',
            numeric: false,
            disablePadding: true,
            label: 'Service Status',
        },
        {
            id: 'Service Name',
            numeric: false,
            disablePadding: true,
            label: 'Service Name',
        },
        {
            id: 'Client Name',
            numeric: false,
            disablePadding: true,
            label: 'Client Name',
        },
        // {
        //     id: 'Contact Number',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Contact Number',
        // },
        // {
        //     id: 'NIDS No',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'NIDS No',
        // },
        {
            id: 'Gender',
            numeric: false,
            disablePadding: true,
            label: 'Gender',
        },
        {
            id: 'Service location',
            numeric: false,
            disablePadding: true,
            label: 'Service location',
        },
        {
            id: 'Option',
            numeric: false,
            disablePadding: true,
            label: 'Option',
        },
    ];
    // for selected service
    useEffect(() => {
        setSelectedItem(selected_service)
    }, [selected_service])

    // // for assign the data
    useEffect(() => {
        const copy = [...service];
        for (const key in my_services) {
            const inData = my_services[key];
            copy.push({
                id: Number(key) + 1,
                code: inData.service_type.service_code,
                service_date: inData.service_type.service_date,
                service_status: inData.service_type.service_status,
                service_title: inData.service_type.service_title,
                client_name: inData.client_details.client_name,
                contact_no: inData.client_details.contact_no,
                NIDS_no: inData.client_details.NIDS_no,
                gender: inData.client_details.gender,
                service_location: inData.client_details.service_location,
            });
        };
        setService(copy);
    }, [my_services])

    // // for filter the data
    useEffect(() => {
        if (selectedItem) {
            const find = my_services?.find((e) => e?.service_type?.service_code === selectedItem?.serv_code);
            if (find) {
                setDetailsItem(find)
                setSteps([
                    {
                        label: 'Service Type',
                        tabActive: false,
                        screen: <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ p: 2 }}>
                            <Typography sx={{ fontFamily: "Roboto-Medium", color: "#505253", textTransform: "capitalize" }}>
                                Service Type
                            </Typography>
                            <InfoCardService data={find.service_type} />
                        </Grid>
                    },
                    {
                        label: 'Client Details',
                        tabActive: false,
                        screen: <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ p: 2 }}>
                            <Typography sx={{ fontFamily: "Roboto-Medium", color: "#505253", textTransform: "capitalize" }}>
                                Client Details
                            </Typography>
                            <InfoCardDetails data={find.client_details} />
                        </Grid>
                    },
                    {
                        label: 'Check List',
                        tabActive: false,
                        screen: <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ p: 2 }}>
                            <Typography sx={{ fontFamily: "Roboto-Medium", color: "#505253", textTransform: "capitalize" }}>
                                Check List
                            </Typography>
                            <CheckList data={find.check_list} />
                        </Grid>
                    },
                    {
                        label: 'Staff Review',
                        tabActive: false,
                        screen: <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ p: 2 }}>
                            <Typography sx={{ fontFamily: "Roboto-Medium", color: "#505253", textTransform: "capitalize" }}>
                                Staff Review
                            </Typography>
                            <StaffReviewSelection
                                screenWidth={screenWidth}
                                dataList={find.staff_marking}
                                setNewEntryDataTitle={setNewEntryDataTitle}
                                service={dataList}
                                setDataList={setDataList}
                                setService={setService}
                                selectedItem={selectedItem}
                                setSelectedItem={setSelectedItem}
                            />
                        </Grid>
                    },
                ])
            }
        }
    }, [selectedItem]);

    // //  
    const handleChange = (event, newValue) => {
        setTabs(newValue);
    };
    const [open, setOpen] = useState(true);

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <Grid container sx={{ display: "flex", justifyContent: "center", my: 2, marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, overflow: "hidden" }}>
            {selectedItem ? (
                <Grid item={true} xs={12} sm={12} md={12} lg={11} component={Paper} elevation={0}>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ py: 2, mx: 4, display: "flex" }}>
                        <Stack direction="row" spacing={2}>
                            <Button sx={{ fontFamily: "Roboto-Medium", textTransform: "capitalize" }} onClick={() => detailsHandle()} component="label" variant="outlined" startIcon={<ArrowBackIcon />}>
                                Back
                            </Button>
                            <Button sx={{ fontFamily: "Roboto-Medium", textTransform: "capitalize" }} component="label" variant="outlined" startIcon={<SaveIcon />}>
                                Save
                            </Button>
                            <Button sx={{ fontFamily: "Roboto-Medium", textTransform: "capitalize" }} component="label" variant="outlined" startIcon={<AddLocationIcon />}>
                                I have Arrived
                            </Button>
                            <Button sx={{ fontFamily: "Roboto-Medium", textTransform: "capitalize" }} component="label" variant="outlined" startIcon={<AddAlarmIcon />}>
                                Start Shift
                            </Button>
                            <Button sx={{ fontFamily: "Roboto-Medium", textTransform: "capitalize" }} component="label" variant="outlined" startIcon={<AccessAlarmIcon />}>
                                End Shift
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ px: 4, mx: 4 }}>
                        <Tabs value={tabs} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Visit Details" sx={{ fontFamily: "Roboto-Medium", textTransform: "capitalize" }} {...a11yProps(0)} />
                            <Tab label="Visit Days" sx={{ fontFamily: "Roboto-Medium", textTransform: "capitalize" }} {...a11yProps(1)} />
                        </Tabs>
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ py: 1, px: 3, }}>
                        <CustomTabPanel value={tabs} index={0}>
                            {steps?.map((v) => (
                                v.screen
                            ))}
                        </CustomTabPanel>
                        <CustomTabPanel value={tabs} index={1}>
                            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ m: 2, border: "1px solid gray", borderRadius: 1 }}>
                                <ListItemButton onClick={handleClick} >
                                    <ListItemIcon>
                                        <EventIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="14-03-2024" secondary="Thursday" />
                                    {open ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemText primary={
                                                <Stack direction={window.screen.width < 900 ? "column" : "row"} spacing={2}>
                                                    <Button sx={{ fontFamily: "Roboto-Medium", textTransform: "capitalize" }} onClick={() => { setModalOpen(true) }} component="label" variant="outlined" startIcon={<RequestQuoteIcon />}>
                                                        Reimbursement
                                                    </Button>
                                                    <Button sx={{ fontFamily: "Roboto-Medium", textTransform: "capitalize" }} onClick={() => { setModalOpenKm(true) }} component="label" variant="outlined" startIcon={<LocationOnIcon />}>
                                                        Kilometers Claim
                                                    </Button>
                                                    <Button sx={{ fontFamily: "Roboto-Medium", textTransform: "capitalize" }} onClick={() => { setOverTime(true) }} component="label" variant="outlined" startIcon={<WatchLaterIcon />}>
                                                        Overtime request
                                                    </Button>
                                                    <Button sx={{ fontFamily: "Roboto-Medium", textTransform: "capitalize" }} onClick={() => { setFeedBacks(true) }} component="label" variant="outlined" startIcon={<ReportProblemIcon />}>
                                                        Feedback
                                                    </Button>
                                                </Stack>
                                            } />
                                        </ListItemButton>
                                    </List>
                                </Collapse>
                            </Grid>
                        </CustomTabPanel>
                    </Grid>
                </Grid>
            ) : (
                <Grid item={true} xs={11.5} sm={11.5} md={11.5} lg={11} component={Paper} elevation={0}>
                    {service.length && <MyShifts setSelectedItem={setSelectedItem} />}
                    {/* {service.length && <MyServicesList rows={service} headCells={headCells} setSelectedItem={setSelectedItem} />} */}
                </Grid>
            )}
            {/* Reimbursement meters claim */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={modalOpen}>
                    <Box sx={style}>
                        <Box>
                            <Typography variant="h5" component="h2" sx={{ textTransform: "capitalize" }}>
                                Reimbursement
                            </Typography>
                        </Box>
                        <Box sx={{ py: 2, }}>
                            {reimbursement.map((v, i) => {
                                if (v.type === "text" || v.type === "number") {
                                    return (
                                        <TextField
                                            type={v.type}
                                            multiline={v.multiline}
                                            key={i}
                                            required={v.required}
                                            label={v.title}
                                            htmlFor={v.title}
                                            sx={{ m: 1, width: v.width }}
                                            value={v.value}
                                            onChange={(e) => {
                                                const copy = [...reimbursement];
                                                copy[i].value = e.target.value;
                                                setReimbursement(copy);
                                            }}
                                        />
                                    )
                                }
                                if (v.type === "date") {
                                    return (
                                        <DatePickerUI label={v.title} defaultValue={v.value} width={v.width} />
                                    )
                                }
                                if (v.type === "file") {
                                    return (
                                        <Button sx={{ m: 1, width: v.width, padding: "15px 16px" }} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                                            {v.title}
                                            <VisuallyHiddenInput type="file" onChange={(e) => { console.log(e.target.files) }} />
                                        </Button>
                                    )
                                }
                            })}
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained">
                                    Save
                                </Button>
                                <Button onClick={() => { setModalOpen(false); }} variant="contained">
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
            {/* kilometers claim */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={modalOpenKm}
                onClose={() => setModalOpenKm(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={modalOpenKm}>
                    <Box sx={style}>
                        <Box>
                            <Typography variant="h5" component="h2" sx={{ textTransform: "capitalize" }}>
                                Kilometers Claim
                            </Typography>
                        </Box>
                        <Box sx={{ py: 2 }}>
                            {kilometers.map((v, i) => {
                                if (v.type === "text" || v.type === "number") {
                                    return (
                                        <TextField
                                            type={v.type}
                                            multiline={v.multiline}
                                            key={i}
                                            required={v.required}
                                            label={v.title}
                                            htmlFor={v.title}
                                            sx={{ m: 1, width: v.width }}
                                            value={v.value}
                                            onChange={(e) => {
                                                const copy = [...kilometers];
                                                copy[i].value = e.target.value;
                                                setKilometers(copy);
                                            }}
                                        />
                                    )
                                }
                                if (v.type === "time") {
                                    return (
                                        <TimePickerUI label={v.title} defaultValue={v.value} width={v.width} />
                                    )
                                }
                                if (v.type === "date") {
                                    return (
                                        <DatePickerUI label={v.title} defaultValue={v.value} width={v.width} />
                                    )
                                }
                                if (v.type === "file") {
                                    return (
                                        <Button sx={{ m: 1, width: v.width, padding: "15px 16px" }} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                                            {v.title}
                                            <VisuallyHiddenInput type="file" onChange={(e) => { console.log(e.target.files) }} />
                                        </Button>
                                    )
                                }
                            })}
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained">
                                    Save
                                </Button>
                                <Button onClick={() => { setModalOpenKm(false); }} variant="contained">
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
            {/* OverTime claim */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={overTime}
                onClose={() => setOverTime(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={overTime}>
                    <Box sx={style}>
                        <Box>
                            <Typography variant="h5" component="h2" sx={{ textTransform: "capitalize" }}>
                                Overtime Request
                            </Typography>
                        </Box>
                        <Box sx={{ py: 2 }}>
                            <List sx={{ width: '92%', maxWidth: window.screen.width, bgcolor: 'background.paper' }}>
                                <ListItem
                                    component={Paper}
                                    elevation={1}
                                    sx={{ py: 2, display: "flex", flexDirection: { lg: "row", xs: "column", md: "row", sm: "column" }, }}
                                >
                                    <ListItemText
                                        primary={"Monday"}
                                        primaryTypographyProps={{
                                            style: {
                                                textTransform: "capitalize",
                                                fontFamily: "Roboto-Medium"
                                            }
                                        }}
                                    />
                                    <TextField
                                        type="number"
                                        label="Extra Hours"
                                        value={1}
                                        sx={{ mx: 1, width: screenWidth }}
                                    />
                                    <TextField
                                        type="text"
                                        multiline={true}
                                        label="Description / Reasoning"
                                        // value={"12:00"}
                                        sx={{ mx: 1, width: screenWidth }}
                                    />
                                </ListItem>
                            </List>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained">
                                    Save
                                </Button>
                                <Button onClick={() => { setOverTime(false); }} variant="contained">
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
            {/* Feedback or report */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={feedBacks}
                onClose={() => setFeedBacks(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={feedBacks}>
                    <Box sx={style}>
                        <Box>
                            <Typography variant="h5" component="h2" sx={{ textTransform: "capitalize" }}>
                                Feedback
                            </Typography>
                        </Box>
                        <Box sx={{ py: 2 }}>
                            <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap', }}>
                                {category.map((v, i) => {
                                    return (
                                        <div key={v.title}>
                                            {v.type === "drop_down" ? (
                                                <TextField
                                                    select={true}
                                                    label={v.title}
                                                    htmlFor={v.title}
                                                    required={v.required}
                                                    sx={{ m: 1, width: v.width }}
                                                    value={v.value}
                                                    onChange={(e) => {
                                                        const copy = [...category];
                                                        copy[i].value = e.target.value;
                                                        setCategory(copy);
                                                    }}
                                                >
                                                    {v?.options?.map((nV, nI) => (
                                                        <MenuItem key={nI} value={nV.title} >
                                                            {nV.title}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            ) : null}
                                            {v.type === "text" || v.type === "email" ? (
                                                <TextField
                                                    type={v.type}
                                                    label={v.title}
                                                    multiline={v.multiline}
                                                    required={v.required}
                                                    htmlFor={v.title}
                                                    value={v.value}
                                                    sx={{ m: 1, width: v.width }}
                                                    onChange={(e) => {
                                                        const copy = [...category];
                                                        copy[i].value = e.target.value;
                                                        setCategory(copy);
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                    )
                                })}
                            </Grid>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained">
                                    Save
                                </Button>
                                <Button onClick={() => { setFeedBacks(false); }} variant="contained">
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Grid >
    )
}