import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import {
    Grid, Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Paper, Checkbox, IconButton, Tooltip, Button, Stack,
    Slide,
    Snackbar,
    Alert,
    AlertTitle,
    CircularProgress,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Check, Clear } from '@mui/icons-material';
import { UpdateReimbursement } from '../../../services/finance_service';
import { reimbursementListState } from '../../../middleware/reducers/payment_proccess_reducer';
import _ from 'lodash';
import moment from 'moment';

const SlideTransition = (props) => {
    return <Slide {...props} direction="left" />;
}

const createData = (id, service_code, service_status, service_name, support_worker, client_name, contact, address, service_location, amount, address_of_payment) => {
    return {
        id,
        service_code,
        service_status,
        service_name,
        support_worker,
        client_name,
        contact,
        address, service_location, amount, address_of_payment
    };
}

const rows = [
    createData(1, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Dr.Faraz", "Falkan", "0254898756", "abc address", "East Aus", 350, "same as service location"),
    createData(3, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Dr.Faraz", "Falkan", "0254898756", "abc address", "East Aus", 350, "same as service location"),
    createData(2, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Dr.Faraz", "Falkan", "0254898756", "abc address", "East Aus", 350, "same as service location"),
    createData(4, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Dr.Faraz", "Falkan", "0254898756", "abc address", "East Aus", 350, "same as service location"),
    createData(5, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Dr.Faraz", "Falkan", "0254898756", "abc address", "East Aus", 350, "same as service location"),
];

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array, comparator) => {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
    {
        id: 'ID',
        numeric: false,
        disablePadding: true,
        label: 'ID',
    },
    {
        id: 'Service Code',
        numeric: true,
        disablePadding: false,
        label: 'Service Code',
    },
    {
        id: 'Service Status',
        numeric: true,
        disablePadding: false,
        label: 'Service Status',
    },
    {
        id: 'Service Name',
        numeric: true,
        disablePadding: false,
        label: 'Service Name',
    },
    {
        id: 'Support Worker',
        numeric: true,
        disablePadding: false,
        label: 'Support Worker',
    },
    {
        id: 'Client Name',
        numeric: true,
        disablePadding: false,
        label: 'Client Name',
    },
    {
        id: 'Purpose Note',
        numeric: true,
        disablePadding: false,
        label: 'Purpose Note',
    },
    {
        id: 'Created Date',
        numeric: true,
        disablePadding: false,
        label: 'Created Date',
    },
    {
        id: 'Amount (AUD)',
        numeric: true,
        disablePadding: false,
        label: 'Amount (AUD)',
    },
    {
        id: 'Reimbursement Status',
        numeric: true,
        disablePadding: false,
        label: 'Reimbursement Status',
    },
];

const EnhancedTableHead = (props) => {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells?.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        // padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell?.id ? order : false}
                        style={{ minWidth: headCell?.label?.length >= 10 ? 180 : 100, fontFamily: "Roboto-Medium" }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell?.label}
                            {orderBy === headCell?.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected, handleApproval, loading } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >

                </Typography>
            )}
            {numSelected > 0 ? (
                <Stack direction="row" spacing={2}>
                    <Tooltip title="Reject Selected Reimbursement" placement="top">
                        <Button onClick={() => handleApproval("rejected")} startIcon={<Clear color='error' />} color="primary" variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Reject {loading ? <CircularProgress color="secondary" size={26} sx={{ ml: 1 }} /> : null}
                        </Button>
                    </Tooltip>
                    <Tooltip title="Approved Selected Reimbursement" placement="top">
                        <Button onClick={() => handleApproval("approved")} startIcon={<Check color='success' />} color="primary" variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Approved {loading ? <CircularProgress color="secondary" size={26} sx={{ ml: 1 }} /> : null}
                        </Button>
                    </Tooltip>
                </Stack>
            ) : null}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export const AdminReimbursementList = () => {
    const dispatch = useDispatch()
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [visibleRows, setVisibleRows] = React.useState([]);

    const _list_of_reimbursment = useSelector((state) => state._payment_processing_reducer.reimbursement_list);
    const [listOfReimbursmentState, setListOfReimbursmentState] = React.useState(null)
    const [apiUpdateReimbursementById, loading, error_rm] = UpdateReimbursement()

    const [snacks, setSnack] = React.useState({
        open: false,
        Transition: SlideTransition,
        vertical: 'top',
        horizontal: 'right',
    });

    const [errorSnackMessage, setErrorSnackMessage] = React.useState({
        title: "",
        message: "",
        severity: "",
        variant: "filled"
    });

    const handleCloseSnacks = () => {
        setSnack({
            ...snacks,
            open: false,
        });
    };

    const handleSnacksApper = (title, message, severity) => {
        setSnack({
            ...snacks,
            open: true,
        });
        // / // // // display message
        setErrorSnackMessage({
            ...errorSnackMessage,
            title,
            message,
            severity
        })
    }

    React.useEffect(() => {
        if (_list_of_reimbursment) {
            const array = []
            for (const key in _list_of_reimbursment) {
                const data = _list_of_reimbursment[key].attributes;
                array.push({
                    id: Number(key) + 1,
                    dbId: _list_of_reimbursment[key]?.id,
                    service_code: data?.service_shift_id?.data?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.charge_band_rate_id?.data?.attributes?.service_code?.data?.attributes?.support_item_number,
                    service_name: data?.service_shift_id?.data?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.charge_band_rate_id?.data?.attributes?.service_title,
                    service_status: data?.service_shift_id?.data?.attributes?.shift_status,
                    support_worker: data?.service_shift_id?.data?.attributes?.staff_detail_id?.data?.attributes?.first_name,
                    client_name: data?.service_shift_id?.data?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.client_detail_id?.data?.attributes?.first_name,
                    purpose_note: data?.purpose_note,
                    amount: data?.amount,
                    created_date: data?.created_date,
                    approved_status: data?.approved_status,
                    rejected_status: data?.rejected_status,
                })
            }
            setListOfReimbursmentState(array)
        }
    }, [_list_of_reimbursment])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = listOfReimbursmentState?.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected?.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected?.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected?.concat(selected?.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected?.concat(selected?.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected?.slice(0, selectedIndex),
                selected?.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listOfReimbursmentState?.length) : 0;

    React.useEffect(() => {
        // Sort and slice the data whenever the dependencies change
        const sortedData = stableSort(listOfReimbursmentState, getComparator(order, orderBy))?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        );

        // Update the visible rows state
        setVisibleRows(sortedData);
    }, [order, orderBy, page, rowsPerPage, listOfReimbursmentState]);

    const updateReimbursementById = async (data, flag) => {
        try {
            const copy = _.cloneDeep(_list_of_reimbursment);
            let count = 0;
            for (const key in data) {
                const response = await apiUpdateReimbursementById({
                    variables: {
                        "id": data[key].dbId,
                        "data": {
                            [flag + "_status"]: true
                        }
                    }
                });
                if (response?.data) {
                    // console.log("response", response.data.updateReimbursement.data)
                    const res = response.data.updateReimbursement.data;
                    for (const key in copy) {
                        if (copy[key].id === res.id) {
                            copy[key].attributes.approved_status = res.attributes.approved_status;
                            copy[key].attributes.rejected_status = res.attributes.rejected_status;
                        }
                    }
                    count++
                }
            }
            dispatch(reimbursementListState(copy))
            if (count === data.length) {
                handleSnacksApper("Reimbursement", "Status changed to " + flag, "success")
            }
        } catch (error) {
            let message = "";
            if (error?.message || error_rm) {
                const errors = error_rm;
                if (errors?.networkError) {
                    message = "Network error occurred. Please try again later"
                }
                if (errors?.graphQLErrors) {
                    message = errors.graphQLErrors[0]?.message
                }
                if (error?.message) {
                    message = error?.message;
                }
                handleSnacksApper("Reimbursement", message, "error")
            }
        }
    }

    const handleApproval = (flag) => {
        try {
            const filteredArray = listOfReimbursmentState.filter(value =>
                selected.find(array => array === value.id)
            );
            if (filteredArray.length) {
                updateReimbursementById(filteredArray, flag)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Grid container sx={{ m: 2, marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, }}>
            {listOfReimbursmentState?.length ? (
                <Grid item={true} xs={12} sm={12} md={11.5} lg={11.5} sx={{ border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }}>
                    {selected?.length > 0 && (
                        <EnhancedTableToolbar loading={loading} handleApproval={handleApproval} numSelected={selected?.length} />
                    )}
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected?.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={listOfReimbursmentState?.length}
                            />
                            <TableBody>
                                {visibleRows?.map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                            >
                                                {row.id}
                                            </TableCell>
                                            <TableCell align="left">{row.service_code}</TableCell>
                                            <TableCell align="left">{row.service_status}</TableCell>
                                            <TableCell align="left">{row.service_name}</TableCell>
                                            <TableCell align="left">{row.support_worker}</TableCell>
                                            <TableCell align="left">{row.client_name}</TableCell>
                                            <TableCell align="left">{row.purpose_note}</TableCell>
                                            <TableCell align="left">{moment(row.created_date).format("DD-MM-YYYY")}</TableCell>
                                            <TableCell align="left">{row.amount}</TableCell>
                                            <TableCell align="left">{row.approved_status ? "Approved" : row.rejected_status ? "Rejected" : "Pending"}</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={listOfReimbursmentState?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            ) : (
                <Typography color={"secondary"} sx={{ fontFamily: "Roboto-Regular", fontSize: 20 }}>
                    The Reimbursement are not available in the selected date range
                </Typography>
            )}

            <Snackbar open={snacks.open} autoHideDuration={5000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
                <Alert
                    onClose={handleCloseSnacks}
                    severity={errorSnackMessage.severity}
                    variant={errorSnackMessage.variant}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{errorSnackMessage.title}</AlertTitle>
                    {errorSnackMessage.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
}