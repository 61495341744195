import * as React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Collapse, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Toolbar, Tooltip, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import { PriceList } from './screen/price_list';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import { AdjustHoursUpdate } from './screen/adjust_hour';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { PaymentProcessing } from './screen/payroll';
import { InvoiceLedger } from './screen/invoice_ledger';
import { HeaderToolbar } from '../../components/header/header_toolbar';
import { NDISPriceList } from './screen/ndis_price_list';

const drawerWidth = 70;

const FinanceDashboard = (props) => {
    const navigate = useNavigate()
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const [options, setOptions] = React.useState([
        {
            option: 'Price List',
            icon: <PriceChangeIcon fontSize={"large"} />,
            active: true,
            screen: <NDISPriceList />
        },
        {
            option: 'Adjust Hours',
            icon: <BuildCircleIcon fontSize={"large"} />,
            active: false,
            screen: <AdjustHoursUpdate />
        },
        {
            option: 'Payment Processing',
            icon: <RoomPreferencesIcon fontSize={"large"} />,
            active: false,
            screen: <PaymentProcessing />
        },
        {
            option: 'Invoice Ledger',
            icon: <ReceiptLongIcon fontSize={"large"} />,
            active: false,
            screen: <InvoiceLedger />
        },
    ]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleMenuItem = (option, i) => {
        const copy = [...options];
        for (const key in copy) {
            if (key === String(i)) {
                copy[key].active = true
            } else {
                copy[key].active = false
            }
        }
        setOptions(copy);
    };

    const drawer = (
        <div style={{ overflow: 'hidden', }}>
            <Toolbar onClick={() => navigate(-1)} sx={{ justifyContent: 'center' }}>
                <img alt="Logo" style={{ width: 40, objectFit: 'fitt' }} src="/assets/icons/logo-without-text.png" />
            </Toolbar>
            <Divider />
            <List sx={{ pt: 0 }}>
                {options.map((v, i) => (
                    <ListItem key={i} disablePadding sx={{ backgroundColor: v.active ? "#013447" : "#ffffff", }}>
                        <ListItemButton onClick={() => handleMenuItem(v.option, i)}>
                            <Tooltip title={v.option} placement="right">
                                <ListItemIcon style={{ color: v.active ? "#ffffff" : "#013447", }}>
                                    {v.icon}
                                </ListItemIcon>
                            </Tooltip>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    // Remove this const when copying and pasting into your project.
    const container = window !== undefined ? () => window().document.body : undefined;
    const activeTab = options.find((e) => e.active);

    return (
        <Box sx={{ display: 'flex', overflow: "hidden", bgcolor: "#F5F7F6" }}>
            <CssBaseline />
            <AppBar
                color="primaryHeader"
                elevation={0}
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography color={"secondary"} sx={{ fontFamily: "Roboto-Bold", fontSize: 24 }}>
                        {activeTab.option}
                    </Typography>
                    <Box sx={{ flexGrow: 1, justifyContent: "flex-end", display: { xs: 'none', md: 'flex', lg: 'flex' }, }} >
                        <HeaderToolbar />
                    </Box>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"

                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, overflowY: "scroll", height: "calc(97vh)",
                }}
            >
                <Toolbar />
                {activeTab.screen}
            </Box>
        </Box>
    );
}

FinanceDashboard.propTypes = {
    window: PropTypes.func,
};

export default FinanceDashboard;