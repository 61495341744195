import { createTheme, ThemeProvider } from '@mui/material/styles';

// export default createTheme({
//     palette: {
//         primary: {
//             main: '#6ECA35',
//         },
//         secondary: {
//             main: '#3A89CC',
//         },
//         primaryText: {
//             main: '#000000',
//         },
//         secondaryText: {
//             main: '#746B6B',
//         },
//         secondaryHeading: {
//             main: '#3A89CC',
//         },
//         primaryBox: {
//             main: '#F5F7F6',
//         },
//         secondaryBox: {
//             main: '#ffffff',
//         },
//         primaryHeader: {
//             main: '#ffffff',
//         },
//         secondaryHeader: {
//             main: '#e3e3e3',
//         },
//         screenBackground: {
//             main: "#F5F7F6"
//         }
//     },
// });



export default createTheme({
    palette: {
        primary: {
            main: '#35BE00',
            text: "#3A89CC",
            icon: "#ffffff",
            info: "#03a9f4"
        },
        secondary: {
            main: '#013447',
            text: '#000000',
            text2: '#90a4ae',
            text3: '#808080',
        },
        primaryText: {
            main: '#000000',
        },
        secondaryText: {
            main: '#90a4ae',
        },
        heading: {
            main: '#013447',
        },
        primaryBox: {
            main: '#F5F7F6',
        },
        secondaryBox: {
            main: '#ffffff',
        },
        primaryHeader: {
            main: '#ffffff',
        },
        secondaryHeader: {
            main: '#e3e3e3',
        },
        screenBackground: {
            main: "#F5F7F6"
        }
    },
});