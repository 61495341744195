import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import APIGQL from "./graphql";

export const GetSchadAwards = () => {
    const { data, loading } = useQuery(APIGQL.ApiGetSchadAwards, {
        fetchPolicy: "network-only",
    })
    const schadsData = data?.schadsAwards?.data;   

    return [schadsData, loading]
}