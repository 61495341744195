import { Grid, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NDISClaimList } from "./ndis_claim";
import { OrganizationClaim, SelfClaim } from "./org_and_self_claim";
import { useSelector } from "react-redux";

export const ClaimProcess = ({ screenWidth }) => {
    const [claimType, setClaimType] = useState([
        {
            title: "Select Claim Type",
            width: screenWidth,
            value: "NDIS Funded",
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Organization Funded",
                    selected: true
                },
                {
                    title: "NDIS Funded",
                    selected: false
                },
                {
                    title: "Self Funded",
                    selected: false
                },
            ]
        },
    ]);
    const [selectedType, setSelectedType] = useState("NDIS");
    const [selfFunding, setSefFunding] = useState([])
    const [ndisFunded, setNdisFunded] = useState([])
    const [organizationFunded, setOrganizationFunded] = useState([])

    const _completed_shifts = useSelector((state) => state._payment_processing_reducer.completed_shifts);

    const groupById = (data) => {
        // Grouping objects by their id using reduce
        const grouped = data.reduce((acc, obj) => {
            // If the id is not already a key in the accumulator, create a new array for it
            const serv_id = obj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.id
            const id = obj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.client_detail_id?.data?.id;
            // Create a unique key combining id and serviceId
            const uniqueKey = `${id}-${serv_id}`;
            if (!acc[uniqueKey] ) {
                acc[uniqueKey] = [];
            }
            // Push the object into the array for this id
            acc[uniqueKey].push(obj);
            return acc;
        }, {});

        // Convert the grouped object into an array of arrays
        return Object.values(grouped);
    }

    useEffect(() => {
        if (_completed_shifts) {
            const copy_self = [...selfFunding];
            const copy_ndis = [...ndisFunded];
            const copy_org = [...organizationFunded];

            for (const data of _completed_shifts) {
                const fSource = data?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.funding_source;
                if (fSource?.toLowerCase() === "self funded") {
                    copy_self.push(data);
                }
                if (fSource?.toLowerCase() === "ndis funded") {
                    copy_ndis.push(data);
                }
                if (fSource?.toLowerCase() === "organization funded") {
                    copy_org.push(data);
                }
            }

            if (copy_self.length) {
                const modified = groupById(copy_self);
                // console.log("copy_self", modified);
                setSefFunding(modified);
            }

            if (copy_ndis.length) {
                const modified = groupById(copy_ndis);
                // console.log("copy_ndis", modified);
                setNdisFunded(modified);
            }

            if (copy_org.length) {
                const modified = groupById(copy_org);
                // console.log("copy_org", modified);
                setOrganizationFunded(modified);
            }
        }
    }, [_completed_shifts])

    return (
        <Grid container sx={{ my: 2, marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, }}>
            <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                {claimType.map((v, i) => {
                    return (
                        <TextField
                            select={true}
                            label={v.title}
                            htmlFor={v.title}
                            key={i}
                            required={v.required}
                            sx={{ m: 2, width: v.width }}
                            value={v.value}
                            onChange={(e) => {
                                const copy = [...claimType];
                                copy[i].value = e.target.value;
                                setClaimType(copy)
                                setSelectedType(e.target.value)
                            }}
                        >
                            {v?.options?.map((option) => (
                                <MenuItem key={option.title} value={option.title}>
                                    {option.title}
                                </MenuItem>
                            ))}
                        </TextField>
                    )
                })}
            </Grid>
            <Grid item={true} xs={12} sm={12} md={11.6} lg={11.6}>
                {selectedType.includes("NDIS") && <NDISClaimList ndisFunded={ndisFunded} />}
                {selectedType.includes("Organization") && <OrganizationClaim organizationFunded={organizationFunded} />}
                {selectedType.includes("Self") && <SelfClaim selfFunding={selfFunding} />}
            </Grid>
        </Grid>
    )
}