import axios from "axios";
import { API, AUTH_TOKEN } from "../config/constant";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import APIGQL from "./graphql";


export const AuthService = {
    login: async (email, password) => {

        const headers = {
            'Authorization': "Bearer " + AUTH_TOKEN,
        }
        const response = await axios.post(`${API}/auth/local`, {
            identifier: email,
            password: password,
        });

        localStorage.setItem('user', JSON.stringify(response.data.user));
        localStorage.setItem('jwt', response.data.jwt);

        return response.data;
    },
    logout: () => {
        localStorage.removeItem('user');
        localStorage.removeItem('jwt');
    },
    getCurrentUser: () => {
        return JSON.parse(localStorage.getItem('user'));
    },
    getToken: () => {
        return localStorage.getItem('jwt');
    }
};

export const Authentication = () => {
    const [loginNewUser, { data, error }] = useMutation(APIGQL.ApiLogin);
    const newData = data?.login;
    if (error) {
        return error;
    }

    return [newData, loginNewUser];
}

export const FilterUserById = () => {
    const [userDetails, { loading, error }] = useLazyQuery(APIGQL.ApiUserDetails, {
        fetchPolicy: 'network-only'
    })
    return [userDetails, loading, error]
}
