import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectComp({ options, label, initialValue, handleChange }) {
    return (
        <Box sx={{ minWidth: 300 }}>
            <FormControl fullWidth>
                <InputLabel>{label}</InputLabel>
                <Select                    
                    value={initialValue ? initialValue.id : ''}
                    label={label}
                    onChange={handleChange}
                >
                    {
                        options.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.label}
                            </MenuItem>
                        ))
                    }

                </Select>
            </FormControl>
        </Box>
    );
}
