import { useLazyQuery } from "@apollo/client";
import APIGQL from "./graphql";


export const GetStaffList = () => {
    const [staffList, { loading, error }] = useLazyQuery(APIGQL.ApiGetStaffDetailsList, {
        fetchPolicy: "network-only"
    })
    return [staffList, loading, error];
}

export const PreferenceForStaffWorkerById = () => {
    const [prefById, { loading, error }] = useLazyQuery(APIGQL.ApiPreferenceForStaffWorkerById, {
        fetchPolicy: "network-only"
    })
    return [prefById, loading, error];
}

export const VisitDaysByServiceId = () => {
    const [visitById, { loading, error }] = useLazyQuery(APIGQL.ApiVisitDaysByServiceId, {
        fetchPolicy: "network-only"
    })
    return [visitById, loading, error];
}

export const ServiceShiftFilters = () => {
    const [ShiftsById, { loading }] = useLazyQuery(APIGQL.ApiServiceShiftFilters, {
        fetchPolicy: "network-only"
    })
    return [ShiftsById, loading];
}

export const WorkingDaysByFilters = () => {
    const [WorkingDay, { loading }] = useLazyQuery(APIGQL.ApiWorkingDaysByFilters, {
        fetchPolicy: "network-only"
    })
    return [WorkingDay, loading];
}