import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Alert, AlertTitle, Box, Button, CircularProgress, Grid, Paper, Slide, Snackbar, Stack, Step, StepConnector, StepContent, StepLabel, Stepper, TextField, Tooltip, Typography, stepConnectorClasses } from "@mui/material";
import PropTypes from 'prop-types';
import LocalPharmacyRoundedIcon from '@mui/icons-material/LocalPharmacyRounded';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import AirlineSeatIndividualSuiteIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
// import IndividualRiskAssessment from "../form_screens/individual_risk-assessment";
// import HomeRiskAssessment from "../form_screens/home_risk-assessment";
// import SafteyPlans from "../form_screens/safety_plans";
// import NewClients from "../form_screens/new_client";
// import MedicalHistoryForm from "../form_screens/medical_history_form";
// import { CustomModalDraftList } from "./screen/custom_modal";
import { useSelector, useDispatch } from "react-redux";
import { client_id, client_onboard_data_lists, selected_draft } from "../../middleware/reducers/client_onboarding_reducer";
import { DynamicFrontendContent, DesignFrontendModules } from "../../services/design_frontend";
import { DynamicFormScreen } from "./screen/dynamic_form_screen";
import { useNavigate } from "react-router-dom";
import NotificationModal from "../../components/common/NotificationModal";
import { Formik } from 'formik';
import * as Yup from 'yup';


import { SendOnboardingLinkEmail } from "../../services/client_service";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 24,
        left: 'calc(-50% + 26px)',
        right: 'calc(50% + 26px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#cccccc',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: "#6ECA35",
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor: "rgba(0,0,0,0.5)",
    }),

}));

const ColorlibStepIcon = (props) => {
    const { active, completed, className } = props;

    const icons = {
        1: <MedicalInformationIcon />,
        2: <LocalPharmacyRoundedIcon />,
        3: <AirlineSeatIndividualSuiteIcon />,
        4: <AddHomeWorkIcon />,
        5: <HealthAndSafetyIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const NewClientManagement = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [openDraft, setOpenDraft] = useState(false);
    const _draft_list = useSelector((state) => state._client_onboarding_module.draftList);
    const [clientDraftList, setClientDraftList] = useState(_draft_list);
    const [dynamicDesignData, loading] = DynamicFrontendContent();
    const dynamicModules = DesignFrontendModules()
    const _saving_list = useSelector((state) => state._client_onboarding_module.client_onboard_data_list);

    const [steps, setSteps] = useState([]);

    const SlideTransition = (props) => {
        return <Slide {...props} direction="left" />;
    }

    const [snacks, setSnack] = useState({
        open: false,
        Transition: SlideTransition,
        vertical: 'top',
        horizontal: 'right',
    });

    const [errorSnackMessage, setErrorSnackMessage] = useState({
        title: "",
        message: "",
        severity: "",
        variant: "filled"
    });

    const [showGuardianEmailFormModal, setShowGuardianEmailFormModal] = useState(false);
    const [createGuardianFormEmailList, createGuardianFormEmailListLoading, createGuardianFormEmailListError] = SendOnboardingLinkEmail();

    const handleCloseSnacks = () => {
        setSnack({
            ...snacks,
            open: false,
        });
    };

    const handleSnacksApper = (title, message, severity) => {
        setSnack({
            ...snacks,
            open: true,
        });
        // / // // // display message
        setErrorSnackMessage({
            ...errorSnackMessage,
            title,
            message,
            severity
        })
    };

    useEffect(() => {
        try {
            if (dynamicModules?.length && dynamicDesignData?.length) {
                const copy = [...steps];
                let horizontalNextStepIndex = 0;

                for (const key in dynamicModules) {
                    if (dynamicModules[key]?.attributes?.active && dynamicModules[key]?.attributes?.category === "Client") {
                        const title = dynamicModules[key]?.attributes?.title;
                        const matchCategory = dynamicDesignData.filter((e) => e.attributes?.design_frontend_module_id?.data?.attributes?.title === title)

                        if (matchCategory[horizontalNextStepIndex + 1]) {
                            horizontalNextStepIndex++
                        }
                        else {
                            horizontalNextStepIndex = -1;
                        }

                        copy.push({
                            label: title,
                            screen: <DynamicFormScreen
                                category={matchCategory}
                                horizontalNextStepIndex={horizontalNextStepIndex}
                                setNextHorizontalStepActive={handleNext}
                            />
                        })
                    }
                }
                setSteps(copy)
            }
        } catch (error) {
            console.log(error)
        }
    }, [dynamicModules, dynamicDesignData])

    const handleNext = (index) => {
        setActiveStep(index);
    };

    const handleModal = () => {
        setOpenDraft(false);
    }

    useEffect(() => {
        if (clientDraftList?.length) {
            setOpenDraft(true);
            dispatch(selected_draft(null));
        } else {
            setOpenDraft(false);
        }
    }, [])

    const saveAndSubmitStaffInfo = () => {
        try {
            if (_saving_list) {
                dispatch(client_onboard_data_lists(null));
                dispatch(client_id(null));
                navigate("/client-dashboard", {
                    state: {
                        activeOption: 0
                    }
                });
                /// add a logic to display success message
            } else {
                throw "data list empty or something missing"
            }
        } catch (error) {
            console.log("ooppssss!..", error)
        }
    }

    const guardianEmailForm = <>
        <Box>
            <Typography color={"primary"} sx={{ fontFamily: "Roboto-Bold", fontSize: "1.5em" }}>
                Send Onboarding Form Link
            </Typography>
        </Box>
        <Box>
            <Formik
                initialValues={{
                    email_recipient: '',
                    form_url: `${window.location.origin}/guardian-form`,
                }}
                validationSchema={
                    Yup.object({
                        email_recipient: Yup.string()
                            .email('Enter a valid email')
                            .required('Email is required'),
                    })
                }
                onSubmit={async (values) => {
                    try {
                        const response = await createGuardianFormEmailList({
                            variables: values
                        })
                        if (response?.data) {
                            handleSnacksApper("Onboarding Form", "Email has been sent successfully", "success")
                            setShowGuardianEmailFormModal(false)
                        }

                    } catch (error) {
                        let message = "";
                        if (error?.message || createGuardianFormEmailListError) {
                            const errors = createGuardianFormEmailListError;

                            if (errors?.networkError) {
                                message = "Network error occurred. Please try again later"
                            }
                            if (errors?.graphQLErrors) {
                                message = errors.graphQLErrors[0]?.message + "or fields are empty.!"
                            }
                            if (error?.message) {
                                message = error?.message;
                            }
                            handleSnacksApper('Onboarding Form', message, "error")
                        }
                    }

                }}
            >
                {
                    ({ handleChange, values, handleSubmit, handleBlur, touched, errors }) => {
                        return <form onSubmit={handleSubmit}>
                            <Grid container sx={{ display: 'flex', gap: '15px', margin: '10px 0px' }}>
                                <Grid item={true} xs={12}>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            id="email_recipient"
                                            name="email_recipient"
                                            label="Email"
                                            value={values.email_recipient}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.email_recipient && Boolean(errors.email_recipient)}
                                            helperText={touched.email_recipient && errors.email_recipient}
                                            sx={{ flex: 1 }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            id="form_url"
                                            name="form_url"
                                            label="Form URL"
                                            disabled
                                            value={values.form_url}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.form_url && Boolean(errors.form_url)}
                                            helperText={touched.form_url && errors.form_url}
                                            sx={{ flex: 1 }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item={true} xs={12}>
                                    <Box>
                                        <Button color="primary" variant="contained" fullWidth type="submit">
                                            Submit
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    }
                }
            </Formik>
        </Box>
    </>


    return (
        <Box>
            <Snackbar open={snacks.open} autoHideDuration={5000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
                <Alert
                    onClose={handleCloseSnacks}
                    severity={errorSnackMessage.severity}
                    variant={errorSnackMessage.variant}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{errorSnackMessage.title}</AlertTitle>
                    {errorSnackMessage.message}
                </Alert>
            </Snackbar>
            <NotificationModal
                openModalVariable={showGuardianEmailFormModal}
                setOpenModalVariable={setShowGuardianEmailFormModal}
                content={guardianEmailForm}
            />
            <Grid container sx={{ justifyContent: "center", marginTop: { xs: "10%", sm: "2%", lg: "1%" }, marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, overflowY: "hidden" }} >
                {loading ? (
                    <Box
                        sx={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                        }}
                    >
                        <CircularProgress color="secondary" size={50} />
                    </Box>
                ) : null}
                {steps?.length ? (
                    <Grid item={true} xs={12} sm={12} lg={11}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                            <Button color={"primary"}
                                variant="outlined"
                                sx={{
                                    bgcolor: "#ffffff",
                                    fontFamily: "Roboto-Medium",
                                    textTransform: "capitalize",
                                    color: "#000000",
                                    border: "2px solid #6ECA35",
                                    ":hover": {
                                        border: "2px solid #6ECA35",
                                    }
                                }}
                                onClick={() => {
                                    setShowGuardianEmailFormModal(true)
                                }}
                            >
                                Send Link
                            </Button>
                        </Grid>
                        <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
                            {steps?.map((v, i) => (
                                <Step key={i} sx={{ padding: { xs: 0 } }}>
                                    <StepLabel sx={{ cursor: "pointer", }} onClick={() => handleNext(i)} StepIconComponent={ColorlibStepIcon}>
                                        <span sx={{ color: activeStep === i ? "currentColor" : "#cccccc", fontFamily: "Roboto-Regular", fontSize: "12px !important" }}>
                                            {v.label}
                                        </span>
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Grid item={true} sx={{ mt: 1, py: 1, display: 'flex', justifyContent: "center", }}>
                            {steps[activeStep]?.screen}
                        </Grid>
                        <Grid item={true} xs={11} sm={12} md={12} lg={12} sx={{ display: "flex", justifyContent: 'flex-end' }}>
                            <Stack direction={"row"} spacing={2}>
                                {/* <Tooltip title="Can Change After Save" placement="left">
                                <Button color={"primary"}
                                    variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Save
                                </Button>
                            </Tooltip> */}
                                <Tooltip title="Can Not Change After Submit" placement="right">
                                    <Button color={"primary"} onClick={() => saveAndSubmitStaffInfo()}
                                        variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                        Submit
                                    </Button>
                                </Tooltip>
                            </Stack>
                        </Grid>
                        {/* <CustomModalDraftList openDraft={openDraft} handleModal={handleModal} clientDraftList={clientDraftList} /> */}
                    </Grid>
                ) : null}
                {!dynamicDesignData?.length && !loading ? (
                    <Grid item={true} xs={12} sm={12} lg={12} sx={{ display: "flex", height: "calc(85vh)", justifyContent: "center", alignItems: "center", }}>
                        <Typography>
                            No Data
                        </Typography>
                    </Grid>
                ) : null}
            </Grid >
        </Box>
    )
}

export default NewClientManagement;