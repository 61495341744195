import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box, Button, CircularProgress, Container, Grid, Step, StepConnector, StepLabel, Stepper, Typography, stepConnectorClasses } from "@mui/material";
import PropTypes from 'prop-types';
import LocalPharmacyRoundedIcon from '@mui/icons-material/LocalPharmacyRounded';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import AirlineSeatIndividualSuiteIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import { useSelector } from "react-redux";
import { DynamicFrontendContent, DesignFrontendModules } from "../../../services/design_frontend";
import { DynamicFormScreen } from "./dynamic_form_screen";
import NotificationModal from "../../../components/common/NotificationModal";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 24,
    left: 'calc(-50% + 26px)',
    right: 'calc(50% + 26px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#cccccc',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: "#6ECA35",
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundColor: "rgba(0,0,0,0.5)",
  }),

}));

const ColorlibStepIcon = (props) => {
  const { active, completed, className } = props;

  const icons = {
    1: <MedicalInformationIcon />,
    2: <LocalPharmacyRoundedIcon />,
    3: <AirlineSeatIndividualSuiteIcon />,
    4: <AddHomeWorkIcon />,
    5: <HealthAndSafetyIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const GuardianForm = () => {
  const [activeStep, setActiveStep] = useState(0);  
  const _draft_list = useSelector((state) => state._client_onboarding_module.draftList);  
  const [dynamicDesignData, loading] = DynamicFrontendContent();
  const dynamicModules = DesignFrontendModules()  
  const [steps, setSteps] = useState([]);

  const [openNotificatonModalVariable, setOpenNotificatonModalVariable] = useState(false);

  const guardianFormInputCategories = (title) => {
    let categories = [
      'Basic Information',
      'Contact Information',
      'Emergency Contact Information',
      'Support Worker Preferences',
      'Client Goals',
      'Medication',
      'Medical History',
      'Medical History Details',
    ];

    return categories.includes(title)
  }

  useEffect(() => {
    try {
      if (dynamicModules?.length && dynamicDesignData?.length) {
        const copy = [...steps];
        let horizontalNextStepIndex = 0;
        for (const key in dynamicModules) {

          if (dynamicModules[key]?.attributes?.active && dynamicModules[key]?.attributes?.category === "Client") {
            const title = dynamicModules[key]?.attributes?.title;

            const matchCategory = dynamicDesignData.filter((e) => {
              const isTitleMatch = e.attributes?.design_frontend_module_id?.data?.attributes?.title === title;
              const frontend_category_title = e.attributes?.design_frontend_category_id?.data?.attributes?.title;
              return isTitleMatch && guardianFormInputCategories(frontend_category_title);
            })


            if (matchCategory[horizontalNextStepIndex + 1]) {
              horizontalNextStepIndex++
            }
            else {
              horizontalNextStepIndex = -1;
            }

            if (matchCategory.length > 0) {
              copy.push({
                label: title,
                screen: <DynamicFormScreen
                  category={matchCategory}
                  horizontalNextStepIndex={horizontalNextStepIndex}
                  setNextHorizontalStepActive={handleNext}
                />
              })
            }
          }
        }
        setSteps(copy)
        setOpenNotificatonModalVariable(true)
      }
    } catch (error) {
      console.error(error)
    }
  }, [dynamicModules, dynamicDesignData])

  const handleNext = (index) => {
    setActiveStep(index);
  }; 

  const guardianFormAcceptanceModalContent = <>
    <Grid container>
      <Grid item={true} xs={12}>
        <Box>
          <Typography>
            By submitting this form, you acknowledge and consent that the information provided will be used for the purposes of delivering and managing services in compliance with the National Disability Insurance Scheme (NDIS) guidelines and the Australian Privacy Act 1988.<br></br>Your personal data will be securely stored and handled in accordance with applicable privacy regulations to ensure confidentiality and protect your rights.<br></br>This information may be shared with relevant third parties, such as healthcare providers or staff workers, only when necessary to provide the services requested and ensure your well-being.
          </Typography>
        </Box>
        <Box sx={{ margin: '10px 0px', display: 'flex', justifyContent: 'center' }}>
          <Button color={"primary"} onClick={() => {
            setOpenNotificatonModalVariable(false)
          }}
            variant="outlined"
            sx={{
              bgcolor: "#ffffff",
              fontFamily: "Roboto-Medium",
              textTransform: "capitalize",
              color: "#000000",
              border: "2px solid #6ECA35",
              ":hover": {
                border: "2px solid #6ECA35",
              }
            }}>
            Close
          </Button>
        </Box>
      </Grid>
    </Grid>
  </>;
  return (
    <>
      <NotificationModal
        content={guardianFormAcceptanceModalContent}
        openModalVariable={openNotificatonModalVariable}
        setOpenModalVariable={setOpenNotificatonModalVariable}
      />
      <Box sx={{ margin: '25px' }}>
        <Container sx={{
          minWidth: { xl: '1400px', lg: '1000px', md: '850px', sm: '550px' }
        }}>
          <Grid container sx={{
            justifyContent: "center",
            marginTop: {
              xs: "10%",
              sm: "2%",
              lg: "1%"
            },
            overflowY: "hidden"
          }} >
            <Box>
              <img style={{ width: 230, height: 55, }} alt="Logo" src="/assets/icons/logo-landscap.png" />
            </Box>
            {loading ? (
              <Box
                sx={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CircularProgress color="secondary" size={50} />
              </Box>
            ) : null}
            {steps?.length ? (
              <Box sx={{ margin: '25px 0px 0px 0px', width:'100%' }}>
                <Grid item={true} xs={12} sm={12} lg={12}>
                  <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
                    {steps?.map((v, i) => (
                      <Step key={i} sx={{ padding: { xs: 0 } }}>
                        <StepLabel sx={{ cursor: "pointer", }} onClick={() => handleNext(i)} StepIconComponent={ColorlibStepIcon}>
                          <span sx={{ color: activeStep === i ? "currentColor" : "#cccccc", fontFamily: "Roboto-Regular", fontSize: "12px !important" }}>
                            {v.label}
                          </span>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <Grid item={true} sx={{ mt: 1, py: 1, display: 'flex', justifyContent: "center", }}>
                    {steps[activeStep]?.screen}
                  </Grid>                 
                </Grid>
              </Box>
            ) : null}
            {!dynamicDesignData?.length && !loading ? (
              <Grid item={true} xs={12} sm={12} lg={12} sx={{ display: "flex", height: "calc(85vh)", justifyContent: "center", alignItems: "center", }}>
                <Typography>
                  No Data
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default GuardianForm;