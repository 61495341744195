import React from "react";
import { ResponsiveAppBar } from "../../components/header";
import DashboardOptions from "../../components/dashboard_options";

const Dashboard = () => {
    return (
        <div style={{ height: "calc(100vh)",backgroundColor: "#F5F7F6" }}>
            <ResponsiveAppBar />
            <DashboardOptions />
        </div>
    )
}

export default Dashboard;