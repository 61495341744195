import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import SpinnerBackdrop from './SpinnerBackdrop';

function DragAndDrop({ setFileData, setFileError, showSpinner }) {
  const [files, setFiles] = useState([]);
  const [error, setErrorMessage] = useState(null);

  const onDrop = (acceptedFiles, rejectedFiles) => {
    setErrorMessage(null); // Reset error message
    setFileError(false);
    if (rejectedFiles.length > 0) {
      setErrorMessage("Please select a valid .xlsx file.");
      setFileError(true)
      return;
    }

    const file = acceptedFiles[0];
    setFiles([file]);
    setFileData(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
    onDropRejected: () => {
      setErrorMessage("Please select a valid .xlsx file.");
      setFileError(true)
    },
  });

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    }}>
      <Box sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        border: '1px dashed',
        borderRadius: '10px',
      }}>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <p><u>Click</u> or Drag and Drop to Upload Files</p>
          <em>(Only *.xlsx files will be accepted)</em>
        </div>
      </Box>
      <SpinnerBackdrop 
        showSpinner={showSpinner}
      />
      {error && (
        <Typography color="error" sx={{ marginTop: 2 }}>
          {error}
        </Typography>
      )}

      <Box sx={{ margin: '10px 0px' }}>
        {files.map(file => (
          <div key={file.name}>{file.name}</div>
        ))}
      </Box>
    </Box>
  );
}

export default DragAndDrop;
