import React, { useState } from "react";
import { HeaderWithLogo } from "../../components/header";
import LoginComponent from "../../components/login";
import { Box } from "@mui/material";


const Login = () => {
    return (
        <Box sx={{ backgroundColor: "#F5F7F6" }} className="login-page" >
            <HeaderWithLogo />
            <LoginComponent />
        </Box>
    )
}

export default Login;